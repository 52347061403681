import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface HttpOptions {
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
	context?: HttpContext;
	observe?: 'body';
	params?:
		| HttpParams
		| {
				[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
		  };
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	constructor(private http: HttpClient) {}

	get(url: string, options?: HttpOptions): Observable<any> {
		return this.http.get<any>(url, options);
	}

	post(url: string, body: any, options?: HttpOptions): Observable<any> {
		return this.http.post<any>(url, body, options);
	}

	delete(url: string, options?: any): Observable<any> {
		return this.http.delete<any>(url, options);
	}

	patch(url: string, body: any, options?: HttpOptions): Observable<any> {
		return this.http.patch<any>(url, body, options);
	}

	put(url: string, body: any, options?: HttpOptions): Observable<any> {
		return this.http.put<any>(url, body, options);
	}
}
