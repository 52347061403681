import { ReconciliationReport, ReconData } from '../../models/reconciliation.model';

export class InitReconciliationState {
	static readonly type: string = '[Reconciliation] InitReconciliationState';
}

export class GetReconReports {
	static readonly type: string = '[Reconciliation] GetReconReports';
}

export class AddReconDataToReconReport {
	static readonly type: string = '[Reconciliation] AddReconDataToReconReport';
	constructor(
		public reconReport: ReconciliationReport,
		public reconData: ReconData
	) {}
}

export class CreateReconReport {
	static readonly type: string = '[Reconciliation] CreateReconReport';
	constructor(public reconReport: ReconciliationReport) {}
}

export class UpdateReconReport {
	static readonly type: string = '[Reconciliation] UpdateReconReport';
	constructor(public reconReport: ReconciliationReport) {}
}

export class DuplicateReconReport {
	static readonly type: string = '[Reconciliation] DuplicateReconReport';
	constructor(
		public reconId: string,
		public newReconReportName: string
	) {}
}

export class DeleteReconReport {
	static readonly type: string = '[Reconciliation] DeleteReconReport';
	constructor(public reconId: string) {}
}

export class ClearReconciliationState {
	static readonly type: string = '[Reconciliation] ClearReconciliationState';
}

export class RetryLoadReconciliationData {
	static readonly type: string = '[Reconciliation] RetryLazyLoadReportsData';
}

export class ClearLastCreatedReconId {
	static readonly type: string = '[Reconciliation] ClearLastCreatedReconciliationId';
}

export class SetLastCreatedReconId {
	static readonly type: string = '[Reconciliation] SetLastCreatedReconciliationId';
	constructor(public id: string) {}
}
