import { InjectionToken } from '@angular/core';
import { TrxnSearchData } from './transaction-search-parameter.model';

export interface TrxnTabData {
	searchData: TrxnSearchData;
	sortFilter: any;
	positionType: string;
	currentWindow: any;
}

export const TAB_DATA = new InjectionToken<TrxnTabData>('TAB_DATA');

export const TABS = {};
