import moment from 'moment';

export function getPeriods(
	endDate: moment.Moment,
	startDateParam: moment.Moment,
	cadence: string,
	trimWeekends: boolean,
	returnCompletePeriods?: boolean
): number {
	const startDate = startDateParam.clone();
	if (cadence === 'daily') {
		if (trimWeekends) {
			let dayCheck = startDate;
			let diff = 0;
			while (dayCheck <= endDate) {
				if (dayCheck.isoWeekday() !== 6 && dayCheck.isoWeekday() !== 7) {
					diff += 1;
				}
				if (returnCompletePeriods) {
					if (dayCheck.isoWeekday() === 6 && dayCheck.isoWeekday() === 7) {
						diff -= 1;
					}
				}
				dayCheck = dayCheck.add(1, 'days');
			}
			return diff || 1;
		}
		const dayDiff = endDate.diff(startDate, 'days') + 1;
		return dayDiff || 1;
	} else if (cadence === 'weekly') {
		const weekDiff = endDate.diff(startDate, 'weeks');
		return weekDiff || 1;
	} else if (cadence === 'monthly') {
		const monthDiff = endDate.diff(startDate.startOf('month'), 'months');
		return monthDiff || 1;
	} else if (cadence === 'quarterly') {
		const quarterDiff = endDate.diff(startDate.startOf('quarter'), 'quarters');
		return quarterDiff || 1;
	}
}

export function getStartDate(endDate: Date, cadence: string, periods: number, trimWeekends: boolean): Date {
	const startDate = new Date(endDate);
	if (cadence === 'daily' && trimWeekends) {
		while (startDate.getDay() === 6 || startDate.getDay() === 0) {
			startDate.setDate(startDate.getDate() - 1);
		}
	}
	for (let i = 0; i < periods; i++) {
		if (cadence === 'daily' && i < periods - 1) {
			startDate.setDate(startDate.getDate() - 1);
			while ((startDate.getDay() === 6 || startDate.getDay() === 0) && trimWeekends) {
				startDate.setDate(startDate.getDate() - 1);
			}
		} else if (cadence === 'weekly') {
			startDate.setDate(startDate.getDate() - 7);
		} else if (cadence === 'monthly') {
			startDate.setMonth(startDate.getMonth() - 1);
		} else if (cadence === 'quarterly') {
			startDate.setMonth(startDate.getMonth() - 3);
		}
	}
	return startDate;
}

export function getEndDate(startDateMoment: moment.Moment, cadence: string, periods: number, trimWeekends: boolean) {
	const startDate = startDateMoment.toDate();
	if (cadence === 'daily' && trimWeekends) {
		while (startDate.getDay() === 6 || startDate.getDay() === 0) {
			startDate.setDate(startDate.getDate() + 1);
		}
	}
	for (let i = 0; i < periods; i++) {
		if (cadence === 'daily' && i < periods - 1) {
			startDate.setDate(startDate.getDate() + 1);
			while ((startDate.getDay() === 6 || startDate.getDay() === 0) && trimWeekends) {
				startDate.setDate(startDate.getDate() + 1);
			}
		} else if (cadence === 'weekly') {
			startDate.setDate(startDate.getDate() + 7);
		} else if (cadence === 'monthly') {
			startDate.setMonth(startDate.getMonth() + 1);
		} else if (cadence === 'quarterly') {
			startDate.setMonth(startDate.getMonth() + 3);
		}
	}
	const monthString = (startDate.getUTCMonth() + 1 + '').padStart(2, '0');
	const dayString = (startDate.getUTCDate() + '').padStart(2, '0');
	const dateString = `${startDate.getUTCFullYear()}-${monthString}-${dayString}`;
	return dateString;
}
