import { AccountFilterType } from 'src/app/features/reports/models/account-filters.model';
import { TransactionFilterType } from 'src/app/shared/models/transaction-filters.model';

export class InitFilterObjectState {
	static readonly type: string = '[FilterObject] InitFilterObjectState';
}

export class ClearFilterObjectState {
	static readonly type: string = '[FilterObject] ClearFilterObjectState';
}

export class ResetFilterObjectState {
	static readonly type: string = '[FilterObject] ResetFilterObjectState';
}

export class LoadAllFilters {
	static readonly type: string = '[FilterObject] LoadAllFilters';
	constructor(public forceRefresh?: boolean) {}
}

export class UpdateFilter {
	static readonly type: string = '[FilterObject] UpdateFilter';
	constructor(
		public callType: string,
		public filterType: TransactionFilterType | AccountFilterType
	) {}
}
