import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
	GetTemplatesResponse,
	InstitutionFieldsResponse,
	InternationalPaymentsCountryResponse,
	Template,
	TemplateToCreate,
	TemplateToDelete,
} from '../../models/template.model';

@Injectable({
	providedIn: 'root',
})
export class TemplatesService {
	constructor(private httpService: HttpService) {}

	getTemplates(): Observable<GetTemplatesResponse> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/organization/templates`);
	}

	getInstitutionFields(): Observable<InstitutionFieldsResponse> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/v1/institutions`);
	}

	getInternationalPaymentsCountries(): Observable<InternationalPaymentsCountryResponse> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/v1/countries`);
	}

	createTemplate(body: TemplateToCreate): Observable<Template> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/organization/templates`, body);
	}

	updateTemplate(body: Template): Observable<Template> {
		return this.httpService.patch(`${environment.workspaceAPI('payments')}/organization/templates`, body);
	}

	deleteTemplate(body: TemplateToDelete): Observable<Template> {
		return this.httpService.delete(`${environment.workspaceAPI('payments')}/organization/templates`, { body: body });
	}

	getTemplateById(templateId: string): Observable<GetTemplatesResponse> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/organization/templates?id=${templateId}`);
	}
}
