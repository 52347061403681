import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function tqlQueryValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const queryItemArray: FormArray = control.get('queryItems') as FormArray;
		const containsInvalidFormControl: boolean = !!queryItemArray.controls.find((formControl: FormControl) => formControl.status === 'INVALID');
		if (containsInvalidFormControl) {
			return {
				invalidQuery: false,
			};
		} else {
			return null;
		}
	};
}
