import * as Highcharts from 'highcharts';
import { DataSeries, DataSeriesPoint } from './graphs.models';

export class DataPoint extends Highcharts.Point {
	data: any;
}

export class ChartPointerEvent extends PointerEvent {
	point: Highcharts.Point;
}

export abstract class HighChartsBuilder<T> {
	constructor(public options: T) {}

	abstract buildOptions(): Highcharts.Options;
}

export interface LineChartBuilderOptions {
	tooltipsEnabled: boolean;
	dataSeries: DataSeries[];
	legendsEnabled?: boolean;
	labels?: string[];
	pointFormatter?: Highcharts.FormatterCallbackFunction<Highcharts.Point>;
}

export interface ColumnChartBuilderOptions {
	dataSeries: DataSeries[];
	showTitles: boolean;
	maxValueRef: number;
	showValueLabels: boolean;
	barSize: 'small' | 'medium';
	labels?: string[];
	style?: 'detailed';
}

export interface DynamicChartBuilderOptions {
	config: Highcharts.Options;
}

export interface PercentageBarChartBuilderOptions {
	percentageBarValue: number;
}

export class LineChartBuilder extends HighChartsBuilder<LineChartBuilderOptions> {
	constructor(options: LineChartBuilderOptions) {
		super(options);
	}

	buildOptions(): Highcharts.Options {
		return {
			chart: {
				type: 'spline',
				marginTop: 40,
				margin: this.options.legendsEnabled ? undefined : [8, 8, 8, 8],
				spacingTop: 0,
				style: {
					fontFamily: 'AkkuratLLWeb',
					fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
					fontSize: '16px',
				},
			},
			tooltip: {
				enabled: this.options.tooltipsEnabled,
				backgroundColor: null,
				borderWidth: 0,
				shadow: false,
				useHTML: true,
				style: {
					padding: '0px',
					color: '#FFFFFF',
					fontFamily: 'AkkuratLLWeb',
					fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
					fontSize: '16px',
				},
			},
			exporting: {
				enabled: false,
			},
			legend: {
				enabled: !!this.options.legendsEnabled,
			},
			title: null,
			credits: {
				enabled: false,
			},
			xAxis: {
				tickWidth: this.options.legendsEnabled ? 1 : 0,
				lineWidth: this.options.legendsEnabled ? 1 : 0,
				tickmarkPlacement: 'on',
				crosshair: this.options.tooltipsEnabled
					? {
							width: 1,
							color: 'rgba(0, 0, 0, 0.56)',
					  }
					: false,
				labels: {
					enabled: !!this.options.labels,
				},
				categories: this.options.labels,
			},
			yAxis: {
				title: null,
				gridLineWidth: this.options.legendsEnabled ? 1 : 0,
				labels: {
					enabled: this.options.legendsEnabled,
				},
			},
			plotOptions: {
				series: {
					marker: {
						enabled: false,
					},
				},
			},
			series: this.options.dataSeries.map(
				(s: DataSeries): Highcharts.SeriesOptionsType => ({
					type: 'line',
					name: s.name,
					color: s.color,
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled: false,
							},
						},
						fillColor: '#D5255A',
						lineWidth: 1,
						lineColor: 'white',
						radius: 6,
					},
					data: s.points.map((p: DataSeriesPoint) => ({
						y: p.y,
						custom: p.tooltip,
						marker: { enabled: p.displayMarker },
					})),
					tooltip: {
						headerFormat: null,
						pointFormatter: this.options.pointFormatter,
					},
				})
			),
		};
	}
}

export class ColumnChartBuilder extends HighChartsBuilder<ColumnChartBuilderOptions> {
	constructor(options: ColumnChartBuilderOptions) {
		super(options);
	}

	buildOptions(): Highcharts.Options {
		if (this.options.style === 'detailed') {
			return this.detailedChartOptions();
		}
		return this.plainChartOptions();
	}

	private detailedChartOptions(): Highcharts.Options {
		return {
			chart: {
				type: 'column',
				spacingTop: 20,
				style: {
					fontFamily: 'AkkuratLLWeb',
					fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
					fontSize: '16px',
				},
			},
			title: {
				text: '',
			},
			xAxis: [
				{
					tickWidth: 1,
					tickmarkPlacement: 'on',
					labels: {
						enabled: true,
						y: 25,
					},
					categories: this.options.labels,
				},
			],
			yAxis: {
				min: 0,
				labels: {
					enabled: true,
				},
				title: null,
			},
			tooltip: {
				enabled: false,
			},
			series: this.options.dataSeries.map(
				(s: DataSeries): Highcharts.SeriesOptionsType => ({
					type: 'column',
					name: s.name,
					data: s.points.map(p => ({ y: p.y })),
				})
			),
			exporting: {
				enabled: false,
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
		};
	}

	private plainChartOptions(): Highcharts.Options {
		const points = this.options.dataSeries[0].points;
		const labels: string[] = points.map((x: DataSeriesPoint) => x.title);
		const percentages = this.dataPointsToPercentages(points.map((x: DataSeriesPoint) => x.y));
		return {
			chart: {
				type: 'column',
				spacingTop: 20,
				margin: [0, 0, 0, 0],
				style: {
					fontFamily: 'AkkuratLLWeb',
					fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
					fontSize: '16px',
				},
			},
			title: {
				text: '',
			},
			xAxis: [
				{
					tickWidth: this.options.showTitles ? 1 : 0,
					tickmarkPlacement: 'on',
					lineWidth: 0,
					labels: {
						enabled: this.options.showTitles,
						y: 25,
					},
					categories: labels,
				},
				{
					// Top xAxis
					linkedTo: 0,
					tickWidth: 0,
					lineWidth: 0,
					opposite: true,
					labels: {
						enabled: this.options.showValueLabels,
					},
					categories: percentages.map((p: number) => p.toFixed(1).toString() + '%'),
				},
			],
			yAxis: {
				min: 0,
				labels: {
					enabled: false,
				},
				title: null,
				gridLineWidth: 0,
			},
			tooltip: {
				enabled: false,
			},
			plotOptions: {
				column: {
					stacking: 'percent',
					borderRadius: 2,
					pointWidth: this.barWidth,
				},
			},
			series: [
				{
					type: 'column',
					name: 'reference',
					color: '#F5F5F5',
					data: percentages.map((x: number) => 100 - parseFloat(x.toFixed(0))),
				},
				{
					type: 'column',
					name: 'values',
					color: '#0064FD',
					data: percentages,
				},
			],
			exporting: {
				enabled: false,
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
		};
	}

	private dataPointsToPercentages(dataPoints: number[]) {
		const maxPoint = this.options.maxValueRef === undefined ? Math.max(...dataPoints) : this.options.maxValueRef;
		const dataAsPercentages = dataPoints.map((value: number) => (value * 100) / maxPoint);
		return dataAsPercentages;
	}

	private get barWidth(): number {
		const sizeMap = {
			small: 13.9,
			medium: 24.2,
		};
		return sizeMap[this.options.barSize || 'medium'];
	}
}

export class PercentageBarChartBuilder extends HighChartsBuilder<PercentageBarChartBuilderOptions> {
	constructor(options: PercentageBarChartBuilderOptions) {
		super(options);
	}

	buildOptions(): Highcharts.Options {
		return {
			chart: {
				type: 'bar',
				margin: [0, 0, 0, 8],
				animation: false,
				style: {
					fontFamily: 'AkkuratLLWeb',
					fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
					fontSize: '16px',
				},
			},
			title: {
				text: '',
			},
			xAxis: [
				{
					tickWidth: 1,
					tickmarkPlacement: 'on',
					lineWidth: 0,
					labels: {
						enabled: false,
					},
				},
				{
					// Top xAxis
					linkedTo: 0,
					tickWidth: 0,
					lineWidth: 0,
					opposite: true,
					labels: {
						enabled: false,
					},
				},
			],
			yAxis: {
				min: 0,
				labels: {
					enabled: false,
				},
				title: null,
				gridLineWidth: 0,
			},
			tooltip: {
				enabled: false,
			},
			plotOptions: {
				column: {
					stacking: 'percent',
					borderRadius: 5,
				},
			},
			series: [
				{
					type: 'column',
					name: 'reference',
					color: '#F5F5F5',
					data: [100 - this.options.percentageBarValue],
					groupPadding: 0,
					pointPadding: 0,
				},
				{
					type: 'column',
					name: 'values',
					color: '#0064FD',
					data: [this.options.percentageBarValue],
					groupPadding: 0,
					pointPadding: 0,
				},
			],
			exporting: {
				enabled: false,
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
		};
	}
}

export class DynamicChartBuilder extends HighChartsBuilder<DynamicChartBuilderOptions> {
	constructor(options: DynamicChartBuilderOptions) {
		super(options);
	}

	buildOptions(): Highcharts.Options {
		return this.options.config;
	}
}

export type ChartBuilder = LineChartBuilder | ColumnChartBuilder | PercentageBarChartBuilder | DynamicChartBuilder;
