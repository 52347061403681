import { ConsentType } from '../../models/consent-management';

export class InitConsentManagementState {
	static readonly type: string = '[ConsentManagement] InitConsentManagementState';
}

export class GetConsentPartners {
	static readonly type: string = '[ConsentManagement] GetConsentPartners';
}

export class ClearConsentManagementState {
	static readonly type: string = '[ConsentManagement] ClearConsentManagementState';
}

export class GetPartnerById {
	static readonly type: string = '[ConsentManagement] GetPartnerById';
	constructor(public partnerId: string) {}
}

export class UpdatePartnerStatus {
	static readonly type: string = '[ConsentManagement] UpdatePartnerStatus';
	constructor(
		public partnerId: string,
		public consentStatus: ConsentType
	) {}
}
