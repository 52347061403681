export class InitUserConfigState {
	static readonly type: string = '[UserConfig] InitUserConfigState';
}

export class ResetUserConfigState {
	static readonly type: string = '[UserConfig] ResetUserConfigState';
}

export class GetUserConfig {
	static readonly type: string = '[UserConfig] GetUserConfig';
}

export class ClearUserConfigState {
	static readonly type: string = '[UserConfig] ClearUserConfigState';
}
