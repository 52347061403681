import { Currency, defaultCurrency } from 'src/app/shared/models/currency.model';
import { Cadence } from './cadence.model';
import { GroupByKey } from '@trovata/app/shared/utils/key-translator';
import { Rounding } from '@trovata/app/shared/models/rounding.model';
import { GenericOption } from '@trovata/app/shared/models/option.model';
import { ChartType } from '@trovata/app/shared/models/highcharts.model';
import { TQLPropertyKey } from '@trovata/app/shared/models/tql.model';

export class Analysis {
	public currencies: string[];
	public divisionIds: string[];
	public entityIds: string[];
	public institutionIds: string[];
	public currency: string;
	public period: string;
	public periodData: PeriodData[];
}

export class PeriodData {
	public date: string;
	public creditAmount: number;
	public debitAmount: number;
	public monthToMonthBurnRate: number;
	public quarterlyBurnRate: number;
	public biyearlyBurnRate: number;
	public yearlyBurnRate: number;
}

export class AnalysisData<T = AnalysisBalanceValue | AnalysisTransactionValue> {
	type: T extends AnalysisBalanceValue ? AnalysisType.balances : AnalysisType.transactions;
	aggregation: AnalysisDataAggregation<T>[];
	summary: T[];
	currencyConverted: string;
	uniformCurrency?: string;
	metrics?: T extends AnalysisBalanceValue ? BalanceAnalysisDataMetrics : TransactionsAnalysisDataMetrics;
	cadence: Cadence;
	groupBy?: GroupByKey[];
	convertedCurrency?: string;
	balanceProperty?: AnalysisBalanceProperty;
	tql?: Object;
	startDate?: string;
	endDate?: string;
}

export interface TransactionsAnalysisDataMetrics {
	creditTotal: number;
	debitTotal: number;
	netTotal: number;
	netAverage: number;
	netMin: number;
	netMax: number;
	netTotalChange: number;
	netTotalChangePercent: number;
}

export interface BalanceAnalysisDataMetrics {
	average: number;
	min: number;
	max: number;
	totalChange: number;
	totalChangePercent: number;
	periodChange: number;
	periodChangePercent: number;
}

export class AnalysisDataAggregation<T> {
	type?: string;
	value?: string;
	aggregation: AnalysisDataAggregation<T>[];
	summary: T[];
	uniformCurrency?: string;
}

export class AnalysisTransactionValue {
	date: string;
	credit: number;
	debit: number;
	net: number;
}

export class AnalysisBalanceValue {
	date: string;
	accountIds: string[];
	containsIsFilled: boolean;
	containsIsCalculated: boolean;
	bankOpeningAvailable: number;
	bankClosingAvailable: number;
	bankOpeningLedger: number;
	bankClosingLedger: number;
	bankCurrentAvailable: number;
	bankCurrentAvailableConverted: number;
	bankOpeningAvailableConverted: number;
	bankClosingAvailableConverted: number;
	bankOpeningLedgerConverted: number;
	bankClosingLedgerConverted: number;
	bankSynthetic: number;
	bankSyntheticConverted: number;
	trovataOpeningBalance: number;
	trovataOpeningBalanceConverted: number;
	trovataClosingBalance: number;
	trovataClosingBalanceConverted: number;
	compositeBalance: number;
	compositeBalanceConverted: number;
	compositeField: string;
	compositeFieldConverted: string;
	currency: string;
	currencyConverted: string;
	fakeBalance?: boolean;
	currencyNative?: string;
}

export enum AnalysisBalanceProperty {
	compositeBalance = 'compositeBalance',
	compositeBalanceConverted = 'compositeBalanceConverted',
	bankClosingAvailable = 'bankClosingAvailable',
	bankClosingLedger = 'bankClosingLedger',
	bankCurrentAvailable = 'bankCurrentAvailable',
	bankOpeningAvailable = 'bankOpeningAvailable',
	bankOpeningLedger = 'bankOpeningLedger',
	bankClosingAvailableConverted = 'bankClosingAvailableConverted',
	bankClosingLedgerConverted = 'bankClosingLedgerConverted',
	bankCurrentAvailableConverted = 'bankCurrentAvailableConverted',
	bankOpeningAvailableConverted = 'bankOpeningAvailableConverted',
	bankOpeningLedgerConverted = 'bankOpeningLedgerConverted',
	bankSynthetic = 'bankSynthetic',
	bankSyntheticConverted = 'bankSyntheticConverted',
	bankOpeningClosingLedgerConverted = 'bankOpeningClosingLedgerConverted',
	bankOpeningClosingLedger = 'bankOpeningClosingLedger',
}

export const adjustDualBalanceTypes: (balanceType: AnalysisBalanceProperty | string) => AnalysisBalanceProperty = (balanceType: AnalysisBalanceProperty) => {
	switch (balanceType) {
		case AnalysisBalanceProperty.bankOpeningClosingLedgerConverted:
			balanceType = AnalysisBalanceProperty.bankClosingLedgerConverted;
			break;
		case AnalysisBalanceProperty.bankOpeningClosingLedger:
			balanceType = AnalysisBalanceProperty.bankClosingLedger;
			break;
		default:
			balanceType = balanceType;
	}
	return balanceType;
};

export const isConvertedBalance: (type: AnalysisBalanceProperty) => boolean = (type: AnalysisBalanceProperty) =>
	type === AnalysisBalanceProperty.compositeBalanceConverted ||
	type === AnalysisBalanceProperty.bankSyntheticConverted ||
	type === AnalysisBalanceProperty.bankOpeningClosingLedgerConverted ||
	type === AnalysisBalanceProperty.bankOpeningLedgerConverted ||
	type === AnalysisBalanceProperty.bankClosingLedgerConverted ||
	type === AnalysisBalanceProperty.bankOpeningAvailableConverted ||
	type === AnalysisBalanceProperty.bankClosingAvailableConverted ||
	type === AnalysisBalanceProperty.bankCurrentAvailableConverted;

export enum AnalysisTimeFrame {
	days = 'days',
	weeks = 'weeks',
	months = 'months',
	quarters = 'quarters',
}

export const cadenceToTimeFrame: (cadence: Cadence) => AnalysisTimeFrame = (cadence: Cadence) => {
	switch (cadence) {
		case Cadence.daily:
			return AnalysisTimeFrame.days;
		case Cadence.weekly:
			return AnalysisTimeFrame.weeks;
		case Cadence.monthly:
			return AnalysisTimeFrame.months;
		case Cadence.quarterly:
			return AnalysisTimeFrame.quarters;
		default:
			return cadence;
	}
};

export const timeFrameToCadence: (timeFrame: AnalysisTimeFrame) => Cadence = (timeFrame: AnalysisTimeFrame) => {
	switch (timeFrame) {
		case AnalysisTimeFrame.days:
			return Cadence.daily;
		case AnalysisTimeFrame.weeks:
			return Cadence.weekly;
		case AnalysisTimeFrame.months:
			return Cadence.monthly;
		case AnalysisTimeFrame.quarters:
			return Cadence.quarterly;
		default:
			return timeFrame;
	}
};

export class AnalysisGridRow {
	constructor(type: string, groupValue: string[] | string) {
		this.type = type;
		this.groupValue = groupValue;
	}
	type: string;
	groupValue: string[] | string;
	currency: Currency | string;
}

export enum AnalysisType {
	transactions = 'transactions',
	balances = 'balances',
}

export enum AnalysisPeriods {
	'Bi-weekly' = 12,
	'Weekly' = 12,
	'Daily' = 30,
	'Monthly' = 3,
}

export interface AnalysisGetRequestParams {
	cadence: Cadence;
	groupBy?: string[];
	excludeWeekends?: boolean;
	currencyOverride?: string;
	tqlJSONExpression?: Object;
	balanceProperty?: string;
	uniformCurrency?: string;
	startDate: string;
	endDate: string;
}

export interface AnalysisSettings {
	dataSettings: AnalysisDataSettings;
	displaySettings: AnalysisDisplaySettings;
}

export interface AnalysisDataSettings {
	analysisType: AnalysisType;
	cadence: Cadence;
	groupBy?: GroupByKey[];
	currencyOverride?: string;
	balanceProperty: AnalysisBalanceProperty;
}

export interface AnalysisDisplaySettings {
	trueRounding: boolean;
	trueRoundingOption: Rounding;
	headlineValue: AnalysisChartHeadline;
	headlineToggle: boolean;
	chartType: ChartType;
	chartToggle: boolean;
	tableToggle: boolean;
	trendMetrics: boolean;
	primaryMetric: AnalysisTrendMetric;
	netToggle: boolean;
	userOrdered?: any;
}

export enum AnalysisTrendMetric {
	totalChange = 'totalChange',
	periodChange = 'periodChange',
	average = 'average',
	maximum = 'maximum',
	minimum = 'minimum',
}

export const getAnalysisTrendMetricOptions = (analysisType: AnalysisType): GenericOption[] => {
	if (analysisType === AnalysisType.transactions) {
		return [...trendMetricOptions];
	} else if (analysisType === AnalysisType.balances) {
		return [
			{
				id: AnalysisTrendMetric.periodChange,
				key: AnalysisTrendMetric.periodChange,
				displayValue: 'Period Change',
			},
			...trendMetricOptions,
		];
	}
};

export const trendMetricOptions: GenericOption[] = [
	{
		id: AnalysisTrendMetric.totalChange,
		key: AnalysisTrendMetric.totalChange,
		displayValue: 'Total Change',
	},
	{
		id: AnalysisTrendMetric.average,
		key: AnalysisTrendMetric.average,
		displayValue: 'Average',
	},
	{
		id: AnalysisTrendMetric.maximum,
		key: AnalysisTrendMetric.maximum,
		displayValue: 'Maximum',
	},
	{
		id: AnalysisTrendMetric.minimum,
		key: AnalysisTrendMetric.minimum,
		displayValue: 'Minimum',
	},
];
export type AnalysisChartHeadline = Record<TransactionsAnalysisChartHeadline & BalanceAnalysisChartHeadline, string>;

export enum TransactionsAnalysisChartHeadline {
	netCashflow = 'netCashflow',
	creditDebit = 'creditDebit',
}

export enum TransactionsAnalysisChartDisplayValues {
	netCashflow = 'Net Cashflow',
	creditDebit = 'Credit/Debit',
}

export const transactionsAnalysisHeadlineOptions: GenericOption<TransactionsAnalysisChartHeadline>[] = [
	// {
	// 	key: TransactionsAnalysisChartHeadline.creditDebit,
	// 	id: TransactionsAnalysisChartHeadline.creditDebit,
	// 	displayValue: TransactionsAnalysisChartDisplayValues.creditDebit,
	// },
	{
		key: TransactionsAnalysisChartHeadline.netCashflow,
		id: TransactionsAnalysisChartHeadline.netCashflow,
		displayValue: TransactionsAnalysisChartDisplayValues.netCashflow,
	},
];

export enum BalanceAnalysisChartHeadline {
	previousPeriod = 'previousPeriod',
	currentBalance = 'currentBalance',
}

export enum BalanceAnalysisChartDisplayValues {
	previousPeriod = 'Previous Period',
	currentBalance = 'Current Balance',
}

export const balanceAnalysisHeadlineOptions: GenericOption<BalanceAnalysisChartHeadline>[] = [
	{
		key: BalanceAnalysisChartHeadline.previousPeriod,
		id: BalanceAnalysisChartHeadline.previousPeriod,
		displayValue: BalanceAnalysisChartDisplayValues.previousPeriod,
	},
	{
		key: BalanceAnalysisChartHeadline.currentBalance,
		id: BalanceAnalysisChartHeadline.currentBalance,
		displayValue: BalanceAnalysisChartDisplayValues.currentBalance,
	},
];

// legacy
export const groupByOptionsLegacy: GenericOption[] = [
	{ id: 'currency', key: 'currency', displayValue: 'Currency' },
	{ id: 'institutionId', key: 'institution', displayValue: 'Institution' },
	{ id: 'accountId', key: 'account', displayValue: 'Account' },
];

export const transactionGroupByOptionsLegacy: GenericOption[] = [{ id: 'tag', key: 'tag', displayValue: 'Tag' }];

export const balanceGroupByOptionsLegacy: GenericOption[] = [
	{ id: 'region', key: 'region', displayValue: 'Region' },
	{ id: 'entity', key: 'entity', displayValue: 'Entity' },
	{ id: 'division', key: 'division', displayValue: 'Division' },
];

export const groupByOptions: GenericOption[] = [
	{ id: GroupByKey.currency, displayValue: 'Currency' },
	{ id: GroupByKey.institution, displayValue: 'Institution' },
	{ id: GroupByKey.account, displayValue: 'Account' },
	{ id: GroupByKey.entityId, displayValue: 'Entity' },
	{ id: GroupByKey.entityRegion, displayValue: 'Entity Region' },
	{ id: GroupByKey.entityDivision, displayValue: 'Entity Division' },
];

export const transactionGroupByOptions: GenericOption[] = [{ id: GroupByKey.tag, displayValue: 'Tag' }];

export const balanceGroupByOptions: GenericOption[] = [
	{ id: GroupByKey.accountGroupB, displayValue: 'Region (Legacy)' },
	{ id: GroupByKey.accountGroupA, displayValue: 'Entity (Legacy)' },
	{ id: GroupByKey.accountGroupC, displayValue: 'Division (Legacy)' },
];

export const defaultTransactionsAnalysisDisplaySettings: AnalysisDisplaySettings = {
	trueRounding: false,
	trueRoundingOption: null,
	headlineValue: TransactionsAnalysisChartHeadline.netCashflow,
	headlineToggle: true,
	chartType: ChartType.column,
	chartToggle: true,
	tableToggle: true,
	trendMetrics: true,
	netToggle: false,
	primaryMetric: AnalysisTrendMetric.totalChange,
	userOrdered: null,
};

export const defaultTransactionsAnalysisDataSettings: AnalysisDataSettings = {
	cadence: Cadence.daily,
	analysisType: AnalysisType.transactions,
	balanceProperty: AnalysisBalanceProperty.compositeBalanceConverted,
	currencyOverride: defaultCurrency.code,
};

export const isTransactionsAnalysis = (
	analysisData: AnalysisData<AnalysisTransactionValue> | AnalysisData<AnalysisBalanceValue>
): analysisData is AnalysisData<AnalysisTransactionValue> => analysisData.type === AnalysisType.transactions;

export const isBalanceAnalysis = (
	analysisData: AnalysisData<AnalysisTransactionValue> | AnalysisData<AnalysisBalanceValue>
): analysisData is AnalysisData<AnalysisBalanceValue> => analysisData.type === AnalysisType.balances;

export const isTransactionsAnalysisAggregation = (
	analysisAggregationData: AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>
): analysisAggregationData is AnalysisDataAggregation<AnalysisTransactionValue> => analysisAggregationData.type === AnalysisType.transactions;

export const isBalanceAnalysisAggregation = (
	analysisAggregationData: AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>
): analysisAggregationData is AnalysisDataAggregation<AnalysisBalanceValue> => analysisAggregationData.type === AnalysisType.balances;

export const mapGroupTypeToTQLField = (groupType: string): string => {
	switch (groupType) {
		case 'accountGroupA':
			return TQLPropertyKey.entity;
		case 'accountGroupB':
			return TQLPropertyKey.region;
		case 'accountGroupC':
			return TQLPropertyKey.division;
		default:
			return groupType;
	}
};
