import { GetGlCodesResponse, GetTagsResponse, Tag, TagType } from 'src/app/features/transactions/models/tag.model';
import { TransactionTagResponse } from 'src/app/features/transactions/models/transaction.model';
import { Request, Server } from 'miragejs';
import { AppSchema, TagDbData } from '../types';

export function addTagsWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post('/data/tql/tags-list', (schema: AppSchema, request: Request): Tag[] => [...schema.db.tags.where({ tagType: TagType.transaction })]);
	server.post('/data/tags-list', (schema: AppSchema, request: Request): GetTagsResponse => {
		let glTags: TagDbData[] = [];
		glTags = [...schema.db.tags.where({ tagType: TagType.glTagAuto })];
		glTags = [...glTags, ...schema.db.tags.where({ tagType: TagType.glTag })];
		return { tags: glTags };
	});
	server.get(
		'/data/tags/gl/gl-code',
		(): GetGlCodesResponse => ({
			glCodes: [
				{
					name: '4040',
					description: 'Distributions',
					codeId: '04967882-9aee-416c-b227-73ffb2b10026',
				},
				{
					name: '7071',
					description: 'Income',
					codeId: '21603823-fc1d-4bd1-ac21-8542ee353920',
				},
				{
					name: '1000',
					description: 'General',
					codeId: 'generalCodeId',
				},
				{
					name: '2000',
					description: 'Debit Code',
					codeId: 'debitCodeId',
				},
			],
		})
	);
	server.put('/data/tags/gl/gl-code/**', () => {
		unhandledRequestMade();
		return null;
	});
	server.post('/data/tags/gl/gl-code/deletion', () => {
		unhandledRequestMade();
		return null;
	});
	server.post('/data/transactions/gl-tag/**', () => {
		unhandledRequestMade();
		return null;
	});
	server.post(
		'/data/transactions/tags',
		(): TransactionTagResponse => ({
			tagObjects: [],
			totalTags: 0,
			batchSize: null,
			nextBatchId: null,
			totalBatchSize: null,
		})
	);
	server.post('/data/accounts/tags', () => {
		unhandledRequestMade();
		return null;
	});
	server.post('/data/tags', () => {
		unhandledRequestMade();
		return null;
	});
	server.post('/data/tql/tags', () => {
		unhandledRequestMade();
		return null;
	});
	server.put('/data/v2/tags/**', () => {
		unhandledRequestMade();
		return null;
	});
	server.delete('/data/accounts/tags/**', () => {
		unhandledRequestMade();
		return null;
	});
	server.delete('/data/tags/**', () => null);
}
