import { Transaction, TransactionsRequestBody, TransactionsSummary } from 'src/app/features/transactions/models/transaction.model';
import { Server, Request } from 'miragejs';
import { AppSchema, AccountDbData, TransactionDbData, InstitutionDbData } from '../types';

export function addTransactionsWorkspaceHandlers(server: Server, unhandledRequestMade: () => void): void {
	server.post('/data/transactions/manual', () => {
		unhandledRequestMade();
		return null;
	});

	server.post('/data/transactions', (schema: AppSchema, request: Request): { transactions: Transaction[] } => {
		const body: TransactionsRequestBody = JSON.parse(request.requestBody);
		if (body.startDate || body.endDate || body.sort) {
			unhandledRequestMade();
		}
		const positionType: string = body.positionType ? body.positionType[0] : null;
		const institutionType: string = body.institutionType;
		const institutionId: string = body.institutionId;
		const accountId: string = body.accountId?.length ? body.accountId[0] : null;
		const currency: string = body.currency?.length ? body.currency[0] : null;
		const q: string = body.q;
		const from: number = body.from;
		const size: number = body.from;
		const type: string[] = body.type ?? null;

		let filteredTransactions: Transaction[] = [];
		let handled: boolean = false;
		if (type && type.length) {
			filteredTransactions = [...filteredTransactions, ...schema.db.transactions.where({ type: type[0] })];
			handled = true;
		}
		if (institutionType) {
			filteredTransactions = [...filteredTransactions, ...schema.db.transactions.where({ dataProviderId: 'MANUAL' })];
			handled = true;
		}
		if (institutionId) {
			const accounts: AccountDbData[] = schema.db.accounts.where({
				institutionId,
			});
			const accountIds: string[] = accounts.map((account: AccountDbData) => account.id);
			const institutionTrxns: Transaction[] = schema.db.transactions.filter((trxn: TransactionDbData) => accountIds.includes(trxn.dbAccountId));

			filteredTransactions = [...filteredTransactions, ...institutionTrxns];
			handled = true;
		}
		if (currency) {
			filteredTransactions = [...filteredTransactions, ...schema.db.transactions.where({ currency })];
			handled = true;
		}
		if (q) {
			const matchingInsts: InstitutionDbData[] = schema.db.institutions.filter(inst => {
				if (inst.institutionName.toLowerCase().includes(q)) {
					return inst;
				}
			});
			const instAccounts: InstitutionDbData[] = matchingInsts.map((inst: InstitutionDbData) => schema.db.accounts.where({ institutionId: inst.id }));
			const foundAccounts: AccountDbData[] = schema.db.accounts.filter(
				(acct: AccountDbData) => acct.name.toLowerCase().includes(q) || acct.accountNumber.includes(q)
			);
			const instAcctIds: string[] = instAccounts.map((inst: InstitutionDbData) => inst.id);
			const foundAcctIds: string[] = foundAccounts.map((acct: AccountDbData) => acct.id);
			const foundTransactions: Transaction[] = schema.db.transactions.filter(
				(trxn: TransactionDbData) =>
					instAcctIds.includes(trxn.dbAccountId) || foundAcctIds.includes(trxn.dbAccountId) || trxn.description.toLowerCase().includes(q)
			);

			filteredTransactions = [...filteredTransactions, ...foundTransactions];
			handled = true;
		}
		if (accountId) {
			filteredTransactions = [...filteredTransactions, ...schema.db.transactions.where({ dbAccountId: accountId })];
			handled = true;
		}
		if (body.tagId) {
			filteredTransactions = [
				...filteredTransactions,
				...schema.db.transactions.filter((trxn: TransactionDbData) => trxn.tags.find(tag => tag.tagId === body.tagId[0])),
			];
			handled = true;
		}
		if (body.glTagId) {
			filteredTransactions = [
				...filteredTransactions,
				...schema.db.transactions.filter((trxn: TransactionDbData) => trxn.tags.find(tag => tag.tagId === body.glTagId[0])),
			];
			handled = true;
		}
		if (positionType === 'INVESTMENT') {
			filteredTransactions = [];
			handled = true;
		}
		if (!handled) {
			filteredTransactions = schema.db.transactions;
		}

		if ((from || from === 0) && size) {
			filteredTransactions = filteredTransactions.slice(from, from + size);
		}
		return { transactions: filteredTransactions };
	});

	server.post('/data/transactions/summary', (schema: AppSchema, request: Request): TransactionsSummary => {
		const body: {
			positionType: string;
			institutionType: string;
			glTagId: string[];
			type: string[];
			tagId: string[];
		} = JSON.parse(request.requestBody);
		let trxnLength: number = schema.db.transactions.length;
		if (body.glTagId) {
			trxnLength = schema.db.transactions.filter((trxn: TransactionDbData) => trxn.tags.find(tag => tag.tagId === body.glTagId[0])).length;
		}
		if (body.tagId) {
			trxnLength = schema.db.transactions.filter((trxn: TransactionDbData) => trxn.tags.find(tag => tag.tagId === body.tagId[0])).length;
		}
		if (body.type && body.type.length) {
			trxnLength = schema.db.transactions.where({
				type: body.type[0],
			}).length;
		}
		if (body.institutionType) {
			trxnLength = schema.db.transactions.where({
				dataProviderId: 'MANUAL',
			}).length;
		}
		if (body.positionType && body.positionType[0] === 'INVESTMENT') {
			trxnLength = 0;
		}
		return {
			currencySummary: {
				converted: 0,
				currencyConverted: 'USD',
			},
			totalTransactions: trxnLength,
		};
	});
}
