import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { TransactionFilters, TransactionFilterSettings, TransactionFilterType } from 'src/app/shared/models/transaction-filters.model';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Institution } from '../models/institution.model';

@Injectable({
	providedIn: 'root',
})
export class TransactionFiltersService {
	version = 3;

	private filterTypeObj: TransactionFilterSettings = new TransactionFilterSettings();

	constructor(private httpClient: HttpClient) {}

	public getTransactionFilters(filterType: TransactionFilterType, fromSize?: number, isPagedCall?: boolean, filterSize?: number) {
		const completeFilter: TransactionFilters = new TransactionFilters();

		const body: {
			filter?: TransactionFilterType;
			size?: number;
			initialFromSize?: number;
		} = {};
		body.filter = filterType;

		const size = filterSize ? filterSize : 1000;
		body[this.filterTypeObj[filterType].size] = size;

		const initialFromSize = fromSize ? fromSize : 0;
		body[this.filterTypeObj[filterType].from] = initialFromSize;

		const url = environment.trovataAPI('workspace') + '/data/transactions/filters';
		return this.httpClient
			.post<TransactionFilters>(url, body, {
				observe: 'response',
			})
			.pipe(
				switchMap(async resp => {
					if (isPagedCall) {
						return resp.body;
					}

					completeFilter[filterType] = <any>resp.body[filterType];
					completeFilter[this.filterTypeObj[filterType].total] = resp.body[this.filterTypeObj[filterType].total];
					let fromSizeInt = this.getFromSize(filterType, completeFilter);

					while (this.callCheck(filterType, completeFilter)) {
						try {
							const newRes = await this.getFiltersPaged(filterType, fromSizeInt);
							this.addNewResults(filterType, newRes, completeFilter);
							fromSizeInt = this.getFromSize(filterType, completeFilter);
						} catch (err) {
							throw new Error(err);
						}
					}
					if (filterType === TransactionFilterType.institutions) {
						completeFilter[filterType] = completeFilter[filterType].map((institution: Institution) => {
							institution.institutionNickname = institution.institutionNickname || institution.institutionName;
							return institution;
						});
					}
					return {
						[filterType]: completeFilter[filterType],
						[this.filterTypeObj[filterType].total]: completeFilter[this.filterTypeObj[filterType].total],
					};
				}),
				catchError(err => throwError(err))
			);
	}

	private getFiltersPaged(filterType: TransactionFilterType, fromSize: number) {
		return new Promise((resolve, reject) => {
			this.getTransactionFilters(filterType, fromSize, true).subscribe(
				res => {
					resolve(res);
				},
				err => {
					reject(err);
					throwError(err);
				}
			);
		});
	}

	private getFromSize(filterType: TransactionFilterType, completeFilter: TransactionFilters) {
		const size = completeFilter[filterType].length;
		return size;
	}

	private addNewResults(filterType: TransactionFilterType, trxnFilterObj, completeFilter: TransactionFilters) {
		completeFilter[filterType].push(...trxnFilterObj[filterType]);
	}

	private callCheck(filterType: TransactionFilterType, filters: TransactionFilters) {
		if (filters[filterType] && filters[this.filterTypeObj[filterType].total] > filters[filterType].length) {
			return true;
		} else {
			return false;
		}
	}
}
