import { environment } from 'src/environments/environment';
import { AccessMap, FeatureId, UserAccessConfig } from 'src/app/features/settings/models/feature.model';
import { Request, Server } from 'miragejs';
import { addAccountGroupingEdgeHandlers } from './account-grouping-handlers.mock';
import { addAccountsWorkspaceHandlers } from './accounts-handlers.mock';
import { addAnalysisEdgeHandlers, addAnalysisWorkspaceHandlers } from './analysis-handlers.mock';
import { addCurrencyEdgeHandlers } from './currency-handlers.mock';
import { addCurrentCashWorkspaceHandlers } from './current-cash-handlers.mock';
import { addFiltersWorkspaceHandlers } from './filters-handlers.mock';
import { addForecastWorkspaceHandlers } from './forecast-handlers.mock';
import { addInsightsWorkspaceHandlers } from './insights-handlers.mock';
import { addInvoiceWorkspaceHandlers } from './invoice-handlers.mock';
import { addReconciliationWorkspaceHandlers } from './reconciliation-handlers.mock';
import { addReportsWorkspaceHandlers } from './reports-handlers.mock';
import {
	addDashboardEdgePrefsHandlers,
	addForecastsEdgePrefsHandlers,
	addFreemiumPrefsEdgeHandlers,
	addFreemiumPrefsWorkspaceHandlers,
	addReportsEdgePrefsHandlers,
} from './preferences-handlers.mock';
import { addTagsWorkspaceHandlers } from './tags-handlers.mock';
import { addTransactionsWorkspaceHandlers } from './transactions-handlers.mock';
import { addCashPositionWorkspaceHandlers } from './cash-position-handlers.mock';
import { addEntitiesWorkspaceHandlers } from './entities-handlers.mock';

export function handleRoutes(server: Server, unhandledRequestMade: () => void, userAccessConfig: UserAccessConfig, customerAccessMap: AccessMap) {
	server.logging = false;
	const noAccounts = !userAccessConfig.currentCustomer?.accountCount;
	if (noAccounts) {
		// WORKSPACE ROUTES
		server.urlPrefix = environment.trovataAPI('workspace');
		addTagsWorkspaceHandlers(server, unhandledRequestMade);
		addCurrentCashWorkspaceHandlers(server, unhandledRequestMade);
		addFiltersWorkspaceHandlers(server, unhandledRequestMade);
		addTransactionsWorkspaceHandlers(server, unhandledRequestMade);
		addAccountsWorkspaceHandlers(server, unhandledRequestMade);
		addFreemiumPrefsWorkspaceHandlers(server, unhandledRequestMade);
		addAnalysisWorkspaceHandlers(server, unhandledRequestMade);

		// EDGE ROUTES
		server.urlPrefix = environment.edgeAPI();
		addAnalysisEdgeHandlers(server, unhandledRequestMade);
		addFreemiumPrefsEdgeHandlers(server);
		addCurrencyEdgeHandlers(server);
		addAccountGroupingEdgeHandlers(server);

		server.pretender.passthroughRequest = (verb, path) => {
			if (verb !== 'GET' && !checkPath(path)) {
				unhandledRequestMade();
				return null;
			}
		};
	}

	// WORKSPACE ROUTES
	if (!customerAccessMap.featureIds.has(FeatureId.reports) || noAccounts) {
		server.urlPrefix = environment.trovataAPI('workspace');
		addReportsWorkspaceHandlers(server);
		server.urlPrefix = environment.edgeAPI();
		addReportsEdgePrefsHandlers(server);
	}
	if (!customerAccessMap.featureIds.has(FeatureId.entities) || noAccounts) {
		server.urlPrefix = environment.trovataAPI('workspace');
		addEntitiesWorkspaceHandlers(server, unhandledRequestMade);
	}
	if (!customerAccessMap.featureIds.has(FeatureId.forecast) || noAccounts) {
		server.urlPrefix = environment.trovataAPI('workspace');
		addForecastWorkspaceHandlers(server, unhandledRequestMade);
		server.urlPrefix = environment.edgeAPI();
		addForecastsEdgePrefsHandlers(server);
	}
	if (!customerAccessMap.featureIds.has(FeatureId.cashFlowReconciliation) || noAccounts) {
		server.urlPrefix = environment.trovataAPI('workspace');
		addReconciliationWorkspaceHandlers(server, unhandledRequestMade);
	}
	if (!customerAccessMap.featureIds.has(FeatureId.dashboard) || noAccounts) {
		server.urlPrefix = environment.edgeAPI();
		addDashboardEdgePrefsHandlers(server);
	}

	server.urlPrefix = environment.trovataAPI('workspace');
	if (!customerAccessMap.featureIds.has(FeatureId.invoices) || noAccounts) {
		addInvoiceWorkspaceHandlers(server);
	}
	if (!customerAccessMap.featureIds.has(FeatureId.insights) || noAccounts) {
		addInsightsWorkspaceHandlers(server);
	}
	if (!customerAccessMap.featureIds.has(FeatureId.cashPosition) || noAccounts) {
		addCashPositionWorkspaceHandlers(server, unhandledRequestMade);
	}

	// EDGE ROUTES
	server.urlPrefix = environment.edgeAPI();

	// this function keeps certain non-GET trovata.io apis from showing the connection needed banner
	const checkPath = (path: string) =>
		path.includes('plaid') ||
		path.includes('cache') ||
		path.includes('/onboarding/customer') ||
		path.includes('marketing') ||
		path.includes('/connections/request') ||
		path.includes('/checkout') ||
		path.includes('/accounts/manual') ||
		path.includes('/institutions/manual') ||
		path.includes('/submissions/v3/integration') ||
		path.includes('/identity') ||
		path.includes('/tql') ||
		path.includes('/partners') ||
		path.includes('/user/notifications') ||
		path.includes('entitlements');

	// passthroughs allows requests that aren't handled above to pass through
	server.urlPrefix = environment.edgeAPI();
	server.passthrough();
	// switch url prefix to workspace passthroughs
	server.urlPrefix = environment.trovataAPI('workspace');
	server.passthrough();
	// switch to manage microservice for passthroughs
	server.urlPrefix = environment.trovataAPI('manage');
	server.passthrough();
	// switch to payments microservice for passthroughs
	server.urlPrefix = environment.trovataAPI('payments');
	server.passthrough();

	// this passthrough function allows requests with different domains than trovata.io to passthrough
	server.passthrough((request: Request) => request.url.includes('chilipiper') || request.url.includes('hsforms') || request.url.includes('lottie'));
}
