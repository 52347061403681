import { ReportV4, ReportV4ElementData } from 'src/app/features/reports/models/report.model';
import { Request, Server } from 'miragejs';
import { AppSchema } from '../types';

export function addReportsWorkspaceHandlers(server: Server) {
	server.post('/data/v4/reports-list', (schema: AppSchema): { reports: ReportV4[] } => ({
		reports: schema.db.reports,
	}));
	server.post('/data/v4/reports/**/data', (schema: AppSchema, request: Request): { data: ReportV4ElementData[] } => {
		const reportIdParam: string = request.params['*'];
		const elements = schema.db.reports.findBy({
			reportId: reportIdParam,
		}).elements;
		return { data: elements };
	});
}
