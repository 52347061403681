import { Observable, Subject, takeUntil } from 'rxjs';

export function firstValidValueFrom<T>(selector$: Observable<T>): Promise<T> {
	const cleanupObservable$: Subject<boolean> = new Subject<boolean>();
	return new Promise((resolve, reject) => {
		selector$.pipe(takeUntil(cleanupObservable$)).subscribe(
			resp => {
				if (resp !== null && resp !== undefined) {
					cleanupObservable$.next(true);
					cleanupObservable$.complete();
					resolve(resp);
				}
			},
			err => {
				cleanupObservable$.next(true);
				cleanupObservable$.complete();
				reject(err);
			}
		);
	});
}
