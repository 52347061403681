import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, Select, State, StateContext, Store } from '@ngxs/store';
import { combineLatest, firstValueFrom, Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Cadence } from '../../models/cadence.model';
import { ReportV4, ReportType, ReportV4ElementData, ReportV4Element, ReportV4Parameters } from '../../models/report.model';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { ReportsService } from '../../services/reports.service';
import {
	AddReportDataToReport,
	LazyLoadReportsData,
	GetReports,
	CreateReport,
	DeleteReport,
	UpdateReport,
	ClearReportsState,
	InitReportsState,
	ResetReportsState,
	UpdateChangedReportsState,
} from '../actions/reports.actions';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { ActionType, ReportUpdatedEvent } from 'src/app/shared/models/updated-events.model';
import { FeatureId } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { AllPreferences } from 'src/app/shared/models/preferences.model';
import { PreferencesState } from 'src/app/shared/store/state/preferences/preferences.state';
import { SnackType } from 'src/app/shared/models/snacks.model';
import { PreferencesFacadeService } from 'src/app/shared/services/facade/preferences.facade.service';
import { CalendarSettings } from 'src/app/shared/models/date-range-picker.model';
import { DeleteReportResponse, GetReportsResponse } from '../../models/report.model.api';
import { AnalysisBalanceProperty, AnalysisBalanceValue, AnalysisDataAggregation, isBalanceAnalysis } from '../../models/analysis.model';
import { BalanceAnalysisOptions } from '../../models/analysis-chart-view-model';

export class ReportsStateModel extends EntitledStateModel {
	reports: ReportV4[];
	preFetchInFlight: boolean;
}

@State<ReportsStateModel>({
	name: 'reports',
	defaults: {
		reports: null,
		preFetchInFlight: false,
		isCached: false,
	},
})
@Injectable()
export class ReportsState {
	private isInitialized: boolean;

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	@Select(CustomerFeatureState.hasPermissionOrShouldDemo(FeatureId.reports)) shouldSeeReports: Observable<boolean>;
	@Select(PreferencesState.preferences) preferences$: Observable<AllPreferences>;

	@Selector() static reports(reports: ReportsStateModel): ReportV4[] {
		return reports.reports;
	}

	@Selector() static reportsPreFetchInFlight(reports: ReportsStateModel): boolean {
		return reports.preFetchInFlight;
	}

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private preferncesFacadeService: PreferencesFacadeService,
		private reportsService: ReportsService,
		private updatedEventsService: UpdatedEventsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.isInitialized = false;
	}

	@Action(InitReportsState)
	async initReportsState(context: StateContext<ReportsStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const reportsStateIsCached: boolean = this.reportsStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.shouldSeeReports]).subscribe({
				next: ([appReady, shouldSeeReports]: [boolean, boolean]) => {
					if (!this.isInitialized && appReady && shouldSeeReports !== undefined) {
						if (shouldSeeReports) {
							if (reportsStateIsCached) {
								const state: ReportsStateModel = deserializedState.reports;
								context.patchState(state);
								// check for reports that never got their data
								this.lazyLoadVisibleReportsData(context);
							} else {
								context.dispatch(new GetReports());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ reports: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetReports)
	getReports(context: StateContext<ReportsStateModel>): Observable<HttpResponse<GetReportsResponse>> {
		return this.reportsService.getReports().pipe(
			tap((response: HttpResponse<GetReportsResponse>) => {
				const reports: ReportV4[] = response.body.reports;
				reports.forEach((eachReport: ReportV4) => {
					eachReport.reportType = ReportType.report;
				});
				const sortedReports: ReportV4[] = this.sortReportsByName(reports);
				const state: ReportsStateModel = context.getState();
				state.isCached = true;
				context.patchState(state);
				this.addReportsToReportsState(context, sortedReports);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(CreateReport)
	createReport(context: StateContext<ReportsStateModel>, action: CreateReport): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const createdReportResponse: HttpResponse<ReportV4> = await firstValueFrom(this.reportsService.createReport(action.report));
				this.addReportsToReportsState(context, [createdReportResponse.body]);
				await this.lazyLoadData(context, createdReportResponse.body);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateReport)
	updateReport(context: StateContext<ReportsStateModel>, action: UpdateReport): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				const reportCopy: ReportV4 = JSON.parse(JSON.stringify(action.report));
				delete reportCopy.reportData;
				const updateReportResponse: HttpResponse<ReportV4> = await firstValueFrom(this.reportsService.updateReport(reportCopy));
				this.addReportsToReportsState(context, [updateReportResponse.body]);
				await this.lazyLoadData(context, updateReportResponse.body);
				this.updatedEventsService.updateItem(new ReportUpdatedEvent(ActionType.update, reportCopy.reportId, reportCopy));
				resolve(true);
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(DeleteReport)
	deleteReport(context: StateContext<ReportsStateModel>, action: DeleteReport): Observable<HttpResponse<DeleteReportResponse>> {
		return this.reportsService.deleteReport(action.reportId).pipe(
			tap((response: HttpResponse<DeleteReportResponse>) => {
				const state: ReportsStateModel = context.getState();
				const filteredReports: ReportV4[] = state.reports.filter((filterReport: ReportV4) => filterReport.reportId !== action.reportId);
				const sortedReports: ReportV4[] = this.sortReportsByName(filteredReports);
				state.reports = sortedReports;
				this.updatedEventsService.updateItem(new ReportUpdatedEvent(ActionType.delete, action.reportId));
				context.patchState(state);
				context.dispatch(new GetReports());
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(AddReportDataToReport)
	addReportDataToReport(context: StateContext<ReportsStateModel>, action: AddReportDataToReport): void {
		const state: ReportsStateModel = context.getState();
		const reportToAddDataTo: ReportV4 = action.report;
		const reportData: ReportV4ElementData = action.reportData[0];
		if (isBalanceAnalysis(reportData.data)) {
			this.trimBalanceDataAggregation(reportData.data, reportToAddDataTo);
		}
		reportToAddDataTo.reportData = [action.reportData[0]];
		const filteredReports: ReportV4[] = state.reports.filter((filterReport: ReportV4) => filterReport.reportId !== action.report.reportId);
		filteredReports.push(reportToAddDataTo);
		state.reports = this.sortReportsByName(filteredReports);
		context.patchState(state);
	}

	private trimBalanceDataAggregation(aggregation: AnalysisDataAggregation<AnalysisBalanceValue>, report: ReportV4): void {
		const balanceAnalysisOptions: BalanceAnalysisOptions = new BalanceAnalysisOptions();
		const primaryBalanceProperty: AnalysisBalanceProperty = balanceAnalysisOptions.getCorrespondingBalanceType(report.elements[0].parameters.balanceProperty);
		const secondaryBalanceProperty: string = balanceAnalysisOptions.getSecondaryBalanceType(primaryBalanceProperty);
		aggregation.summary = aggregation.summary.map((dataItem: AnalysisBalanceValue) => {
			const newDataItem: Partial<AnalysisBalanceValue> = {
				date: dataItem.date,
			};
			if (primaryBalanceProperty.includes('Converted')) {
				if (primaryBalanceProperty.includes('composite')) {
					newDataItem.compositeFieldConverted = dataItem.compositeFieldConverted;
				}
			} else {
				newDataItem['currencyNative'] = dataItem.currencyNative;
				if (primaryBalanceProperty.includes('composite')) {
					newDataItem.compositeField = dataItem.compositeField;
				}
			}
			newDataItem[primaryBalanceProperty] = dataItem[primaryBalanceProperty];
			if (secondaryBalanceProperty) {
				newDataItem[secondaryBalanceProperty] = dataItem[secondaryBalanceProperty];
			}
			return <AnalysisBalanceValue>newDataItem;
		});

		if (aggregation.aggregation.length) {
			aggregation.aggregation.forEach((childAggregation: AnalysisDataAggregation<AnalysisBalanceValue>) => {
				this.trimBalanceDataAggregation(childAggregation, report);
			});
		}
	}

	@Action(LazyLoadReportsData)
	async lazyLoadReportData(context: StateContext<ReportsStateModel>, action: LazyLoadReportsData): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const state: ReportsStateModel = context.getState();
				const report: ReportV4 = state.reports.find((findReport: ReportV4) => findReport.reportId === action.reportId);
				await this.lazyLoadData(context, report);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(ResetReportsState)
	resetReportsState(context: StateContext<ReportsStateModel>): void {
		context.dispatch(new ClearReportsState());
		context.dispatch(new InitReportsState());
	}

	@Action(ClearReportsState)
	clearReportsState(context: StateContext<ReportsStateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: ReportsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(UpdateChangedReportsState)
	UpdateChangedReportsState(context: StateContext<ReportsStateModel>, action: UpdateChangedReportsState): void {
		const state: ReportsStateModel = context.getState();
		const reportsCopy: ReportV4[] = JSON.parse(JSON.stringify(state.reports));
		action.reports.forEach((report: ReportV4) => {
			report.reportType = ReportType.report;
		});
		action.reports.forEach(changedReport => {
			const i: number = reportsCopy.findIndex(report => report.reportId === changedReport.reportId);
			if (i >= 0) {
				reportsCopy[i] = changedReport;
			} else {
				// incase of newly created report is not in store
				reportsCopy.push(changedReport);
			}
		});
		state.reports = this.sortReportsByName(reportsCopy);
		context.patchState(state);
	}

	private reportsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedReportsState: ReportsStateModel | undefined = deserializedState.reports;
		if (deserializedReportsState && deserializedReportsState.reports && deserializedReportsState.isCached) {
			return true;
		} else {
			return false;
		}
	}

	private addReportsToReportsState(context: StateContext<ReportsStateModel>, sortedReports: ReportV4[]): void {
		const state: ReportsStateModel = context.getState();
		// todo is this necessary?
		sortedReports.forEach((report: ReportV4) => {
			report.reportType = ReportType.report;
		});
		if (sortedReports && state.reports) {
			const newReportsToAdd: ReportV4[] = sortedReports.filter(
				(filterReport: ReportV4) => !state.reports.find((findReport: ReportV4) => filterReport.reportId === findReport.reportId)
			);
			const reportsToBeUpdated: ReportV4[] = sortedReports.filter(
				(filterReport: ReportV4) => !state.reports.find((findReport: ReportV4) => filterReport.lastModifiedDate === findReport.lastModifiedDate)
			);
			if (newReportsToAdd.length) {
				state.reports = state.reports.concat(newReportsToAdd);
				state.reports = this.sortReportsByName(state.reports);
				context.patchState(state);
			} else if (reportsToBeUpdated.length) {
				reportsToBeUpdated.forEach((eachUpdatedReport: ReportV4) => {
					state.reports = state.reports.filter((filterReport: ReportV4) => filterReport.reportId !== eachUpdatedReport.reportId);
				});
				state.reports = state.reports.concat(reportsToBeUpdated);
				state.reports = this.sortReportsByName(state.reports);
				context.patchState(state);
			}
		} else if (sortedReports && !state.reports) {
			state.reports = sortedReports;
			context.patchState(state);
			this.lazyLoadVisibleReportsData(context);
		}
	}

	private async lazyLoadVisibleReportsData(context: StateContext<ReportsStateModel>): Promise<void> {
		try {
			context.patchState({ preFetchInFlight: true });
			const visibleReportIds: string[] = await this.preferncesFacadeService.getVisibleSnackIds(SnackType.report);
			const state: ReportsStateModel = context.getState();
			const reportsToLoad: ReportV4[] = [];
			state.reports.forEach((eachReport: ReportV4) => {
				if (!eachReport.reportData && visibleReportIds.includes(eachReport.reportId)) {
					reportsToLoad.push(eachReport);
				}
			});
			// by catching errors in promise all and returning this allows it to wait for all requests to either error out or complete succesfully
			// todo improve error handling
			await Promise.all(reportsToLoad.map((report: ReportV4) => this.lazyLoadData(context, report).catch(error => new Error(error))));
			context.patchState({ preFetchInFlight: false });
		} catch (error) {
			context.patchState({ preFetchInFlight: false });
			throw error;
		}
	}

	private lazyLoadData(context: StateContext<ReportsStateModel>, report: ReportV4): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				const reportElement: ReportV4Element = report.elements[0];
				const calendarSettings: CalendarSettings = new CalendarSettings(
					reportElement.preferences.calendarSettings.startDate,
					reportElement.preferences.calendarSettings.endDate,
					reportElement.preferences.calendarSettings.lastModifiedDate,
					reportElement.preferences.calendarSettings.rollingType,
					reportElement.preferences.calendarSettings.toDateOption,
					reportElement.preferences.calendarSettings.showWeekends,
					reportElement.preferences.calendarSettings.customOptionCadence,
					reportElement.preferences.calendarSettings.customOptionPeriods
				);
				const reportParameters: ReportV4Parameters = reportElement.parameters;
				const cadence: string = reportParameters.cadence || Cadence.daily;
				const weekends: boolean = calendarSettings.showWeekends === false ? false : true;

				this.reportsService
					.getReportData({
						report: report,
						cadence: cadence,
						includeWeekends: weekends,
						endDate: calendarSettings.endDate,
						startDate: calendarSettings.startDate,
					})
					.subscribe({
						next: async (reportData: ReportV4ElementData[]) => {
							context.dispatch(new AddReportDataToReport(report, reportData));
							resolve();
						},
						error: (httpError: HttpErrorResponse) => {
							report.errorMessage = httpError.message;
							reject();
						},
					});
			} catch (error) {
				report.errorMessage = error;
				reject(error);
			}
		});
	}

	private sortReportsByName(reports: ReportV4[]): ReportV4[] {
		reports = reports.sort((reportA: ReportV4, reportB: ReportV4) => {
			if (reportA.name.toLowerCase() < reportB.name.toLowerCase()) {
				return -1;
			} else if (reportA.name.toLowerCase() > reportB.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return reports;
	}
}
