import { CurrentCash } from '../../models/current-cash.model';

export class InitCurrentCashState {
	static readonly type: string = '[CurrentCash] InitCurrentCashState';
}

export class ClearCurrentCashState {
	static readonly type: string = '[CurrentCash] ClearCurrentCashState';
}

export class ResetCurrentCashState {
	static readonly type: string = '[CurrentCash] ResetCurrentCashState';
}

export class GetCurrentCashReports {
	static readonly type: string = '[CurrentCash] GetCurrentCashReports';
}

export class SaveCurrentCashReport {
	static readonly type: string = '[CurrentCash] SaveCurrentCashReport';
	constructor(public currentCash?: CurrentCash) {}
}

export class DeleteCurrentCashReport {
	static readonly type: string = '[CurrentCash] DeleteCurrentCashReport';
	constructor(public currentCashId: string) {}
}

export class AddDataToCurrentCashReport {
	static readonly type: string = '[CurrentCash] AddDataToCurrentCashReport';
	constructor(public currentCash: CurrentCash) {}
}

export class UpdateChangedCurrentCashState {
	static readonly type: string = '[CurrentCash] UpdateChangedCurrentCashState';
	constructor(public currCashReports: CurrentCash[]) {}
}

export class GetCashPosition {
	static readonly type: string = '[CurrentCash] GetDemoBalance';
	constructor() {}
}
