import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PaymentsAccount, AccountToUpdate, InstitutionInstructions, GetPaymentsAccountsResponse } from '../../models/account.model';
import { AccountsListBody } from '../../models/payment.model';

@Injectable({
	providedIn: 'root',
})
export class PaymentsAccountsService {
	constructor(private httpService: HttpService) {}

	getAccounts(body: AccountsListBody): Observable<GetPaymentsAccountsResponse> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/organization/accounts-list`, body);
	}

	updateAccount(body: AccountToUpdate): Observable<PaymentsAccount> {
		return this.httpService.patch(`${environment.workspaceAPI('payments')}/v1/organization/accounts`, body);
	}

	getInstitutionInstructions(): Observable<InstitutionInstructions> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/institution/instructions`);
	}

	getAccountById(accountId: string): Observable<GetPaymentsAccountsResponse> {
		const body: AccountsListBody = {
			accountId: [accountId],
			includeBalance: true,
		};
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/organization/accounts-list`, body);
	}
}
