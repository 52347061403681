import { Injectable } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentCash } from '../../features/balances/models/current-cash.model';
import { ReportV4 } from '../../features/reports/models/report.model';
import { TrovataAppState } from '../models/state.model';
import { WorkerEventData, WorkerEventDataAction, WorkerEventDataType } from '../models/worker.model';

@Injectable({
	providedIn: 'root',
})
export class WebWorkerService {
	messageEvent$: Subject<MessageEvent>;

	private worker: Worker;

	constructor() {
		this.messageEvent$ = new Subject();
		this.initWebWorker();
	}

	private initWebWorker(): void {
		if (typeof Worker !== 'undefined') {
			this.worker = new Worker(new URL('../utils/web-worker.worker', import.meta.url));

			this.worker.onmessage = (event: MessageEvent) => {
				this.messageEvent$.next(event); // event received from postMessage(response) in web-worker.worker.ts
			};

			this.worker.onerror = error => {
				throw error;
			};
		} else {
			throw new Error('Browser does not support web workers!'); // should never happen for our clients: https://caniuse.com/webworkers
		}
	}

	private decompressString(type: WorkerEventDataType, id: string, compressedString: string) {
		const workerEventData: WorkerEventData = {
			action: WorkerEventDataAction.decompressString,
			type: type,
			id: id,
			param: compressedString,
		};
		this.postMessage(workerEventData);
	}

	private parseString(type: WorkerEventDataType, id: string, JSONString: string) {
		const workerEventData: WorkerEventData = {
			action: WorkerEventDataAction.parseString,
			type: type,
			id: id,
			param: JSONString,
		};
		this.postMessage(workerEventData);
	}

	getCurrentCashHashDict(ccReports: CurrentCash[]) {
		const workerEventData: WorkerEventData = {
			action: WorkerEventDataAction.getCurrentCashHashDict,
			type: WorkerEventDataType.currentCashArray,
			id: null,
			param: ccReports,
		};
		this.postMessage(workerEventData);
	}

	getReportsHashDict(reports: ReportV4[]) {
		const reportsForHashDict: ReportV4[] = reports.filter((report: ReportV4) => report.reportData);
		const workerEventData: WorkerEventData = {
			action: WorkerEventDataAction.getReportsHashDict,
			type: WorkerEventDataType.reportArray,
			id: null,
			param: reportsForHashDict,
		};
		this.postMessage(workerEventData);
	}

	private postMessage(workerEventData: WorkerEventData) {
		this.worker.postMessage(workerEventData); // sent to addEventListener('message') in web-worker.worker.ts
	}

	terminateWorker(): void {
		this.worker.terminate();
	}

	parseState(type: WorkerEventDataType, id: string, string: string): Promise<TrovataAppState> {
		const parseComplete$: Subject<boolean> = new Subject();
		return new Promise((resolve, reject) => {
			try {
				this.messageEvent$.pipe(takeUntil(parseComplete$)).subscribe((event: MessageEvent) => {
					if (event.data.action === WorkerEventDataAction.parseString) {
						const parsedState: string = event.data.id;
						if (parsedState === id) {
							parseComplete$.next(true);
							parseComplete$.complete();
							resolve(event['data'].param);
						}
					}
				});
				this.parseString(type, id, string);
			} catch (err) {
				parseComplete$.next(true);
				parseComplete$.complete();
				reject(err);
			}
		});
	}

	decompress(type: WorkerEventDataType, id: string, compressedString: string): Promise<any> {
		const decompressionComplete$: Subject<boolean> = new Subject();
		return new Promise((resolve, reject) => {
			try {
				this.messageEvent$.pipe(takeUntil(decompressionComplete$)).subscribe((event: MessageEvent) => {
					if (event.data.action === WorkerEventDataAction.decompressedString) {
						const decompressedId: string = event.data.id;
						if (decompressedId === id) {
							decompressionComplete$.next(true);
							decompressionComplete$.complete();
							resolve(event['data'].param);
						}
					}
				});
				this.decompressString(type, id, compressedString);
			} catch (err) {
				decompressionComplete$.next(true);
				decompressionComplete$.complete();
				reject(err);
			}
		});
	}
}
