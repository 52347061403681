export interface Country {
	countryName: CountryName;
	countryCode: CountryCode;
}

export enum CountryCode {
	AF = 'AF',
	AX = 'AX',
	AL = 'AL',
	DZ = 'DZ',
	AS = 'AS',
	AD = 'AD',
	AO = 'AO',
	AI = 'AI',
	AQ = 'AQ',
	AG = 'AG',
	AR = 'AR',
	AM = 'AM',
	AW = 'AW',
	AU = 'AU',
	AT = 'AT',
	AZ = 'AZ',
	BH = 'BH',
	BS = 'BS',
	BD = 'BD',
	BB = 'BB',
	BY = 'BY',
	BE = 'BE',
	BZ = 'BZ',
	BJ = 'BJ',
	BM = 'BM',
	BT = 'BT',
	BO = 'BO',
	BQ = 'BQ',
	BA = 'BA',
	BW = 'BW',
	BV = 'BV',
	BR = 'BR',
	IO = 'IO',
	BN = 'BN',
	BG = 'BG',
	BF = 'BF',
	BI = 'BI',
	KH = 'KH',
	CM = 'CM',
	CA = 'CA',
	CV = 'CV',
	KY = 'KY',
	CF = 'CF',
	TD = 'TD',
	CL = 'CL',
	CN = 'CN',
	CX = 'CX',
	CC = 'CC',
	CO = 'CO',
	KM = 'KM',
	CG = 'CG',
	CD = 'CD',
	CK = 'CK',
	CR = 'CR',
	CI = 'CI',
	HR = 'HR',
	CU = 'CU',
	CW = 'CW',
	CY = 'CY',
	CZ = 'CZ',
	DK = 'DK',
	DJ = 'DJ',
	DM = 'DM',
	DO = 'DO',
	EC = 'EC',
	EG = 'EG',
	SV = 'SV',
	GQ = 'GQ',
	ER = 'ER',
	EE = 'EE',
	ET = 'ET',
	FK = 'FK',
	FO = 'FO',
	FJ = 'FJ',
	FI = 'FI',
	FR = 'FR',
	GF = 'GF',
	PF = 'PF',
	TF = 'TF',
	GA = 'GA',
	GM = 'GM',
	GE = 'GE',
	DE = 'DE',
	GH = 'GH',
	GI = 'GI',
	GR = 'GR',
	GL = 'GL',
	GD = 'GD',
	GP = 'GP',
	GU = 'GU',
	GT = 'GT',
	GG = 'GG',
	GN = 'GN',
	GW = 'GW',
	GY = 'GY',
	HT = 'HT',
	HM = 'HM',
	VA = 'VA',
	HN = 'HN',
	HK = 'HK',
	HU = 'HU',
	IS = 'IS',
	IN = 'IN',
	ID = 'ID',
	IR = 'IR',
	IQ = 'IQ',
	IE = 'IE',
	IM = 'IM',
	IL = 'IL',
	IT = 'IT',
	JM = 'JM',
	JP = 'JP',
	JE = 'JE',
	JO = 'JO',
	KZ = 'KZ',
	KE = 'KE',
	KI = 'KI',
	KP = 'KP',
	KR = 'KR',
	KW = 'KW',
	KG = 'KG',
	LA = 'LA',
	LV = 'LV',
	LB = 'LB',
	LS = 'LS',
	LR = 'LR',
	LY = 'LY',
	LI = 'LI',
	LT = 'LT',
	LU = 'LU',
	MO = 'MO',
	MK = 'MK',
	MG = 'MG',
	MW = 'MW',
	MY = 'MY',
	MV = 'MV',
	ML = 'ML',
	MT = 'MT',
	MH = 'MH',
	MQ = 'MQ',
	MR = 'MR',
	MU = 'MU',
	YT = 'YT',
	MX = 'MX',
	FM = 'FM',
	MD = 'MD',
	MC = 'MC',
	MN = 'MN',
	ME = 'ME',
	MS = 'MS',
	MA = 'MA',
	MZ = 'MZ',
	MM = 'MM',
	NA = 'NA',
	NR = 'NR',
	NP = 'NP',
	NL = 'NL',
	NC = 'NC',
	NZ = 'NZ',
	NI = 'NI',
	NE = 'NE',
	NG = 'NG',
	NU = 'NU',
	NF = 'NF',
	MP = 'MP',
	NO = 'NO',
	OM = 'OM',
	PK = 'PK',
	PW = 'PW',
	PS = 'PS',
	PA = 'PA',
	PG = 'PG',
	PY = 'PY',
	PE = 'PE',
	PH = 'PH',
	PN = 'PN',
	PL = 'PL',
	PT = 'PT',
	PR = 'PR',
	QA = 'QA',
	RE = 'RE',
	RO = 'RO',
	RU = 'RU',
	RW = 'RW',
	BL = 'BL',
	SH = 'SH',
	KN = 'KN',
	LC = 'LC',
	MF = 'MF',
	PM = 'PM',
	VC = 'VC',
	WS = 'WS',
	SM = 'SM',
	ST = 'ST',
	SA = 'SA',
	SN = 'SN',
	RS = 'RS',
	SC = 'SC',
	SL = 'SL',
	SG = 'SG',
	SX = 'SX',
	SK = 'SK',
	SI = 'SI',
	SB = 'SB',
	SO = 'SO',
	ZA = 'ZA',
	GS = 'GS',
	SS = 'SS',
	ES = 'ES',
	LK = 'LK',
	SD = 'SD',
	SR = 'SR',
	SJ = 'SJ',
	SZ = 'SZ',
	SE = 'SE',
	CH = 'CH',
	SY = 'SY',
	TW = 'TW',
	TJ = 'TJ',
	TZ = 'TZ',
	TH = 'TH',
	TL = 'TL',
	TG = 'TG',
	TK = 'TK',
	TO = 'TO',
	TT = 'TT',
	TN = 'TN',
	TR = 'TR',
	TM = 'TM',
	TC = 'TC',
	TV = 'TV',
	UG = 'UG',
	UA = 'UA',
	AE = 'AE',
	GB = 'GB',
	US = 'US',
	UM = 'UM',
	UY = 'UY',
	UZ = 'UZ',
	VU = 'VU',
	VE = 'VE',
	VN = 'VN',
	VG = 'VG',
	VI = 'VI',
	WF = 'WF',
	EH = 'EH',
	YE = 'YE',
	ZM = 'ZM',
	ZW = 'ZW',
}

export enum CountryName {
	AFGHANISTAN = 'Afghanistan',
	ÅLAND_ISLANDS = 'Åland Islands',
	ALBANIA = 'Albania',
	ALGERIA = 'Algeria',
	AMERICAN_SAMOA = 'American Samoa',
	ANDORRA = 'Andorra',
	ANGOLA = 'Angola',
	ANGUILLA = 'Anguilla',
	ANTARCTICA = 'Antarctica',
	ANTIGUA_AND_BARBUDA = 'Antigua and Barbuda',
	ARGENTINA = 'Argentina',
	ARMENIA = 'Armenia',
	ARUBA = 'Aruba',
	AUSTRALIA = 'Australia',
	AUSTRIA = 'Austria',
	AZERBAIJAN = 'Azerbaijan',
	BAHRAIN = 'Bahrain',
	BAHAMAS = 'Bahamas',
	BANGLADESH = 'Bangladesh',
	BARBADOS = 'Barbados',
	BELARUS = 'Belarus',
	BELGIUM = 'Belgium',
	BELIZE = 'Belize',
	BENIN = 'Benin',
	BERMUDA = 'Bermuda',
	BHUTAN = 'Bhutan',
	BOLIVIA_PLURINATIONAL_STATE_OF = 'Bolivia, Plurinational State of',
	BONAIRE_SINT_EUSTATIUS_AND_SABA = 'Bonaire, Sint Eustatius and Saba',
	BOSNIA_AND_HERZEGOVINA = 'Bosnia and Herzegovina',
	BOTSWANA = 'Botswana',
	BOUVET_ISLAND = 'Bouvet Island',
	BRAZIL = 'Brazil',
	BRITISH_INDIAN_OCEAN_TERRITORY = 'British Indian Ocean Territory',
	BRUNEI_DARUSSALAM = 'Brunei Darussalam',
	BULGARIA = 'Bulgaria',
	BURKINA_FASO = 'Burkina Faso',
	BURUNDI = 'Burundi',
	CAMBODIA = 'Cambodia',
	CAMEROON = 'Cameroon',
	CANADA = 'Canada',
	CAPE_VERDE = 'Cape Verde',
	CAYMAN_ISLANDS = 'Cayman Islands',
	CENTRAL_AFRICAN_REPUBLIC = 'Central African Republic',
	CHAD = 'Chad',
	CHILE = 'Chile',
	CHINA = 'China',
	CHRISTMAS_ISLAND = 'Christmas Island',
	COCOS_KEELING_ISLANDS = 'Cocos (Keeling) Islands',
	COLOMBIA = 'Colombia',
	COMOROS = 'Comoros',
	CONGO = 'Congo',
	CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE = 'Congo, the Democratic Republic of the',
	COOK_ISLANDS = 'Cook Islands',
	COSTA_RICA = 'Costa Rica',
	CÔTE_DIVOIRE = 'Côte d`Ivoire',
	CROATIA = 'Croatia',
	CUBA = 'Cuba',
	CURAÇAO = 'Curaçao',
	CYPRUS = 'Cyprus',
	CZECH_REPUBLIC = 'Czech Republic',
	DENMARK = 'Denmark',
	DJIBOUTI = 'Djibouti',
	DOMINICA = 'Dominica',
	DOMINICAN_REPUBLIC = 'Dominican Republic',
	ECUADOR = 'Ecuador',
	EGYPT = 'Egypt',
	EL_SALVADOR = 'El Salvador',
	EQUATORIAL_GUINEA = 'Equatorial Guinea',
	ERITREA = 'Eritrea',
	ESTONIA = 'Estonia',
	ETHIOPIA = 'Ethiopia',
	FALKLAND_ISLANDS_MALVINAS = 'Falkland Islands (Malvinas)',
	FAROE_ISLANDS = 'Faroe Islands',
	FIJI = 'Fiji',
	FINLAND = 'Finland',
	FRANCE = 'France',
	FRENCH_GUIANA = 'French Guiana',
	FRENCH_POLYNESIA = 'French Polynesia',
	FRENCH_SOUTHERN_TERRITORIES = 'French Southern Territories',
	GABON = 'Gabon',
	GAMBIA = 'Gambia',
	GEORGIA = 'Georgia',
	GERMANY = 'Germany',
	GHANA = 'Ghana',
	GIBRALTAR = 'Gibraltar',
	GREECE = 'Greece',
	GREENLAND = 'Greenland',
	GRENADA = 'Grenada',
	GUADELOUPE = 'Guadeloupe',
	GUAM = 'Guam',
	GUATEMALA = 'Guatemala',
	GUERNSEY = 'Guernsey',
	GUINEA = 'Guinea',
	GUINEA_BISSAU = 'Guinea-Bissau',
	GUYANA = 'Guyana',
	HAITI = 'Haiti',
	HEARD_ISLAND_AND_MCDONALD_ISLANDS = 'Heard Island and McDonald Islands',
	HOLY_SEE_VATICAN_CITY_STATE = 'Holy See (Vatican City State)',
	HONDURAS = 'Honduras',
	HONG_KONG = 'Hong Kong',
	HUNGARY = 'Hungary',
	ICELAND = 'Iceland',
	INDIA = 'India',
	INDONESIA = 'Indonesia',
	IRAN_ISLAMIC_REPUBLIC_OF = 'Iran, Islamic Republic of',
	IRAQ = 'Iraq',
	IRELAND = 'Ireland',
	ISLE_OF_MAN = 'Isle of Man',
	ISRAEL = 'Israel',
	ITALY = 'Italy',
	JAMAICA = 'Jamaica',
	JAPAN = 'Japan',
	JERSEY = 'Jersey',
	JORDAN = 'Jordan',
	KAZAKHSTAN = 'Kazakhstan',
	KENYA = 'Kenya',
	KIRIBATI = 'Kiribati',
	KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF = 'Korea, Democratic People`s Republic of',
	KOREA_REPUBLIC_OF = 'Korea, Republic of',
	KUWAIT = 'Kuwait',
	KYRGYZSTAN = 'Kyrgyzstan',
	LAO_PEOPLES_DEMOCRATIC_REPUBLIC = 'Lao People`s Democratic Republic',
	LATVIA = 'Latvia',
	LEBANON = 'Lebanon',
	LESOTHO = 'Lesotho',
	LIBERIA = 'Liberia',
	LIBYA = 'Libya',
	LIECHTENSTEIN = 'Liechtenstein',
	LITHUANIA = 'Lithuania',
	LUXEMBOURG = 'Luxembourg',
	MACAO = 'Macao',
	MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF = 'Macedonia, the Former Yugoslav Republic of',
	MADAGASCAR = 'Madagascar',
	MALAWI = 'Malawi',
	MALAYSIA = 'Malaysia',
	MALDIVES = 'Maldives',
	MALI = 'Mali',
	MALTA = 'Malta',
	MARSHALL_ISLANDS = 'Marshall Islands',
	MARTINIQUE = 'Martinique',
	MAURITANIA = 'Mauritania',
	MAURITIUS = 'Mauritius',
	MAYOTTE = 'Mayotte',
	MEXICO = 'Mexico',
	MICRONESIA_FEDERATED_STATES_OF = 'Micronesia, Federated States of',
	MOLDOVA_REPUBLIC_OF = 'Moldova, Republic of',
	MONACO = 'Monaco',
	MONGOLIA = 'Mongolia',
	MONTENEGRO = 'Montenegro',
	MONTSERRAT = 'Montserrat',
	MOROCCO = 'Morocco',
	MOZAMBIQUE = 'Mozambique',
	MYANMAR = 'Myanmar',
	NAMIBIA = 'Namibia',
	NAURU = 'Nauru',
	NEPAL = 'Nepal',
	NETHERLANDS = 'Netherlands',
	NEW_CALEDONIA = 'New Caledonia',
	NEW_ZEALAND = 'New Zealand',
	NICARAGUA = 'Nicaragua',
	NIGER = 'Niger',
	NIGERIA = 'Nigeria',
	NIUE = 'Niue',
	NORFOLK_ISLAND = 'Norfolk Island',
	NORTHERN_MARIANA_ISLANDS = 'Northern Mariana Islands',
	NORWAY = 'Norway',
	OMAN = 'Oman',
	PAKISTAN = 'Pakistan',
	PALAU = 'Palau',
	PALESTINE_STATE_OF = 'Palestine, State of',
	PANAMA = 'Panama',
	PAPUA_NEW_GUINEA = 'Papua New Guinea',
	PARAGUAY = 'Paraguay',
	PERU = 'Peru',
	PHILIPPINES = 'Philippines',
	PITCAIRN = 'Pitcairn',
	POLAND = 'Poland',
	PORTUGAL = 'Portugal',
	PUERTO_RICO = 'Puerto Rico',
	QATAR = 'Qatar',
	RÉUNION = 'Réunion',
	ROMANIA = 'Romania',
	RUSSIAN_FEDERATION = 'Russian Federation',
	RWANDA = 'Rwanda',
	SAINT_BARTHÉLEMY = 'Saint Barthélemy',
	SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA = 'Saint Helena, Ascension and Tristan da Cunha',
	SAINT_KITTS_AND_NEVIS = 'Saint Kitts and Nevis',
	SAINT_LUCIA = 'Saint Lucia',
	SAINT_MARTIN_FRENCH_PART = 'Saint Martin (French part)',
	SAINT_PIERRE_AND_MIQUELON = 'Saint Pierre and Miquelon',
	SAINT_VINCENT_AND_THE_GRENADINES = 'Saint Vincent and the Grenadines',
	SAMOA = 'Samoa',
	SAN_MARINO = 'San Marino',
	SAO_TOME_AND_PRINCIPE = 'Sao Tome and Principe',
	SAUDI_ARABIA = 'Saudi Arabia',
	SENEGAL = 'Senegal',
	SERBIA = 'Serbia',
	SEYCHELLES = 'Seychelles',
	SIERRA_LEONE = 'Sierra Leone',
	SINGAPORE = 'Singapore',
	SINT_MAARTEN_DUTCH_PART = 'Sint Maarten (Dutch part)',
	SLOVAKIA = 'Slovakia',
	SLOVENIA = 'Slovenia',
	SOLOMON_ISLANDS = 'Solomon Islands',
	SOMALIA = 'Somalia',
	SOUTH_AFRICA = 'South Africa',
	SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'South Georgia and the South Sandwich Islands',
	SOUTH_SUDAN = 'South Sudan',
	SPAIN = 'Spain',
	SRI_LANKA = 'Sri Lanka',
	SUDAN = 'Sudan',
	SURINAME = 'Suriname',
	SVALBARD_AND_JAN_MAYEN = 'Svalbard and Jan Mayen',
	SWAZILAND = 'Swaziland',
	SWEDEN = 'Sweden',
	SWITZERLAND = 'Switzerland',
	SYRIAN_ARAB_REPUBLIC = 'Syrian Arab Republic',
	TAIWAN_PROVINCE_OF_CHINA = 'Taiwan, Province of China',
	TAJIKISTAN = 'Tajikistan',
	TANZANIA_UNITED_REPUBLIC_OF = 'Tanzania, United Republic of',
	THAILAND = 'Thailand',
	TIMOR_LESTE = 'Timor-Leste',
	TOGO = 'Togo',
	TOKELAU = 'Tokelau',
	TONGA = 'Tonga',
	TRINIDAD_AND_TOBAGO = 'Trinidad and Tobago',
	TUNISIA = 'Tunisia',
	TURKEY = 'Turkey',
	TURKMENISTAN = 'Turkmenistan',
	TURKS_AND_CAICOS_ISLANDS = 'Turks and Caicos Islands',
	TUVALU = 'Tuvalu',
	UGANDA = 'Uganda',
	UKRAINE = 'Ukraine',
	UNITED_ARAB_EMIRATES = 'United Arab Emirates',
	UNITED_KINGDOM = 'United Kingdom',
	UNITED_STATES = 'United States',
	UNITED_STATES_MINOR_OUTLYING_ISLANDS = 'United States Minor Outlying Islands',
	URUGUAY = 'Uruguay',
	UZBEKISTAN = 'Uzbekistan',
	VANUATU = 'Vanuatu',
	VENEZUELA_BOLIVARIAN_REPUBLIC_OF = 'Venezuela, Bolivarian Republic of',
	VIET_NAM = 'Viet Nam',
	VIRGIN_ISLANDS_BRITISH = 'Virgin Islands, British',
	VIRGIN_ISLANDS_US = 'Virgin Islands, U.S.',
	WALLIS_AND_FUTUNA = 'Wallis and Futuna',
	WESTERN_SAHARA = 'Western Sahara',
	YEMEN = 'Yemen',
	ZAMBIA = 'Zambia',
	ZIMBABWE = 'Zimbabwe',
}

export const COUNTRIES: Country[] = [
	{ countryName: CountryName.UNITED_STATES, countryCode: CountryCode.US },
	{ countryName: CountryName.AFGHANISTAN, countryCode: CountryCode.AF },
	{ countryName: CountryName.ÅLAND_ISLANDS, countryCode: CountryCode.AX },
	{ countryName: CountryName.ALBANIA, countryCode: CountryCode.AL },
	{ countryName: CountryName.ALGERIA, countryCode: CountryCode.DZ },
	{ countryName: CountryName.AMERICAN_SAMOA, countryCode: CountryCode.AS },
	{ countryName: CountryName.ANDORRA, countryCode: CountryCode.AD },
	{ countryName: CountryName.ANGOLA, countryCode: CountryCode.AO },
	{ countryName: CountryName.ANGUILLA, countryCode: CountryCode.AI },
	{ countryName: CountryName.ANTARCTICA, countryCode: CountryCode.AQ },
	{ countryName: CountryName.ANTIGUA_AND_BARBUDA, countryCode: CountryCode.AG },
	{ countryName: CountryName.ARGENTINA, countryCode: CountryCode.AR },
	{ countryName: CountryName.ARMENIA, countryCode: CountryCode.AM },
	{ countryName: CountryName.ARUBA, countryCode: CountryCode.AW },
	{ countryName: CountryName.AUSTRALIA, countryCode: CountryCode.AU },
	{ countryName: CountryName.AUSTRIA, countryCode: CountryCode.AT },
	{ countryName: CountryName.AZERBAIJAN, countryCode: CountryCode.AZ },
	{ countryName: CountryName.BAHRAIN, countryCode: CountryCode.BH },
	{ countryName: CountryName.BAHAMAS, countryCode: CountryCode.BS },
	{ countryName: CountryName.BANGLADESH, countryCode: CountryCode.BD },
	{ countryName: CountryName.BARBADOS, countryCode: CountryCode.BB },
	{ countryName: CountryName.BELARUS, countryCode: CountryCode.BY },
	{ countryName: CountryName.BELGIUM, countryCode: CountryCode.BE },
	{ countryName: CountryName.BELIZE, countryCode: CountryCode.BZ },
	{ countryName: CountryName.BENIN, countryCode: CountryCode.BJ },
	{ countryName: CountryName.BERMUDA, countryCode: CountryCode.BM },
	{ countryName: CountryName.BHUTAN, countryCode: CountryCode.BT },
	{ countryName: CountryName.BOLIVIA_PLURINATIONAL_STATE_OF, countryCode: CountryCode.BO },
	{ countryName: CountryName.BONAIRE_SINT_EUSTATIUS_AND_SABA, countryCode: CountryCode.BQ },
	{ countryName: CountryName.BOSNIA_AND_HERZEGOVINA, countryCode: CountryCode.BA },
	{ countryName: CountryName.BOTSWANA, countryCode: CountryCode.BW },
	{ countryName: CountryName.BOUVET_ISLAND, countryCode: CountryCode.BV },
	{ countryName: CountryName.BRAZIL, countryCode: CountryCode.BR },
	{ countryName: CountryName.BRITISH_INDIAN_OCEAN_TERRITORY, countryCode: CountryCode.IO },
	{ countryName: CountryName.BRUNEI_DARUSSALAM, countryCode: CountryCode.BN },
	{ countryName: CountryName.BULGARIA, countryCode: CountryCode.BG },
	{ countryName: CountryName.BURKINA_FASO, countryCode: CountryCode.BF },
	{ countryName: CountryName.BURUNDI, countryCode: CountryCode.BI },
	{ countryName: CountryName.CAMBODIA, countryCode: CountryCode.KH },
	{ countryName: CountryName.CAMEROON, countryCode: CountryCode.CM },
	{ countryName: CountryName.CANADA, countryCode: CountryCode.CA },
	{ countryName: CountryName.CAPE_VERDE, countryCode: CountryCode.CV },
	{ countryName: CountryName.CAYMAN_ISLANDS, countryCode: CountryCode.KY },
	{ countryName: CountryName.CENTRAL_AFRICAN_REPUBLIC, countryCode: CountryCode.CF },
	{ countryName: CountryName.CHAD, countryCode: CountryCode.TD },
	{ countryName: CountryName.CHILE, countryCode: CountryCode.CL },
	{ countryName: CountryName.CHINA, countryCode: CountryCode.CN },
	{ countryName: CountryName.CHRISTMAS_ISLAND, countryCode: CountryCode.CX },
	{ countryName: CountryName.COCOS_KEELING_ISLANDS, countryCode: CountryCode.CC },
	{ countryName: CountryName.COLOMBIA, countryCode: CountryCode.CO },
	{ countryName: CountryName.COMOROS, countryCode: CountryCode.KM },
	{ countryName: CountryName.CONGO, countryCode: CountryCode.CG },
	{ countryName: CountryName.CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE, countryCode: CountryCode.CD },
	{ countryName: CountryName.COOK_ISLANDS, countryCode: CountryCode.CK },
	{ countryName: CountryName.COSTA_RICA, countryCode: CountryCode.CR },
	{ countryName: CountryName.CÔTE_DIVOIRE, countryCode: CountryCode.CI },
	{ countryName: CountryName.CROATIA, countryCode: CountryCode.HR },
	{ countryName: CountryName.CUBA, countryCode: CountryCode.CU },
	{ countryName: CountryName.CURAÇAO, countryCode: CountryCode.CW },
	{ countryName: CountryName.CYPRUS, countryCode: CountryCode.CY },
	{ countryName: CountryName.CZECH_REPUBLIC, countryCode: CountryCode.CZ },
	{ countryName: CountryName.DENMARK, countryCode: CountryCode.DK },
	{ countryName: CountryName.DJIBOUTI, countryCode: CountryCode.DJ },
	{ countryName: CountryName.DOMINICA, countryCode: CountryCode.DM },
	{ countryName: CountryName.DOMINICAN_REPUBLIC, countryCode: CountryCode.DO },
	{ countryName: CountryName.ECUADOR, countryCode: CountryCode.EC },
	{ countryName: CountryName.EGYPT, countryCode: CountryCode.EG },
	{ countryName: CountryName.EL_SALVADOR, countryCode: CountryCode.SV },
	{ countryName: CountryName.EQUATORIAL_GUINEA, countryCode: CountryCode.GQ },
	{ countryName: CountryName.ERITREA, countryCode: CountryCode.ER },
	{ countryName: CountryName.ESTONIA, countryCode: CountryCode.EE },
	{ countryName: CountryName.ETHIOPIA, countryCode: CountryCode.ET },
	{ countryName: CountryName.FALKLAND_ISLANDS_MALVINAS, countryCode: CountryCode.FK },
	{ countryName: CountryName.FAROE_ISLANDS, countryCode: CountryCode.FO },
	{ countryName: CountryName.FIJI, countryCode: CountryCode.FJ },
	{ countryName: CountryName.FINLAND, countryCode: CountryCode.FI },
	{ countryName: CountryName.FRANCE, countryCode: CountryCode.FR },
	{ countryName: CountryName.FRENCH_GUIANA, countryCode: CountryCode.GF },
	{ countryName: CountryName.FRENCH_POLYNESIA, countryCode: CountryCode.PF },
	{ countryName: CountryName.FRENCH_SOUTHERN_TERRITORIES, countryCode: CountryCode.TF },
	{ countryName: CountryName.GABON, countryCode: CountryCode.GA },
	{ countryName: CountryName.GAMBIA, countryCode: CountryCode.GM },
	{ countryName: CountryName.GEORGIA, countryCode: CountryCode.GE },
	{ countryName: CountryName.GERMANY, countryCode: CountryCode.DE },
	{ countryName: CountryName.GHANA, countryCode: CountryCode.GH },
	{ countryName: CountryName.GIBRALTAR, countryCode: CountryCode.GI },
	{ countryName: CountryName.GREECE, countryCode: CountryCode.GR },
	{ countryName: CountryName.GREENLAND, countryCode: CountryCode.GL },
	{ countryName: CountryName.GRENADA, countryCode: CountryCode.GD },
	{ countryName: CountryName.GUADELOUPE, countryCode: CountryCode.GP },
	{ countryName: CountryName.GUAM, countryCode: CountryCode.GU },
	{ countryName: CountryName.GUATEMALA, countryCode: CountryCode.GT },
	{ countryName: CountryName.GUERNSEY, countryCode: CountryCode.GG },
	{ countryName: CountryName.GUINEA, countryCode: CountryCode.GN },
	{ countryName: CountryName.GUINEA_BISSAU, countryCode: CountryCode.GW },
	{ countryName: CountryName.GUYANA, countryCode: CountryCode.GY },
	{ countryName: CountryName.HAITI, countryCode: CountryCode.HT },
	{ countryName: CountryName.HEARD_ISLAND_AND_MCDONALD_ISLANDS, countryCode: CountryCode.HM },
	{ countryName: CountryName.HOLY_SEE_VATICAN_CITY_STATE, countryCode: CountryCode.VA },
	{ countryName: CountryName.HONDURAS, countryCode: CountryCode.HN },
	{ countryName: CountryName.HONG_KONG, countryCode: CountryCode.HK },
	{ countryName: CountryName.HUNGARY, countryCode: CountryCode.HU },
	{ countryName: CountryName.ICELAND, countryCode: CountryCode.IS },
	{ countryName: CountryName.INDIA, countryCode: CountryCode.IN },
	{ countryName: CountryName.INDONESIA, countryCode: CountryCode.ID },
	{ countryName: CountryName.IRAN_ISLAMIC_REPUBLIC_OF, countryCode: CountryCode.IR },
	{ countryName: CountryName.IRAQ, countryCode: CountryCode.IQ },
	{ countryName: CountryName.IRELAND, countryCode: CountryCode.IE },
	{ countryName: CountryName.ISLE_OF_MAN, countryCode: CountryCode.IM },
	{ countryName: CountryName.ISRAEL, countryCode: CountryCode.IL },
	{ countryName: CountryName.ITALY, countryCode: CountryCode.IT },
	{ countryName: CountryName.JAMAICA, countryCode: CountryCode.JM },
	{ countryName: CountryName.JAPAN, countryCode: CountryCode.JP },
	{ countryName: CountryName.JERSEY, countryCode: CountryCode.JE },
	{ countryName: CountryName.JORDAN, countryCode: CountryCode.JO },
	{ countryName: CountryName.KAZAKHSTAN, countryCode: CountryCode.KZ },
	{ countryName: CountryName.KENYA, countryCode: CountryCode.KE },
	{ countryName: CountryName.KIRIBATI, countryCode: CountryCode.KI },
	{ countryName: CountryName.KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF, countryCode: CountryCode.KP },
	{ countryName: CountryName.KOREA_REPUBLIC_OF, countryCode: CountryCode.KR },
	{ countryName: CountryName.KUWAIT, countryCode: CountryCode.KW },
	{ countryName: CountryName.KYRGYZSTAN, countryCode: CountryCode.KG },
	{ countryName: CountryName.LAO_PEOPLES_DEMOCRATIC_REPUBLIC, countryCode: CountryCode.LA },
	{ countryName: CountryName.LATVIA, countryCode: CountryCode.LV },
	{ countryName: CountryName.LEBANON, countryCode: CountryCode.LB },
	{ countryName: CountryName.LESOTHO, countryCode: CountryCode.LS },
	{ countryName: CountryName.LIBERIA, countryCode: CountryCode.LR },
	{ countryName: CountryName.LIBYA, countryCode: CountryCode.LY },
	{ countryName: CountryName.LIECHTENSTEIN, countryCode: CountryCode.LI },
	{ countryName: CountryName.LITHUANIA, countryCode: CountryCode.LT },
	{ countryName: CountryName.LUXEMBOURG, countryCode: CountryCode.LU },
	{ countryName: CountryName.MACAO, countryCode: CountryCode.MO },
	{ countryName: CountryName.MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF, countryCode: CountryCode.MK },
	{ countryName: CountryName.MADAGASCAR, countryCode: CountryCode.MG },
	{ countryName: CountryName.MALAWI, countryCode: CountryCode.MW },
	{ countryName: CountryName.MALAYSIA, countryCode: CountryCode.MY },
	{ countryName: CountryName.MALDIVES, countryCode: CountryCode.MV },
	{ countryName: CountryName.MALI, countryCode: CountryCode.ML },
	{ countryName: CountryName.MALTA, countryCode: CountryCode.MT },
	{ countryName: CountryName.MARSHALL_ISLANDS, countryCode: CountryCode.MH },
	{ countryName: CountryName.MARTINIQUE, countryCode: CountryCode.MQ },
	{ countryName: CountryName.MAURITANIA, countryCode: CountryCode.MR },
	{ countryName: CountryName.MAURITIUS, countryCode: CountryCode.MU },
	{ countryName: CountryName.MAYOTTE, countryCode: CountryCode.YT },
	{ countryName: CountryName.MEXICO, countryCode: CountryCode.MX },
	{ countryName: CountryName.MICRONESIA_FEDERATED_STATES_OF, countryCode: CountryCode.FM },
	{ countryName: CountryName.MOLDOVA_REPUBLIC_OF, countryCode: CountryCode.MD },
	{ countryName: CountryName.MONACO, countryCode: CountryCode.MC },
	{ countryName: CountryName.MONGOLIA, countryCode: CountryCode.MN },
	{ countryName: CountryName.MONTENEGRO, countryCode: CountryCode.ME },
	{ countryName: CountryName.MONTSERRAT, countryCode: CountryCode.MS },
	{ countryName: CountryName.MOROCCO, countryCode: CountryCode.MA },
	{ countryName: CountryName.MOZAMBIQUE, countryCode: CountryCode.MZ },
	{ countryName: CountryName.MYANMAR, countryCode: CountryCode.MM },
	{ countryName: CountryName.NAMIBIA, countryCode: CountryCode.NA },
	{ countryName: CountryName.NAURU, countryCode: CountryCode.NR },
	{ countryName: CountryName.NEPAL, countryCode: CountryCode.NP },
	{ countryName: CountryName.NETHERLANDS, countryCode: CountryCode.NL },
	{ countryName: CountryName.NEW_CALEDONIA, countryCode: CountryCode.NC },
	{ countryName: CountryName.NEW_ZEALAND, countryCode: CountryCode.NZ },
	{ countryName: CountryName.NICARAGUA, countryCode: CountryCode.NI },
	{ countryName: CountryName.NIGER, countryCode: CountryCode.NE },
	{ countryName: CountryName.NIGERIA, countryCode: CountryCode.NG },
	{ countryName: CountryName.NIUE, countryCode: CountryCode.NU },
	{ countryName: CountryName.NORFOLK_ISLAND, countryCode: CountryCode.NF },
	{ countryName: CountryName.NORTHERN_MARIANA_ISLANDS, countryCode: CountryCode.MP },
	{ countryName: CountryName.NORWAY, countryCode: CountryCode.NO },
	{ countryName: CountryName.OMAN, countryCode: CountryCode.OM },
	{ countryName: CountryName.PAKISTAN, countryCode: CountryCode.PK },
	{ countryName: CountryName.PALAU, countryCode: CountryCode.PW },
	{ countryName: CountryName.PALESTINE_STATE_OF, countryCode: CountryCode.PS },
	{ countryName: CountryName.PANAMA, countryCode: CountryCode.PA },
	{ countryName: CountryName.PAPUA_NEW_GUINEA, countryCode: CountryCode.PG },
	{ countryName: CountryName.PARAGUAY, countryCode: CountryCode.PY },
	{ countryName: CountryName.PERU, countryCode: CountryCode.PE },
	{ countryName: CountryName.PHILIPPINES, countryCode: CountryCode.PH },
	{ countryName: CountryName.PITCAIRN, countryCode: CountryCode.PN },
	{ countryName: CountryName.POLAND, countryCode: CountryCode.PL },
	{ countryName: CountryName.PORTUGAL, countryCode: CountryCode.PT },
	{ countryName: CountryName.PUERTO_RICO, countryCode: CountryCode.PR },
	{ countryName: CountryName.QATAR, countryCode: CountryCode.QA },
	{ countryName: CountryName.RÉUNION, countryCode: CountryCode.RE },
	{ countryName: CountryName.ROMANIA, countryCode: CountryCode.RO },
	{ countryName: CountryName.RUSSIAN_FEDERATION, countryCode: CountryCode.RU },
	{ countryName: CountryName.RWANDA, countryCode: CountryCode.RW },
	{ countryName: CountryName.SAINT_BARTHÉLEMY, countryCode: CountryCode.BL },
	{ countryName: CountryName.SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA, countryCode: CountryCode.SH },
	{ countryName: CountryName.SAINT_KITTS_AND_NEVIS, countryCode: CountryCode.KN },
	{ countryName: CountryName.SAINT_LUCIA, countryCode: CountryCode.LC },
	{ countryName: CountryName.SAINT_MARTIN_FRENCH_PART, countryCode: CountryCode.MF },
	{ countryName: CountryName.SAINT_PIERRE_AND_MIQUELON, countryCode: CountryCode.PM },
	{ countryName: CountryName.SAINT_VINCENT_AND_THE_GRENADINES, countryCode: CountryCode.VC },
	{ countryName: CountryName.SAMOA, countryCode: CountryCode.WS },
	{ countryName: CountryName.SAN_MARINO, countryCode: CountryCode.SM },
	{ countryName: CountryName.SAO_TOME_AND_PRINCIPE, countryCode: CountryCode.ST },
	{ countryName: CountryName.SAUDI_ARABIA, countryCode: CountryCode.SA },
	{ countryName: CountryName.SENEGAL, countryCode: CountryCode.SN },
	{ countryName: CountryName.SERBIA, countryCode: CountryCode.RS },
	{ countryName: CountryName.SEYCHELLES, countryCode: CountryCode.SC },
	{ countryName: CountryName.SIERRA_LEONE, countryCode: CountryCode.SL },
	{ countryName: CountryName.SINGAPORE, countryCode: CountryCode.SG },
	{ countryName: CountryName.SINT_MAARTEN_DUTCH_PART, countryCode: CountryCode.SX },
	{ countryName: CountryName.SLOVAKIA, countryCode: CountryCode.SK },
	{ countryName: CountryName.SLOVENIA, countryCode: CountryCode.SI },
	{ countryName: CountryName.SOLOMON_ISLANDS, countryCode: CountryCode.SB },
	{ countryName: CountryName.SOMALIA, countryCode: CountryCode.SO },
	{ countryName: CountryName.SOUTH_AFRICA, countryCode: CountryCode.ZA },
	{ countryName: CountryName.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS, countryCode: CountryCode.GS },
	{ countryName: CountryName.SOUTH_SUDAN, countryCode: CountryCode.SS },
	{ countryName: CountryName.SPAIN, countryCode: CountryCode.ES },
	{ countryName: CountryName.SRI_LANKA, countryCode: CountryCode.LK },
	{ countryName: CountryName.SUDAN, countryCode: CountryCode.SD },
	{ countryName: CountryName.SURINAME, countryCode: CountryCode.SR },
	{ countryName: CountryName.SVALBARD_AND_JAN_MAYEN, countryCode: CountryCode.SJ },
	{ countryName: CountryName.SWAZILAND, countryCode: CountryCode.SZ },
	{ countryName: CountryName.SWEDEN, countryCode: CountryCode.SE },
	{ countryName: CountryName.SWITZERLAND, countryCode: CountryCode.CH },
	{ countryName: CountryName.SYRIAN_ARAB_REPUBLIC, countryCode: CountryCode.SY },
	{ countryName: CountryName.TAIWAN_PROVINCE_OF_CHINA, countryCode: CountryCode.TW },
	{ countryName: CountryName.TAJIKISTAN, countryCode: CountryCode.TJ },
	{ countryName: CountryName.TANZANIA_UNITED_REPUBLIC_OF, countryCode: CountryCode.TZ },
	{ countryName: CountryName.THAILAND, countryCode: CountryCode.TH },
	{ countryName: CountryName.TIMOR_LESTE, countryCode: CountryCode.TL },
	{ countryName: CountryName.TOGO, countryCode: CountryCode.TG },
	{ countryName: CountryName.TOKELAU, countryCode: CountryCode.TK },
	{ countryName: CountryName.TONGA, countryCode: CountryCode.TO },
	{ countryName: CountryName.TRINIDAD_AND_TOBAGO, countryCode: CountryCode.TT },
	{ countryName: CountryName.TUNISIA, countryCode: CountryCode.TN },
	{ countryName: CountryName.TURKEY, countryCode: CountryCode.TR },
	{ countryName: CountryName.TURKMENISTAN, countryCode: CountryCode.TM },
	{ countryName: CountryName.TURKS_AND_CAICOS_ISLANDS, countryCode: CountryCode.TC },
	{ countryName: CountryName.TUVALU, countryCode: CountryCode.TV },
	{ countryName: CountryName.UGANDA, countryCode: CountryCode.UG },
	{ countryName: CountryName.UKRAINE, countryCode: CountryCode.UA },
	{ countryName: CountryName.UNITED_ARAB_EMIRATES, countryCode: CountryCode.AE },
	{ countryName: CountryName.UNITED_KINGDOM, countryCode: CountryCode.GB },
	{ countryName: CountryName.UNITED_STATES_MINOR_OUTLYING_ISLANDS, countryCode: CountryCode.UM },
	{ countryName: CountryName.URUGUAY, countryCode: CountryCode.UY },
	{ countryName: CountryName.UZBEKISTAN, countryCode: CountryCode.UZ },
	{ countryName: CountryName.VANUATU, countryCode: CountryCode.VU },
	{ countryName: CountryName.VENEZUELA_BOLIVARIAN_REPUBLIC_OF, countryCode: CountryCode.VE },
	{ countryName: CountryName.VIET_NAM, countryCode: CountryCode.VN },
	{ countryName: CountryName.VIRGIN_ISLANDS_BRITISH, countryCode: CountryCode.VG },
	{ countryName: CountryName.VIRGIN_ISLANDS_US, countryCode: CountryCode.VI },
	{ countryName: CountryName.WALLIS_AND_FUTUNA, countryCode: CountryCode.WF },
	{ countryName: CountryName.WESTERN_SAHARA, countryCode: CountryCode.EH },
	{ countryName: CountryName.YEMEN, countryCode: CountryCode.YE },
	{ countryName: CountryName.ZAMBIA, countryCode: CountryCode.ZM },
	{ countryName: CountryName.ZIMBABWE, countryCode: CountryCode.ZW },
];
