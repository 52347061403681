import { Server } from 'miragejs';

export function addInvoiceWorkspaceHandlers(server: Server) {
	server.get('/data/invoices', () => ({
		invoices: [],
		total: 0,
		invoiceProvider: [],
		openInvoiceSummary: {
			AR: null,
			AP: null,
		},
		queryInvoiceSummary: {
			AR: null,
			AP: null,
		},
	}));
}
