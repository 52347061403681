import { formatDate, TitleCasePipe } from '@angular/common';
import { SeriesOptionsType } from 'highcharts';
import { FilterMap, FilterOption } from '../../../shared/models/abstract-filter.model';
import { Formatter } from '../../../shared/utils/formatter';
import { Cadence } from '../../reports/models/cadence.model';
import { CurrencyDict, Currency } from '../../../shared/models/currency.model';
import { Snack, SnackCarousel, SnackSize, SnackType } from 'src/app/shared/models/snacks.model';
import { AnalysisPeriods, AnalysisType } from '../../reports/models/analysis.model';
import { PermissionId, PermissionMap } from '../../settings/models/feature.model';
import { DataSeries } from './graphs.models';
import { ComponentDefinition } from '../utils/insight-card.utils';
import { ColumnChartTrendRowComponent } from '../components/column-chart-trend-row/column-chart-trend-row.component';
import { LineChartTrendRowComponent } from '../components/line-chart-trend-row/line-chart-trend-row.component';
import { BarChartRowComponent } from '../components/bar-chart-row/bar-chart-row.component';
import { TrovataHighchartsColors } from 'src/app/shared/models/highcharts.model';

export interface Insight {
	insightId: string;
	title: string;
	description: string;
	family: InsightFamily;
	familyType: InsightFamilyType;
	date: string;
	size: SnackSize;
	metadata: InsightMetadata;
}

export enum InsightFamily {
	detector = 'detector',
	statistic = 'statistic',
	recommendation = 'recommendation',
	number = 'number',
}

export enum InsightFamilyType {
	anomaly = 'anomaly',
	direction = 'direction',
	feature = 'feature',
	number = 'number',
	recurrent = 'recurrent',
	categoryHistorical = 'category_historical',
	categoryCurrentDebit = 'category_current_debit',
	categoryCurrentCredit = 'category_current_credit',
	reports = 'reports',
	tags = 'tags',
}

export enum InsightCategory {
	number = 'number',
	direction = 'direction',
	categories = 'categories',
	recurrent = 'recurrent',
	merchants = 'merchants',
	recommendation = 'recommendation',
	anomaly = 'anomaly',
}

export interface InsightMetadata {
	displayData: InsightDisplayData;
	actionData: InsightActionData[];
}

export enum InsightDirection {
	up = 'up',
	down = 'down',
}

// Action Data
export interface InsightActionData {
	actionType: InsightActionType;
	api: InsightActionAPIInfo;
	displayText: { prompt: string; actionIcon: string };
	description?: string;
}

export interface InsightActionAPIInfo {
	body: Object;
	queryParams: Object;
	url: string;
}

export enum InsightActionType {
	transactionsExplorer = 'transactionsExplorer',
	analysis = 'analysis',
	createReport = 'createReport',
	createTag = 'createTag',
	recon = 'recon',
}

export interface GraphData {
	series: SeriesOptionsType[];
	tooltips?: boolean;
	labels?: boolean;
	colors?: string[];
	options?: Object;
	isPercentage?: boolean;
	maxValue?: number;
}

// Display Data
export interface InsightDisplayData {
	displayLabel?: string;
	displayValue?: string;
	displayType?: string;
	displayDelta?: number;
	displayCurrency?: string;
	displayPercentage?: string;
	displayIcon?: string;
	displayIconColor?: string;
	startDate: string;
	endDate: string;
	dateRange?: string;
	detailView: boolean;
	headers?: string[];
	graphData?: GraphData;
	showType: boolean;
	groupChanges?: InsightGroupChangeItem[];
	snackGroupChanges?: InsightGroupChangeItem[];
	analysisType?: AnalysisType;
	cadence: Cadence;
	periods: AnalysisPeriods;
	titleOverride?: boolean;
	dates?: string[];
	view: InsightsGroupRowView;
	sizeV2: 'medium' | 'large' | 'xlarge';
}

export interface InsightGroupChangeItem {
	groupLabel?: string;
	groupType?: string;
	groupDisplay?: string;
	groupValue?: string;
	groupHistory?: number[];
	groupTotal?: number;
	groupFromAmount?: number;
	groupToAmount?: number;
	groupFromDate?: string;
	groupToDate?: string;
	groupDelta?: number;
	groupDirection?: InsightDirection;
	groupColorClass?: string;
	groupArrowRotateClass?: string;
	groupPercentage?: string;
	groupCategory?: string;
	groupAccountNumber?: string;
	groupAccountId?: string;
	groupCurrency?: string;
	groupIds?: string[];
	groupIcon?: string;
	groupIconColor?: string;
	groupGraphData?: GraphData;
	groupDisplayType?: string;
	groupTransactionType?: string;
}

export interface DetectorDisplayData extends InsightDisplayData {
	dates: string[];
	datesReadable: string;
}

export interface RecommendationDisplayData extends InsightDisplayData {
	customerCount: number;
	median: number;
}

// Insight View
export class InsightView {
	title: string;
	description: string;
	displayDate: string;
	displayType: string;
	headers: string[];
	groupRows: GroupRow[];
	actions: InsightActionData[];
	displayActions: InsightActionData[];
	chart: InsightChartViewModel;
	hideArrow?: boolean;

	constructor(insight: Insight, filterMap: FilterMap, currencyDict: CurrencyDict, locale: string) {
		this.title = insight.title;
		this.description = insight.description;
		this.displayDate = formatDate(insight.date, 'M/dd/yy', locale);
		this.displayType = new TitleCasePipe().transform(insight.familyType);
		this.headers = insight?.metadata?.displayData?.headers || [];

		if (insight?.metadata?.actionData.length) {
			this.displayActions = insight.metadata.actionData;
		}

		switch (insight.family) {
			case InsightFamily.detector: {
				this.chart = new InsightSimpleChartViewModel(insight.metadata.displayData.graphData, false, InsightFamily.detector);
				break;
			}
			case InsightFamily.statistic: {
				this.hideArrow = true;
				const displayData: InsightDisplayData = <InsightDisplayData>insight?.metadata?.displayData;
				this.groupRows = displayData?.groupChanges?.map((row: InsightGroupChangeItem) => new StatisticInsightGroupRow(row, false, currencyDict, filterMap));
				break;
			}
			default:
				console.error('Family not implemented for InsightView');
				break;
		}
	}
}

// Insight Snacks
export class InsightSnack extends Snack {
	title: string;
	description: string;
	displayDate: string;
	insightType: string;
	type: SnackType.insight = SnackType.insight;
	displayValue: string;
	insight: Insight;
	showType?: boolean;
	groupRows?: StatisticInsightGroupRow[];
	chartViewModel?: InsightChartViewModel;
	displayIconColor?: string;
	displayIcon?: string;
	displayPercentage?: string;

	protected formatter: Formatter = new Formatter();

	constructor(insight: Insight, locale: string) {
		super(insight.size, SnackType.insight);
		this.insight = insight;
		this.title = insight?.title;
		this.description = insight?.description;
		const dateRange: string = insight?.metadata?.displayData?.dateRange;
		this.displayDate = dateRange ? dateRange : formatDate(insight.date, 'M/dd/yy', locale);
		this.insightType = new TitleCasePipe().transform(insight.familyType);
		this.showType = insight?.metadata?.displayData?.showType;
	}
}

export class DetectorInsightSnack extends InsightSnack {
	declare chartViewModel: InsightChartViewModel;
	constructor(insight: Insight, filterMap: FilterMap, locale: string) {
		super(insight, locale);
		const displayData: DetectorDisplayData = <DetectorDisplayData>insight?.metadata?.displayData;
		this.displayValue = filterMap['accountId'].options.find((accountFilter: FilterOption) => accountFilter?.id === displayData?.displayValue)?.displayValue;
		this.chartViewModel = new InsightSimpleChartViewModel(displayData?.graphData, true, insight.family);
	}
}

export class RecommendationInsightSnack extends InsightSnack {
	declare chartViewModel: InsightSimpleChartViewModel;
	constructor(insight: Insight, locale: string) {
		super(insight, locale);
		const displayData: RecommendationDisplayData = <RecommendationDisplayData>insight.metadata.displayData;
		this.chartViewModel = new InsightSimpleChartViewModel(displayData.graphData, true, InsightFamily.recommendation);
	}
}

export class StatisticInsightSnack extends InsightSnack {
	constructor(insight: Insight, filterMap: FilterMap, currencyDict: CurrencyDict, locale: string) {
		super(insight, locale);
		const displayData: InsightDisplayData = <InsightDisplayData>insight?.metadata?.displayData;
		this.groupRows = displayData?.snackGroupChanges?.map((row: InsightGroupChangeItem) => new StatisticInsightGroupRow(row, true, currencyDict, filterMap));
	}
}

export class NumberInsightSnack extends InsightSnack {
	constructor(insight: Insight, filterMap: FilterMap, currencyDict: CurrencyDict, locale: string) {
		super(insight, locale);

		const displayData: InsightDisplayData = <InsightDisplayData>insight?.metadata?.displayData;
		this.displayValue = this.formatter.formatValue(displayData?.displayDelta, currencyDict[displayData?.displayCurrency]);

		if (displayData?.titleOverride) {
			this.title = filterMap?.[displayData.displayType]?.options.find((accountFilter: FilterOption) => accountFilter?.id === displayData?.displayValue)
				?.displayValue;
		}

		this.displayPercentage = displayData?.displayPercentage;
		this.displayIcon = displayData?.displayIcon;
		this.displayIconColor = displayData?.displayIconColor;
	}
}

export class InsightActionDialogViewModel {
	actionItems: InsightActionData[];
	constructor(insight: Insight, permissions: PermissionMap) {
		const actionData: InsightActionData[] = [];
		insight?.metadata?.actionData?.map((action: InsightActionData) => {
			switch (action.actionType) {
				case InsightActionType.analysis:
					if (permissions.has(PermissionId.readAnalysis)) {
						actionData.push(action);
					}
					break;
				case InsightActionType.createReport:
					if (permissions.has(PermissionId.createReports)) {
						actionData.push(action);
					}
					break;
				case InsightActionType.createTag:
					if (permissions.has(PermissionId.createTags)) {
						actionData.push(action);
					}
					break;
				case InsightActionType.recon:
					if (permissions.has(PermissionId.readReconReport)) {
						actionData.push(action);
					}
					break;
				case InsightActionType.transactionsExplorer:
					if (permissions.has(PermissionId.readTransactionsExplorer)) {
						actionData.push(action);
					}
					break;

				default:
					actionData.push(action);
					break;
			}
		});
		this.actionItems = actionData;
	}
}

export class GroupRow {
	groupValue: string;
	groupDisplayValue?: string;
	groupLabel: string;
	groupPercentage: string;
	groupColorClass?: string;
	groupArrowRotateClass?: string;
	groupCurrency: Currency;
	formatter: Formatter;
	chartViewModel: InsightSimpleChartViewModel;
	groupType: string;
	groupTransactionType?: string;
	groupDisplay: string;
	insightFamilyType: InsightFamilyType;
	groupHistory?: string[];
	groupIds?: string[];
	groupAccountId?: string;
	groupAccountNumber?: string;
	groupCategory?: string;
	groupDescription?: string;
	groupDirection?: InsightDirection;
	groupDelta?: number;
	groupToDate?: string;
	groupFromDate?: string;
	groupToAmount?: number;
	groupFromAmount?: number;
	groupTotal?: number;
	groupIcon?: string;
	groupIconColor?: string;
	groupGraphData: GraphData;
	groupDisplayType?: string;
}

export class StatisticInsightGroupRow extends GroupRow {
	currency: Currency;
	constructor(dataItem: InsightGroupChangeItem, isSnackView: boolean, currencyDict: CurrencyDict, filterMap?: FilterMap) {
		super();
		this.formatter = new Formatter();
		this.currency = currencyDict[dataItem?.groupCurrency];
		this.groupDisplay = filterMap?.[dataItem?.groupType]
			? filterMap[dataItem?.groupType]?.options?.find((accountFilter: FilterOption) => accountFilter.id === dataItem.groupAccountId)?.displayValue
			: dataItem?.groupDisplay;
		this.groupPercentage = dataItem?.groupPercentage;
		this.chartViewModel = new InsightSimpleChartViewModel(dataItem?.groupGraphData, isSnackView, InsightFamily.statistic);
		this.groupColorClass = dataItem?.groupColorClass || '';
		this.groupArrowRotateClass = dataItem?.groupArrowRotateClass || '';
		this.groupDisplayValue = dataItem?.groupValue ? this.formatter.formatValue(dataItem?.groupValue, this.currency, null, null, isSnackView) : '';
		this.groupIds = dataItem?.groupIds?.length ? dataItem?.groupIds : [];
		this.groupTransactionType = dataItem?.groupTransactionType;
	}
}

// Insight Center
export class InsightCenterViewModel {
	carousels: SnackCarousel<InsightSnack>[];
	constructor(insightsResp: Object, filterMap: FilterMap, currencyDict: CurrencyDict, locale: string) {
		this.carousels = [];

		Object.keys(insightsResp).forEach(key => {
			const insights: Insight[] = insightsResp?.[key]?.insights;
			const size: SnackSize = insightsResp?.[key]?.size;
			const displayName: string = insightsResp?.[key]?.displayName;
			const position: number = insightsResp?.[key]?.position;
			let mappedInsights: InsightSnack[];

			switch (key) {
				case 'anomaly': {
					mappedInsights = insights.map((insight: Insight) => new DetectorInsightSnack(insight, filterMap, locale));
					break;
				}
				case 'recurrent':
				case 'categories':
				case 'merchants':
				case 'direction': {
					mappedInsights = insights.map((insight: Insight) => new StatisticInsightSnack(insight, filterMap, currencyDict, locale));
					break;
				}
				case 'number': {
					mappedInsights = insights.map((insight: Insight) => new NumberInsightSnack(insight, filterMap, currencyDict, locale));
					break;
				}
				case 'recommendation': {
					mappedInsights = insights.map((insight: Insight) => new RecommendationInsightSnack(insight, locale));
					break;
				}
				default:
					console.error('Insight type not implemented');
			}

			const carousel: SnackCarousel<InsightSnack> = new SnackCarousel<InsightSnack>(mappedInsights, displayName, InsightCategory[key], size, null, position);
			this.carousels.push(carousel);
			this.carousels.sort((a, b) => a?.position - b?.position);
		});
	}
}

export enum SnacksPerRowClass {
	one = 'single-row',
	two = 'double-row',
	three = 'triple-row',
	four = 'quad-row',
	five = 'pent-row',
}

export abstract class InsightChartViewModel {
	protected formatter: Formatter;
	noData: boolean;
	insightFamilyType: InsightFamilyType;
	constructor() {
		this.noData = false;
		this.formatter = new Formatter();
	}

	abstract displayChart(): Highcharts.Options;
}

export enum SimpleGraphType {
	line = 'line',
	bar = 'bar',
	column = 'column',
	pie = 'pie',
}

export class InsightSimpleChartViewModel extends InsightChartViewModel {
	constructor(
		public graphData: GraphData,
		private isSnackView: boolean,
		private type?: InsightFamily
	) {
		super();
		this.formatter = new Formatter();
		this.noData = !!this.graphData?.series?.length;
	}

	dataSeries(): any[] {
		// TODO
		const series = this.graphData.series;
		return series;
	}

	displayChart(): Highcharts.Options {
		const cOptions: Highcharts.Options = this.getChartOptions();
		cOptions.series = this.graphData?.series;

		if (this.isSnackView && this.type === InsightFamily.statistic) {
			cOptions.plotOptions.column.pointWidth = 15;
		}

		return { ...cOptions, ...this.graphData?.options };
	}
	private getChartOptions(): Highcharts.Options {
		const chartOptions: Highcharts.Options = {
			title: {
				text: '',
			},
			chart: {
				renderTo: 'chart',
				style: {
					fontFamily: 'AkkuratLLWeb',
					fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
					fontSize: '16px',
				},
			},
			colors: this.graphData?.colors?.length ? this.graphData.colors : TrovataHighchartsColors,
			xAxis: {
				labels: {
					enabled: false,
				},
			},
			yAxis: {
				title: null,
				labels: {
					enabled: false,
				},
			},
			plotOptions: {
				column: {
					colorByPoint: true,
				},
				pie: {
					dataLabels: {
						enabled: this.graphData?.labels,
					},
				},
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			tooltip: {
				useHTML: true,
				split: true,
				enabled: this.graphData?.tooltips,
				formatter: e => {
					if (this.type === InsightFamily.detector) {
						const point = e.chart.hoverPoint;
						const category = point.name ? point.name : point.category;
						const upperLimit = point['data'].ul;
						const lowerLimit = point['data'].ll;
						const delta = point['data'].deltas;
						const count = point['data'].count;

						return `<div style=font-size: 10px; text-align: center>
                      Transaction Count <span>\u25CF</span> ${category}
                    <div>Upper Limit: <strong>${upperLimit}</strong></div>
                  <div>Lower Limit: <strong>${lowerLimit}</strong></div>
                  <div>Delta: <strong>${delta}</strong></div>
                  <div>Count: <strong>${count}</strong></div>`;
					} else {
						const hoverpoint = e.chart.hoverPoint;
						return `<div style=display:flex;justify-content: space-between; margin-bottom: 3px>
              <div style=padding-right:5px;>
                <div><strong>${hoverpoint['tooltip']}</strong></div>
              </div>
            </div>`;
					}
				},
				style: {
					fontFamily: 'AkkuratLLWeb',
					fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
					fontSize: '16px',
				},
			},
			series: [],
			exporting: {
				enabled: false,
			},
			responsive: {
				rules: [
					{
						condition: {
							maxHeight: 300,
						},
						chartOptions: {
							legend: {
								enabled: false,
							},
							chart: {
								spacingBottom: 0,
								zooming: {
									type: null,
								},
								animation: false,
								spacingLeft: -9,
								spacingRight: -9,
								spacingTop: 0,
								style: {
									fontFamily: 'AkkuratLLWeb',
									fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
									fontSize: '16px',
								},
							},
							xAxis: {
								visible: false,
							},
							yAxis: {
								visible: false,
							},
							plotOptions: {
								series: {
									animation: false,
								},
							},
						},
					},
					{
						condition: {
							maxHeight: 100,
						},
						chartOptions: {
							legend: {
								enabled: false,
							},
							chart: {
								spacingBottom: 0,
								zooming: {
									type: null,
								},
								animation: false,
								spacingLeft: -5,
								spacingRight: -5,
								spacingTop: 0,
								style: {
									fontFamily: 'AkkuratLLWeb',
									fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
									fontSize: '16px',
								},
							},
							xAxis: {
								visible: false,
							},
							yAxis: this.graphData?.isPercentage
								? {
										visible: false,
										min: 0,
										max: this.isSnackView ? 100 : this.graphData.maxValue + 35 <= 100 ? this.graphData.maxValue + 35 : 100,
								  }
								: {
										visible: false,
								  },
							tooltip: {
								enabled: false,
							},
							plotOptions: {
								series: {
									states: {
										hover: {
											enabled: false,
										},
									},
									animation: false,
								},
							},
						},
					},
				],
			},
		};
		return chartOptions;
	}
}

export enum TrendDirection {
	up = 'arrow_upward',
	down = 'arrow_downward',
	neutral = 'horizontal_rule',
}
export enum TrendColor {
	green = 'green',
	red = 'red',
	gray = 'grey',
}
export const ChartColorMap = {
	'direction-down': TrendColor.red,
	'direction-up': TrendColor.green,
};

export const TrendDirectionMap = {
	'arrow-rotate-up': TrendDirection.up,
	'arrow-rotate-down': TrendDirection.down,
	'horizontal-rule': TrendDirection.neutral,
};

export interface TrendInputs {
	color: TrendColor;
	percentage: string;
	direction: TrendDirection;
}
export interface InsightGroupRow {
	title: string;
	subtitle?: string;
	value: string;
	displayValue?: string;
	isPercentage?: boolean;
	graphData: DataSeries[];
	trend: TrendInputs;
	dense: boolean;
}

export interface BarChartRowViewModel {
	title: string;
	barValue: number;
	displayValue: string;
	isPercentage: boolean;
}

export enum InsightsGroupRowView {
	ColumnChartTrendRow = 'column_chart_trend_row',
	ColumnChartTrendRowDense = 'column_chart_trend_row_dense',
	LineChartTrendRow = 'line_chart_trend_row',
	LineChartTrendRowDense = 'line_chart_trend_row_dense',
	BarChartRow = 'bar_chart_row',
	PercentageBarChartRow = 'percentage_bar_chart_row',
}

export class InsightRowResolver {
	static resolve(componentKey: InsightsGroupRowView) {
		const groupRowViewDictionary: { [key: string]: ComponentDefinition } = {
			[InsightsGroupRowView.ColumnChartTrendRow]: {
				componentType: ColumnChartTrendRowComponent,
			},
			[InsightsGroupRowView.ColumnChartTrendRowDense]: {
				componentType: ColumnChartTrendRowComponent,
				inputs: {
					dense: true,
				},
			},
			[InsightsGroupRowView.LineChartTrendRow]: {
				componentType: LineChartTrendRowComponent,
			},
			[InsightsGroupRowView.LineChartTrendRowDense]: {
				componentType: LineChartTrendRowComponent,
				inputs: {
					dense: true,
				},
			},
			[InsightsGroupRowView.BarChartRow]: {
				componentType: BarChartRowComponent,
				inputs: {},
				displayOptions: {
					hideDivider: true,
				},
			},
			[InsightsGroupRowView.PercentageBarChartRow]: {
				componentType: BarChartRowComponent,
				inputs: {
					isPercentage: true,
				},
				displayOptions: {
					hideDivider: true,
				},
			},
		};
		if (!(componentKey in groupRowViewDictionary)) {
			throw new Error(`Could not resolve component definition for: ${componentKey}`);
		}
		return groupRowViewDictionary[componentKey];
	}
}
