import { Server, Request } from 'miragejs';
import {
	analysisBalanceValues,
	balancesAggregation,
	analysisTransactionValuesLegacy,
	transactionsAggregationLegacy,
	analysisTransactionValues,
} from '../helpers.mock';
import { AppSchema } from '../types';
import {
	AnalysisData,
	AnalysisBalanceValue,
	AnalysisGetRequestParams,
	AnalysisType,
	AnalysisTransactionValue,
	AnalysisBalanceProperty,
} from '@trovata/app/features/reports/models/analysis.model';
import { Cadence } from '@trovata/app/features/reports/models/cadence.model';
import { randAmount } from '@ngneat/falso';
import { AnalysisTransactionLegacy } from '@trovata/app/features/reports/models/analysis.legacy.model';
import { DateTime } from 'luxon';

export function addAnalysisWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post('/data/v4/transactions/analysis', (schema: AppSchema, request: Request): AnalysisData<AnalysisTransactionValue> => {
		const body: AnalysisGetRequestParams = JSON.parse(request.requestBody);
		if (body.excludeWeekends || body.groupBy?.length) {
			unhandledRequestMade();
		}

		const trxnAnalysis: AnalysisData<AnalysisTransactionValue> = {
			type: AnalysisType.transactions,
			startDate: DateTime.now().minus({ weeks: 2 }).toFormat('yyyy-MM-dd'),
			endDate: DateTime.now().toFormat('yyyy-MM-dd'),
			currencyConverted: 'USD',
			cadence: Cadence.daily,
			groupBy: [],
			aggregation: [],
			metrics: {
				creditTotal: 31069388.3,
				debitTotal: 28296400.06,
				netTotal: 2772988.27,
				netAverage: 92432.94,
				netMin: -768084.76,
				netMax: 1283749.43,
				netTotalChange: -768084.76,
				netTotalChangePercent: -21.69,
			},
			summary: analysisTransactionValues(14, 'daily'),
			tql: null,
		};
		return trxnAnalysis;
	});

	server.post('/data/v4/accounts/balances/historical/analysis', (schema: AppSchema, request: Request): AnalysisData<AnalysisBalanceValue> => {
		const body: AnalysisGetRequestParams = JSON.parse(request.requestBody);
		if (body.excludeWeekends || body.groupBy?.length) {
			unhandledRequestMade();
		}

		const trxnAnalysis: AnalysisData<AnalysisBalanceValue> = {
			type: AnalysisType.balances,
			startDate: DateTime.now().minus({ weeks: 2 }).toFormat('yyyy-MM-dd'),
			endDate: DateTime.now().toFormat('yyyy-MM-dd'),
			currencyConverted: 'USD',
			cadence: Cadence.daily,
			balanceProperty: AnalysisBalanceProperty.bankClosingLedgerConverted,
			metrics: {
				average: 20958262.24,
				min: 20163406.24,
				max: 22415186.82,
				totalChange: 88434.95,
				totalChangePercent: 0.43,
				periodChange: -178962.48,
				periodChangePercent: -0.86,
			},
			groupBy: [],
			aggregation: [],
			summary: analysisBalanceValues(14, 'daily'),
			tql: null,
		};
		return trxnAnalysis;
	});
}

export function addAnalysisEdgeHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post('/analysis/transactions', (schema: AppSchema, request: Request): { data: AnalysisTransactionLegacy } => {
		const body = JSON.parse(request.requestBody);
		const groupByList: string[] = body.groupBy?.split(',');
		const groupBy: string = groupByList ? groupByList[0] : '';
		if (body.trimWeekends || groupByList?.length > 1) {
			unhandledRequestMade();
		}
		const periods: number = body.periods;
		const cadence: string = body.cadence;
		const groupType: string = '';
		const groupIds = groupBy ? schema.db.accounts.map(acc => acc[groupBy]) : [];

		const trxnAnalysis: { data: AnalysisTransactionLegacy } = {
			data: {
				summary: {
					currencyConverted: body['currencyOverride'] ?? 'USD',
					creditAmount: randAmount(),
					creditAmountConverted: randAmount(),
					debitAmount: 0,
					debitAmountConverted: 0,
					creditCountTransactions: 15,
					debitCountTransactions: 0,
				},
				transactionsAggregation: transactionsAggregationLegacy(groupIds, groupType, periods, cadence),
				forecast: analysisTransactionValuesLegacy(periods, cadence),
				currencyConverted: body['currencyOverride'] ?? 'USD',
				transactions: analysisTransactionValuesLegacy(periods, cadence),
			},
		};
		return trxnAnalysis;
	});

	server.post('/data/v4/accounts/balances/historical/analysis', (schema: AppSchema, request: Request): { data: AnalysisData<AnalysisBalanceValue> } => {
		const body: AnalysisGetRequestParams = JSON.parse(request.requestBody);
		if (body.excludeWeekends || body.groupBy?.length > 1) {
			unhandledRequestMade();
		}
		// const periods: number = body.periods;
		const cadence: string = body.cadence;
		const groupType: string = '';
		const groupBy: string = body.groupBy?.length ? body.groupBy[0] : '';
		const groupIds = groupBy ? schema.db.accounts.map(acc => acc[groupBy]) : [];

		return {
			data: {
				summary: analysisBalanceValues(14, cadence),
				aggregation: balancesAggregation(groupIds, groupType, 14, cadence),
				currencyConverted: 'USD',
				type: AnalysisType.balances,
				cadence: cadence as Cadence,
			},
		};
	});
}
