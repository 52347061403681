import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PollRequestKey } from '../models/cancel-poll-request.model';

@Injectable({
	providedIn: 'root',
})
export class CancelPollRequestService {
	pollSubjectsToCancel: { [pollRequestKey: string]: Subject<void> };
	pollIntervalsToClear: { [pollRequestKey: string]: NodeJS.Timeout };

	constructor() {
		this.pollSubjectsToCancel = {};
		this.pollIntervalsToClear = {};
	}

	createPollSubject(pollRequestKey: PollRequestKey): void {
		if (!this.pollSubjectsToCancel[pollRequestKey]) {
			this.pollSubjectsToCancel[pollRequestKey] = new Subject<void>();
		}
	}

	cancelPollSubject(pollRequestKey: PollRequestKey): void {
		if (this.pollSubjectsToCancel[pollRequestKey]) {
			this.pollSubjectsToCancel[pollRequestKey].next();
			this.pollSubjectsToCancel[pollRequestKey].complete();
			delete this.pollSubjectsToCancel[pollRequestKey];
		}
	}

	setPollInterval(pollRequestKey: PollRequestKey, pollInterval: NodeJS.Timeout): void {
		if (!this.pollIntervalsToClear[pollRequestKey]) {
			this.pollIntervalsToClear[pollRequestKey] = pollInterval;
		}
	}

	clearPollInterval(pollRequestKey: PollRequestKey): void {
		if (this.pollIntervalsToClear[pollRequestKey]) {
			clearInterval(this.pollIntervalsToClear[pollRequestKey]);
			delete this.pollIntervalsToClear[pollRequestKey];
		}
	}

	stopAllPolling(): void {
		const pollIntervalKeys: PollRequestKey[] = Object.keys(this.pollIntervalsToClear) as PollRequestKey[];
		if (pollIntervalKeys.length) {
			pollIntervalKeys.forEach((pollRequestKey: PollRequestKey) => {
				this.clearPollInterval(pollRequestKey);
				this.cancelPollSubject(pollRequestKey);
			});
		}
	}
}
