import { HttpClient, HttpParams } from '@angular/common/http';
import {
	Connection,
	ConnectionRequest,
	CreateConnectionBody,
	CreateConnectionRequestBody,
	CreateOAuthConnectionBody,
	CreateOAuthConnectionResponse,
	GetConnectionRequestsResponse,
	GetConnectionsResponse,
	PatchConnectionRequestBody,
	ReconnectConnectionBody,
	ReconnectConnectionResponse,
} from '../models/connections.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ConnectionsService {
	constructor(private httpClient: HttpClient) {}

	getConnections(): Observable<GetConnectionsResponse> {
		const url: string = environment.trovataAPI('workspace') + '/entitlements/connections';
		return this.httpClient.get<GetConnectionsResponse>(url);
	}

	getConnectionRequests(): Observable<GetConnectionRequestsResponse> {
		const url: string = environment.trovataAPI('workspace') + '/entitlements/connections/request';
		return this.httpClient.get<GetConnectionRequestsResponse>(url);
	}

	createConnection(body: CreateConnectionBody): Observable<Connection> {
		const url: string = environment.trovataAPI('workspace') + '/entitlements/connections';
		return this.httpClient.post<Connection>(url, body);
	}

	reconnectConnection(body: ReconnectConnectionBody): Observable<ReconnectConnectionResponse> {
		const url: string = environment.trovataAPI('workspace') + `/entitlements/connections/${body.connectionId}/reconnect`;
		return this.httpClient.put<ReconnectConnectionResponse>(url, body);
	}

	refreshConnection(connectionId: string): Observable<Connection> {
		const url: string = environment.trovataAPI('workspace') + `/entitlements/connections/${connectionId}/refresh`;
		return this.httpClient.put<Connection>(url, {});
	}

	revokeConnection(connectionId: string): Observable<Connection> {
		const url: string = environment.trovataAPI('workspace') + `/entitlements/connections/${connectionId}/revoke`;
		return this.httpClient.put<Connection>(url, {});
	}

	createOAuthConnection(body: CreateOAuthConnectionBody): Observable<CreateOAuthConnectionResponse> {
		const url: string = environment.trovataAPI('workspace') + '/entitlements/connections/oauth';
		return this.httpClient.post<CreateOAuthConnectionResponse>(url, body);
	}

	handleOAuthCallback(connectionId: string, queryString: string): Observable<Connection> {
		const url: string = environment.trovataAPI('workspace') + `/entitlements/connections/${connectionId}/oauth-callback`;
		return this.httpClient.put<Connection>(url, { queryString });
	}

	postConnectionRequest(connectionRequestBody: CreateConnectionRequestBody): Observable<ConnectionRequest> {
		const url: string = environment.trovataAPI('workspace') + '/entitlements/connections/request';
		return this.httpClient.post<ConnectionRequest>(url, connectionRequestBody);
	}

	patchConnectionRequest(patchConnectionBody: PatchConnectionRequestBody, connectionRequestId: number): Observable<ConnectionRequest> {
		const url: string = environment.trovataAPI('workspace') + '/entitlements/connections/request';
		const params: HttpParams = new HttpParams().set('connectionRequestId', connectionRequestId);
		return this.httpClient.patch<ConnectionRequest>(url, patchConnectionBody, {
			params: params,
		});
	}
}
