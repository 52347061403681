export class InitPlaidState {
	static readonly type: string = '[Plaid] InitPlaidState';
}

export class ClearPlaidState {
	static readonly type: string = '[Plaid] ClearPlaidState';
}

export class ResetPlaidState {
	static readonly type: string = '[Plaid] ResetPlaidState';
}

export class GetPlaidItems {
	static readonly type: string = '[Plaid] GetPlaidItems';
}
