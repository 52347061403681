import { Rounding } from 'src/app/shared/models/rounding.model';
import { DateTime } from 'luxon';
import { CashPositionColumnConfig } from './paginated-cash-positions-acct-table-view.model';
import { AccountTagType } from '../../transactions/models/tag.model';
export class CashPositionPagePreferences {
	cashPositionFavorites: string[];
	cashPositionIndividualPreferences: {
		[cashPositionId: string]: CashPositionPreference;
	};
}

export class CashPositionPreference {
	columns: CashPositionColumnConfig[];
}

export enum CashPositionGrouping {
	currency = 'currency',
	institutionId = 'institutionId',
	accountId = 'accountId',
	entityId = 'entityId',
	entityRegion = 'entityRegion',
	entityDivision = 'entityDivision',
	entity = 'entity',
	region = 'region',
	division = 'division',
}

export class CashPositionGroupType {
	value: CashPositionGrouping;
	view: string;
}

export const acctGroupTypes: CashPositionGroupType[] = [
	{ value: CashPositionGrouping.accountId, view: 'Account' },
	{ value: CashPositionGrouping.institutionId, view: 'Bank' },
	{ value: CashPositionGrouping.entityId, view: 'Entity Alias' },
	{ value: CashPositionGrouping.entityRegion, view: 'Entity Region' },
	{ value: CashPositionGrouping.entityDivision, view: 'Entity Division' },
	{ value: CashPositionGrouping.entity, view: AccountTagType.entity },
	{ value: CashPositionGrouping.region, view: AccountTagType.region },
	{ value: CashPositionGrouping.division, view: AccountTagType.division },
	{ value: CashPositionGrouping.currency, view: 'Currency' },
];

export class CashPosition {
	cashPositioningId: string;
	name: string;
	currency: string;
	includeWeekends: boolean;
	balanceRounding: Rounding;
	accounts: CashPositionAccount[];
	lastModifiedDate: string;
	createdAt: string;
	utcOffset: number;
	data?: CashPositionData;
	latestVersion?: CashPositionData;
	versions?: CashPositionVersion[];
	versionOverviews?: CashPositionVersionOverview[];
	dataErrorMessage?: string;
	versionsErrorMessage?: string;
	latestVersionErrorMessage?: string;
	versionOverviewsErrorMessage?: string;
}

export class CashPositionAccount {
	accountId: string;
	closingBalance?: string;
	openingBalance?: string;
	threshold?: number;
}

export enum CashPositionClosingBal {
	currentDayClosingLedger = 'currentDayClosingLedger',
	currentDayClosingAvailable = 'currentDayClosingAvailable',
	nextDayOpeningLedger = 'nextDayOpeningLedger',
	nextDayOpeningAvailable = 'nextDayOpeningAvailable',
}

export class CashPositionBalanceOpt<T> {
	balanceOpt: T;
	display: string;
	default?: boolean;
}

export const CashPositionClosingBalanceOpts: CashPositionBalanceOpt<CashPositionClosingBal>[] = [
	{
		balanceOpt: CashPositionClosingBal.currentDayClosingLedger,
		display: 'Current day closing ledger',
		default: true,
	},
	{
		balanceOpt: CashPositionClosingBal.currentDayClosingAvailable,
		display: 'Current day closing available',
	},
	{
		balanceOpt: CashPositionClosingBal.nextDayOpeningAvailable,
		display: 'Next day opening available',
	},
	{
		balanceOpt: CashPositionClosingBal.nextDayOpeningLedger,
		display: 'Next day opening ledger',
	},
];

export enum CashPositionOpeningBal {
	currentDayOpeningLedger = 'currentDayOpeningLedger',
	currentDayOpeningAvailable = 'currentDayOpeningAvailable',
	currentDayCurrentAvailable = 'currentDayCurrentAvailable',
	previousDayClosingLedger = 'previousDayClosingLedger',
	previousDayClosingAvailable = 'previousDayClosingAvailable',
}

export const CashPositionOpeningBalanceOpts: CashPositionBalanceOpt<CashPositionOpeningBal>[] = [
	{
		balanceOpt: CashPositionOpeningBal.currentDayCurrentAvailable,
		display: 'Current day current available',
	},
	{
		balanceOpt: CashPositionOpeningBal.currentDayOpeningAvailable,
		display: 'Current day opening available',
	},
	{
		balanceOpt: CashPositionOpeningBal.currentDayOpeningLedger,
		display: 'Current day opening ledger',
		default: true,
	},
	{
		balanceOpt: CashPositionOpeningBal.previousDayClosingAvailable,
		display: 'Previous day closing available',
	},
	{
		balanceOpt: CashPositionOpeningBal.previousDayClosingLedger,
		display: 'Previous day closing ledger',
	},
];

export class CashPositionListResponse {
	reports: CashPosition[];
}

export class CashPositionVersionsResponse {
	versions: CashPositionVersion[];
}

export class CashPositionVersionOverviewResponse {
	versions: CashPositionVersionOverview[];
}

export enum CashPositionVersionStatus {
	latest = 'Latest',
	closed = 'Closed',
}

export class CashPositionVersion {
	versionId: string;
	createdAt?: string;
	createdBy?: string;
	targetDate?: string;
	accounts?: CashPositionAccountData[];
	totals?: CashPositionCurrencyTotal[];
}

export interface CashPositionVersionOverview {
	date?: string;
	delta: number;
	targetBalance: number;
	expectedBalance: number;
	acctsBelowTarget?: number;
}

export class CashPositionData {
	groups?: CashPositionGroup[];
	versionId?: string;
	createdAt?: string;
	createdBy?: string;
}

export class CashPositionGroup {
	groupValue: string;
	groupType: string;
	data: {
		accounts: CashPositionAccountData[];
		totals: CashPositionCurrencyTotal[];
	};
}

export class CashPositionVersionPostBody {
	accounts: CashPositionAccountData[];
	totals: CashPositionCurrencyTotal[];
}

export class CashPositionVersionResponseBody {
	versionId: string;
	createdAt: string;
	createdBy: string;
	accounts: CashPositionAccountData[];
	totals: CashPositionCurrencyTotal[];
}

export class CashPositionCurrencyTotal {
	currency: string;
	openingBalance: number;
	closingBalance: number;
	currentTransaction: number;
	assumedTransactions: number;
	expectedBalance: number;
	targetBalance: number;
	delta: number;
}

export class CashPositionAccountData {
	accountId: string;
	institutionId: string;
	entityGroupingId: string;
	regionGroupingId: string;
	divisionGroupingId: string;
	currency: string;
	comment: string[];
	openingBalance: number;
	closingBalance: number;
	currentTransaction: number;
	assumedTransactions: number;
	assumedTransactionsList: AccountAssumedTransactions;
	expectedBalance: number;
	targetBalance: number;
	delta: number;
}

export class AccountAssumedTransactions {
	prefillLabels: boolean;
	prefillValues: boolean;
	assumptions: AssumedTransaction[];
}

export class AssumedTransaction {
	label: string;
	value: number;
	reconciled: boolean = false;
}

export class CashPositionTimezone {
	utcOffset: number;
	utcMidnight: DateTime;
	utcCloseTime: DateTime;
	localHour: number;
	versionDate: DateTime;

	constructor(utcOffset: number, versionDate?: string) {
		this.utcOffset = utcOffset;
		this.utcMidnight = DateTime.fromObject({ hour: 0 }, { zone: 'utc' });
		this.utcCloseTime = this.utcMidnight.plus({ hour: utcOffset });
		this.localHour = this.utcCloseTime.toLocal().hour;
		if (versionDate) {
			this.versionDate = DateTime.fromISO(versionDate, {
				zone: 'UTC',
			}).toLocal();
		}
	}

	getLocalCloseTimeHour() {
		return this.localHour;
	}

	setOffsetValue(localCloseTimeHour: number) {
		this.localHour = localCloseTimeHour;
		this.utcCloseTime = DateTime.fromObject({ hour: this.localHour }, { zone: 'local' }).toUTC();

		let newOffset: number = this.utcMidnight.plus({
			hours: this.utcCloseTime.hour,
		}).hour;
		if (newOffset > 12) {
			newOffset = -(24 - newOffset);
		}
		this.utcOffset = newOffset;
		return this.utcOffset;
	}

	// Autosaves must happen the moment BEFORE close time, so if close is 12 PM, autosave is 11:59:59.99999,
	// whereas opening time happens at closing time, 12:00:00.0000

	getNextClose(): DateTime {
		const dataDate: DateTime = this.versionDate || DateTime.now();
		if (this.getIsDayClosed()) {
			return dataDate
				.set({ hour: this.localHour - 1 })
				.endOf('hour')
				.plus({ days: 1 });
		} else {
			return dataDate.set({ hour: this.localHour - 1 }).endOf('hour');
		}
	}

	getPreviousClose(): DateTime {
		return this.getNextClose().minus({ days: 1 });
	}

	getOpenTime(): DateTime {
		return this.getPreviousClose().plus({ hour: 1 }).startOf('hour');
	}

	// transaction date according to Curtis is open time - utc offset
	getTransactionDate(): string {
		return this.getOpenTime().toUTC().minus({ hours: this.utcOffset }).toFormat('yyyy-MM-dd');
	}

	getIsDayClosed() {
		return this.localHour <= (this.versionDate || DateTime.local()).hour;
	}
}
