import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { CacheService } from 'src/app/shared/services/cache.service';
import { PopoutService } from 'src/app/features/transactions/services/popout.service';
import { Logout, SetAccessToken, SetAuthenticated, SetAuthErrorMessage, SetRefreshToken } from '../../actions/auth.actions';
import { throwError } from 'rxjs';
import * as LocalForage from 'localforage';
import { WebWorkerService } from '../../../services/web-worker.service';
import { ClearTrovataAppStates, SetAppReady } from '../../actions/core.actions';
import { GetFeatureConfig, GetFeaturePermissions } from 'src/app/features/settings/store/actions/customer-feature.actions';
import { TrovataAppState } from '../../../models/state.model';
import { UserAccessConfig } from 'src/app/features/settings/models/feature.model';
import { SetAnalyticsVariable as HeapSetAnalyticsVariable, SetLogin as HeapSetLogin } from '../../actions/heap-analytics.actions';
import { SetLogin } from '../../actions/google-analytics.actions';
import { firstValidValueFrom } from 'src/app/shared/utils/firstValidValueFrom';
import { AppMockService } from 'src/app/shared/mock/app.mock.service';
import { AuthUser } from '@trovata/app/core/models/auth.model';
import { SessionWebWorkerService } from '@trovata/app/core/services/session-web-worker.service';

interface NeedsOnboardingObject {
	needsOnboarding: boolean;
}

interface LocalStorageAuthBody {
	access_token: string;
	audience: string;
	client_id: string;
	decodedToken: Object;
	expires_in: number;
	id_token: string;
	oauthTokenScope: string;
	refresh_token: string;
	scope: string;
	token_type: string;
}

export class AuthStateModel {
	authUser: AuthUser;
	accessToken: string;
	refreshToken: string;
	authenticated: boolean;
	authErrorMessage: string;
}

@State<AuthStateModel>({
	name: 'auth',
	defaults: {
		authUser: null,
		accessToken: null,
		refreshToken: null,
		authenticated: null,
		authErrorMessage: null,
	},
})
@Injectable()
export class AuthState implements NgxsOnInit {
	constructor(
		private authService: AuthService,
		private cacheService: CacheService,
		private popoutService: PopoutService,
		private sessionService: SessionWebWorkerService,
		private router: Router,
		private store: Store,
		private webWorkerService: WebWorkerService,
		private mockService: AppMockService
	) {}

	async ngxsOnInit(context: StateContext<AuthStateModel>) {
		try {
			await LocalForage.ready();

			this.setSubscriptions(context);
			this.checkForInstitutionOAuthCallback();
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Selector() static accessToken(authState: AuthStateModel): string {
		return `Bearer ${authState.accessToken}`;
	}

	@Action(Logout)
	logout(context: StateContext<AuthStateModel>, action: Logout) {
		this.webWorkerService.terminateWorker();
		this.sessionService.cleanup();
		this.cacheService.clear();
		context.dispatch(new ClearTrovataAppStates());
		window.localStorage.removeItem('loginTime');
		window.localStorage.removeItem('auth');
		window.localStorage.removeItem('refreshToken');
		window.localStorage.removeItem('latestActivity');
		this.popoutService.closePopoutModal();
		(<any>window).Intercom('shutdown');
		if (action.customerDeleted) {
			this.authService.logout();
		} else {
			this.authService.logout({
				logoutParams: {
					returnTo: document.location.origin,
				},
			});
		}
	}

	@Action(SetAccessToken)
	setAccessToken(context: StateContext<AuthStateModel>, action: SetAccessToken) {
		const state: AuthStateModel = context.getState();
		localStorage.setItem('auth', action.accessToken);
		state.accessToken = action.accessToken;
		context.patchState(state);
		context.dispatch(new SetAuthenticated(true));
	}

	@Action(SetRefreshToken)
	setRefreshToken(context: StateContext<AuthStateModel>, action: SetRefreshToken) {
		const state: AuthStateModel = context.getState();
		state.refreshToken = action.refreshToken;
		context.patchState(state);
	}

	@Action(SetAuthenticated)
	setAuthenticated(context: StateContext<AuthStateModel>, action: SetAuthenticated) {
		const state: AuthStateModel = context.getState();
		state.authenticated = action.authenticated;
		context.patchState(state);
	}

	@Action(SetAuthErrorMessage)
	setErrorMessage(context: StateContext<AuthStateModel>, action: SetAuthErrorMessage) {
		const state: AuthStateModel = context.getState();
		localStorage.setItem('auth', action.authErrorMessage);
		state.authErrorMessage = action.authErrorMessage;
		context.patchState(state);
	}

	private checkForInstitutionOAuthCallback() {
		if (/\/home\/connections(\/\w+)?\/success/.test(window.location.pathname)) {
			const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
			if (queryParams.has('code') && queryParams.has('state')) {
				queryParams.set('_state', queryParams.get('state'));
				queryParams.delete('state');

				this.router.navigate([window.location.pathname], {
					queryParams: Object.fromEntries(queryParams.entries()),
				});
			}
		}
	}

	private setSubscriptions(context: StateContext<AuthStateModel>) {
		this.authService.error$.subscribe(async (error: Error) => {
			if (error.message === 'user is blocked') {
				this.router.navigate(['/customer-deleted']);
			}
		});
		this.authService.isAuthenticated$.subscribe(async (authenticated: boolean) => {
			if (!this.shouldDoNothing() && authenticated) {
				try {
					await this.sessionService.refreshTokens();

					const needsOnboarding: NeedsOnboardingObject = await this.needsOnboarding();
					if (needsOnboarding.needsOnboarding) {
						this.router.navigate(['/onboard']);
					} else {
						await this.restartMirage();
						this.store.dispatch(new SetAppReady(true));

						this.sessionService.initSession();

						// because of angulars life cycles, using document.location is more accurate than using this.router.url
						if (['/login', '/'].includes(document.location.pathname)) {
							this.store.dispatch(new SetLogin());
							this.store.dispatch(new HeapSetLogin(true));
							this.router.navigate(['/balances'], {
								queryParamsHandling: 'preserve',
								preserveFragment: true,
							});
						}
					}
				} catch (error) {
					throw error;
				}
			} else if (this.shouldDoNothing()) {
				return;
			} else {
				const params: { [k: string]: string } | void = this.getParams();

				if (params && params['utm_campaign'] && params['utm_medium'] && params['utm_source']) {
					localStorage.setItem('utm_campaign', params['utm_campaign']);
					localStorage.setItem('utm_medium', params['utm_medium']);
					localStorage.setItem('utm_source', params['utm_source']);
				}
				if (params && params.signup) {
					// this.authService.loginWithRedirect({ prompt: 'login', signup: true }); // use for custom html auth0 login
					this.authService.loginWithRedirect({
						authorizationParams: {
							prompt: 'login',
							screen_hint: 'signup',
						},
					}); // use for widget auth0 login
				} else {
					this.authService.loginWithRedirect({
						authorizationParams: {
							prompt: 'login',
						},
					});
				}
			}
		});
		this.authService.user$.subscribe((authUser: AuthUser | null) => {
			const state: AuthStateModel = context.getState();
			if (state && !state.authUser && authUser) {
				state.authUser = authUser;
				context.patchState(state);
			} else if (
				state &&
				state.authUser &&
				authUser &&
				state.authUser['https://auth.trovata.io/userinfo/userId'] !== authUser['https://auth.trovata.io/userinfo/userId']
			) {
				/**
				 * This happens when you log in on another workspace app with a different user logged in on this app
				 */
				state.authUser = authUser;
				context.patchState(state);
				// context.dispatch(new ResetTrovataAppStates()); // TODO: Fix bugs that this causes in some components
			}
		});
	}

	private async restartMirage(): Promise<void> {
		const state: TrovataAppState = await firstValidValueFrom(this.store.dispatch(new GetFeaturePermissions()));
		this.mockService.restartMirage(state.customerFeature.userAccessConfig, state.customerFeature.customerAccessMap);
	}

	private shouldDoNothing(): boolean {
		// auth check based on certain query params or route
		if (this.shouldDoNothingParams() || this.shouldDoNothingRoute()) {
			return true;
		} else {
			return false;
		}
	}

	private shouldDoNothingParams(): boolean {
		// let the LoginComponent handle 'error' && 'error_description' && 'm' query params
		const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
		const params: { [key: string]: string } = Object.fromEntries(urlSearchParams.entries());
		const paramKeys: string[] = Object.keys(params);
		let jpmflow: boolean = false;
		if (paramKeys.includes('m')) {
			jpmflow = true;
			this.store.dispatch(new HeapSetAnalyticsVariable('jpmFlow', jpmflow));
		}
		if (paramKeys.includes('error' && 'error_description') || paramKeys.includes('m') || paramKeys.includes('bank')) {
			if (paramKeys.includes('m')) {
				window.location.href = 'https://trovata.io/partner/jp-morgan-access/';
			}
			return true;
		} else {
			return false;
		}
	}

	private shouldDoNothingRoute(): boolean {
		// ignore auth checks for these un-authenticated routes
		const route: string = document.location.href.split(document.location.origin)[1];
		if (route === '/offer' || route === '/signup' || route === '/customer-deleted') {
			return true;
		} else {
			return false;
		}
	}

	private getFeatureConfig(): Promise<TrovataAppState> {
		return new Promise((resolve, reject) => {
			this.store.dispatch(new GetFeatureConfig()).subscribe({
				next: (state: TrovataAppState) => {
					resolve(state);
				},
				error: err => reject(err),
			});
		});
	}

	private needsOnboarding(): Promise<NeedsOnboardingObject> {
		return new Promise(async (resolve, reject) => {
			try {
				const state: TrovataAppState = await this.getFeatureConfig();
				const featureConfig: UserAccessConfig = state.customerFeature.userAccessConfig;
				const needsOnboardingObject: NeedsOnboardingObject = {
					needsOnboarding: false,
				};

				if (!featureConfig.currentCustomer) {
					needsOnboardingObject.needsOnboarding = true;
				}
				resolve(needsOnboardingObject);
			} catch (error: any) {
				this.store.dispatch(new SetAuthErrorMessage('We hit a snag. Try again later.'));
				reject(error);
			}
		});
	}

	private getParams(): { [k: string]: string } | void {
		if (window.location.search) {
			const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
			const params: { [k: string]: string } = Object.fromEntries(urlSearchParams.entries());
			return params;
		}
	}
}
