export class InitSettingsState {
	static readonly type: string = '[Settings] InitSettingsState';
}

export class SetSettingsTabIndex {
	static readonly type: string = '[Settings] SetSettingsTabIndex';
	constructor(public tabIndex: number) {}
}

export class ClearSettingsState {
	static readonly type: string = '[Settings] ClearSettingsState';
}

export class ResetSettingsState {
	static readonly type: string = '[Settings] ResetSettingsState';
}
