export enum PollRequestKey {
	GetPaymentsAccounts = 'GetPaymentsAccounts',
	GetPaymentsAccountById = 'GetPaymentsAccountById',
	GetTemplates = 'GetTemplates',
	GetTemplateById = 'GetTemplateById',
	GetWorkflows = 'GetWorkflows',
	GetWorkflowById = 'GetWorkflowById',
	GetPaymentById = 'GetPaymentById',
	GetAssignedAndBatchPayments = 'GetAssignedAndBatchPayments',
	GetBatchAndBatchsPayments = 'GetBatchAndBatchsPayments',
	GetUserGroups = 'GetUserGroups',
	GetUserGroupById = 'GetUserGroupById',
	GetStoreAuditLogsAndSearchFilters = 'GetStoreAuditLogsAndSearchFilters',
	GetApiAuditLogsAndSearchFilters = 'GetApiAuditLogsAndSearchFilters',
}
