import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./core/modules/core/core.module').then(m => m.CoreModule),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(
			routes
			// { enableTracing: true } // <-- debugging purposes only
		),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
