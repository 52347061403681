export class SubscriptionsAndProducts {
	subscriptions: Subscription[];
	products: Product[];
}

export interface Subscription {
	productId: string;
	name: string;
	description: string;
	monthlyAmount: string;
	yearlyAmount: string;
	discountAmount: string;
	subscribed: boolean;
	subscriptionId: string;
	trialing: boolean;
	freeTrialDaysLeft: number;
	discountProvidedBy: string;
	cadence: SubscriptionCadence;
	upsellAmount: string;
	isTrialAvailable: boolean;
	bullets: { description: string; enabled: boolean }[];
}

export interface Product {
	name: string;
	description: string;
	monthlyAmount: string;
	yearlyAmount: string;
	discountAmount: string;
	subscribed: boolean;
	subscriptionId: string;
	trialing: boolean;
	included: boolean;
	freeTrialDaysLeft: number;
	discountProvidedBy: string;
	cadence: SubscriptionCadence;
	upsellAmount: string;
	bullets: { description: string; enabled: boolean }[];
}

export enum SubscriptionCadence {
	monthly = 'monthly',
	yearly = 'yearly',
}

export interface CustomerSubscriptionId {
	subscriptionId: string;
}

export interface SubscriptionChangeData {
	name: string;
	action: 'add' | 'remove';
}

export interface CheckoutPreview {
	invoices: PreviewInvoice[];
}

export interface PreviewInvoice {
	dueDate: string;
	amountDue: string;
	products: {
		name: string;
		startDate: string;
		endDate: string;
		amount: string;
		description: string;
		action: 'add' | 'remove' | null;
		subscriptionId: string;
	}[];
}

export interface CustomerSubscriptionProduct {
	name: string;

	freeTrial?: boolean;
}

export interface CustomerSubscriptions {
	subscriptions: CustomerSubscription[];
}

export interface CustomerSubscription {
	subscriptionId: string;
	billingCycleAnchor: string;
	cancelAt: string;
	cancelAtPeriodEnd: boolean;
	subscriptionCanceledAt: string;
	collectionMethod: string;
	creationDate: string;
	currentPeriodEnd: string;
	currentPeriodStart: string;
	daysUntilDue: number;
	defaultPaymentMethod: string;
	defaultSource: string;
	endedAt: string;
	discount: Discount;
	items: SubscriptionItems;
	latestInvoiceId: string;
	nextPendingInvoiceItemInvoice: string;
	pauseCollection: PauseCollection;
	pendingInvoiceItemInterval: PendingInvoiceItemInterval;
	status: SubscriptionStatus;
	trialEnd: string;
	trialStart: string;
}

export interface Discount {
	discountId: string;
	coupon: DiscountCoupon;
	end: string;
	start: string;
	subscriptionId: string;
}

export interface DiscountCoupon {
	couponId: string;
	amountOff: string;
	currency: string;
	duration: string;
	durationInMonths: number;
	name: string;
	percentOff: string;
	couponLabel: object;
}

export interface SubscriptionItems {
	subscriptionItems: SubscriptionItem[];
}

export interface SubscriptionItem {
	subscriptionItemId: string;
	creationDate: string;
	metadata: object;
	quantity: number;
	subscription: string;
	price: Price;
}

export interface Price {
	priceId: string;
	active: boolean;
	created: string;
	currency: string;
	nickname: string;
	productId: string;
	priceLabel: string;
	recurring: Recurring;
	unitAmount: number;
}

export interface Recurring {
	interval: string;
}

export interface PauseCollection {
	behavior: string;
	resumesAt: string;
}

export interface PendingInvoiceItemInterval {
	interval: string;
	intervalCount: string;
}

export interface PendingUpdate {
	billingCycleAnchor: string;
	expiresAt: string;
	subscriptionItems: SubscriptionItem[];
	trialEnd: string;
}

export enum SubscriptionStatus {
	active = 'active',
	past_due = 'past_due',
	unpaid = 'unpaid',
	canceled = 'canceled',
	incomplete = 'incomplete',
	incomplete_expired = 'incomplete_expired',
}

export interface SubscriptionUpdate {
	subscriptionId: string;
	products: SubscriptionChangeData[];
	cadence: SubscriptionCadence;
	autopay: boolean;
}

export interface CheckoutSession {
	checkoutSessionId: string;
	checkoutStatus: 'open' | 'complete' | 'expired';
	stripeCheckoutUrl: string;
}
