import { Predicate } from '@angular/core';
import { Cadence } from '../../reports/models/cadence.model';
import { StreamPreviewMLModel } from './forecastV3-stream-preview';
import { SearchData } from '@trovata/app/shared/models/search-parameter.model';
import { CalendarSettings } from '@trovata/app/shared/models/date-range-picker.model';
import { CustomMenuOption } from '@trovata/app/shared/models/custom-menu.model';
import { ColumnType, EditType, PaginatedTableColumn, PaginatedTableViewModel } from '@trovata/app/shared/models/paginated-table-view-model';



export type StreamDict = Map<string, Stream>;

export class Stream{
  name: string;
  description: string;
  cadence: Cadence;
  currency: string;
  currencyConverted?: string;
  values: StreamValue[];
  forecastedDataSource: DataSourceType;
  forecastedDataParams;
  actualsDataSource: DataSourceType;
  actualsDataParams;
  modelName?: StreamPreviewMLModel | string;
  modelStats?;
  modelParams?;
  streamId: string;
  startDate: string;
  endDate?: string;
  isRolling: boolean;
  rollingOffset?: number;
  rollingPeriods?: number;
  errorMessage?: string;
}

export interface IStreamPayload {
  forecastedDataSource?: DataSourceType;
  forecastedDataParams?;
  actualsDataSource?: DataSourceType;
  actualsDataParams?;
  values?: StreamValue[];
  currency?: string;
  modelName?: string;
  modelStats?;
  modelParams?;
  name?: string;
  description?: string;
  cadence?: Cadence;
  startDate?: string;
  endDate?: string;
  isRolling?: boolean;
  rollingOffset?: number;
  rollingPeriods?: number;

}

export class StreamPayload implements IStreamPayload{
  forecastedDataSource?: DataSourceType;
  forecastedDataParams?;
  actualsDataSource?: DataSourceType;
  actualsDataParams?;
  values?: StreamValue[];
  currency: string;
  modelName?: string;
  modelStats?;
  modelParams?;
  name?: string;
  description?: string;
  cadence?: Cadence;
  startDate?: string;
  endDate?: string;
  isRolling?: boolean;
  rollingOffset?: number;
  rollingPeriods?: number;


  constructor(
    name?: string,
    description?: string,
    currency?: string,
    cadence?: Cadence,
    forecastedDataSource?: DataSourceType,
    forecastedSearchData?: SearchData,
    actualDataSource?: DataSourceType,
    actualsSearchData?: SearchData,
    values?: StreamValue[],
    modelName?: string,
    modelStats?,
    modelParams?,
    calenderSettings?: CalendarSettings,
    maxAge?: number,
  ){
    this.name = name;
    this.description = description;
    this.currency = currency;
    this.cadence = cadence;
    this.forecastedDataSource = forecastedDataSource;
    this.actualsDataSource = actualDataSource;
    this.values = values;
    this.modelName = modelName;
    this.modelStats = modelStats;
    this.modelParams = modelParams;
    this.startDate = calenderSettings?.startDate;
    this.endDate = calenderSettings?.endDate;
    this.isRolling = calenderSettings?.isRolling;
    this.rollingOffset = calenderSettings?.periodsOffset;
    this.rollingPeriods = calenderSettings?.periods;



    if(this.forecastedDataSource === DataSourceType.Transactions || this.forecastedDataSource === DataSourceType.Invoices){
      this.forecastedDataParams = this.getDataParams(forecastedSearchData, maxAge);
    }else{
      this.forecastedDataParams = {};
    }
    if(this.actualsDataSource === DataSourceType.Transactions || this.actualsDataSource === DataSourceType.Invoices){
      this.actualsDataParams = this.getDataParams(actualsSearchData);
    }else {
      this.actualsDataParams = {};
    }

  }


  getDataParams(searchData: SearchData, maxAge?: number): any {
    const dataParams = {};
    if(searchData){
      if(searchData.startDateParameter){
        dataParams[searchData.startDateParameter.type] = searchData.startDateParameter.value;
      }
      if(searchData.endDateParameter){
        dataParams[searchData.endDateParameter.type] = searchData.endDateParameter.value;
      }
      if(searchData.filter){
        dataParams['q'] = searchData.filter;
      }
      if(searchData.parameters?.length>0){
        searchData.parameters.forEach(param=>{
          if(dataParams[param.type]){
            dataParams[param.type].push(param.value);
          } else{
            dataParams[param.type] = [param.value];
          }
        });
      }
      if(maxAge){
        dataParams['maxAge'] = maxAge;
      }
    }
    if (dataParams['tag']) {
      dataParams['tagId'] = dataParams['tag'];
    }
    return dataParams;
  }

}

export class StreamValue {
  date: string;
  forecasted?: number;
  actual?: number;
  forecastedManuallyEdited?: boolean;
  actualManuallyEdited?: boolean;
  forecastedWithFactor?: number;
}
export class StreamRow{
  clickable: boolean;
  stream: Stream;
  name: string;
  forecastedDataSource: string;
  actualsDataSource: DataSourceType;
  streamId: string;
  menu: boolean;
  menuOptions: CustomMenuOption[];
  selected: boolean;

  constructor(stream: Stream, deleteDisabled: boolean){
    this.clickable = true;
    this.stream = stream;
    this.name = stream.name;
    this.forecastedDataSource = stream.forecastedDataSource.charAt(0).toUpperCase() + stream.forecastedDataSource.slice(1);
    this.actualsDataSource = stream.actualsDataSource;
    this.streamId = stream.streamId;
    this.menu = true;
    this.setMenuOptions(deleteDisabled);
  }

  setMenuOptions(deleteDisabled: boolean): void {
    this.menuOptions = [
      new CustomMenuOption('view', 'View', true, false),
      new CustomMenuOption('delete', 'Delete', true, deleteDisabled)
    ];
  }
}

export class StreamListViewModel {

  streams: Stream[];
  streamRows: StreamRow[];
  filteredStreamRows: StreamRow[];
  table: PaginatedTableViewModel<StreamRow>;
  tableColumns: PaginatedTableColumn[];
  isSelectionMode: boolean;

  constructor(streams: Stream[], isSelectionMode: boolean, deleteDisabled?: boolean){
    this.streams = streams;
    this.streamRows = [];
    this.isSelectionMode = isSelectionMode;
    this.setCompleteStreamRows(this.streams, deleteDisabled);
    this.setFilteredStreamRows();
    this.setTableColumns();
  }

  setCompleteStreamRows(allStreams: Stream[], deleteDisabled: boolean): void {
    allStreams.forEach(stream=>{
      this.streamRows.push(new StreamRow(stream, deleteDisabled));
    });
  }

  setFilteredStreamRows(txtFilter?: string): void {
    this.filteredStreamRows = this.streamRows.filter(streamRowFilter(txtFilter));
    this.table = new PaginatedTableViewModel('streamId', this.filteredStreamRows, this.isSelectionMode, null, null, 'No Streams matching filter');
  }

  setTableColumns(): void {
    this.tableColumns = [
      new PaginatedTableColumn('name', ColumnType.string, 'Name', null, true, true),
      new PaginatedTableColumn('forecastedDataSource', ColumnType.string, 'Type', null, true, true),
      new PaginatedTableColumn('menu', ColumnType.menuButton, null, null, false, true, null,
        {textAlign: 'center', icon: 'more_vert'}, {btnId: 'openStreamMenu', icon: 'more_vert', type: EditType.button })
    ];
    if(this.isSelectionMode){
      this.tableColumns.pop();
    }
  }

}

const streamRowFilter: (txtFilter: string) => Predicate<StreamRow> = (txtFilter: string) => (row: StreamRow) => {
  if(!txtFilter){
    return true;
  }
  let returnValue: boolean = false;
  if(txtFilter){
    const lowerCaseFilter: string = txtFilter.toLocaleLowerCase();
    if(
      (row.name != null && row.name.toLocaleLowerCase().indexOf(lowerCaseFilter)!== -1) ||
      (row.forecastedDataSource != null && row.forecastedDataSource.toLocaleLowerCase().indexOf(lowerCaseFilter)!== -1)
    ){
      returnValue = true;
    }
  }
  return returnValue;
};


export enum DataSourceType{
  Transactions = 'transactions',
  Manual = 'manual',
  Invoices = 'invoices'
}


export class CreateStreamApiResponse {
  modelName: string;
  modelParams: object;
  modelStats: object;
  values: StreamValue[];
}

export class DateValueObject {
  date: string;
  value: number;

  constructor(date?: string, value?: number) {
    if(this.date) {
      this.date = date;
    }
    if(this.value) {
      this.value = value;
    }
  }
}

export enum ForecastValueType {
  Forecasted = 'forecasted',
  Actual = 'actual',
  Invoice = 'invoice'
}