import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import {
	ClearGoogleAnalyticsState,
	InitGoogleAnalyticsState,
	ResetGoogleAnalyticsState,
	SetLogin,
	LoadUserData,
	UserAction,
	UserActionWithParam,
	Ecommerce as EcommerceModel,
	Ecommerce,
} from '../../actions/google-analytics.actions';
import { environment } from 'src/environments/environment';
import { UserActions } from '../../../utils/analyticsEnums';
import { IdentityService } from 'src/app/features/settings/services/identity.service';

// Google Analytics dataLayer appears to maintain its' own store, but that store can be invalidated by history changes
// and closing then re-opening our app, something our store can persist through. So we can use our store to correct the
// dataLayer.  It probably won't catch all invalidations, but it's better than nothing.

export class GoogleAnalyticsStateModel {
	isLogin: boolean;
	userDataLoaded: boolean;
}

@State<GoogleAnalyticsStateModel>({
	name: 'googleAnalytics',
	defaults: {
		isLogin: false,
		userDataLoaded: false,
	},
})
@Injectable()
export class GoogleAnalyticsState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private theWindow = <any>window;

	constructor(
		private serializationService: SerializationService,
		private store: Store
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitGoogleAnalyticsState)
	async InitGoogleAnalyticsState(context: StateContext<GoogleAnalyticsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (appReady) {
						const state: GoogleAnalyticsStateModel = deserializedState.googleAnalytics;
						if (state) {
							context.patchState(state);
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(ResetGoogleAnalyticsState)
	ResetGoogleAnalyticsState(context: StateContext<GoogleAnalyticsStateModel>) {
		context.dispatch(new ClearGoogleAnalyticsState());
		context.dispatch(new InitGoogleAnalyticsState());
	}

	@Action(ClearGoogleAnalyticsState)
	ClearGoogleAnalyticsState(context: StateContext<GoogleAnalyticsStateModel>) {
		this.appReadySub.unsubscribe();
		const state: GoogleAnalyticsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(SetLogin)
	async setLogin(context: StateContext<GoogleAnalyticsStateModel>) {
		const state = context.getState();
		if (state.isLogin === false) {
			state.isLogin = true;
			context.patchState(state);
			this.theWindow.dataLayer.push({
				event: UserActions.proxyLogin,
			});
		}
	}

	@Action(LoadUserData)
	async loadUserData(context: StateContext<GoogleAnalyticsStateModel>, action: LoadUserData) {
		const state = context.getState();
		if (state.userDataLoaded === false) {
			state.userDataLoaded = true;
			context.patchState(state);
			this.theWindow.dataLayer.push({
				event: UserActions.proxyUserDataLoad,
				user: {
					id: action.userId,
				},
				subscription: action.parameters,
			});
		}
	}

	@Action(UserAction)
	async userAction(context: StateContext<GoogleAnalyticsStateModel>, action: UserAction) {
		this.theWindow.dataLayer.push({
			event: action.event,
		});
	}

	@Action(UserActionWithParam)
	async userActionWithParam(context: StateContext<GoogleAnalyticsStateModel>, action: UserActionWithParam) {
		this.theWindow.dataLayer.push({
			event: action.event,
			event_parameters: action.parameters,
		});
	}

	@Action(Ecommerce)
	async ecommerce(context: StateContext<GoogleAnalyticsStateModel>, action: Ecommerce) {
		this.theWindow.dataLayer.push({
			event: UserActions.ecommercePurchase,
			ecommerce: action.parameters,
		});
	}
}
