import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class InvoiceFiltersService {
	version = 3;

	constructor(private httpClient: HttpClient) {}

	public getInvoiceFilters(type?: string): Observable<HttpResponse<Object>> {
		let headers = new HttpHeaders();
		headers = headers.append('invoiceProvider', 'sage');
		let parameters = new HttpParams();

		if (type) {
			parameters = parameters.append('invoiceType', type);
		}

		const url = `${environment.trovataAPI('workspace')}/data/invoices/summary`;
		return this.httpClient
			.get(url, {
				params: parameters,
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}
}
