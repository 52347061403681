import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { firstValueFrom, lastValueFrom, Observable, Subscription, throwError } from 'rxjs';
import { Subscription as TrovataSubscription, SubscriptionsAndProducts } from '../../models/subscriptions.model';
import { SubscriptionService } from '../../services/subscription.service';
import {
	ClearSubscriptionsState,
	DeleteSubscription,
	GetSubscriptions,
	InitSubscriptionsState,
	ResetSubscriptionsState,
	UpdateSubscription,
} from '../actions/subscriptions.actions';

export class SubscriptionsStateModel {
	subscriptionsAndProducts: SubscriptionsAndProducts;
	currentSubscription: TrovataSubscription;
}

@State<SubscriptionsStateModel>({
	name: 'subscriptions',
	defaults: {
		subscriptionsAndProducts: null,
		currentSubscription: null,
	},
})
@Injectable()
export class SubscriptionsState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	@Selector() static subscriptions(subscriptionsState: SubscriptionsStateModel): SubscriptionsAndProducts {
		return subscriptionsState.subscriptionsAndProducts;
	}

	@Selector() static currentSubscription(subscriptionsState: SubscriptionsStateModel): TrovataSubscription {
		return subscriptionsState.currentSubscription;
	}

	constructor(
		private subscriptionService: SubscriptionService,
		private store: Store,
		private serializationService: SerializationService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitSubscriptionsState)
	async initSubscriptionsState(context: StateContext<SubscriptionsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const plaidStateIsCached: boolean = this.subscriptionsStateIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (plaidStateIsCached && appReady) {
						const state: SubscriptionsStateModel = deserializedState.subscriptions;
						context.patchState(state);
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetSubscriptions)
	getSubscriptions(context: StateContext<SubscriptionsStateModel>): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const subscriptions: SubscriptionsAndProducts = await firstValueFrom(this.subscriptionService.getSubscriptions());
				const state: SubscriptionsStateModel = context.getState();
				state.subscriptionsAndProducts = subscriptions;
				state.currentSubscription = subscriptions.subscriptions.find(sub => sub.subscribed);
				context.patchState(state);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateSubscription)
	updateSubscription(context: StateContext<SubscriptionsStateModel>, action: UpdateSubscription): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await this.subscriptionService.updateSubscription(action.subUpdate).toPromise();
				context.dispatch(new GetSubscriptions());
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(DeleteSubscription)
	async deleteSubscription(context: StateContext<SubscriptionsStateModel>, action: DeleteSubscription) {
		try {
			await lastValueFrom(this.subscriptionService.deleteSubscription(action.subscriptionId));

			context.dispatch(new GetSubscriptions());

			return;
		} catch (error) {
			throw error;
		}
	}

	@Action(ResetSubscriptionsState)
	resetSubscriptionsState(context: StateContext<SubscriptionsStateModel>) {
		context.dispatch(new ClearSubscriptionsState());
	}

	@Action(ClearSubscriptionsState)
	clearSubscriptionsState(context: StateContext<SubscriptionsStateModel>) {
		this.appReadySub.unsubscribe();
		const state: SubscriptionsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private subscriptionsStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState && deserializedState.subscriptions && deserializedState.subscriptions.subscriptionsAndProducts) {
			return true;
		} else {
			return false;
		}
	}
}
