import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Select, Selector } from '@ngxs/store';
import {
	checkForBadHttpErrorMessage,
	checkToAddStateApiError,
	checkToRemoveStateApiError,
	StateApiError,
	TrovataAppState,
} from 'src/app/core/models/state.model';
import { combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { GetWorkflowsResponse, Workflow } from '../../../models/workflow.model';
import { WorkflowsService } from '../../../services/workflows/workflow.service';
import {
	ClearWorkflowsState,
	CreateWorkflow,
	DeleteWorkflow,
	GetWorkflowById,
	GetWorkflows,
	InitWorkflowsState,
	PostWorkflowAdminApproval,
	ResetWorkflowsState,
	UpdateWorkflow,
} from '../../actions/workflows.actions';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { AdminApprovalService } from 'src/app/shared/services/admin-approval.service';
import {
	AdminApprovalRecordStatus,
	ChangeRequest,
	ChangeRequestType,
	PaymentsRecordType,
	PostAdminApprovalResponse,
} from 'src/app/shared/models/admin-approval.model';
import { PermissionMap, PermissionId } from 'src/app/features/settings/models/feature.model';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { AuthUser } from '@trovata/app/core/models/auth.model';
import { ReadyForReviewSnack, ReadyForReviewSnackParams, sortSnacksByDate } from 'src/app/shared/models/ready-for-review-snack.model';
import { DateTime } from 'luxon';
import { TrovataResourceType, TrovataResourceViewText } from 'src/app/shared/models/trovata.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CancelPollRequestService } from '@trovata/app/shared/services/cancel-poll-request.service';
import { PollRequestKey } from '@trovata/app/shared/models/cancel-poll-request.model';

export class WorkflowsStateModel extends EntitledStateModel {
	workflows: Workflow[];
	workflowSnacks: ReadyForReviewSnack[];
	apiErrors: StateApiError[];
}

@State<WorkflowsStateModel>({
	name: 'workflows',
	defaults: {
		workflows: null,
		workflowSnacks: null,
		isCached: false,
		apiErrors: null,
	},
})
@Injectable()
export class WorkflowsState {
	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;
	@Select(CustomerFeatureState.paymentsEnabled) paymentsEnabled$: Observable<boolean>;

	private appReady$: Observable<boolean>;
	private authUser$: Observable<AuthUser>;
	private appReadySub: Subscription;
	private isInitialized: boolean;
	private authUser: AuthUser;

	constructor(
		private workflowsService: WorkflowsService,
		private store: Store,
		private serializationService: SerializationService,
		private adminApprovalService: AdminApprovalService,
		private sanitizer: DomSanitizer,
		private cancelPollRequestService: CancelPollRequestService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.authUser$ = this.store.select((state: TrovataAppState) => state.auth.authUser);
	}

	@Selector()
	static workflows(state: WorkflowsStateModel): Workflow[] {
		return state.workflows;
	}

	@Selector()
	static workflowsIsCached(state: WorkflowsStateModel): boolean {
		return state.isCached;
	}

	@Selector()
	static workflowsApiErrors(state: WorkflowsStateModel): StateApiError[] {
		return state.apiErrors;
	}

	@Selector()
	static workflowSnacks(state: WorkflowsStateModel): ReadyForReviewSnack[] {
		return state.workflowSnacks;
	}

	@Action(InitWorkflowsState)
	async initWorkflowsState(context: StateContext<WorkflowsStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const workflowsStateIsCached: boolean = this.workflowsStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$, this.authUser$, this.paymentsEnabled$]).subscribe({
				next: ([appReady, permissions, authUser, paymentsEnabled]: [boolean, PermissionMap, AuthUser, boolean]) => {
					if (!this.isInitialized && appReady && permissions && authUser && paymentsEnabled) {
						this.authUser = authUser;
						if (permissions.has(PermissionId.readWorkflows)) {
							if (workflowsStateIsCached) {
								const state: WorkflowsStateModel = deserializedState.workflows;
								this.postProcessWorkflowsData(context, state);
							} else {
								this.initDefaultWorkflowsState(context);
							}
							this.isInitialized = true;
						} else {
							context.patchState({ workflows: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetWorkflows)
	getWorkflows(context: StateContext<WorkflowsStateModel>): Observable<GetWorkflowsResponse> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetWorkflows;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		return this.workflowsService.getWorkflows().pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getWorkflowsResponse: GetWorkflowsResponse) => {
				checkToRemoveStateApiError(context, GetWorkflows);
				const workflows: Workflow[] = getWorkflowsResponse.workflows;
				this.postProcessWorkflowsData(context, null, workflows);
				this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return checkToAddStateApiError(error, context, GetWorkflows, 'Workflows are down right now. Please try again later.');
				})
			)
		);
	}

	@Action(CreateWorkflow)
	createWorkflow(context: StateContext<WorkflowsStateModel>, action: CreateWorkflow): Observable<Workflow> {
		return this.workflowsService.createWorkflow(action.workflowToCreate).pipe(
			tap((workflow: Workflow) => {
				const state: WorkflowsStateModel = context.getState();
				const workflowsCopy: Workflow[] = [...state.workflows];
				workflowsCopy.push(workflow);
				this.postProcessWorkflowsData(context, null, workflowsCopy);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Create workflow is down right now. Please try again later.')))
		);
	}

	@Action(UpdateWorkflow)
	updateWorkflow(context: StateContext<WorkflowsStateModel>, action: UpdateWorkflow): Observable<Workflow> {
		return this.workflowsService.updateWorkflow(action.workflowToUpdate).pipe(
			tap((workflow: Workflow) => {
				const state: WorkflowsStateModel = context.getState();
				const filteredWorkflows: Workflow[] = state.workflows.filter((filterWorkflow: Workflow) => filterWorkflow.workflowId !== workflow.workflowId);
				filteredWorkflows.push(workflow);
				this.postProcessWorkflowsData(context, null, filteredWorkflows);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Update workflow is down right now. Please try again later.')))
		);
	}

	@Action(DeleteWorkflow)
	deleteWorkflow(context: StateContext<WorkflowsStateModel>, action: DeleteWorkflow): Observable<Workflow> {
		return this.workflowsService.deleteWorkflow(action.workflowToDelete).pipe(
			tap((workflow: Workflow) => {
				const state: WorkflowsStateModel = context.getState();
				const filteredWorkflows: Workflow[] = state.workflows.filter(
					(filterWorkflow: Workflow) => filterWorkflow.workflowId !== action.workflowToDelete.workflowId
				);
				if (workflow && Object.keys(workflow).length) {
					filteredWorkflows.push(workflow);
				}
				this.postProcessWorkflowsData(context, null, filteredWorkflows);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Delete workflow is down right now. Please try again later.')))
		);
	}

	@Action(GetWorkflowById)
	getWorkflowById(context: StateContext<WorkflowsStateModel>, action: GetWorkflowById): Observable<GetWorkflowsResponse> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetWorkflowById;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		return this.workflowsService.getWorkflowById(action.workflowId).pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getWorkflowsResponse: GetWorkflowsResponse) => {
				const state: WorkflowsStateModel = context.getState();
				const gotWorkflow: Workflow = getWorkflowsResponse.workflows[0];
				if (gotWorkflow) {
					const filteredWorkflows: Workflow[] = state.workflows.filter((workflow: Workflow) => workflow.workflowId !== gotWorkflow.workflowId);
					filteredWorkflows.push(gotWorkflow);
					this.postProcessWorkflowsData(context, null, filteredWorkflows);
				}
				this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return checkForBadHttpErrorMessage(error, 'Workflow is down right now. Please try again later.');
				})
			)
		);
	}

	@Action(PostWorkflowAdminApproval)
	postWorkflowAdminApproval(context: StateContext<WorkflowsStateModel>, action: PostWorkflowAdminApproval): Observable<PostAdminApprovalResponse> {
		return this.adminApprovalService.postAdminApproval(action.adminApproval, PaymentsRecordType.WORKFLOW).pipe(
			tap((response: PostAdminApprovalResponse) => {
				const state: WorkflowsStateModel = context.getState();
				const changeRequest: ChangeRequest = response.changeRequest;
				const approvalActionType: ChangeRequestType = changeRequest.changeType;
				const approvalStatus: AdminApprovalRecordStatus = changeRequest.status;
				const workflow: Workflow = response.record as Workflow;
				if (
					(approvalActionType === ChangeRequestType.CREATE && approvalStatus === AdminApprovalRecordStatus.APPROVED) ||
					(approvalActionType === ChangeRequestType.UPDATE && approvalStatus === AdminApprovalRecordStatus.APPROVED) ||
					(approvalActionType === ChangeRequestType.UPDATE && approvalStatus === AdminApprovalRecordStatus.REJECTED) ||
					(approvalActionType === ChangeRequestType.DELETE && approvalStatus === AdminApprovalRecordStatus.REJECTED)
				) {
					const filteredWorkflows: Workflow[] = state.workflows.filter((filterWorkflow: Workflow) => filterWorkflow.workflowId !== workflow.workflowId);
					filteredWorkflows.push(workflow);
					this.postProcessWorkflowsData(context, null, filteredWorkflows);
				} else if (
					(approvalActionType === ChangeRequestType.DELETE && approvalStatus === AdminApprovalRecordStatus.APPROVED) ||
					(approvalActionType === ChangeRequestType.CREATE && approvalStatus === AdminApprovalRecordStatus.REJECTED)
				) {
					const filteredWorkflows: Workflow[] = state.workflows.filter((filterWorkflow: Workflow) => filterWorkflow.workflowId !== changeRequest.recordId);
					this.postProcessWorkflowsData(context, null, filteredWorkflows);
				}
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Workflow approval is down right now. Please try again later.')))
		);
	}

	@Action(ClearWorkflowsState)
	clearWorkflowsState(context: StateContext<WorkflowsStateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: WorkflowsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetWorkflowsState)
	resetWorkflowsState(context: StateContext<WorkflowsStateModel>): void {
		context.dispatch(new ClearWorkflowsState());
		context.dispatch(new GetWorkflows());
	}

	private workflowsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedWorkflowsState: WorkflowsStateModel | undefined = deserializedState.workflows;
		if (
			deserializedWorkflowsState &&
			deserializedWorkflowsState.workflows &&
			deserializedWorkflowsState.workflowSnacks &&
			deserializedWorkflowsState.isCached
		) {
			return true;
		} else {
			return false;
		}
	}

	private initDefaultWorkflowsState(context: StateContext<WorkflowsStateModel>): void {
		const state: WorkflowsStateModel = context.getState();
		state.workflows = [];
		state.workflowSnacks = [];
		state.apiErrors = [];
		state.isCached = false;
		context.patchState(state);
		context.dispatch(new GetWorkflows());
	}

	private postProcessWorkflowsData(context: StateContext<WorkflowsStateModel>, state?: WorkflowsStateModel, workflows?: Workflow[]): void {
		const stateToProcess: WorkflowsStateModel = state ? state : context.getState();
		const workflowsToProcess: Workflow[] = workflows ? workflows : stateToProcess.workflows;
		const workflowNotificationSnacks: ReadyForReviewSnack[] = this.getNotificationSnacks(workflowsToProcess);
		const sortedWorkflows: Workflow[] = this.sortWorkflowsAlphabetically(workflowsToProcess);
		stateToProcess.workflowSnacks = workflowNotificationSnacks;
		stateToProcess.workflows = sortedWorkflows;
		stateToProcess.isCached = true;
		context.patchState(stateToProcess);
	}

	private getNotificationSnacks(workflows: Workflow[]): ReadyForReviewSnack[] {
		let workflowNotificationSnacks: ReadyForReviewSnack[] = [];
		workflows.forEach((workflow: Workflow) => {
			workflow.needsReview = this.checkIfWorkflowNeedsReview(workflow);
			if (workflow.needsReview) {
				const requestor: string = 'A user '; // TODO: Update with actual user name once apis are updated
				const actionText: string = `${workflow.changeRequest.changeType.toLowerCase()}d a `;
				const workflowNameText: string = `workflow (${workflow.name})`;
				const htmlString: string = `
        <span class="font-700-14-20">${requestor}</span>
        <span class="font-400-14-20">${actionText}</span>
        <span class="font-700-14-20">${workflowNameText}</span>
        `;
				const messageSafeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlString);
				const readyForReviewSnackParams: ReadyForReviewSnackParams = {
					date: DateTime.fromISO(workflow.changeRequest.createdAt).toLocaleString(DateTime.DATE_MED),
					messageHtml: messageSafeHtml,
					resource: workflow,
					resourceId: workflow.workflowId,
					resourceType: TrovataResourceType.workflow,
					resourceViewText: TrovataResourceViewText.Workflow,
					sortByDate: workflow.changeRequest.createdAt,
				};
				const snack: ReadyForReviewSnack = new ReadyForReviewSnack(readyForReviewSnackParams);
				workflowNotificationSnacks.push(snack);
			}
		});
		workflowNotificationSnacks = sortSnacksByDate(workflowNotificationSnacks);
		return workflowNotificationSnacks;
	}

	private checkIfWorkflowNeedsReview(workflow: Workflow): boolean {
		if (workflow.changeRequest && workflow.changeRequest.createdBy !== this.authUser['https://auth.trovata.io/userinfo/userId']) {
			return true;
		}
		return false;
	}

	private sortWorkflowsAlphabetically(workflows: Workflow[]): Workflow[] {
		const sortedWorkflows: Workflow[] = workflows.sort((workflowA: Workflow, workflowB: Workflow) => {
			if (workflowA.name.toLocaleLowerCase() < workflowB.name.toLocaleLowerCase()) {
				return -1;
			} else if (workflowA.name.toLocaleLowerCase() > workflowB.name.toLocaleLowerCase()) {
				return 1;
			}
			return 0;
		});
		return sortedWorkflows;
	}
}
