import { Server } from 'miragejs';
import { AccountDbData, AppRegistry } from '../types';
import { Entity, EntityExtraProperty, EntityFieldType } from '@trovata/app/features/entities/models/entity.model';
import { rand, randNumber, randWord } from '@ngneat/falso';

export function createEntitiesAndProps(server: Server<AppRegistry>): void {
	// extra properties: string, boolean, and number
	const stringExtraProperty: EntityExtraProperty = server.create('extraProp', { type: EntityFieldType.text });
	const booleanExtraProperty: EntityExtraProperty = server.create('extraProp', { type: EntityFieldType.boolean });
	const numberExtraProperty: EntityExtraProperty = server.create('extraProp', { type: EntityFieldType.number });

	let acctIds: string[] = server.db.accounts.map((acct: AccountDbData) => acct.id);
	let acctSelection: string[] = rand(acctIds, { length: randNumber({ min: 0, max: 3 }) }).filter((num: string) => num !== undefined);
	acctIds = acctIds.filter((acctId: string) => acctSelection.indexOf(acctId) < 0);

	// parent entities

	const parentEntity1: Entity = server.create('entity', {
		extraProperties: [
			{ extraPropertyId: stringExtraProperty.extraPropertyId, value: randWord({ length: 3 }).join(' ') },
			{ extraPropertyId: booleanExtraProperty.extraPropertyId, value: rand(['True', 'False']) },
			{ extraPropertyId: numberExtraProperty.extraPropertyId, value: randNumber() },
		],
		accounts: acctSelection,
	});
	acctSelection.forEach((acctId: string) => server.db.accounts.update(acctId, { entityId: parentEntity1.entityId }));

	acctSelection = rand(acctIds, { length: randNumber({ min: 0, max: 3 }) }).filter((num: string) => num !== undefined);
	acctIds = acctIds.filter((acctId: string) => acctSelection.indexOf(acctId) < 0);
	const parentEntity2: Entity = server.create('entity', {
		extraProperties: [
			{ extraPropertyId: stringExtraProperty.extraPropertyId, value: randWord({ length: 3 }).join(' ') },
			{ extraPropertyId: booleanExtraProperty.extraPropertyId, value: rand(['True', 'False']) },
			{ extraPropertyId: numberExtraProperty.extraPropertyId, value: randNumber() },
		],
		accounts: acctSelection,
	});
	acctSelection.forEach((acctId: string) => server.db.accounts.update(acctId, { entityId: parentEntity2.entityId }));

	acctSelection = rand(acctIds, { length: randNumber({ min: 0, max: 3 }) }).filter((num: string) => num !== undefined);
	acctIds = acctIds.filter((acctId: string) => acctSelection.indexOf(acctId) < 0);
	const parentEntity3: Entity = server.create('entity', {
		extraProperties: [
			{ extraPropertyId: stringExtraProperty.extraPropertyId, value: randWord({ length: 3 }).join(' ') },
			{ extraPropertyId: booleanExtraProperty.extraPropertyId, value: rand(['True', 'False']) },
			{ extraPropertyId: numberExtraProperty.extraPropertyId, value: randNumber() },
		],
		accounts: acctSelection,
	});
	acctSelection.forEach((acctId: string) => server.db.accounts.update(acctId, { entityId: parentEntity3.entityId }));

	// child entities

	acctSelection = rand(acctIds, { length: randNumber({ min: 0, max: 3 }) }).filter((num: string) => num !== undefined);
	acctIds = acctIds.filter((acctId: string) => acctSelection.indexOf(acctId) < 0);
	const childEntity1: Entity = server.create('entity', {
		extraProperties: [
			{ extraPropertyId: stringExtraProperty.extraPropertyId, value: randWord({ length: 3 }).join(' ') },
			{ extraPropertyId: booleanExtraProperty.extraPropertyId, value: rand(['True', 'False']) },
			{ extraPropertyId: numberExtraProperty.extraPropertyId, value: randNumber() },
		],
	});
	acctSelection.forEach((acctId: string) => server.db.accounts.update(acctId, { entityId: childEntity1.entityId }));

	acctSelection = rand(acctIds, { length: randNumber({ min: 0, max: 3 }) }).filter((num: string) => num !== undefined);
	acctIds = acctIds.filter((acctId: string) => acctSelection.indexOf(acctId) < 0);
	const childEntity2: Entity = server.create('entity', {
		extraProperties: [
			{ extraPropertyId: stringExtraProperty.extraPropertyId, value: randWord({ length: 3 }).join(' ') },
			{ extraPropertyId: booleanExtraProperty.extraPropertyId, value: rand(['True', 'False']) },
			{ extraPropertyId: numberExtraProperty.extraPropertyId, value: randNumber() },
		],
		accounts: acctSelection,
	});
	acctSelection.forEach((acctId: string) => server.db.accounts.update(acctId, { entityId: childEntity2.entityId }));

	acctSelection = rand(acctIds, { length: randNumber({ min: 0, max: 3 }) }).filter((num: string) => num !== undefined);
	acctIds = acctIds.filter((acctId: string) => acctSelection.indexOf(acctId) < 0);
	const childEntity3: Entity = server.create('entity', {
		extraProperties: [
			{ extraPropertyId: stringExtraProperty.extraPropertyId, value: randWord({ length: 3 }).join(' ') },
			{ extraPropertyId: booleanExtraProperty.extraPropertyId, value: rand(['True', 'False']) },
			{ extraPropertyId: numberExtraProperty.extraPropertyId, value: randNumber() },
		],
		accounts: acctSelection,
	});
	acctSelection.forEach((acctId: string) => server.db.accounts.update(acctId, { entityId: childEntity3.entityId }));

	// grandchild entities

	acctSelection = rand(acctIds, { length: randNumber({ min: 0, max: 3 }) }).filter((num: string) => num !== undefined);
	acctIds = acctIds.filter((acctId: string) => acctSelection.indexOf(acctId) < 0);
	const grandchildEntity1: Entity = server.create('entity', {
		extraProperties: [
			{ extraPropertyId: stringExtraProperty.extraPropertyId, value: randWord({ length: 3 }).join(' ') },
			{ extraPropertyId: booleanExtraProperty.extraPropertyId, value: rand(['True', 'False']) },
			{ extraPropertyId: numberExtraProperty.extraPropertyId, value: randNumber() },
		],
		accounts: acctSelection,
	});
	acctSelection.forEach((acctId: string) => server.db.accounts.update(acctId, { entityId: grandchildEntity1.entityId }));

	acctSelection = rand(acctIds, { length: randNumber({ min: 0, max: 3 }) }).filter((num: string) => num !== undefined);
	acctIds = acctIds.filter((acctId: string) => acctSelection.indexOf(acctId) < 0);
	const grandchildEntity2: Entity = server.create('entity', {
		extraProperties: [
			{ extraPropertyId: stringExtraProperty.extraPropertyId, value: randWord({ length: 3 }).join(' ') },
			{ extraPropertyId: booleanExtraProperty.extraPropertyId, value: rand(['True', 'False']) },
			{ extraPropertyId: numberExtraProperty.extraPropertyId, value: randNumber() },
		],
		accounts: acctSelection,
	});
	acctSelection.forEach((acctId: string) => server.db.accounts.update(acctId, { entityId: grandchildEntity2.entityId }));
	// tree structure response
	server.create('getEntityTreeResponse', {
		entityTree: {
			trees: [
				{
					entityId: parentEntity1.entityId,
					subEntities: [
						{ entityId: childEntity1.entityId, subEntities: [] },
						{
							entityId: childEntity2.entityId,
							subEntities: [
								{ entityId: grandchildEntity1.entityId, subEntities: [] },
								{ entityId: grandchildEntity2.entityId, subEntities: [] },
							],
						},
					],
				},
				{
					entityId: parentEntity2.entityId,
					subEntities: [{ entityId: childEntity3.entityId, subEntities: [] }],
				},
			],
		},
		unassignedEntities: [parentEntity3.entityId],
	});
}
