import { CurrentCashData, CurrentCash } from 'src/app/features/balances/models/current-cash.model';
import { Server } from 'miragejs';
import { AppSchema, AccountDbData } from '../types';
import { AnalysisBalanceProperty } from 'src/app/features/reports/models/analysis.model';

export function addCurrentCashWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post('/data/current-cash/:id/data', (schema: AppSchema): CurrentCashData => schema.db.currentCashes[0]);

	server.post('/data/current-cash', (): CurrentCashData => {
		unhandledRequestMade();
		return null;
	});

	server.delete('/data/current-cash/:id', () => {
		unhandledRequestMade();
		return null;
	});

	server.post('/data/current-cash/data', (schema: AppSchema): CurrentCashData => schema.db.currentCashes[0]);

	server.post('/data/current-cash-list', (schema): { currentCash: CurrentCash[] } => {
		const accounts: AccountDbData[] = schema.db.accounts;
		const accountIds: string[] = accounts.map((account: AccountDbData) => account.accountId);
		return {
			currentCash: [
				{
					accountIds,
					currencyOverride: 'USD',
					name: 'Current Cash',
					description: '',
					preferences: {
						rounding: 0,
						balanceType: AnalysisBalanceProperty.compositeBalanceConverted,
					},
					lastModifiedDate: '2023-03-01T15:48:07.832622',
					createdDate: '2023-03-01T15:48:07.832647',
					currentCashId: 'currentCashSnackId',
				},
			],
		};
	});
}
