import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpService } from '../../core/services/http.service';
import {
	ChiliPiperInstance,
	PlaidLinkInstance,
	InitializedPlaidLinkData,
	PostPlaidTokenBody,
	PutPlaidTokenBody,
	GetPlaidItemsResponse,
} from '../models/widgets.model';
declare const Plaid: PlaidLinkInstance;
declare const ChiliPiper: ChiliPiperInstance;

@Injectable({
	providedIn: 'root',
})
export class WidgetsService {
	plaidLinkEnvironment: string;

	private testing: boolean;
	private postOrPutEnvironment: string;

	constructor(private httpService: HttpService) {
		this.testing = environment.production ? false : true;
		this.plaidLinkEnvironment = this.testing ? 'sandbox' : 'production';
		this.postOrPutEnvironment = this.testing ? 'sandbox' : 'prod';
	}

	getConnectionAnimationJson(): Observable<unknown> {
		return this.httpService.get('https://lottie.host/1913ec21-ce72-465d-996e-8098ccf05197/MpRek8jlf6.json');
	  }	

	postPlaidToken(products: string[]): Observable<string> {
		const body: PostPlaidTokenBody = {
			environment: this.postOrPutEnvironment,
			products: products,
			redirect_uri: environment.plaidRedirectUri,
		};
		return this.httpService.post(`${environment.edgeAPI()}/connections/plaid/token`, body);
	}

	postUpdateModeToken(itemId: string) {
		const body: PostPlaidTokenBody = {
			environment: this.postOrPutEnvironment,
			redirect_uri: environment.plaidRedirectUri,
			update: { account_selection_enabled: true },
			item_id: itemId,
		};
		return this.httpService.post(`${environment.edgeAPI()}/connections/plaid/token`, body);
	}

	putPlaidToken(publicToken: string, linkSessionId: string): Observable<string> {
		const body: PutPlaidTokenBody = {
			environment: this.postOrPutEnvironment,
			publicToken,
			linkSessionId,
		};

		return this.httpService.put(`${environment.edgeAPI()}/connections/plaid/token`, body);
	}

	getPlaidToken(itemId: string) {
		let parameters = new HttpParams();
		if (itemId) {
			parameters = parameters.append('itemId', itemId);
		}
		return this.httpService.get(`${environment.edgeAPI()}/connections/plaid/token`, { params: parameters });
	}

	getPlaidItems(): Observable<GetPlaidItemsResponse> {
		return this.httpService.post(`${environment.edgeAPI()}/data/plaid/items/list`, {});
	}

	async initPlaidLink(itemId?: string, isAddAccounts = false): Promise<InitializedPlaidLinkData> {
		return new Promise(async (resolve, reject) => {
			try {
				const plaidInstance: PlaidLinkInstance = await this.loadPlaidLinkScript();

				const products: string[] = ['transactions'];

				let plaidLinkToken: string;
				if (itemId && isAddAccounts) {
					plaidLinkToken = await lastValueFrom(this.postUpdateModeToken(itemId));
				} else if (itemId) {
					plaidLinkToken = await lastValueFrom(this.getPlaidToken(itemId));
				} else {
					plaidLinkToken = await lastValueFrom(this.postPlaidToken(products));
				}

				plaidLinkToken = JSON.parse(plaidLinkToken).linkToken;

				const initializedPlaidLinkData: InitializedPlaidLinkData = {
					plaidInstance: plaidInstance,
					plaidLinkToken: plaidLinkToken,
				};

				resolve(initializedPlaidLinkData);
			} catch (error: any) {
				reject(error);
			}
		});
	}

	private loadPlaidLinkScript(): Promise<PlaidLinkInstance> {
		const scriptElements: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName('script');
		let plaidScriptAlreadyLoaded = false;
		for (let i = 0; i < scriptElements.length; i++) {
			if (scriptElements[i].src === 'https://cdn.plaid.com/link/v2/stable/link-initialize.js') {
				plaidScriptAlreadyLoaded = true;
			}
		}
		return new Promise((resolve, reject) => {
			if (plaidScriptAlreadyLoaded) {
				resolve(Plaid);
			} else {
				const scriptElement: HTMLScriptElement = window.document.createElement('script');
				scriptElement.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

				scriptElement.onload = () => {
					resolve(Plaid);
				};

				scriptElement.onerror = () => {
					reject();
				};

				document.getElementsByTagName('head')[0].appendChild(scriptElement);
			}
		});
	}

	removePlaidScripts() {
		const scriptElements: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName('script');
		for (let i = 0; i < scriptElements.length; i++) {
			if (scriptElements[i].src.includes('cdn.plaid.com')) {
				scriptElements[i].remove();
			}
		}
		const linkElements: HTMLCollectionOf<HTMLLinkElement> = document.getElementsByTagName('link');
		for (let i = 0; i < linkElements.length; i++) {
			if (linkElements[i].href.includes('cdn.plaid.com')) {
				linkElements[i].remove();
			}
		}
	}

	private abbrToState(input: string, to: string): string {
		const states: string[][] = [
			['Arizona', 'AZ'],
			['Alabama', 'AL'],
			['Alaska', 'AK'],
			['Arkansas', 'AR'],
			['California', 'CA'],
			['Colorado', 'CO'],
			['Connecticut', 'CT'],
			['Delaware', 'DE'],
			['Florida', 'FL'],
			['Georgia', 'GA'],
			['Hawaii', 'HI'],
			['Idaho', 'ID'],
			['Illinois', 'IL'],
			['Indiana', 'IN'],
			['Iowa', 'IA'],
			['Kansas', 'KS'],
			['Kentucky', 'KY'],
			['Louisiana', 'LA'],
			['Maine', 'ME'],
			['Maryland', 'MD'],
			['Massachusetts', 'MA'],
			['Michigan', 'MI'],
			['Minnesota', 'MN'],
			['Mississippi', 'MS'],
			['Missouri', 'MO'],
			['Montana', 'MT'],
			['Nebraska', 'NE'],
			['Nevada', 'NV'],
			['New Hampshire', 'NH'],
			['New Jersey', 'NJ'],
			['New Mexico', 'NM'],
			['New York', 'NY'],
			['North Carolina', 'NC'],
			['North Dakota', 'ND'],
			['Ohio', 'OH'],
			['Oklahoma', 'OK'],
			['Oregon', 'OR'],
			['Pennsylvania', 'PA'],
			['Rhode Island', 'RI'],
			['South Carolina', 'SC'],
			['South Dakota', 'SD'],
			['Tennessee', 'TN'],
			['Texas', 'TX'],
			['Utah', 'UT'],
			['Vermont', 'VT'],
			['Virginia', 'VA'],
			['Washington', 'WA'],
			['West Virginia', 'WV'],
			['Wisconsin', 'WI'],
			['Wyoming', 'WY'],
		];

		if (to === 'abbr') {
			input = input.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
			for (let i = 0; i < states.length; i++) {
				if (states[i][0] === input) {
					return states[i][1];
				}
			}
		} else if (to === 'name') {
			input = input.toUpperCase();
			for (let i = 0; i < states.length; i++) {
				if (states[i][1] === input) {
					return states[i][0];
				}
			}
		}
	}

	loadHChiliPiperScript(): Promise<ChiliPiperInstance> {
		const scriptElements: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName('script');
		let chiliPiperScriptAlreadyLoaded = false;
		for (let i = 0; i < scriptElements.length; i++) {
			if (scriptElements[i].src === 'https://js.chilipiper.com/marketing.js') {
				chiliPiperScriptAlreadyLoaded = true;
			}
		}
		return new Promise((resolve, reject) => {
			if (chiliPiperScriptAlreadyLoaded) {
				resolve(ChiliPiper);
			} else {
				const scriptElement: HTMLScriptElement = window.document.createElement('script');
				scriptElement.src = 'https://js.chilipiper.com/marketing.js';

				scriptElement.onload = () => {
					resolve(ChiliPiper);
				};

				scriptElement.onerror = () => {
					reject();
				};

				window.document.body.appendChild(scriptElement);
			}
		});
	}
}
