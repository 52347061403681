import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';
import { CurrentCash, CurrentCashData } from '../models/current-cash.model';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class CurrentCashService {
	constructor(private httpClient: HttpClient) {}

	createCurrentCash(currentCash?: CurrentCash): Observable<CurrentCash> {
		const url = `${environment.trovataAPI('workspace')}/data/current-cash`;
		return this.httpClient.post<CurrentCash>(url, currentCash, { observe: 'response' }).pipe(map(resp => resp.body));
	}

	deleteCurrentCashReport(currentCashId: string) {
		const url = environment.trovataAPI('workspace') + `/data/current-cash/${currentCashId}`;
		return this.httpClient.delete(url).pipe(catchError(err => throwError(() => new Error(err))));
	}

	getCurrentCashReports(): Observable<CurrentCash[]> {
		const url = environment.trovataAPI('workspace') + '/data/current-cash-list';
		return this.httpClient.post<{ currentCash: CurrentCash[] }>(url, {}).pipe(
			map(res => res.currentCash),
			catchError(err => throwError(() => err))
		);
	}

	getCurrentCashData(currentCashReportId: string): Observable<CurrentCashData> {
		const params: HttpParams = new HttpParams().set('endDate', DateTime.now().startOf('day').toFormat('yyyy-MM-dd'));
		const url = environment.trovataAPI('workspace') + `/data/current-cash/${currentCashReportId}/data`;
		return this.httpClient.post<CurrentCashData>(url, {}, { params: params }).pipe(catchError(err => throwError(() => new Error(err))));
	}

	getCurrentCashPreview(acctIds: string[], currencyOverride?: string): Observable<CurrentCashData> {
		const params: HttpParams = new HttpParams().set('endDate', DateTime.now().startOf('day').toFormat('yyyy-MM-dd'));
		const url = environment.trovataAPI('workspace') + '/data/current-cash/data';
		return this.httpClient
			.post<CurrentCashData>(url, { accountIds: acctIds, currencyOverride }, { params: params })
			.pipe(catchError(err => throwError(() => err)));
	}

	editCurrentCash(currentCash: CurrentCash): Observable<CurrentCash> {
		const url = `${environment.trovataAPI('workspace')}/data/current-cash/${currentCash.currentCashId}`;
		return this.httpClient
			.put<CurrentCash>(url, currentCash, { observe: 'response' })
			.pipe(catchError(err => throwError(() => new Error(err))))
			.pipe(map(resp => resp.body));
	}
}
