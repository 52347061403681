import { Snack, SnackSize, SnackType } from 'src/app/shared/models/snacks.model';
import { BatchPayment, Payment } from '../../features/payments/models/payment.model';
import { Template } from '../../features/payments/models/template.model';
import { PaymentsAccount } from '../../features/payments/models/account.model';
import { UserGroup } from '../../features/settings/models/user-group.model';
import { TrovataResourceType, TrovataResourceViewText } from './trovata.model';
import { Workflow } from '../../features/payments/models/workflow.model';
import { SafeHtml } from '@angular/platform-browser';

export type ReadyForReviewResource = Payment | BatchPayment | Workflow | Template | PaymentsAccount | UserGroup;

export enum ReadyForReviewAction {
	initiated = 'initiated',
	created = 'created',
	updated = 'updated',
	deleted = 'deleted',
}

export interface ReadyForReviewSnackParams {
	date: string;
	messageHtml: SafeHtml;
	resource: ReadyForReviewResource;
	resourceId: string;
	resourceType: TrovataResourceType;
	resourceViewText: TrovataResourceViewText;
	sortByDate: string;
}

export class ReadyForReviewSnack extends Snack {
	date: string;
	messageHtml: SafeHtml;
	resource: ReadyForReviewResource;
	resourceId: string; // Can also use Snack.id as well
	resourceType: TrovataResourceType;
	resourceViewText: TrovataResourceViewText;
	sortByDate: string;

	constructor(params: ReadyForReviewSnackParams) {
		super(SnackSize.small, SnackType.readyForReview, params.resourceId);
		this.date = params.date;
		this.messageHtml = params.messageHtml;
		this.resource = params.resource;
		this.resourceId = params.resourceId;
		this.resourceType = params.resourceType;
		this.resourceViewText = params.resourceViewText;
		this.sortByDate = params.sortByDate;
	}
}

export function sortSnacksByDate(snacks: ReadyForReviewSnack[]): ReadyForReviewSnack[] {
	const sortedSnacks: ReadyForReviewSnack[] = snacks.sort((snackA: ReadyForReviewSnack, snackB: ReadyForReviewSnack) => {
		if (new Date(snackA.sortByDate) > new Date(snackB.sortByDate)) {
			return -1;
		} else if (new Date(snackA.sortByDate) < new Date(snackB.sortByDate)) {
			return 1;
		}
		return 0;
	});
	return sortedSnacks;
}
