import { AfterViewChecked, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartBuilder } from 'src/app/features/insights/models/highcharts.model';
import offlineExporting from 'highcharts/modules/offline-exporting';
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);
offlineExporting(Highcharts);
@Component({
	selector: 'trovata-app-highcharts',
	templateUrl: './app-highcharts.component.html',
	styleUrls: ['./app-highcharts.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHighchartsComponent implements AfterViewChecked {
	@Input() chartBuilder: ChartBuilder;

	@Output() highChartsChart: EventEmitter<Highcharts.Chart>;

	chart: Highcharts.Chart;
	chartCallback: (chart: Highcharts.Chart) => void;
	highcharts: typeof Highcharts;
	insightChartOptions: Highcharts.Options;

	constructor() {
		this.highcharts = Highcharts;
		this.highChartsChart = new EventEmitter();
		this.chartCallback = this.callback.bind(this);
		this.insightChartOptions = {};
	}

	ngAfterViewChecked(): void {
		this.reflow();
	}

	ngOnChanges(): void {
		this.insightChartOptions = this.chartBuilder.buildOptions();
	}

	reflow(): void {
		try {
			this.chart.reflow();
		} catch (error) {}
	}

	private callback(chart: Highcharts.Chart): void {
		if(!this.chart) {
			this.chart = chart;
			this.highChartsChart.emit(this.chart);
		}
	}
}
