import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from '@trovata/app/core/models/state.model';
import { SerializationService } from '@trovata/app/core/services/serialization.service';
import {
	AuditLog,
	AuditLogProductCategory,
	GetAuditLogSearchFiltersResponse,
	GetAuditLogsRequestBody,
	GetAuditLogsResponse,
} from '@trovata/app/shared/models/audit-log.model';
import { Observable, catchError, takeUntil, tap, throwError } from 'rxjs';
import {
	ClearAuditLogsState,
	GetAuditLogSearchFilters,
	GetPaginatedAuditLogs,
	InitAuditLogsState,
	ResetAuditLogsState,
	ResetCachedAuditLogs,
} from '../../actions/audit-log.actions';
import { AuditLogService } from '@trovata/app/shared/services/audit-log.service';
import { firstValidValueFrom } from '@trovata/app/shared/utils/firstValidValueFrom';
import { CancelPollRequestService } from '@trovata/app/shared/services/cancel-poll-request.service';
import { PollRequestKey } from '@trovata/app/shared/models/cancel-poll-request.model';
import { CustomerFeatureState } from '@trovata/app/features/settings/store/state/customer-feature.state';
import { PermissionId, PermissionMap } from '@trovata/app/features/settings/models/feature.model';

export class AuditLogsStateModel {
	auditLogs: AuditLog[];
	auditActors: (string | null)[];
	auditProductCategories: AuditLogProductCategory[];
}

@State<AuditLogsStateModel>({
	name: 'auditLogs',
	defaults: {
		auditLogs: null,
		auditActors: null,
		auditProductCategories: null,
	},
})
@Injectable()
export class AuditLogsState {
	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;

	private appReady$: Observable<boolean>;
	private initGetAuditLogsSize: number;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private auditService: AuditLogService,
		private cancelPollRequestService: CancelPollRequestService
	) {
		this.initGetAuditLogsSize = 100;
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector()
	static auditLogs(state: AuditLogsStateModel): AuditLog[] {
		return state.auditLogs;
	}

	@Selector()
	static auditActors(state: AuditLogsStateModel): (string | null)[] {
		return state.auditActors;
	}

	@Selector()
	static auditProductCategories(state: AuditLogsStateModel): AuditLogProductCategory[] {
		return state.auditProductCategories;
	}

	@Action(InitAuditLogsState)
	async initAuditLogsState(context: StateContext<AuditLogsStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const auditLogsStateIsCached: boolean = this.auditLogsStateIsCached(deserializedState);
			const appReady: boolean = await firstValidValueFrom(this.appReady$);
			const hasReadPermission: boolean = (await firstValidValueFrom(this.userAvailablePermissions$)).has(PermissionId.readAuditEvents);
			if (auditLogsStateIsCached && appReady && hasReadPermission) {
				const state: AuditLogsStateModel = deserializedState.auditLogs;
				context.patchState(state);
			} else if (!auditLogsStateIsCached && appReady && hasReadPermission) {
				this.initDefaultAuditLogsState(context);
			}
		} catch (error) {
			throw error;
		}
	}

	@Action(GetPaginatedAuditLogs)
	getPaginatedAuditLogs(context: StateContext<AuditLogsStateModel>, action: GetPaginatedAuditLogs): Observable<GetAuditLogsResponse> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetStoreAuditLogsAndSearchFilters;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		return this.auditService.getPaginatedAuditLogs(action.body).pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getAuditLogsResponse: GetAuditLogsResponse) => {
				const apiAuditLogs: AuditLog[] = getAuditLogsResponse.audits;
				const state: AuditLogsStateModel = context.getState();
				state.auditLogs = this.getUniqueAndSortedAuditLogs(state.auditLogs, apiAuditLogs);
				context.patchState(state);
				this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return error;
				})
			)
		);
	}

	@Action(GetAuditLogSearchFilters)
	getAuditLogSearchFilters(context: StateContext<AuditLogsStateModel>): Observable<GetAuditLogSearchFiltersResponse> {
		const storeComboPollRequestKey: PollRequestKey = PollRequestKey.GetStoreAuditLogsAndSearchFilters;
		const apiComboPollRequestKey: PollRequestKey = PollRequestKey.GetApiAuditLogsAndSearchFilters;
		this.cancelPollRequestService.createPollSubject(storeComboPollRequestKey);
		this.cancelPollRequestService.createPollSubject(apiComboPollRequestKey);
		return this.auditService.getAuditLogSearchFilters().pipe(
			takeUntil(
				this.cancelPollRequestService.pollSubjectsToCancel[storeComboPollRequestKey] ||
					this.cancelPollRequestService.pollSubjectsToCancel[apiComboPollRequestKey]
			),
			tap((getAuditLogSearchFiltersResponse: GetAuditLogSearchFiltersResponse) => {
				const state: AuditLogsStateModel = context.getState();
				state.auditActors = getAuditLogSearchFiltersResponse.auditActors;
				state.auditProductCategories = getAuditLogSearchFiltersResponse.auditProductCategories;
				context.patchState(state);
				this.cancelPollRequestService.cancelPollSubject(storeComboPollRequestKey);
				this.cancelPollRequestService.cancelPollSubject(apiComboPollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(storeComboPollRequestKey);
					this.cancelPollRequestService.cancelPollSubject(apiComboPollRequestKey);
					return error;
				})
			)
		);
	}

	@Action(ResetCachedAuditLogs)
	resetCachedAuditLogs(context: StateContext<AuditLogsStateModel>): void {
		const state: AuditLogsStateModel = context.getState();
		if (state.auditLogs.length > this.initGetAuditLogsSize) {
			const slicedAuditLogs: AuditLog[] = state.auditLogs.slice(0, this.initGetAuditLogsSize);
			state.auditLogs = slicedAuditLogs;
			context.patchState(state);
		}
	}

	@Action(ResetAuditLogsState)
	resetAuditLogsState(context: StateContext<AuditLogsStateModel>): void {
		context.dispatch(new ClearAuditLogsState());
		this.initDefaultAuditLogsState(context);
	}

	@Action(ClearAuditLogsState)
	clearAuditLogsState(context: StateContext<AuditLogsStateModel>): void {
		const state: AuditLogsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private initDefaultAuditLogsState(context: StateContext<AuditLogsStateModel>): void {
		const state: AuditLogsStateModel = context.getState();
		state.auditLogs = [];
		state.auditActors = [];
		state.auditProductCategories = [];
		context.patchState(state);
		const body: GetAuditLogsRequestBody = {
			from: 0,
			size: this.initGetAuditLogsSize,
		};
		context.dispatch(new GetPaginatedAuditLogs(body));
		context.dispatch(new GetAuditLogSearchFilters());
	}

	private auditLogsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedAuditLogsState: AuditLogsStateModel | undefined = deserializedState.auditLogs;
		if (
			deserializedAuditLogsState &&
			deserializedAuditLogsState.auditLogs &&
			deserializedAuditLogsState.auditActors &&
			deserializedAuditLogsState.auditProductCategories
		) {
			return true;
		} else {
			return false;
		}
	}

	private getUniqueAndSortedAuditLogs(stateAuditLogs: AuditLog[], apiAuditLogs: AuditLog[]): AuditLog[] {
		const combinedAuditLogs: AuditLog[] = [...stateAuditLogs, ...apiAuditLogs];
		const uniqueAuditLogMap: Map<string, boolean> = new Map();
		stateAuditLogs = combinedAuditLogs.filter(auditLog => {
			const previousAuditLog: boolean = uniqueAuditLogMap.get(auditLog.auditId);
			return previousAuditLog ? false : uniqueAuditLogMap.set(auditLog.auditId, true);
		});
		stateAuditLogs.sort((a, b) => {
			if (new Date(a.occurredAt) > new Date(b.occurredAt)) {
				return -1;
			} else if (new Date(a.occurredAt) < new Date(b.occurredAt)) {
				return 1;
			}
			return 0;
		});
		return stateAuditLogs;
	}
}
