import { StorageEngine } from '@ngxs/storage-plugin';
import * as LocalForage from 'localforage';

export class IndexedDBStorageEngine implements StorageEngine {
	private storageLength: number = 0;

	get length(): number {
		return this.storageLength;
	}

	getItem(key: string): any {
		LocalForage.getItem(key)
			.then((item: any) => item)
			.catch((error: Error) => {
				throw error;
			});
	}

	setItem(key: string, val: string): void {
		LocalForage.setItem(key, val)
			.then((item: any) => {
				this.setStorageLength();
			})
			.catch((error: Error) => {
				throw error;
			});
	}

	removeItem(key: string): void {
		LocalForage.removeItem(key)
			.then((item: any) => {
				this.setStorageLength();
			})
			.catch((error: Error) => {
				throw error;
			});
	}

	clear(): void {
		LocalForage.clear()
			.then(() => {
				this.setStorageLength();
			})
			.catch((error: Error) => {
				throw error;
			});
	}

	key(val: number): string {
		return val.toString();
	}

	private async setStorageLength() {
		this.storageLength = await LocalForage.length();
	}
}
