import {
	CreateConnectionBody,
	CreateConnectionRequestBody,
	CreateOAuthConnectionBody,
	PatchConnectionRequestBody,
	ReconnectConnectionBody,
} from '../../models/connections.model';

export class InitConnectionsState {
	static readonly type: string = '[Connections] InitConnectionsState';
}

export class ClearConnectionsState {
	static readonly type: string = '[Connections] ClearConnectionsState';
}

export class ResetConnectionsState {
	static readonly type: string = '[Connections] ResetConnectionsState';
}

export class GetConnections {
	static readonly type: string = '[Connections] GetConnections';
}

export class GetConnectionRequests {
	static readonly type: string = '[Connections] GetConnectionRequests';
}

export class RevokeConnection {
	static readonly type: string = '[Connections] RevokeConnection';
	constructor(public connectionId: string) {}
}

export class RefreshConnection {
	static readonly type: string = '[Connections] RefreshConnection';
	constructor(public connectionId: string) {}
}

export class ReconnectConnection {
	static readonly type: string = '[Connections] ReconnectConnection';
	constructor(public body: ReconnectConnectionBody) {}
}

export class CreateConnection {
	static readonly type: string = '[Connections] CreateConnection';
	constructor(public body: CreateConnectionBody) {}
}

export class CreateOauthConnection {
	static readonly type: string = '[Connections] CreateOauthConnection';
	constructor(public body: CreateOAuthConnectionBody) {}
}

export class HandleOauthCallback {
	static readonly type: string = '[Connections] HandleOauthCallback';
	constructor(
		public connectionId: string,
		public queryString: string
	) {}
}

export class PostConnectionRequest {
	static readonly type: string = '[Connections] PostConnectionRequest';
	constructor(public body: CreateConnectionRequestBody) {}
}

export class PatchConnectionRequest {
	static readonly type: string = '[Connections] PatchConnectionRequest';
	constructor(
		public body: PatchConnectionRequestBody,
		public connectionRequestId: number
	) {}
}
