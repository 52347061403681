import { AccountFilters, AccountFilterType } from 'src/app/features/reports/models/account-filters.model';
import { Server, Request } from 'miragejs';
import { TransactionFilters, TransactionFilterType } from '../../models/transaction-filters.model';
import { accountFilters, chosenCurrencies, transactionFilters } from '../helpers.mock';
import { AppSchema } from '../types';

export function addFiltersWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post('/data/accounts/filters', (schema: AppSchema, request: Request) => {
		const body: {
			filterType: AccountFilterType;
			fromSize?: number;
			isPagedCall?: boolean;
			filterSize?: number;
		} = JSON.parse(request.requestBody);
		const filters = accountFilters(schema);
		if (body['filter'] === AccountFilterType.accountTags) {
			return {
				accountTags: filters.accountTags,
			};
		}
	});

	server.post('/data/transactions/filters', (schema: AppSchema, request: Request) => {
		const body: {
			filterType: TransactionFilterType;
			fromSize?: number;
			isPagedCall?: boolean;
			filterSize?: number;
		} = JSON.parse(request.requestBody);
		const filters = transactionFilters(schema);
		if (body['filter'] === TransactionFilterType.institutions) {
			return {
				institutions: filters.institutions,
			};
		}
		if (body['filter'] === TransactionFilterType.currencies) {
			return {
				currencies: chosenCurrencies,
			};
		}
		if (body['filter'] === TransactionFilterType.accounts) {
			return {
				accounts: filters.accounts,
			};
		}
		if (body['filter'] === TransactionFilterType.tags) {
			return {
				tags: filters.tags,
			};
		}
	});
}
