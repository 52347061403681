export class Logout {
	static readonly type: string = '[Auth] Logout';
	constructor(public customerDeleted: boolean = false) {}
}

export class SetAccessToken {
	static readonly type: string = '[Auth] SetAccessToken';
	constructor(public accessToken: string) {}
}

export class SetRefreshToken {
	static readonly type: string = '[Auth] SetRefreshToken';
	constructor(public refreshToken: string) {}
}

export class SetAuthenticated {
	static readonly type: string = '[Auth] SetAuthenticated';
	constructor(public authenticated: boolean) {}
}

export class SetAuthErrorMessage {
	static readonly type: string = '[Auth] SetAuthErrorMessage';
	constructor(public authErrorMessage: string) {}
}
