import { SeriesZonesOptionsObject } from 'highcharts';
import { GenericOption } from './option.model';

export enum ChartType {
	column = 'column',
	pie = 'pie',
	line = 'line',
	scatter = 'scatter',
	stackedbar = 'stackedbar',
}

export const fixChartType: (oldChartType: string) => ChartType = (oldChartType: string) => {
	switch (oldChartType) {
		case 'show_chart':
			return ChartType.line;
		case 'scatter_plot':
			return ChartType.scatter;
		case 'pie_chart':
			return ChartType.pie;
		case 'stacked_bar_chart':
			return ChartType.stackedbar;
		case 'bar_chart':
			return ChartType.column;
		default:
			return ChartType[oldChartType];
	}
};

export const chartTypeOptions: GenericOption[] = [
	{
		key: ChartType.column,
		id: ChartType.column,
		displayValue: 'Bar Chart',
	},
	{
		key: ChartType.stackedbar,
		id: ChartType.stackedbar,
		displayValue: 'Stacked Bar Chart',
	},
	{
		key: ChartType.line,
		id: ChartType.line,
		displayValue: 'Line Chart',
	},
	{
		key: ChartType.scatter,
		id: ChartType.scatter,
		displayValue: 'Scatter Plot',
	},
	{
		key: ChartType.pie,
		id: ChartType.pie,
		displayValue: 'Pie Chart',
	},
];

export const getHighchartsType = (chartType: ChartType): 'area' | 'scatter' | 'pie' | 'column' => {
	switch (chartType) {
		case ChartType.line:
			return 'area';
		case ChartType.scatter:
			return 'scatter';
		case ChartType.pie:
			return 'pie';
		case ChartType.stackedbar:
			return 'column';
		case ChartType.column:
			return 'column';
	}
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TrovataHighchartsColors: string[] = [
	'#0082FF',
	'#00A8E9',
	'#00C4CB',
	'#00D6AA',
	'#0ADC9A',
	'#72E582',
	'#AEE96A',
	'#E4E958',
	'#FDE854',
	'#F4BC4D',
	'#EF8F4B',
	'#EC6563',
	'#FA4173',
	'#D14AC3',
	'#A956F6',
	'#6B73F6',
	'#8199B8',
	'#3F5973',
];

export const TrovataHighChartsBarColorScheme: SeriesZonesOptionsObject[] = [
	{
		value: 0,
		color: '#FF4979',
	},
	{
		color: '#2290FF',
	},
];

export interface GroupOrders {
	order: string[];
}
