import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/services/http.service';
import { Observable } from 'rxjs';
import {
	CreateCustomerUserBody,
	CurrentUser,
	Customer,
	CustomerUser,
	EditCurrentUserBody,
	EditCurrentUserResponse,
	EditCustomerBody,
	EditCustomerUserBody,
	GetCustomerUsersResponse,
} from '../models/identity.model';

@Injectable({
	providedIn: 'root',
})
export class IdentityService {
	constructor(private httpService: HttpService) {}

	getCustomer(): Observable<Customer> {
		return this.httpService.get(`${environment.trovataAPI('workspace')}/identity/customer`);
	}

	getCurrentUser(): Observable<CurrentUser> {
		return this.httpService.get(`${environment.trovataAPI('workspace')}/identity/user`);
	}

	getCustomerUsers(): Observable<GetCustomerUsersResponse> {
		return this.httpService.get(`${environment.trovataAPI('workspace')}/identity/customer/users`);
	}

	editCustomer(body: EditCustomerBody): Observable<Customer> {
		return this.httpService.patch(`${environment.trovataAPI('workspace') + '/identity/customer'}`, body);
	}

	editCurrentUser(body: EditCurrentUserBody): Observable<EditCurrentUserResponse> {
		return this.httpService.patch(`${environment.trovataAPI('workspace')}/identity/user`, body);
	}

	editUserForCustomer(userId: string, body: EditCustomerUserBody): Observable<CustomerUser> {
		return this.httpService.patch(`${environment.trovataAPI('workspace')}/v2/identity/customer/users/${userId}`, body);
	}

	addNewUserForCustomer(body: CreateCustomerUserBody): Observable<any> {
		// TODO type when api works
		return this.httpService.post(`${environment.trovataAPI('workspace')}/v2/identity/customer/users`, body);
	}

	deleteUserFromCustomer(userId: string): Observable<null> {
		return this.httpService.delete(`${environment.trovataAPI('workspace')}/identity/customer/users/${userId}`);
	}

	deleteCustomer(): Observable<null> {
		return this.httpService.delete(`${environment.trovataAPI('workspace')}/identity/customer`);
	}
}
