import { Component } from '@angular/core';
import * as wjcCore from '@grapecity/wijmo';

@Component({
	selector: 'trovata-angular-app',
	template: '<router-outlet></router-outlet>',
	styles: [],
})
export class AppComponent {
	constructor() {
		wjcCore.setLicenseKey(
			// eslint-disable-next-line max-len
			'dev.trovata.io|app.trovata.io|beta.trovata.io|d1.trovata.io|d2.trovata.io|d3.trovata.io,151736762879769#B0ISwwSfdtlOicGbmJCLiEjdzIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBVkZBdmewZXSUJDOwNjRG5kaxMTVFZ6RDd5VkdjbRFnTw2CTh5kSoVEcINVeptmdFdzQzYURvY4Tr56Uz8UQ63kRRtUZIxGNhpET4olNY9mdoZ6T7FGT9V6bxIUersyKPp7KFdnczVFOZlWWyc5KFRGTS5kVptWazs4K54mRrllQYlFZwhXMtZnS5EDW8QlNz3EcFV6dLR7UVhke78GOKhjcvU4bjpHRwc5ZCNmZhF4RENjVXl7U7N7cj5UY7hWYw34L0JUZjJFUu9GTrg7QPJ7Zr96doJkQ8sWVCRkNrtCOCZWcDJTVYxWZvRncOZ6cCFnMyVzZYp5ZQ9GN9dTaIVVQvEDcO3yL5kUQJlVeUBnSwJXS4VDe4lDaHFVbRFkYGVXZZdXNZV4c7pVeTh4N8VnRi3GVxt4dmZXO4YXdXVkUtR6bCFkcIdHW99GNrIGdJZWc5dkViojITJCLiM4N5EkQ5EENiojIIJCLxUDM6kzNwEjN0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsISN4ITM5ADIyATNwMjMwIjI0ICdyNkIsIybp9SY4FmdvJHduMDZs2WauEGdhZ7byRnLyQGLvlmLhRXY63mc49SMkxybp9SY4FmdvJHduEGdlJGLvlmLhRXY63mc49CcwFGLvlmLhRXY63mc49idlRmI0IyctRkIsIyYulEIhRXY63mcUJiOiEmTDJCLikjN7kzN8IjN7YzM7ETNxIIOIQ'
		);
	}
}
