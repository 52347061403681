import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { catchError, Observable, Subscription, takeUntil, tap, throwError } from 'rxjs';
import {
	AccountOwnerControlledGroupName,
	GetResourceTypesResponse,
	GetUserGroupsResponse,
	UserGroup,
	UserGroupPostBody,
	UserGroupResourceType,
} from '../../models/user-group.model';
import { ResourceTypesService } from '../../services/resource-types.service';
import { UserGroupService } from '../../services/user-group.service';
import { GetCustomerUsers } from '../actions/customer-users.actions';
import {
	CancelUserGroupChangeRequest,
	ClearEntitlementsState,
	CreateUserGroup,
	DeleteUserGroup,
	GetUserGroupById,
	GetUserGroups,
	GetUserGroupsResourceTypes,
	InitEntitlementsState,
	RemoveRejectedCreateUserGroup,
	ResetEntitlementsState,
	ReviewUserGroupChangeRequest,
	UpdateUserGroup,
} from '../actions/entitlements.actions';
import { ChangeRequestReviewAction, ChangeRequestStatus, ChangeRequestTypeV2 } from 'src/app/shared/models/admin-approval.model';
import { ReadyForReviewSnack, ReadyForReviewSnackParams, sortSnacksByDate } from 'src/app/shared/models/ready-for-review-snack.model';
import { DateTime } from 'luxon';
import { TrovataResourceType, TrovataResourceViewText } from 'src/app/shared/models/trovata.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PollRequestKey } from '@trovata/app/shared/models/cancel-poll-request.model';
import { CancelPollRequestService } from '@trovata/app/shared/services/cancel-poll-request.service';

export class EntitlementsStateModel {
	userGroups: UserGroup[];
	userGroupSnacks: ReadyForReviewSnack[];
	resourceTypes: UserGroupResourceType[];
}

@State<EntitlementsStateModel>({
	name: 'entitlements',
	defaults: {
		userGroups: null,
		userGroupSnacks: null,
		resourceTypes: null,
	},
})
@Injectable()
export class EntitlementsState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private userGroupsService: UserGroupService,
		private resourceTypesService: ResourceTypesService,
		private sanitizer: DomSanitizer,
		private cancelPollRequestService: CancelPollRequestService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector()
	static userGroupSnacks(state: EntitlementsStateModel): ReadyForReviewSnack[] {
		return state.userGroupSnacks;
	}

	@Selector()
	static resourceTypes(state: EntitlementsStateModel): UserGroupResourceType[] {
		return state.resourceTypes;
	}

	@Selector()
	static userGroups(state: EntitlementsStateModel): UserGroup[] {
		return state.userGroups;
	}

	@Action(InitEntitlementsState)
	async initEntitlementsState(context: StateContext<EntitlementsStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const entitlementsStateIsCached: boolean = this.entitlementsStateIsCached(deserializedState);
			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (entitlementsStateIsCached && appReady) {
						const state: EntitlementsStateModel = deserializedState.entitlements;
						this.postProcessUserGroupsData(context, state);
					} else if (!entitlementsStateIsCached && appReady) {
						context.dispatch(new GetUserGroups());
						context.dispatch(new GetUserGroupsResourceTypes());
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetUserGroupsResourceTypes)
	getUserGroupResourceTypes(context: StateContext<EntitlementsStateModel>): Observable<GetResourceTypesResponse> {
		return this.resourceTypesService.getResourceTypes().pipe(
			tap((getResourceTypesResponse: GetResourceTypesResponse) => {
				const state: EntitlementsStateModel = context.getState();
				state.resourceTypes = getResourceTypesResponse.resourceTypes;
				context.patchState(state);
			})
		);
	}

	@Action(GetUserGroups)
	getUserGroups(context: StateContext<EntitlementsStateModel>): Observable<GetUserGroupsResponse> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetUserGroups;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		return this.userGroupsService.getUserGroups().pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getUserGroupsResponse: GetUserGroupsResponse) => {
				const userGroups: UserGroup[] = getUserGroupsResponse.userGroups;
				this.postProcessUserGroupsData(context, null, userGroups);
				this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return error;
				})
			)
		);
	}

	@Action(GetUserGroupById)
	getUserGroupById(context: StateContext<EntitlementsStateModel>, action: GetUserGroupById): Observable<GetUserGroupsResponse> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetUserGroupById;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		return this.userGroupsService.getUserGroupById(action.userGroupId).pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getUserGroupsResponse: GetUserGroupsResponse) => {
				const state: EntitlementsStateModel = context.getState();
				const userGroup: UserGroup = getUserGroupsResponse.userGroups[0];
				const filteredUserGroups: UserGroup[] = state.userGroups.filter((filterUserGroup: UserGroup) => filterUserGroup.userGroupId !== userGroup.userGroupId);
				filteredUserGroups.push(userGroup);
				this.postProcessUserGroupsData(context, null, filteredUserGroups);
				this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return error;
				})
			)
		);
	}

	@Action(CreateUserGroup)
	createUserGroup(context: StateContext<EntitlementsStateModel>, action: CreateUserGroup): Observable<UserGroup> {
		return this.userGroupsService.createUserGroup(action.postBody).pipe(
			tap((userGroup: UserGroup) => {
				const state: EntitlementsStateModel = context.getState();
				const userGroups: UserGroup[] = state.userGroups;
				userGroups.push(userGroup);
				this.postProcessUserGroupsData(context, null, userGroups);
				if (!userGroup.changeRequest) {
					// Only get/update customer users if userGroup does not involve a review process.
					context.dispatch(new GetCustomerUsers());
				}
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(UpdateUserGroup)
	updateUserGroup(context: StateContext<EntitlementsStateModel>, action: UpdateUserGroup): Observable<UserGroup> {
		action.body = this.checkToRemoveData(action.userGroupId, action.body);
		return this.userGroupsService.updateUserGroup(action.userGroupId, action.body).pipe(
			tap((userGroup: UserGroup) => {
				const state: EntitlementsStateModel = context.getState();
				const filteredUserGroups: UserGroup[] = state.userGroups.filter((filterUserGroup: UserGroup) => filterUserGroup.userGroupId !== userGroup.userGroupId);
				filteredUserGroups.push(userGroup);
				this.postProcessUserGroupsData(context, null, filteredUserGroups);
				if (!userGroup.changeRequest) {
					// Only get/update customer users if userGroup does not involve a review process.
					context.dispatch(new GetCustomerUsers());
				}
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(DeleteUserGroup)
	deleteUserGroup(context: StateContext<EntitlementsStateModel>, action: DeleteUserGroup): Observable<UserGroup | string> {
		return this.userGroupsService.deleteUserGroup(action.userGroupId).pipe(
			tap((userGroup: UserGroup | string) => {
				const state: EntitlementsStateModel = context.getState();
				const filteredUserGroups: UserGroup[] = state.userGroups.filter((filterUserGroup: UserGroup) => filterUserGroup.userGroupId !== action.userGroupId);
				if (typeof userGroup !== 'string') {
					// This should only happen if the userGroup has a change request on it. Will need to confirm it works that way when apis are ready.
					filteredUserGroups.push(userGroup);
				}
				this.postProcessUserGroupsData(context, null, filteredUserGroups);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(ReviewUserGroupChangeRequest)
	reviewUserGroupChangeRequest(context: StateContext<EntitlementsStateModel>, action: ReviewUserGroupChangeRequest): Observable<UserGroup> {
		return this.userGroupsService.reviewChangeRequest(action.changeRequest.changeRequestId, action.body).pipe(
			tap((userGroup: UserGroup) => {
				const state: EntitlementsStateModel = context.getState();
				/**
				 * If request is create or update and is approved then remove userGroup with old change request from state and add in the newly created or updated userGroup.
				 * If request is update or delete and is rejected then remove userGroup with old change request from state and add in the unchanged userGroup.
				 */
				if (
					(action.changeRequest.requestType === ChangeRequestTypeV2.create && action.body.action === ChangeRequestReviewAction.approved) ||
					(action.changeRequest.requestType === ChangeRequestTypeV2.update && action.body.action === ChangeRequestReviewAction.approved) ||
					(action.changeRequest.requestType === ChangeRequestTypeV2.update && action.body.action === ChangeRequestReviewAction.rejected) ||
					(action.changeRequest.requestType === ChangeRequestTypeV2.delete && action.body.action === ChangeRequestReviewAction.rejected) ||
					(action.changeRequest.requestType === ChangeRequestTypeV2.create && action.body.action === ChangeRequestReviewAction.rejected)
				) {
					const filteredUserGroups: UserGroup[] = state.userGroups.filter(
						(filterUserGroup: UserGroup) => filterUserGroup.userGroupId !== userGroup.userGroupId
					);
					if (
						(action.changeRequest.requestType === ChangeRequestTypeV2.create &&
							action.body.action === ChangeRequestReviewAction.approved &&
							userGroup.changeRequest) ||
						(action.changeRequest.requestType === ChangeRequestTypeV2.update &&
							action.body.action === ChangeRequestReviewAction.approved &&
							userGroup.changeRequest)
					) {
						delete userGroup.changeRequest;
					}
					filteredUserGroups.push(userGroup);
					this.postProcessUserGroupsData(context, null, filteredUserGroups);
					/**
					 * Only get/update customer users if request is create or update and is approved.
					 */
					if (
						(action.changeRequest.requestType === ChangeRequestTypeV2.create && action.body.action === ChangeRequestReviewAction.approved) ||
						(action.changeRequest.requestType === ChangeRequestTypeV2.update && action.body.action === ChangeRequestReviewAction.approved)
					) {
						context.dispatch(new GetCustomerUsers());
					}
				} else if (
					/**
					 * If request is delete and is approved remove userGroup from state.
					 */
					action.changeRequest.requestType === ChangeRequestTypeV2.delete &&
					action.body.action === ChangeRequestReviewAction.approved
				) {
					const filteredUserGroups: UserGroup[] = state.userGroups.filter((filterUserGroup: UserGroup) => filterUserGroup.userGroupId !== action.userGroupId);
					this.postProcessUserGroupsData(context, null, filteredUserGroups);
				}
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(CancelUserGroupChangeRequest)
	cancelUserGroupChangeRequest(context: StateContext<EntitlementsStateModel>, action: CancelUserGroupChangeRequest): Observable<UserGroup> {
		return this.userGroupsService.cancelChangeRequest(action.changeRequestId).pipe(
			tap((userGroup: UserGroup) => {
				const state: EntitlementsStateModel = context.getState();
				const filteredUserGroups: UserGroup[] = state.userGroups.filter((filterUserGroup: UserGroup) => filterUserGroup.userGroupId !== action.userGroupId);
				if (userGroup.changeRequest.requestType !== ChangeRequestTypeV2.create) {
					delete userGroup.changeRequest;
					filteredUserGroups.push(userGroup);
				}
				this.postProcessUserGroupsData(context, null, filteredUserGroups);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(RemoveRejectedCreateUserGroup)
	removeRejectedCreateUserGroup(context: StateContext<EntitlementsStateModel>, action: RemoveRejectedCreateUserGroup): void {
		const state: EntitlementsStateModel = context.getState();
		const filteredUserGroups: UserGroup[] = state.userGroups.filter((filterUserGroup: UserGroup) => filterUserGroup.userGroupId !== action.userGroupId);
		this.postProcessUserGroupsData(context, null, filteredUserGroups);
	}

	@Action(ClearEntitlementsState)
	clearEntitlementsState(context: StateContext<EntitlementsStateModel>): void {
		this.appReadySub.unsubscribe();
		const state: EntitlementsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetEntitlementsState)
	resetIdentityState(context: StateContext<EntitlementsStateModel>): void {
		context.dispatch(new GetUserGroups());
	}

	private entitlementsStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState && deserializedState.entitlements && deserializedState.entitlements.userGroups && deserializedState.entitlements.resourceTypes) {
			return true;
		} else {
			return false;
		}
	}

	private postProcessUserGroupsData(context: StateContext<EntitlementsStateModel>, state?: EntitlementsStateModel, userGroups?: UserGroup[]): void {
		const stateToProcess: EntitlementsStateModel = state ? state : context.getState();
		const userGroupsToProcess: UserGroup[] = userGroups ? userGroups : stateToProcess.userGroups;
		const userGroupSnacks: ReadyForReviewSnack[] = this.getUserGroupSnacks(userGroupsToProcess);
		const sortedUserGroups: UserGroup[] = this.sortUserGroupsAlphabetically(userGroupsToProcess);
		stateToProcess.userGroupSnacks = userGroupSnacks;
		stateToProcess.userGroups = sortedUserGroups;
		context.patchState(state);
	}

	private getUserGroupSnacks(userGroups: UserGroup[]): ReadyForReviewSnack[] {
		let userGroupSnacks: ReadyForReviewSnack[] = [];
		userGroups.forEach((userGroup: UserGroup) => {
			if (userGroup.changeRequest) {
				let actionText: string;
				if (userGroup.changeRequest.requestType === ChangeRequestTypeV2.update) {
					if (userGroup.changeRequest.status === ChangeRequestStatus.rejected) {
						actionText = 'rejected changes to ';
					} else {
						actionText = 'requested changes to ';
					}
				} else {
					if (userGroup.changeRequest.status === ChangeRequestStatus.rejected) {
						actionText = `rejected to ${userGroup.changeRequest.requestType.toLowerCase()} `;
					} else {
						actionText = `requested to ${userGroup.changeRequest.requestType.toLowerCase()} `;
					}
				}
				const requestor: string = `${userGroup.changeRequest.creator.firstName} ${userGroup.changeRequest.creator.lastName} `;
				const requestedResourceName: string =
					userGroup.changeRequest.requestType === ChangeRequestTypeV2.create ? userGroup.changeRequest.revision?.name : userGroup.name;
				const htmlString: string = `
        <span class="font-700-14-20">${requestor}</span>
        <span class="font-400-14-20">${actionText}</span>
        <span class="font-700-14-20">${requestedResourceName}</span>
        `;
				const messageSafeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlString);
				const readyForReviewSnackParams: ReadyForReviewSnackParams = {
					date: DateTime.fromISO(new Date(userGroup.changeRequest.createdAt).toISOString()).toLocaleString(DateTime.DATE_MED),
					messageHtml: messageSafeHtml,
					resource: userGroup,
					resourceId: userGroup.userGroupId.toString(),
					resourceType: TrovataResourceType.userGroup,
					resourceViewText: TrovataResourceViewText.UserGroup,
					sortByDate: userGroup.changeRequest.createdAt,
				};
				const snack: ReadyForReviewSnack = new ReadyForReviewSnack(readyForReviewSnackParams);
				userGroupSnacks.push(snack);
			}
		});
		userGroupSnacks = sortSnacksByDate(userGroupSnacks);
		return userGroupSnacks;
	}

	private sortUserGroupsAlphabetically(userGroups: UserGroup[]): UserGroup[] {
		const sortedUserGroups: UserGroup[] = userGroups.sort((userGroupA: UserGroup, userGroupB: UserGroup) => {
			if (userGroupA.name && userGroupB.name) {
				if (userGroupA.name.toLowerCase() < userGroupB.name.toLowerCase()) {
					return -1;
				} else if (userGroupA.name.toLowerCase() > userGroupB.name.toLowerCase()) {
					return 1;
				}
				return 0;
			}
			if (!userGroupA.name) {
				return -1;
			} else if (!userGroupB.name) {
				return 1;
			}
		});
		return sortedUserGroups;
	}

	private checkToRemoveData(userGroupId: number, userGroupPostBody: UserGroupPostBody): UserGroupPostBody {
		const userGroupToUpdate: UserGroup = this.store
			.selectSnapshot((state: TrovataAppState) => state.entitlements.userGroups)
			.find((findUserGroup: UserGroup) => findUserGroup.userGroupId === userGroupId);
		const peerReviewersGroup: UserGroup = this.store.selectSnapshot((state: TrovataAppState) =>
			state.entitlements.userGroups.find(
				(userGroup: UserGroup) => userGroup.isTrovataUserGroup && userGroup.name === AccountOwnerControlledGroupName.peerReviewers
			)
		);
		if (userGroupToUpdate.isTrovataUserGroup) {
			if (userGroupPostBody.description) {
				delete userGroupPostBody.description;
			}
			if (userGroupPostBody.name) {
				delete userGroupPostBody.name;
			}
		}
		if (!userGroupToUpdate.trovataMetadata.arePermissionsEditable && userGroupPostBody.permissionIds) {
			delete userGroupPostBody.permissionIds;
		}
		if (!userGroupToUpdate.trovataMetadata.arePermissionsEditable && userGroupPostBody.featureIds) {
			delete userGroupPostBody.featureIds;
		}
		if (!userGroupToUpdate.trovataMetadata.areResourcesEditable && userGroupPostBody.resources) {
			delete userGroupPostBody.resources;
		}
		if (!userGroupToUpdate.trovataMetadata.areUsersEditable && userGroupPostBody.userIds) {
			delete userGroupPostBody.userIds;
		}
		if (peerReviewersGroup && peerReviewersGroup.userGroupId === userGroupId) {
			// Special case for updating peerReviewersGroup userIds array
			if (userGroupPostBody.permissionIds) {
				delete userGroupPostBody.permissionIds;
			}
			if (userGroupPostBody.featureIds) {
				delete userGroupPostBody.featureIds;
			}
			if (userGroupPostBody.resources) {
				delete userGroupPostBody.resources;
			}
		}
		return userGroupPostBody;
	}
}
