import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConsentPartner, ConsentType } from '../models/consent-management';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ConsentManagementService {
	constructor(private httpClient: HttpClient) {}

	getPartnerList(): Observable<ConsentPartner[]> {
		return this.httpClient.get<ConsentPartner[]>(environment.trovataAPI('workspace') + '/data/developer/customer/partners');
	}

	getPartnerById(partnerId: string): Observable<ConsentPartner> {
		return this.httpClient.get<ConsentPartner>(environment.trovataAPI('workspace') + '/data/developer/partners/' + partnerId);
	}

	initiatePartnerRelationship(partnerId: string): Observable<void> {
		return this.httpClient.post<void>(environment.trovataAPI('workspace') + '/data/developer/customer/partners', {
			partnerId: partnerId,
		});
	}

	providePartnerConsent(partnerId: string, consentResponse: ConsentType): Observable<void> {
		return this.httpClient.post<void>(environment.trovataAPI('workspace') + '/data/developer/customer/partners/consent', {
			partnerId: partnerId,
			consentType: consentResponse,
		});
	}
}
