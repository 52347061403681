export enum TrovataResourceType {
	account = 'account',
	institution = 'institution',
	group = 'group',
	cashPosition = 'cashPosition',
	transaction = 'transaction',
	tag = 'tag',
	glTag = 'glTag',
	report = 'report',
	reconReport = 'reconReport',
	forecast = 'forecast',
	forecastStream = 'forecastStream',
	workbook = 'workbook',
	payment = 'payment',
	bulkPayment = 'bulkPayment',
	workflow = 'workflow',
	template = 'template',
	paymentsAccount = 'paymentsAccount', // TODO: In the future possibly remove and use account = 'account' instead
	user = 'user',
	userGroup = 'userGroup',
}

export enum TrovataResourceViewText {
	Account = 'Account',
	Institution = 'Institution',
	Group = 'Group',
	CashPosition = 'Cash Position',
	Transaction = 'Transaction',
	Tag = 'Tag',
	GlTag = 'Gl Tag',
	Report = 'Report',
	ReconReport = 'Recon Report',
	Forecast = 'Forecast',
	ForecastStream = 'Forecast Stream',
	Workbook = 'Workbook',
	Payment = 'Payment',
	BulkPayment = 'Bulk Payment',
	Workflow = 'Workflow',
	Template = 'Template',
	PaymentsAccount = 'Payments Account', // TODO: In the future possibly remove and use Accounts = 'Accounts' instead
	User = 'User',
	UserGroup = 'User Group',
}

export enum TrovataResourcesViewText {
	Accounts = 'Accounts',
	Institutions = 'Institutions',
	Groups = 'Groups',
	CashPositions = 'Cash Positions',
	Transactions = 'Transactions',
	Tags = 'Tags',
	GlTags = 'Gl Tags',
	Reports = 'Reports',
	ReconReports = 'Recon Reports',
	Forecasts = 'Forecasts',
	ForecastStreams = 'Forecast Streams',
	Workbooks = 'Workbooks',
	Payments = 'Payments',
	BulkPayments = 'Bulk Payments',
	Workflows = 'Workflows',
	Templates = 'Templates',
	PaymentsAccounts = 'Payments Accounts', // TODO: In the future possibly remove and use Accounts = 'Accounts' instead
	Users = 'Users',
	UserGroups = 'User Groups',
}
