import { SnackPreference } from 'src/app/shared/models/preferences.model';
import { SnackPreference as DashboardSnackPref } from '../../balances/models/snacks.model';
import { timeFrameToCadence } from '../../reports/models/analysis.model';
import { Cadence } from '../../reports/models/cadence.model';

export function convertCarouselPrefToHashObject(snackPref: SnackPreference): TagHashObject {
	return {
		tagId: snackPref.id,
		cadence: snackPref.preferences.cadence,
		periods: snackPref.preferences.periods,
	};
}

export function convertDashboardPrefToHashObject(dashboardSnack: DashboardSnackPref): TagHashObject {
	return {
		tagId: dashboardSnack.id,
		cadence: timeFrameToCadence(dashboardSnack.timeFrame),
		periods: dashboardSnack['periods'],
	};
}

export interface TagHashObject {
	tagId: string;
	cadence: Cadence;
	periods: number;
}
