import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PostTagResponse, EditTagBody, Tag, TagPayload, TagType, PostGlAutoPriorityResponse, GetTagsResponse } from '../models/tag.model';
import { SearchParameter, ParameterType } from 'src/app/shared/models/search-parameter.model';
import { TransactionFilterType } from '../../../shared/models/transaction-filters.model';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { UpdateFilter } from 'src/app/shared/store/actions/filter-object.actions';
import { CustomEncoder } from 'src/app/shared/utils/custom-encoder';
import { GetGlTags, GetTags } from '../store/actions/tags.action';
import { TQLPropertyKey } from '@trovata/app/shared/models/tql.model';
import { GetValues } from '@trovata/app/shared/store/actions/tql-fields.actions';

@Injectable({
	providedIn: 'root',
})
export class TagService {
	constructor(
		private httpClient: HttpClient,
		private store: Store
	) {}

	public createTag(tagPayload: TagPayload): Observable<HttpResponse<PostTagResponse>> {
		let parameters = new HttpParams({ encoder: new CustomEncoder() });

		if (tagPayload.q) {
			parameters = parameters.append('q', tagPayload.q.trim());
		}

		tagPayload.params.forEach(function (param) {
			const paramType = param.type === 'tag' ? 'tagId' : param.type;
			parameters = parameters.append(paramType.valueOf(), <string>param.value);
		});
		const tagTypeValue = tagPayload.tagType ? tagPayload.tagType : TagType.transaction;
		parameters = parameters.append(ParameterType.tagType, tagTypeValue);

		const url = environment.trovataAPI('workspace') + '/data/tags';
		return this.httpClient
			.post<PostTagResponse>(
				url,
				{
					tagTitle: tagPayload.tagTitle.trimStart().trimEnd(),
					tagDescription: tagPayload.tagDescription.trimStart().trimEnd(),
				},
				{
					params: parameters,
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(err)));
	}

	public deleteV2Tag(tag: Tag): Observable<HttpResponse<void>> {
		const url = environment.trovataAPI('workspace') + '/data/tags/' + tag.tagId;
		return this.httpClient
			.delete<void>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public editGLTag(tag: Tag, tagPayload: TagPayload): Observable<HttpResponse<void>> {
		const body: EditTagBody = {
			tagTitle: tagPayload.tagTitle.trimStart().trimEnd(),
			tagDescription: tagPayload.tagDescription.trimStart().trimEnd(),
			tagType: tagPayload.tagType,
			filters: {},
		};
		if (tagPayload.q) {
			body.filters.q = tagPayload.q;
		}
		if (tag.priority || tag.priority === 0) {
			body.priority = tag.priority;
		}
		tagPayload.params.forEach(function (param) {
			const paramType = param.type === 'tag' ? 'tagId' : param.type;
			if (
				param.type === ParameterType.accountId ||
				param.type === ParameterType.institutionId ||
				param.type === ParameterType.tagId ||
				param.type === ParameterType.excludeTags ||
				param.type === ParameterType.currency ||
				param.type === ParameterType.includeTransactions ||
				param.type === ParameterType.sort ||
				param.type === ParameterType.tag ||
				param.type === ParameterType.type
			) {
				body.filters[paramType.valueOf()] = body.filters[paramType.valueOf()]
					? [...body.filters[paramType.valueOf()], <string>param.value]
					: [<string>param.value];
			} else {
				body.filters[paramType.valueOf()] = <string>param.value;
			}
		});
		if (tagPayload.includeTransactions && tagPayload.includeTransactions.length > 0) {
			body.filters.includeTransactions = tagPayload.includeTransactions;
		}

		if (tagPayload.excludeTransactions && tagPayload.excludeTransactions.length > 0) {
			body.filters.excludeTransactions = tagPayload.excludeTransactions;
		}

		if (tagPayload.tagType) {
			body.tagType = tagPayload.tagType;
		}

		if (tag.priority || tag.priority === 0) {
			body.priority = tag.priority;
		}

		const url = environment.trovataAPI('workspace') + '/data/v2/tags/' + tag.tagId;
		return this.httpClient.put<void>(url, body, {
			observe: 'response',
		});
	}

	public deleteTQLTag(tag: Tag): Observable<HttpResponse<void>> {
		const url = environment.trovataAPI('workspace') + '/data/tql/tags/' + tag.tagId;
		return this.httpClient
			.delete<void>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	createTQLTag(tag: Tag): Observable<HttpResponse<{ tagId: string }>> {
		const url = environment.trovataAPI('workspace') + '/data/tql/tags';
		const testPostBody = {
			tagTitle: tag.tagTitle,
			tagDescription: tag.tagDescription,
			tagType: tag.tagType,
			includeTransactions: tag.tagMetadata.includeTransactions || [],
			excludeTransactions: tag.tagMetadata.excludeTransactions || [],
			tql: tag.tagMetadata.tql,
		};
		if (tag.priority) {
			testPostBody['priority'] = tag.priority;
		}
		if (tag.tagMetadata.glCode1) {
			testPostBody['glCode1'] = tag.tagMetadata.glCode1;
		}
		if (tag.tagMetadata.glCode2) {
			testPostBody['glCode2'] = tag.tagMetadata.glCode2;
		}
		return this.httpClient.post<{ tagId: string }>(url, testPostBody, {
			observe: 'response',
		});
	}

	editTQLTag(tag: Tag): Observable<HttpResponse<void>> {
		const url = environment.trovataAPI('workspace') + '/data/tql/tags/' + tag.tagId;
		const testPostBody = {
			tagTitle: tag.tagTitle,
			tagDescription: tag.tagDescription,
			tagType: tag.tagType,
			includeTransactions: tag.tagMetadata.includeTransactions || [],
			excludeTransactions: tag.tagMetadata.excludeTransactions || [],
			tql: tag.tagMetadata.tql,
		};
		if (tag.priority) {
			testPostBody['priority'] = tag.priority;
		}
		if (tag.tagMetadata.glCode1) {
			testPostBody['glCode1'] = tag.tagMetadata.glCode1;
		}
		if (tag.tagMetadata.glCode2) {
			testPostBody['glCode2'] = tag.tagMetadata.glCode2;
		}
		return this.httpClient.put<void>(url, testPostBody, {
			observe: 'response',
		});
	}

	public actionTag(tag: Tag, transactionArray: Array<string>, action: string) {
		const url = `${environment.trovataAPI('workspace')}/data/tags/${tag.tagId}/transactions?action=${action}`;
		return this.httpClient.put(
			url,
			{
				transactionIds: transactionArray,
			},
			{
				observe: 'response',
			}
		);
	}

	public getTags(): Observable<HttpResponse<Tag[]>> {
		const body = { tagType: TagType.transaction };

		const url = environment.trovataAPI('workspace') + '/data/tql/tags-list';

		return this.httpClient.post<Tag[]>(url, body, {
			observe: 'response',
		});
	}

	public getGLTags(): Observable<HttpResponse<GetTagsResponse>> {
		const body = {
			tagType: [TagType.glTag, TagType.glTagAuto],
		};

		const url = environment.trovataAPI('workspace') + '/data/tags-list';

		return this.httpClient.post<GetTagsResponse>(url, body, {
			observe: 'response',
		});
	}

	public async updateStoreAndFilters(tagType: TagType) {
		if (tagType === TagType.glTag) {
			await firstValueFrom(this.store.dispatch(new GetGlTags()));
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.glTags));
			this.store.dispatch(new GetValues([TQLPropertyKey.glTag]));
		} else if (tagType === TagType.glTagAuto) {
			await firstValueFrom(this.store.dispatch(new GetGlTags()));
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.glAutoTags));
			this.store.dispatch(new GetValues([TQLPropertyKey.glTag]));
		} else {
			await firstValueFrom(this.store.dispatch(new GetTags()));
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.tags));
			this.store.dispatch(new GetValues([TQLPropertyKey.tag]));
		}
	}

	public postGlAutoPriority(tagIds: string[]): Observable<HttpResponse<PostGlAutoPriorityResponse>> {
		const url = `${environment.trovataAPI('workspace')}/data/tags/gl/priority`;
		return this.httpClient.post<PostGlAutoPriorityResponse>(
			url,
			{
				tagIds: tagIds,
			},
			{
				observe: 'response',
			}
		);
	}

	public glAutoReTag(days: number) {
		const url = environment.trovataAPI('workspace') + '/data/tags/gl/retag';
		let params = new HttpParams();
		if (days) {
			params = params.append('priorDays', days);
		}

		return this.httpClient.post(
			url,
			{},
			{
				params: params,
				observe: 'response',
			}
		);
	}

	public includeTrxnToGLTag(glTagId: string, transactionIds: string[], isAll?: boolean, q?: string, params?: SearchParameter[]) {
		const url = environment.edgeAPI() + `/transactions/gltag/${glTagId}`;

		const body = {
			transactionIds: transactionIds,
			isAll: isAll,
			action: 'include',
			includeIntraday: true,
			q: q || '',
		};
		params?.forEach(function (param) {
			if (param.type === ParameterType.isManual) {
				body['institutionType'] = 'MANUAL';
			} else if (
				param.type === ParameterType.START_DATE ||
				param.type === ParameterType.END_DATE ||
				param.type === ParameterType.untagged ||
				param.type === ParameterType.noGlTag ||
				param.type === ParameterType.startIngestionTimestamp ||
				param.type === ParameterType.endIngestionTimestamp
			) {
				body[param.type] = param.value;
			} else {
				const paramType = param.type === 'tag' ? 'tagId' : param.type;
				body[paramType] = body[paramType] ? [...body[paramType], param.value] : [param.value];
			}
		});
		return this.httpClient.post(url, body, {
			observe: 'response',
			headers: new HttpHeaders({
				'Accept-Version': 'v3',
			}),
		});
	}
}
