
export class CustomMenuOptionEvent<T> {
    option: CustomMenuOption;
    row: T;
  }
  
  export class IconSettings {
    icon: string;
    class?: string;
    isOutline?: boolean;
    spaceApart?: boolean;
  }
  
  export class CustomMenuOption {
  
    divider: boolean | undefined = false;
    optionAria: string | undefined;
    optionClass: string | undefined;
    optionTooltip: string | undefined;
    iconLeft: IconSettings | undefined;
    iconRight: IconSettings | undefined;
  
    constructor(
      public optionId: string,
      public optionText: string,
      public button: boolean,
      public disabled: boolean,
      optionalSettings?: {divider?: boolean;
        optionAria?: string;
        optionClass?: string;
        optionTooltip?: string;
        iconLeft?: IconSettings;
        iconRight?: IconSettings;}
    ){
      if(optionalSettings) {
        this.divider = optionalSettings.divider;
        this.optionAria = optionalSettings.optionAria;
        this.optionClass = optionalSettings.optionClass;
        this.optionTooltip = optionalSettings.optionTooltip;
        this.iconLeft = optionalSettings.iconLeft;
        this.iconRight = optionalSettings.iconRight;
      }
    }
  }