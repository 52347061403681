import { Server } from 'miragejs';
import { factories } from './factories.mock';
import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { models } from './models.mock';
import { handleRoutes } from './route-handlers/route-handlers.mock';
import { seedDb } from './seeds/seed-db.mock';
import { AppRegistry } from './types';
import { AccessMap, UserAccessConfig } from '../../features/settings/models/feature.model';
import { SubscriptionNames } from '../../features/settings/models/subscriptions-view.model';
import { accountFilters, transactionFilters } from './helpers.mock';

@Injectable({
	providedIn: 'root',
})
export class AppMockService {
	requestMadeWithoutConnections$: ReplaySubject<boolean>;
	mirageServer: Server;
	constructor() {
		this.requestMadeWithoutConnections$ = new ReplaySubject<boolean>();
	}

	private unhandledRequestMade() {
		this.requestMadeWithoutConnections$.next(true);
	}

	private startMirage(userAccessConfig: UserAccessConfig, customerAccessMap: AccessMap) {
		if (!this.mirageServer) {
			const callUnhandledRequest = () => this.unhandledRequestMade();
			this.mirageServer = new Server<AppRegistry>({
				models,
				factories,
				seeds(server: Server) {
					seedDb(server);
				},
				routes() {
					handleRoutes(this, callUnhandledRequest, userAccessConfig, customerAccessMap);
				},
			});
		}
	}

	restartMirage(userAccessConfig: UserAccessConfig, customerAccessMap: AccessMap) {
		if (this.mirageServer) {
			this.mirageServer.shutdown();
			this.mirageServer = undefined;
		}
		const subscriptionType = userAccessConfig.currentCustomer.subscriptionType;
		if (
			!userAccessConfig.currentCustomer?.accountCount ||
			(subscriptionType !== SubscriptionNames.corporate && subscriptionType !== SubscriptionNames.enterprise)
		) {
			this.startMirage(userAccessConfig, customerAccessMap);
		}
	}

	getFilters() {
		if (this.mirageServer) {
			const trxnFilters = transactionFilters(this.mirageServer);
			const acctFilters = accountFilters(this.mirageServer);
			return {
				transactionFilters: trxnFilters,
				accountFilters: acctFilters,
				invoiceFilters: undefined,
				invoiceAPFilters: undefined,
				invoiceARFilters: undefined,
			};
		} else {
			return {
				transactionFilters: undefined,
				accountFilters: undefined,
				invoiceFilters: undefined,
				invoiceAPFilters: undefined,
				invoiceARFilters: undefined,
			};
		}
	}
}
