import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TQLElementType, TQLOptionType, TQLValueControlType } from '../models/tql.model';
import { GenericOptionValue } from '../models/option.model';

export function tqlElementValidator(tqlElementType: TQLElementType, tqlValueType?: TQLValueControlType): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const errors: ValidationErrors = {
			invalidOption: false,
		};

		let stringValue: string;
		let numericValue: number;
		let genericOptionValue: GenericOptionValue;
		let genericOptionValueArray: GenericOptionValue[];

		if (Array.isArray(control.value)) {
			genericOptionValueArray = control.value;
		} else if (control.value?.option && control.value?.group) {
			genericOptionValue = control.value;
		} else if (!isNaN(control.value)) {
			numericValue = control.value;
		} else if (typeof control.value === 'string') {
			stringValue = control.value;
		}
		if (!control.value) {
			errors.invalidOption = true;
		} else {
			switch (tqlElementType) {
				case TQLElementType.operator:
					if (!genericOptionValue || genericOptionValue.group.type !== TQLOptionType.operator) {
						errors.invalidOption = true;
					}
					break;
				case TQLElementType.property:
					if (!genericOptionValue || control.value.group.type !== TQLOptionType.property) {
						errors.invalidOption = true;
					}
					break;
				case TQLElementType.value:
					switch (tqlValueType) {
						case TQLValueControlType.multi:
							if (!genericOptionValueArray) {
								errors.invalidOption = true;
							}
							break;
						case TQLValueControlType.string:
							if (!stringValue && !numericValue) {
								errors.invalidOption = true;
							}
							break;
						case TQLValueControlType.boolean:
							if (
								!genericOptionValue ||
								!(genericOptionValue.option.key.toLocaleLowerCase() === 'true' || genericOptionValue.option.key.toLocaleLowerCase() === 'false')
							) {
								errors.invalidOption = true;
							}
							break;
						case TQLValueControlType.number:
							if (!numericValue) {
								errors.invalidOption = true;
							}
							break;
						default:
							if (!genericOptionValue) {
								errors.invalidOption = true;
							}
					}
					break;
			}
		}
		if (errors.invalidOption) {
			return errors;
		} else {
			return null;
		}
	};
}
