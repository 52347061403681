import { FilterOption } from '@trovata/app/shared/models/abstract-filter.model';
import { AccountTargetV3 } from '@trovata/app/shared/models/account-target.model';
import { BulkEntitiesViewModel } from './bulk-entities.view.model';

export interface EntityExtraProperty {
	extraPropertyId: string;
	name: string;
	type: EntityFieldType;
	description?: string;
	isFilter?: boolean;
	values?: any[];
}

export interface ExtraPropertyValue {
	extraPropertyId: string;
	value: string | number | boolean;
}
export interface EntityTree {
	entityId: string;
	subEntities: EntityTree[];
}

export enum EntityFieldType {
	text = 'text',
	boolean = 'boolean',
	date = 'date',
	number = 'number',
}

export interface DisplayTree {
	entityId: string;
	subEntities: DisplayTree[];
	entityRef: TableEntity;
	deleted?: boolean;
	added?: boolean;
}
export class EntityPersonnel {
	personnelId: string;
	name: string;
	title: string;
	phone: string;
	email: string;
	notes: string;
	trovataUserId: string;
}

export type TableEntity = Entity & { [x: string]: unknown };

export interface EntityAccountView {
	name: string;
	entityId: string;
	displayAccounts: EntityDisplayAccount[];
	accountsOverage: number;
	overageTooltip?: string;
	tooltip?: string;
	deleted: boolean;
	added: boolean;
}

export interface EntityDisplayAccount {
	data: FilterOption;
	deleted?: boolean;
	added?: boolean;
}

export class Entity {
	entityId: string;
	name: string;
	nickname: string;
	entityCode: string;
	erp?: string;
	erpEntityReference?: string;
	functionalCurrency: string;
	region?: string;
	division?: string;
	description?: string;
	address?: EntityAddress;
	contactPhone?: string;
	contactEmail?: string;
	contactName?: string;
	openingDate?: string;
	closingDate?: string;
	taxId?: string;
	fiscalCalendarId?: string;
	// TODO fix any
	documents?: any[];
	accounts: string[];
	extraProperties: ExtraPropertyValue[];
	personnel: EntityPersonnel[];
}

export interface EntityAddress {
	addressLine1?: string;
	addressLine2?: string;
	zipCode?: string;
	city?: string;
	stateOrProvince?: string;
	country?: string;
}

type DbEntity = Omit<Entity, 'children' | 'accounts'> & { accounts: string[] };

export interface EntitiesGetBody {
	entityId?: string[];
	name?: string[];
	nickname?: string[];
	startOpeningDate?: string;
	endOpeningDate?: string;
	startClosingDate?: string;
	endClosingDate?: string;
	erp?: string[];
	erpEntityReference?: string[];
	functionalCurrency?: string[];
	closed?: boolean;
	includeAccounts?: boolean;
	accountId?: string[];
	excludeAccountId?: string[];
}

export interface GetEntitiesResponse {
	entities: Entity[];
	totalEntities: number;
}
export class GetEntityTreeResponse {
	unassignedEntities: string[];
	entityTree: { trees: EntityTree[] };
}

export class GetEntityExtraPropertyResponse {
	extraProperties: EntityExtraProperty[];
	totalExtraProperties: number;
}

export class PutEntitiesBody {
	entities: DbEntity[];
	extraProperties: EntityExtraProperty[];
	trees: EntityTree[];

	constructor(viewModel: BulkEntitiesViewModel, editMode?: boolean) {
		const viewModelCopy: BulkEntitiesViewModel = JSON.parse(JSON.stringify(viewModel));
		this.trees = viewModelCopy.tree;
		if (editMode) {
			this.filterDeletedEntities(viewModelCopy);
		}
		this.extraProperties = viewModelCopy.extraProperties;
		this.entities = this.tableEntitiesToEntities(viewModelCopy.tableEntities, this.extraProperties, viewModel.tableEntityIsEmpty);
	}

	private filterDeletedEntities(viewModel: BulkEntitiesViewModel): void {
		viewModel.tableEntities = viewModel.tableEntities.filter(tableEntity => !viewModel.entitiesForDeletion.includes(tableEntity.entityId));
		const findAndDeleteTree = (trees: EntityTree[], entityId: string): EntityTree => {
			const foundTree: EntityTree = trees.find((tree, i) => {
				if (entityId === tree.entityId) {
					trees.splice(i, 1);
					trees.push(...tree.subEntities);
					return tree;
				}
			});
			if (!foundTree) {
				trees.forEach(tree => findAndDeleteTree(tree.subEntities, entityId));
			} else {
				return foundTree;
			}
		};
		viewModel.entitiesForDeletion.forEach(deletedEntityId => {
			findAndDeleteTree(viewModel.tree, deletedEntityId);
		});
	}

	private tableEntitiesToEntities(
		tableEntities: TableEntity[],
		extraProperties: EntityExtraProperty[],
		tableEntityIsEmpty: (entity: Entity | TableEntity) => boolean
	): DbEntity[] {
		// remove empty table rows
		const filteredEntities: TableEntity[] = tableEntities.filter(item => !tableEntityIsEmpty(item));
		const convertedEntities: DbEntity[] = filteredEntities.map(tableEntity => {
			const converted: DbEntity = { ...tableEntity };
			delete converted['customFields'];
			// remove empty fields that were used for table column
			Object.keys(converted).forEach(key => {
				if (!converted[key] && converted[key] !== 0) {
					delete converted[key];
				}
			});
			if (!converted.accounts) {
				converted.accounts = [];
			}
			const extraProps: EntityExtraProperty[] = extraProperties.filter(prop => Object.keys(converted).includes(prop.extraPropertyId));
			converted.extraProperties = [];
			extraProps.forEach(prop => {
				converted.extraProperties.push({ extraPropertyId: prop.extraPropertyId, value: converted[prop.extraPropertyId] });
				delete converted[prop.extraPropertyId];
			});
			return converted;
		});
		return convertedEntities;
	}
}

// events
export interface EntityAccountsChangedEvent {
	item: EntityAccountView;
	accounts: AccountTargetV3[];
}

export interface DisplayTreeChangedEvent {
	entity: DisplayTree;
	selectedTree: DisplayTree[];
	unselectedParents: DisplayTree[];
}

export const entitiesEqual: (ent1: Entity, ent2: Entity) => boolean = (ent1: Entity, ent2: Entity) =>
	entitiesStringEqual(ent1.name, ent2.name) &&
	entitiesStringEqual(ent1.nickname, ent2.nickname) &&
	entitiesStringEqual(ent1.entityCode, ent2.entityCode) &&
	entitiesStringEqual(ent1.erp, ent2.erp) &&
	entitiesStringEqual(ent1.erpEntityReference, ent2.erpEntityReference) &&
	entitiesStringEqual(ent1.functionalCurrency, ent2.functionalCurrency) &&
	entitiesStringEqual(ent1.region, ent2.region) &&
	entitiesStringEqual(ent1.division, ent2.division) &&
	entitiesStringEqual(ent1.description, ent2.description) &&
	entitiesStringEqual(ent1.contactPhone, ent2.contactPhone) &&
	entitiesStringEqual(ent1.contactEmail, ent2.contactEmail) &&
	entitiesStringEqual(ent1.contactName, ent2.contactName) &&
	entitiesStringEqual(ent1.openingDate, ent2.openingDate) &&
	entitiesStringEqual(ent1.closingDate, ent2.closingDate) &&
	entitiesArrayEqual(ent1.personnel, ent2.personnel) &&
	entitiesArrayEqual(ent1.extraProperties, ent2.extraProperties) &&
	entitiesArrayEqual(ent1.accounts, ent2.accounts) &&
	entitiesAddressEqual(ent1.address, ent2.address);

const entitiesAddressEqual: (addr1: EntityAddress, addr2: EntityAddress) => boolean = (addr1: EntityAddress, addr2: EntityAddress) => {
	if (!addr1 && !addr2) {
		return true;
	} else if (!addr1 || !addr2) {
		return false;
	} else {
		return (
			entitiesStringEqual(addr1.addressLine1, addr1.addressLine1) &&
			entitiesStringEqual(addr1.addressLine2, addr1.addressLine2) &&
			entitiesStringEqual(addr1.zipCode, addr1.zipCode) &&
			entitiesStringEqual(addr1.city, addr1.city) &&
			entitiesStringEqual(addr1.stateOrProvince, addr1.stateOrProvince) &&
			entitiesStringEqual(addr1.country, addr1.country)
		);
	}
};

const entitiesStringEqual: (string1: string, string2: string) => boolean = (string1: string, string2: string) => {
	if (!string1 && !string2) {
		return true;
	} else if (!string1 || !string2) {
		return false;
	} else {
		return string1 === string2;
	}
};

const entitiesArrayEqual: (collection1: any[], collection2: any[]) => boolean = (collection1: any[], collection2: any[]) => {
	if (!collection1?.length && !collection2?.length) {
		return true;
	} else if (!collection1?.length || !collection2?.length) {
		return false;
	} else {
		return JSON.stringify(collection1) === JSON.stringify(collection2);
	}
};
