export interface Cache<T> {
	items: Map<string, T>;
	clearCache();
	hasItem(id: string): boolean;
	updateItem(newItem: T);
	updateItems(newItems: T[]);
	getItem(id: string): T;
	getItems(): T[];
	removeItem(id: string);
}

export class GenericCache<T> implements Cache<T> {
	items: Map<string, T> = new Map();

	constructor(private idKey: string) {}

	clearCache() {
		this.items.clear();
	}

	isEmpty(): boolean {
		return !(this.items.size > 0);
	}

	hasItem(id: string): boolean {
		return this.items.has(id);
	}

	updateItem(newItem: T, idKey?: string) {
		this.items.set(idKey || newItem[this.idKey], newItem);
	}

	updateItems(newItems: T[]) {
		newItems.forEach(newItem => {
			this.items.set(newItem[this.idKey], newItem);
		});
	}

	getItem(id: string): T {
		return this.items.get(id);
	}

	getItems(): T[] {
		return Array.from(this.items.values());
	}

	removeItem(id: string) {
		this.items.delete(id);
	}
}
