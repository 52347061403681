export interface WorkerEventData {
	action: WorkerEventDataAction;
	type: WorkerEventDataType;
	id: string;
	param: any;
}

export enum WorkerEventDataAction {
	parseString = 'parseString',
	decompressString = 'decompressString',
	decompressedString = 'decompressedString',
	getReportsHashDict = 'getReportsHashDict',
	reportsHashDict = 'reportsHashDict',
	getCurrentCashHashDict = 'getCurrentCashHashDict',
	currentCashHashDict = 'currentCashHashDict',
}

export enum WorkerEventDataType {
	reportData = 'reportData',
	currentCashData = 'currentCashData',
	reportArray = 'reportArray',
	currentCashArray = 'currentCashArray',
	forecastData = 'forecastData',
	state = 'state',
}
