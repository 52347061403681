import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ParameterType } from 'src/app/shared/models/search-parameter.model';
import moment from 'moment';
import { catchError, switchMap } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { WebWorkerService } from 'src/app/core/services/web-worker.service';
import { v4 as uuid } from 'uuid';
import { WorkerEventDataType } from 'src/app/core/models/worker.model';
import { getPeriods } from '../utils/helpers';
import { DateTime } from 'luxon';
import {
	AnalysisBalanceLegacy,
	AnalysisTransactionLegacy,
	BalanceAnalysisGetRequestParamsLegacy,
	TransactionsAnalysisGetRequestParamsLegacy,
} from '../models/analysis.legacy.model';

@Injectable({
	providedIn: 'root',
})
export class AnalysisLegacyService {
	version = 3;
	constructor(
		private httpClient: HttpClient,
		private webworkerService: WebWorkerService
	) {}

	public getTransactionsAnalysis({
		cadence,
		periods,
		q,
		groupBy,
		params,
		includeForecast,
		startDate,
		endDate,
		trimWeekends = false,
		currencyOverride,
		tqlJSONExpression,
	}: TransactionsAnalysisGetRequestParamsLegacy): Observable<AnalysisTransactionLegacy> {
		const body: object = {};
		if (q) {
			body['q'] = q.trim();
		}
		body['cadence'] = cadence;
		if (periods) {
			body['periods'] = periods;
		}
		if (trimWeekends && cadence === 'daily') {
			body['trimWeekends'] = true;
		}
		if (groupBy) {
			body['groupBy'] = this.fixGroupByTags(groupBy);
		}
		if (currencyOverride) {
			body['currencyOverride'] = currencyOverride;
		}
		if (tqlJSONExpression) {
			body['tql'] = {
				type: 'AST',
				expression: tqlJSONExpression,
			};
		}
		if (params) {
			params.forEach(function (param) {
				if (param.type === ParameterType.isManual) {
					body['institutionType'] = 'MANUAL';
				} else if (param.type === ParameterType.START_DATE || param.type === ParameterType.END_DATE) {
					body[param.type] = param.value;
				} else {
					body[param.type] = body[param.type] ? [...body[param.type], param.value] : [param.value];
				}
			});
		}
		if (endDate) {
			body['endDate'] = formatDate(endDate, 'yyyy-MM-dd', 'en');
		}
		if (startDate && !periods) {
			body['startDate'] = formatDate(startDate, 'yyyy-MM-dd', 'en');
		}
		if (!params?.find(param => param.type === ParameterType.endDate) && !endDate && !startDate) {
			body['endDate'] = DateTime.now().toFormat('yyyy-LL-dd');
		}
		if (includeForecast && (periods ? periods : getPeriods(moment(endDate).startOf('day'), moment(startDate).startOf('day'), cadence, trimWeekends)) >= 5) {
			body['includeForecast'] = 'true';
		}
		const url = environment.edgeAPI() + '/analysis/transactions';
		const mainRequest = this.httpClient
			.post(url, body, {
				headers: new HttpHeaders({
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'Accept-Version': 'v3',
				}),
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
		if (includeForecast && groupBy) {
			const forecastBody = { ...body };
			delete forecastBody['groupBy'];
			const forecastRequest = this.httpClient
				.post(url, forecastBody, {
					headers: new HttpHeaders({
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'Accept-Version': 'v3',
					}),
					observe: 'response',
				})
				.pipe(catchError(err => throwError(() => err)));
			return forkJoin([mainRequest, forecastRequest]).pipe(
				switchMap(async ([mainResults, forecastResults]) => {
					if (mainResults.body['compressedPayload']) {
						try {
							const decompressedPayload = await this.webworkerService.decompress(WorkerEventDataType.reportData, uuid(), mainResults.body['compressedPayload']);
							return decompressedPayload['data'];
						} catch (err) {
							console.error('Failed to decompress data for analysis');
						}
					}
					mainResults.body['data']['forecast'] = forecastResults.body['data']['forecast'];
					return mainResults.body['data'];
				}),
				catchError(err => throwError(() => err))
			);
		} else {
			return mainRequest.pipe(
				map(resp => resp.body['data']),
				catchError(err => throwError(() => err))
			);
		}
	}

	public getBalanceAnalysis({
		cadence,
		periods,
		groupBy,
		params,
		currencyOverride,
		startDate,
		endDate,
		trimWeekends = false,
	}: BalanceAnalysisGetRequestParamsLegacy): Observable<AnalysisBalanceLegacy> {
		const body: object = {};
		body['cadence'] = cadence;
		if (periods) {
			body['periods'] = periods;
		}
		if (trimWeekends && cadence === 'daily') {
			body['trimWeekends'] = true;
		}
		if (groupBy) {
			body['groupBy'] = groupBy;
		}
		if (currencyOverride) {
			body['currencyOverride'] = currencyOverride;
		}

		if (params) {
			params.forEach(function (param) {
				if (param.type === ParameterType.isManual) {
					body['institutionType'] = 'MANUAL';
				} else if (param.type === ParameterType.START_DATE || param.type === ParameterType.END_DATE) {
					body[param.type] = param.value;
				} else {
					const paramType = param.type === 'tag' ? 'tagId' : param.type;
					body[paramType] = body[paramType] ? [...body[paramType], param.value] : [param.value];
				}
			});
		}
		if (endDate) {
			body['endDate'] = formatDate(endDate, 'yyyy-MM-dd', 'en');
		}
		if (startDate && !periods) {
			body['startDate'] = formatDate(startDate, 'yyyy-MM-dd', 'en');
		}
		if (!params?.find(param => param.type === ParameterType.endDate) && !endDate && !startDate) {
			body['endDate'] = DateTime.now().toFormat('yyyy-LL-dd');
		}
		const url = environment.edgeAPI() + '/analysis/balances';
		return this.httpClient
			.post(url, body, {
				headers: new HttpHeaders({
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'Accept-Version': 'v3',
				}),
				observe: 'response',
			})
			.pipe(
				switchMap(async resp => {
					if (resp.body['compressedPayload']) {
						try {
							const decompressedPayload = await this.webworkerService.decompress(WorkerEventDataType.reportData, uuid(), resp.body['compressedPayload']);
							return decompressedPayload['data']['balancesAggregation'];
						} catch (err) {
							console.error('Failed to decompress data for analysis');
						}
					} else {
						return resp.body['data']['balancesAggregation'];
					}
				}),
				catchError(err => throwError(err))
			);
	}

	private fixGroupByTags(groupBy: string): string {
		if (groupBy.includes('tag')) {
			const newGroupBy = [];
			const groupByList = groupBy.split(',').map(group => group.trim());
			groupByList.forEach(eachGroupBy => {
				if (eachGroupBy === 'tag') {
					newGroupBy.push('tags');
				} else {
					newGroupBy.push(eachGroupBy.trim());
				}
			});
			return newGroupBy.join(',');
		} else {
			return groupBy;
		}
	}
}
