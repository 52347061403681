import { of } from 'rxjs';

export interface AuthUser {
	email: string;
	['https://auth.trovata.io/userinfo/customerId']: string;
	['https://auth.trovata.io/userinfo/roles']: string[];
	['https://auth.trovata.io/userinfo/userId']: string;
	name: string;
	nickname: string;
	picture: string;
	sub: string;
	updated_at: string;
}

export const MOCK_AUTH_USER: AuthUser = {
	email: 'test',
	['https://auth.trovata.io/userinfo/customerId']: 'test',
	['https://auth.trovata.io/userinfo/roles']: ['test'],
	['https://auth.trovata.io/userinfo/userId']: 'test',
	name: 'test',
	nickname: 'test',
	picture: 'test',
	sub: 'test',
	updated_at: 'test',
};

export const MOCK_AUTH_SERVICE = {
	user$: of(MOCK_AUTH_USER),
	isAuthenticated$: of(true),
	getAccessTokenSilently: () => of('test'),
	logout: () => true,
};
