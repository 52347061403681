export class RoundingOption {
	name: string;
	value: number;
	symbol: string;
	rounding?: Rounding;
}

export enum Rounding {
	none = 'none',
	one = 'one',
	thousands = 'thousand',
	millions = 'million',
	billions = 'billion',
}

export const roundingOptions: RoundingOption[] = [
	{
		name: 'No Rounding',
		value: null,
		symbol: '',
		rounding: Rounding.none,
	},
	{
		name: 'Whole Number',
		value: 1,
		symbol: '',
		rounding: Rounding.one,
	},
	{
		name: 'Thousands',
		value: 1000,
		symbol: 'K',
		rounding: Rounding.thousands,
	},
	{
		name: 'Millions',
		value: 1000000,
		symbol: 'M',
		rounding: Rounding.millions,
	},
	{
		name: 'Billions',
		value: 1000000000,
		symbol: 'B',
		rounding: Rounding.billions,
	},
];

export const roundingDict: Map<Rounding, RoundingOption> = new Map<Rounding, RoundingOption>([
	[Rounding.none, roundingOptions[0]],
	[Rounding.one, roundingOptions[1]],
	[Rounding.thousands, roundingOptions[2]],
	[Rounding.millions, roundingOptions[3]],
	[Rounding.billions, roundingOptions[4]],
]);
