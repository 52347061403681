import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PercentageBarChartBuilder } from '../../models/highcharts.model';
import { BarChartRowViewModel } from '../../models/insights.model';

@Component({
	selector: 'trovata-bar-chart-row',
	templateUrl: './bar-chart-row.component.html',
	styleUrls: ['./bar-chart-row.component.scss'],
})
export class BarChartRowComponent implements BarChartRowViewModel, OnChanges {
	@Input() title: string;
	@Input() barValue: number;
	@Input() displayValue: string;
	@Input() isPercentage: boolean;

	percentageChartBuilder: PercentageBarChartBuilder;

	constructor() {
		this.isPercentage = false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['barValue'] || changes['isPercentage']) {
			this.upadatePercentageChart();
		}
	}

	private upadatePercentageChart(): void {
		this.percentageChartBuilder = new PercentageBarChartBuilder({
			percentageBarValue: this.barValue,
		});
	}

	get valueClass(): string {
		return this.isPercentage ? 'normal' : 'expand';
	}
}
