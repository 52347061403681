import { BillingDetails, PaymentMethod } from '../../models/payment.model';

export class InitPaymentMethodsState {
	static readonly type: string = '[PaymentMethods] InitPaymentMethodsState';
}

export class GetPaymentMethods {
	static readonly type: string = '[PaymentMethods] GetPaymentMethods';
}

export class UpdatePaymentMethod {
	static readonly type: string = '[PaymentMethods] UpdatePaymentMethod';
	constructor(
		public paymentMethod: PaymentMethod,
		public makeDefault?: boolean
	) {}
}

export class CreatePaymentMethod {
	static readonly type: string = '[PaymentMethods] CreatePaymentMethod';
	constructor(
		public paymentMethodId: string,
		public isDefault?: boolean,
		public billingDetails?: BillingDetails
	) {}
}

export class ClearPaymentMethodsState {
	static readonly type: string = '[PaymentMethods] ClearPaymentMethodsState';
}
