import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil, throttleTime } from 'rxjs';
import { SessionWorkerAction, SessionWorkerEvent } from '../models/session-worker.model';
import { Logout, SetAccessToken, SetRefreshToken } from '../store/actions/auth.actions';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { environment } from '@trovata/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { SessionBroadcastMessage } from '../models/session-timeout';

@Injectable({
	providedIn: 'root',
})
export class SessionWebWorkerService {
	sessionMessageEvent$: Subject<MessageEvent>;
	broadcastChannel: BroadcastChannel;
	debounceActivity$: Subject<void> = new Subject<void>();
	private showWarningSubject$: Subject<boolean> = new Subject<boolean>();
	showWarning$: Observable<boolean>;

	private cleanup$: Subject<void>;

	private sessionWorker: Worker;

	constructor(
		private store: Store,
		private authService: AuthService
	) {
		this.sessionMessageEvent$ = new Subject();
		this.debounceActivity$ = new Subject<void>();
		this.cleanup$ = new Subject<void>();
		this.showWarningSubject$ = new Subject();
		this.showWarning$ = this.showWarningSubject$.asObservable();
		this.initSessionWebWorker();
	}

	private initSessionWebWorker(): void {
		if (typeof Worker !== 'undefined') {
			this.sessionWorker = new Worker(new URL('../utils/session-web-worker.worker', import.meta.url));

			this.sessionWorker.onmessage = (event: MessageEvent) => {
				this.sessionMessageEvent$.next(event); // event received from postMessage(response) in web-worker.worker.ts
			};

			this.sessionWorker.onerror = error => {
				throw error;
			};
		} else {
			throw new Error('Browser does not support web workers!'); // should never happen for our clients: https://caniuse.com/webworkers
		}
	}

	initSession(): void {
		localStorage.setItem('latestActivity', DateTime.local().toISO());
		localStorage.setItem('tokenRefreshedAt', DateTime.local().toISO());
		this.createBroadcastChannel();
		this.subscribeToBroadcastChannel();
		this.subscribeToActivity();
		this.initiateSessionInterval();
	}

	refreshTokens(): Promise<boolean> {
		const access_token: string = this.getAccessToken();
		return new Promise(async (resolve, reject) => {
			this.authService.getAccessTokenSilently().subscribe({
				next: (newAccessToken: string) => {
					if (newAccessToken !== access_token) {
						localStorage.setItem('tokenRefreshedAt', DateTime.local().toISO());
					}
					const refreshToken: string = this.getRefreshToken();
					this.store.dispatch(new SetAccessToken(newAccessToken));
					this.store.dispatch(new SetRefreshToken(refreshToken));
					resolve(true);
				},
				error: error => {
					this.store.dispatch(new Logout());
					reject(error);
				},
			});
		});
	}

	cleanup(): void {
		this.postSessionMessage(SessionWorkerAction.clearSessionInterval);
		this.terminateSessionWorker();
		this.cleanup$.next();
		this.cleanup$.complete();
	}

	private initiateSessionInterval(): void {
		this.postSessionMessage(SessionWorkerAction.initiateSessionInterval);
		this.sessionMessageEvent$.pipe(takeUntil(this.cleanup$)).subscribe((event: MessageEvent) => {
			switch (event.data) {
				case SessionWorkerEvent.checkStatus:
					this.checkSessionStatus();
			}
		});
	}

	private subscribeToActivity(): void {
		this.debounceActivity$.pipe(throttleTime(1000)).subscribe(() => {
			localStorage.setItem('latestActivity', DateTime.local().toISO());
		});
	}

	private subscribeToBroadcastChannel(): void {
		this.broadcastChannel.onmessage = (msg: any): void => {
			if (msg === SessionBroadcastMessage.showTimeoutWarning) {
				this.showWarningSubject$.next(true);
			}

			if (msg === SessionBroadcastMessage.timeout) {
				this.store.dispatch(new Logout());
			}
		};
	}

	private triggerLogout(): void {
		this.broadcastChannel.postMessage(SessionBroadcastMessage.timeout);
		this.store.dispatch(new Logout());
	}

	private displayInactivityTimeoutWarning(): void {
		this.showWarningSubject$.next(true);
		this.broadcastChannel.postMessage(SessionBroadcastMessage.showTimeoutWarning);
	}

	private createBroadcastChannel(): void {
		this.broadcastChannel = new BroadcastChannel('session');
	}

	private checkSessionStatus(): void {
		const storedInactivityTimestamp: string = localStorage.getItem('latestActivity');
		const storedTokenTimestamp: string = localStorage.getItem('tokenRefreshedAt');
		// Convert the stored timestamp to a Luxon DateTime object
		const lastActivityTime: DateTime = DateTime.fromISO(storedInactivityTimestamp);
		const lastTokenRefresh: DateTime = DateTime.fromISO(storedTokenTimestamp);
		// Get the current time
		const currentTime: DateTime = DateTime.now();

		// calculate time since token refresh
		const timeSinceRefresh: number = currentTime.diff(lastTokenRefresh, ['minutes']).minutes;
		if (timeSinceRefresh >= 59) {
			this.refreshTokens();
		}

		// Calculate inactive time in minutes
		const inactiveTime: number = currentTime.diff(lastActivityTime, ['minutes']).minutes;
		if (inactiveTime >= 60 || !lastActivityTime.isValid) {
			this.triggerLogout();
		} else if (inactiveTime >= 58) {
			this.displayInactivityTimeoutWarning();
		} else {
			this.showWarningSubject$.next(false);
		}
	}

	private getAccessToken(): string {
		const storage: Storage = localStorage;
		const refreshToken: string = JSON.parse(
			storage[`@@auth0spajs@@::${environment.auth0ClientId}::${environment.authOAudience}::openid profile email offline_access`]
		)['body']['access_token'];
		return refreshToken;
	}

	private getRefreshToken(): string {
		const storage: Storage = localStorage;
		const refreshToken: string = JSON.parse(
			storage[`@@auth0spajs@@::${environment.auth0ClientId}::${environment.authOAudience}::openid profile email offline_access`]
		)['body']['refresh_token'];
		return refreshToken;
	}

	private postSessionMessage(sessionWorkerAction: SessionWorkerAction): void {
		this.sessionWorker.postMessage(sessionWorkerAction); // sent to addEventListener('message') in web-worker.worker.ts
	}

	private terminateSessionWorker(): void {
		this.sessionWorker.terminate();
	}
}
