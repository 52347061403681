import { PutEntitiesBody, Entity, EntityPersonnel, EntityExtraProperty } from '../../models/entity.model';

export class InitEntitiesState {
	static readonly type: string = '[Entities] InitEntitiesState';
}

export class GetEntities {
	static readonly type: string = '[Entities] GetEntities';
}

export class PutEntities {
	static readonly type: string = '[Entities] PutEntities';
	constructor(public body: PutEntitiesBody) {}
}

export class PutEntity {
	static readonly type: string = '[Entities] PutEntity';
	constructor(public entity: Entity) {}
}

export class DeleteEntity {
	static readonly type: string = '[Entities] DeleteEntity';
	constructor(public entityId: string) {}
}

export class GetEntityTrees {
	static readonly type: string = '[Entities] GetEntityTree';
}

export class GetExtraProperties {
	static readonly type: string = '[Entities] GetExtraProperties';
}

export class CreatePersonnel {
	static readonly type: string = '[Entities] CreatePersonnel';
	constructor(
		public entity: Entity,
		public personnel: EntityPersonnel
	) {}
}

export class PutPersonnel {
	static readonly type: string = '[Entities] EditPersonnel';
	constructor(
		public entity: Entity,
		public personnel: EntityPersonnel
	) {}
}

export class DeletePersonnel {
	static readonly type: string = '[Entities] DeletePersonnel';
	constructor(
		public entity: Entity,
		public personnel: EntityPersonnel
	) {}
}

export class CreateExtraProperty {
	static readonly type: string = '[Entities] CreateExtraProperty';
	constructor(public extraProperty: EntityExtraProperty) {}
}
export class PatchExtraProperty {
	static readonly type: string = '[Entities] PatchExtraProperty';
	constructor(
		public extraPropertyId: string,
		public extraProperty: Partial<EntityExtraProperty>
	) {}
}
export class DeleteExtraProperty {
	static readonly type: string = '[Entities] DeleteExtraProperty';
	constructor(public extraProperty: EntityExtraProperty) {}
}

export class ClearEntitiesState {
	static readonly type: string = '[Entities] ClearEntitiesState';
}
