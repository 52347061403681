import { Server } from 'miragejs';
import { CurrencySummaryResponse } from '../../models/currency.model';
import { AppSchema } from '../types';

export function addCurrencyEdgeHandlers(server: Server) {
	server.get(
		'/currencies/summary',
		(schema: AppSchema): CurrencySummaryResponse => ({
			currencyConverted: 'USD',
			currencyBalances: schema.db.currencyBalances,
		})
	);
}
