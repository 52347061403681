import { GlobalFactorItem, IForecastPayload, IGlobalFactorsPayload } from '../../models/forecastV3-forecast.model';
import { EditedForecastFactorGroups, EditedStreamGroups, IForecastV3Response, StreamGroupsEntity } from '../../models/forecastV3-forecast-response.model';
import { Stream, IStreamPayload, StreamValue } from '../../models/forecastV3-stream.model';
export class InitForecastV3State {
	static readonly type: string = '[ForecastV3] InitForecastV3State';
}

export class ClearForecastV3State {
	static readonly type: string = '[ForecastV3] ClearForecastV3State';
}

export class GetForecasts {
	static readonly type: string = '[ForecastV3] GetForecasts';
}

export class GetStreams {
	static readonly type: string = '[ForecastV3] GetStreams';
}

export class CreateStream {
	static readonly type: string = '[ForecastV3] CreateStream';
	constructor(
		public streamPayload: IStreamPayload,
		public duplicateStreamId?: string,
		public newStreamName?: string
	) {}
}

export class CreateForecast {
	static readonly type: string = '[ForecastV3] CreateForecast';
	constructor(
		public forecastPayload: IForecastPayload,
		public duplicateForecastById?: string,
		public nameDuplicate?: string,
		public cadence?: string
	) {}
}

export class CreateGlobalFactors {
	static readonly type: string = '[ForecastV3] CreateGlobalFactors';
	constructor(
		public forecastFactorsPayload: IGlobalFactorsPayload[],
		public forecastId: string,
		public cadence: string
	) {}
}

export class AddStreamDataToState {
	static readonly type: string = '[ForecastV3] AddStreamDataToState';
	constructor(public stream: Stream) {}
}

export class AddForecastDataToState {
	static readonly type: string = '[ForecastV3] AddForecastDataToState';
	constructor(public forecast: IForecastV3Response) {}
}

export class LazyLoadForecastData {
	static readonly type: string = '[ForecastV3] LazyLoadForecastData';
	constructor(public forecastId: string) {}
}

export class LazyLoadStreamData {
	static readonly type: string = '[ForecastV3] LazyLoadStreamData';
	constructor(public streamId: string) {}
}

export class DeleteStream {
	static readonly type: string = '[ForecastV3] DeleteStream';
	constructor(
		public streamId: string,
		public parentForecast?: IForecastV3Response
	) {}
}

export class DeleteForecast {
	static readonly type: string = '[ForecastV3] DeleteForecast';
	constructor(public forecastId: string) {}
}

export class UpdateStreamData {
	static readonly type: string = '[ForecastV3] UpdateStreamData';
	constructor(
		public streamId: string,
		public streamValues: StreamValue[],
		public parentForecast?: IForecastV3Response
	) {}
}

export class UpdateStream {
	static readonly type: string = '[ForecastV3] UpdateStream';
	constructor(
		public streamId: string,
		public streamPayload: IStreamPayload,
		public values?: StreamValue[],
		public parentForecast?: IForecastV3Response
	) {}
}

export class UpdateForecast {
	static readonly type: string = '[ForecastV3] UpdateForecast';
	constructor(
		public forecastId: string,
		public forecastPayload: IForecastPayload,
		public streamGroupsSource?: StreamGroupsEntity[],
		public editedStreamGroups?: EditedStreamGroups,
		public globalFactorsSource?: GlobalFactorItem[],
		public editedGlobalFactors?: EditedForecastFactorGroups
	) {}
}

export class RetryLazyLoadForecastData {
	static readonly type: string = '[ForecastV3] RetryLazyLoadForecastData';
}
