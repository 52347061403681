import { IWorkbook, Workbook } from '../../models/workbook.model';

export class InitWorkbooksState {
	static readonly type: string = '[Workbooks] InitWorkbooksState';
	constructor() {}
}

export class ClearWorkbooksState {
	static readonly type: string = '[Workbooks] ClearWorkbooksState';
}
export class ResetWorkbooksState {
	static readonly type: string = '[Workbooks] ResetWorkbooksState';
}
export class GetWorkbooks {
	static readonly type: string = '[Workbooks] GetWorkbooks';
	constructor() {}
}

export class CreateWorkbook {
	static readonly type: string = '[Workbooks] CreateWorkbook';
	constructor(public workbook: IWorkbook) {}
}

export class UpdateWorkbook {
	static readonly type: string = '[Workbooks] UpdateWorkbook';
	constructor(
		public workbookId: string,
		public workbook: IWorkbook,
		public workbookToUpdate: Workbook,
		public deletedSheetIds: string[],
		public deletedDataOutletIds: string[]
	) {}
}
export class DeleteWorkbook {
	static readonly type: string = '[Workbooks] DeleteWorkbook';
	constructor(public workbookId: string) {}
}
