import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services/http.service';
import { Observable } from 'rxjs';
import { AdminApproval, PaymentsRecordType, PostAdminApprovalResponse } from '../models/admin-approval.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AdminApprovalService {
	constructor(private httpService: HttpService) {}

	postAdminApproval(body: AdminApproval, type: PaymentsRecordType): Observable<PostAdminApprovalResponse> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/admin-approval/${type}`, body);
	}
}
