import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { CurrentCashStateModel } from '../../features/balances/store/state/current-cash/current-cash.state';
import { CashPositionStateModel } from '../../features/cash-position/store/state/cash-position.state';
import { ForecastV3StateModel } from '../../features/forecasts/store/state/forecastV3.state';
import { PaymentsAccountsStateModel } from '../../features/payments/store/state/accounts/accounts.state';
import { PaymentsStateModel } from '../../features/payments/store/state/payments/payments.state';
import { TemplatesStateModel } from '../../features/payments/store/state/templates/templates.state';
import { WorkflowsStateModel } from '../../features/payments/store/state/workflows/workflow.state';
import { ReconciliationStateModel } from '../../features/reports/store/state/reconciliation.state';
import { ReportsStateModel } from '../../features/reports/store/state/reports.state';
import { PlaidStateModel } from '../../features/settings/store/state/plaid.state';
import { GlCodeStateModel } from '../../features/transactions/store/state/glCode.state';
import { GlTagsStateModel } from '../../features/transactions/store/state/glTags.state';
import { TagSnacksStateModel } from '../../features/transactions/store/state/tag-snacks.state';
import { TagsStateModel } from '../../features/transactions/store/state/tags.state';
import { FilterObjectStateModel } from '../../shared/store/state/filter-object/filter-object.state';
import { InstitutionsStateModel } from '../../shared/store/state/institutions/institutions.state';
import { PreferencesStateModel } from '../../shared/store/state/preferences/preferences.state';
import { TrovataAppState } from '../models/state.model';
import { WorkerEventDataType } from '../models/worker.model';
import { WebWorkerService } from './web-worker.service';

export function serializeState(state: TrovataAppState): string {
	return JSON.stringify(state);
}

export function deserializeState(state: string | null): TrovataAppState | null {
	if (state) {
		return JSON.parse(state);
	} else {
		return null;
	}
}

@Injectable({
	providedIn: 'root',
})
export class SerializationService {
	private deserializedState$: BehaviorSubject<TrovataAppState>;

	constructor(private webworkerService: WebWorkerService) {
		this.deserializedState$ = new BehaviorSubject<TrovataAppState>(null);
	}

	serializeState(state: TrovataAppState): string {
		return serializeState(state);
	}

	async deserializeState(stateString: string | null): Promise<TrovataAppState | null> {
		return new Promise(async resolve => {
			if (stateString) {
				const state: TrovataAppState = await this.webworkerService.parseState(WorkerEventDataType.state, 'trovataAppState', stateString);
				this.deserializedState$.next(state);
				resolve(state);
			} else {
				resolve(null);
			}
		});
	}

	async getDeserializedState(): Promise<TrovataAppState> {
		const stateRetrieved$: Subject<boolean> = new Subject();
		return new Promise(async (resolve, reject) => {
			this.deserializedState$.pipe(takeUntil(stateRetrieved$)).subscribe((appState: TrovataAppState) => {
				if (appState) {
					stateRetrieved$.next(true);
					stateRetrieved$.complete();
					resolve(appState);
				}
			});
		});
	}

	clearDeserializedState() {
		this.deserializedState$.next(null);
	}

	clearFeatureStatesFromDeserializedState(): Promise<TrovataAppState> {
		const stateRetrieved$: Subject<boolean> = new Subject();
		return new Promise(resolve => {
			this.deserializedState$.pipe(takeUntil(stateRetrieved$)).subscribe((appState: TrovataAppState) => {
				if (appState) {
					const featureStates: (
						| PreferencesStateModel
						| FilterObjectStateModel
						| CashPositionStateModel
						| CurrentCashStateModel
						| ReportsStateModel
						| PlaidStateModel
						| ForecastV3StateModel
						| ReconciliationStateModel
						| TagsStateModel
						| GlTagsStateModel
						| GlCodeStateModel
						| TagSnacksStateModel
						| InstitutionsStateModel
					)[] = [
						appState.preferences,
						appState.filterObject,
						appState.cashPosition,
						appState.currentCash,
						appState.reports,
						appState.forecastV3,
						appState.reconciliation,
						appState.tags,
						appState.glTags,
						appState.glCodes,
						appState.tagSnacks,
						appState.institutions,
						appState.plaid,
					];
					featureStates.forEach(featureState => {
						Object.keys(featureState).forEach((key: string) => {
							featureState[key] = null;
						});
					});
					stateRetrieved$.next(true);
					stateRetrieved$.complete();
					resolve(appState);
				}
			});
		});
	}

	clearPaymentsStatesFromDeserializedState(): Promise<TrovataAppState> {
		const stateRetrieved$: Subject<boolean> = new Subject();
		return new Promise(resolve => {
			this.deserializedState$.pipe(takeUntil(stateRetrieved$)).subscribe((appState: TrovataAppState) => {
				if (appState) {
					const paymentsStates: (WorkflowsStateModel | PaymentsStateModel | PaymentsAccountsStateModel | TemplatesStateModel)[] = [
						appState.payments,
						appState.paymentsAccounts,
						appState.templates,
						appState.workflows,
					];
					paymentsStates.forEach((paymentsState: WorkflowsStateModel | PaymentsStateModel | PaymentsAccountsStateModel | TemplatesStateModel) => {
						if (paymentsState && Object.keys(paymentsState).length) {
							Object.keys(paymentsState).forEach((key: string) => {
								paymentsState[key] = null;
							});
						}
					});
					stateRetrieved$.next(true);
					stateRetrieved$.complete();
					resolve(appState);
				}
			});
		});
	}
}
