export class InitCurrencyState {
	static readonly type: string = '[Currency] InitCurrencyState';
}

export class ClearCurrencyState {
	static readonly type: string = '[Currency] ClearCurrencyState';
}

export class ResetCurrencyState {
	static readonly type: string = '[Currency] ResetCurrencyState';
}

export class GetCurrencies {
	static readonly type: string = '[Currency] GetCurrencies';
	constructor(public shouldRefresh?: boolean) {}
}

export class GetCurrencySummary {
	static readonly type: string = '[Currency] GetCurrencySummary';
	constructor() {}
}
