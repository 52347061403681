import { Cadence } from '../../../reports/models/cadence.model';
import { TagSnack } from '../../models/tag-snack.view.model';

export class InitTagSnacksState {
	static readonly type: string = '[TagSnacks] InitTagSnacksState';
}

export class GetTagSnacksData {
	static readonly type: string = '[TagSnacks] GetTagSnacksData';
}

export class AddTagSnackData {
	static readonly type: string = '[TagSnacks] AddTagSnackData';
	constructor(
		public tagId: string,
		public cadence: Cadence,
		public periods: number
	) {}
}

export class RefreshTagSnackData {
	static readonly type: string = '[TagSnacks] RefreshTagSnackData';
	constructor(public tagId: string) {}
}

export class ResetTagSnacksState {
	static readonly type: string = '[TagSnacks] ResetTagSnacksState';
}

export class ClearTagSnacksState {
	static readonly type: string = '[TagSnacks] ClearTagSnacksState';
}
