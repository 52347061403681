import { InjectionToken } from '@angular/core';

export const ENVIRONMENT_TOKEN = new InjectionToken('environment');
export const STORE_TOKEN = new InjectionToken('store');

export class DefaultEnvironment {
	environmentName: string;
	apiSuffix: string;
	beta?: boolean;
	production?: boolean;
	development?: boolean;
	productionToggles?: boolean;
	testing?: boolean;
	stripe?: string;
	stripePublishableKey?: string;
	writeToConsole?: boolean;
	plaidRedirectUri?: string;
	edgeApi: string;
	trovataApi: string;
	developerApi: string;
	workspaceApi: string;
	auth0Domain: string;
	authOAudience: string;
	auth0ClientId: string;
	auth0TestClientId: string;
	authSocket: string;
	notifSocket: string;
	searchSocket: string;
	trovataAISocket: string;
	useTrovataAIMock?: boolean;
	paymentsUat: boolean;
	edgeAPI(microService?: string): string {
		return this.edgeApi + (microService ?? 'api') + this.apiSuffix;
	}
	trovataAPI(microService?: string): string {
		let apiSuffix = this.apiSuffix;
		if (microService === 'payments' && this.paymentsUat) {
			apiSuffix = '-sandbox';
		}
		return this.trovataApi + (microService ?? 'api') + apiSuffix;
	}
	workspaceAPI(microService?: string): string {
		let apiSuffix = this.apiSuffix;
		if (microService === 'payments' && this.paymentsUat) {
			apiSuffix = '-sandbox';
		}
		return this.workspaceApi + (microService ?? 'api') + apiSuffix;
	}
}

export const DEFAULT_ENVIRONMENT_CONFIG: DefaultEnvironment = {
	environmentName: 'local',
	edgeApi: 'https://edge.trovata.io/',
	apiSuffix: '',
	trovataApi: 'https://api.trovata.io/',
	developerApi: 'https://developer-api.trovata.io/v1',
	workspaceApi: 'https://api.trovata.io/workspace',
	auth0Domain: 'login.trovata.io',
	authOAudience: 'https://edge.trovata.io',
	auth0ClientId: '2V1hJn3u4DvP31ZYfaPQ6tA1KAcdwgOA',
	auth0TestClientId: '5ZYVhVlxRGM8NwD6eAHB8bFISLAA0VdI',
	authSocket: 'wss://auth-ws.trovata.io/prod',
	notifSocket: 'wss://edge-ws.trovata.io/prod',
	searchSocket: 'wss://search.trovata.io/prod',
	trovataAISocket: 'wss://chat.trovata.io/dev',
	paymentsUat: false,
	useTrovataAIMock: false,
	edgeAPI(microService?: string): string {
		return this.edgeApi + (microService ?? 'api') + this.apiSuffix;
	},
	trovataAPI(microService?: string): string {
		let apiSuffix = this.apiSuffix;
		if (microService === 'payments' && this.paymentsUat) {
			apiSuffix = '-sandbox';
		}
		return this.trovataApi + (microService ?? 'api') + apiSuffix;
	},
	workspaceAPI(microService?: string): string {
		let apiSuffix = this.apiSuffix;
		if (microService === 'payments' && this.paymentsUat) {
			apiSuffix = '-sandbox';
		}
		return this.workspaceApi + apiSuffix + '/data/' + (microService ?? 'api');
	},
};
