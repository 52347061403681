import { belongsTo, Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { CurrentCashData } from '../../features/balances/models/current-cash.model';
import { Transaction } from '../../features/transactions/models/transaction.model';
import { AccountGrouping } from '../../features/settings/models/account-grouping.model';
import { AccountTargetV3 } from '../models/account-target.model';
import { CurrencyBalance } from '../models/currency.model';
import { Tag } from '../../features/transactions/models/tag.model';
import { Institution } from '../models/institution.model';
import { ReportV4, ReportV4Element, ReportV4ElementData } from '../../features/reports/models/report.model';
import { IForecastV3Response, StreamsEntity } from '../../features/forecasts/models/forecastV3-forecast-response.model';
import { Insight } from '../../features/insights/models/insights.model';
import { CashPosition, CashPositionData } from '../../features/cash-position/models/cash-position.model';
import { Entity, EntityExtraProperty, GetEntityTreeResponse } from '@trovata/app/features/entities/models/entity.model';

const accountModel: ModelDefinition<AccountTargetV3> = Model.extend({
	institution: belongsTo(),
	accountGrouping: belongsTo(),
});
const currencyBalanceModel: ModelDefinition<CurrencyBalance> = Model;
const currentCashDataModel: ModelDefinition<CurrentCashData> = Model;
const transactionModel: ModelDefinition<Transaction & { dbAccountId: string }> = Model.extend({
	dbAccount: belongsTo('account'),
});
const accountGroupingModel: ModelDefinition<AccountGrouping> = Model;
const tagModel: ModelDefinition<Tag> = Model;
const institutionModel: ModelDefinition<Institution> = Model;
const reportModel: ModelDefinition<ReportV4> = Model;
const reportElementModel: ModelDefinition<ReportV4ElementData & ReportV4Element> = Model.extend({
	report: belongsTo(),
});
const forecastModel: ModelDefinition<IForecastV3Response> = Model;
const forecastStreamModel: ModelDefinition<StreamsEntity> = Model;
const insightModel: ModelDefinition<Insight> = Model;
const cashPositionModel: ModelDefinition<CashPosition> = Model;
const cashPositionDetailModel: ModelDefinition<CashPositionData> = Model.extend({
	cashPosition: belongsTo(),
});
const entityModel: ModelDefinition<Entity> = Model;
const entityExtrProp: ModelDefinition<EntityExtraProperty> = Model;
const entityTreeRespModel: ModelDefinition<GetEntityTreeResponse> = Model;

export const models = {
	account: accountModel,
	currencyBalance: currencyBalanceModel,
	currentCash: currentCashDataModel,
	transaction: transactionModel,
	accountGrouping: accountGroupingModel,
	tag: tagModel,
	institution: institutionModel,
	report: reportModel,
	reportElement: reportElementModel,
	forecast: forecastModel,
	forecastStream: forecastStreamModel,
	insight: insightModel,
	cashPosition: cashPositionModel,
	cashPositionDetail: cashPositionDetailModel,
	entity: entityModel,
	extraProp: entityExtrProp,
	treeResp: entityTreeRespModel,
};
