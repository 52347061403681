import { Injectable, Injector, ComponentFactoryResolver, ApplicationRef, ComponentRef } from '@angular/core';
import { DomPortalOutlet, PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { TABS, TAB_DATA } from '../models/popout-token.model';
import { TransactionsGridDialogComponent } from '../components/transactions-grid-dialog/transactions-grid-dialog.component';
import { TransactionPopoutData, TransactionsDialogData, TrovataAIPopoutData } from '../models/popout-data.model';

@Injectable({
  providedIn: 'root'
})
export class PopoutService {

  styleSheetElement: any;

  constructor(private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef) { }

  openOnce(url, target) {
    // open a blank "target" window
    // or get the reference to the existing "target" window
    const winRef = (window as any).open(url, target, '', true);
    // if the "target" window was just opened, change its url
    if (winRef.location.href === 'about:blank') {
      winRef.location.href = url;
    }
    return winRef;
  }

  openTransactionsPopoutModal(dialogData: TransactionsDialogData): void {
    if (!this.isPopoutWindowOpen()) {
      this.openPopoutWindow(dialogData);
      return;
    }

    const isTrovataAIDialog: boolean = (<TrovataAIPopoutData>dialogData).openTrovataAIGrid;

    const transactionsPopoutData: TransactionPopoutData = <TransactionPopoutData>dialogData;

    const isSameTransactionSearch: boolean = (TABS['componentInstance'].data['searchData'] === transactionsPopoutData.searchData &&
          TABS['componentInstance'].data['sortFilter'] === transactionsPopoutData.sortFilter &&
          TABS['componentInstance'].data['positionType'] === transactionsPopoutData.positionType);
    // When tab is open and there is change in data, focus on popout modal reattach compnonent with new data and focus on new tab
    if(!isSameTransactionSearch || isTrovataAIDialog) {
      this.reAttachComponent(dialogData);
    }
    this.focusPopoutWindow();
  }

  private reAttachComponent(dialogData: TransactionsDialogData): void {
    TABS['outlet'].detach();
    dialogData.currentWindow = TABS['windowInstance'].window;
    dialogData.currentDocument = TABS['windowInstance'].document;
    const injector: PortalInjector = this.createInjector(dialogData);
    const componentInstance: TransactionsGridDialogComponent = this.attachTransactionsGridComponent(TABS['outlet'], injector);
    TABS['componentInstance'] = componentInstance;
  }

  private openPopoutWindow(data: TransactionsDialogData): void {
    const windowInstance: object = this.openOnce(
      data.path,
      data.tabTitle
    );

    // Wait for window instance to be created
    setTimeout(() => {
      // passes in the document of the new window instance to be used for copying
      TABS['windowInstance'] = windowInstance;
      data.currentWindow = TABS['windowInstance'].window;
      data.currentDocument = TABS['windowInstance'].document;
      this.createCDKPortal(data, windowInstance);
    }, 1000);
  }


  private createInjector(data): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(TAB_DATA, data);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private createCDKPortal(data, windowInstance) {
    if (windowInstance) {
      // Create a PortalOutlet with the body of the new window document
      const outlet = new DomPortalOutlet(windowInstance.document.body, this.componentFactoryResolver, this.applicationRef, this.injector);

      // // Copy stylesheet link from parent window
      this.styleSheetElement = this.getStyleSheetElement();
      windowInstance.document.head.appendChild(this.styleSheetElement);

      this.styleSheetElement.onload = () => {
        // Copy styles from parent window
        document.querySelectorAll('style').forEach(htmlElement => {
          windowInstance.document.head.appendChild(htmlElement.cloneNode(true));
        });

        // Clear popout modal content
        windowInstance.document.body.innerText = '';
        // Create an injector with modal data
        const injector = this.createInjector(data);

        // Attach the portal
        windowInstance.document.title = data['tabTitle'];
        setTimeout(() => {
          const componentInstance = this.attachTransactionsGridComponent(outlet, injector);
          TABS['outlet'] = outlet;
          TABS['componentInstance'] = componentInstance;
        }, 1000);

      };
    }
  }

  private isPopoutWindowOpen() {
    return TABS['windowInstance'] && !TABS['windowInstance'].closed;
  }

  private focusPopoutWindow() {
    TABS['windowInstance'].focus();
  }

  closePopoutModal() {
    Object.keys(TABS).forEach(() => {
      if (TABS['windowInstance']) {
        TABS['windowInstance'].close();
      }
    });
  }

  private attachTransactionsGridComponent(outlet, injector) {
    const containerPortal = new ComponentPortal(TransactionsGridDialogComponent, null, injector);
    const containerRef: ComponentRef<TransactionsGridDialogComponent> = outlet.attach(containerPortal);
    return containerRef.instance;
  }

  private getStyleSheetElement() {
    const styleSheetElement = document.createElement('link');
    document.querySelectorAll('link').forEach(htmlElement => {
      if (htmlElement.rel === 'stylesheet') {
        const absoluteUrl = new URL(htmlElement.href).href;
        styleSheetElement.rel = 'stylesheet';
        styleSheetElement.href = absoluteUrl;
      }

    });
    return styleSheetElement;
  }
}
