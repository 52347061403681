import { UpdateStrategy } from '../../utils/update-strategy';

export class InitPreferencesState {
	static readonly type: string = '[Preferences] InitPreferencesState';
}

export class ClearPreferencesState {
	static readonly type: string = '[Preferences] ClearPreferencesState';
}

export class ResetPreferencesState {
	static readonly type: string = '[Preferences] ResetPreferencesState';
}

export class GetAllPreferences {
	static readonly type: string = '[Preferences] GetAllPreferences';
}

export class UpdatePreferencesByKey {
	static readonly type: string = '[Preferences] UpdatePreferencesByKey';
	constructor(
		public key: string,
		public preferences: any,
		public updateStrategy: UpdateStrategy
	) {}
}

export class GetCurrencyPreferences {
	static readonly type: string = '[Preferences] GetCurrencyPreferences';
}

export class PostCurrencyPreferences {
	static readonly type: string = '[Preferences] PostCurrencyPreferences';
	constructor(
		public userCurrency: string,
		public instanceCurrency: string
	) {}
}
