import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, Subscription, firstValueFrom, lastValueFrom, throwError } from 'rxjs';
import { ConnectionsService } from '../../services/connections.service';
import {
	Connection,
	ConnectionRequest,
	CreateOAuthConnectionResponse,
	GetConnectionRequestsResponse,
	GetConnectionsResponse,
	ReconnectConnectionResponse,
} from '../../models/connections.model';
import {
	ClearConnectionsState,
	CreateConnection,
	CreateOauthConnection,
	GetConnectionRequests,
	GetConnections,
	HandleOauthCallback,
	InitConnectionsState,
	PatchConnectionRequest,
	PostConnectionRequest,
	ReconnectConnection,
	RefreshConnection,
	ResetConnectionsState,
	RevokeConnection,
} from '../actions/connections.actions';
import { SerializationService } from '@trovata/app/core/services/serialization.service';
import { TrovataAppState } from '@trovata/app/core/models/state.model';

export class ConnectionsStateModel {
	connections: Connection[];
	connectionRequests: ConnectionRequest[];
	authorizationUrl: string;
}

@State<ConnectionsStateModel>({
	name: 'connections',
	defaults: {
		connections: null,
		connectionRequests: null,
		authorizationUrl: null,
	},
})
@Injectable()
export class ConnectionsState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	@Selector() static connections(connectionsState: ConnectionsStateModel): Connection[] {
		return connectionsState.connections;
	}

	@Selector() static connectionRequests(connectionsState: ConnectionsStateModel): ConnectionRequest[] {
		return connectionsState.connectionRequests;
	}

	@Selector() static authorizationUrl(connectionsState: ConnectionsStateModel): string {
		return connectionsState.authorizationUrl;
	}

	constructor(
		private connectionsService: ConnectionsService,
		private store: Store,
		private serializationService: SerializationService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitConnectionsState)
	async initConnectionsState(context: StateContext<ConnectionsStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const connectionsStateIsCached: boolean = this.connectionsStateIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (appReady) {
						if (connectionsStateIsCached) {
							const state: ConnectionsStateModel = deserializedState.connections;
							context.patchState(state);
						} else {
							context.dispatch(new GetConnections());
							context.dispatch(new GetConnectionRequests());
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetConnections)
	async getConnections(context: StateContext<ConnectionsStateModel>): Promise<void> {
		try {
			const resp: GetConnectionsResponse = await firstValueFrom(this.connectionsService.getConnections());
			const state: ConnectionsStateModel = context.getState();
			state.connections = resp.connections;
			context.patchState(state);
		} catch (error) {
			throw error;
		}
	}

	@Action(GetConnectionRequests)
	async getConnectionRequests(context: StateContext<ConnectionsStateModel>): Promise<void> {
		try {
			const resp: GetConnectionRequestsResponse = await firstValueFrom(this.connectionsService.getConnectionRequests());
			const state: ConnectionsStateModel = context.getState();
			state.connectionRequests = resp.connectionRequests;
			context.patchState(state);
		} catch (error) {
			throw error;
		}
	}

	@Action(RevokeConnection)
	async revokeConnection(context: StateContext<ConnectionsStateModel>, action: RevokeConnection): Promise<void> {
		try {
			await lastValueFrom(this.connectionsService.revokeConnection(action.connectionId));
			context.dispatch(new GetConnections());
		} catch (error) {
			throw error;
		}
	}

	@Action(RefreshConnection)
	async refreshConnection(context: StateContext<ConnectionsStateModel>, action: RefreshConnection): Promise<void> {
		try {
			await lastValueFrom(this.connectionsService.refreshConnection(action.connectionId));
			context.dispatch(new GetConnections());
		} catch (error) {
			throw error;
		}
	}

	@Action(ReconnectConnection)
	async reconnectConnection(context: StateContext<ConnectionsStateModel>, action: ReconnectConnection): Promise<void> {
		try {
			const resp: ReconnectConnectionResponse = await lastValueFrom(this.connectionsService.reconnectConnection(action.body));
			const state: ConnectionsStateModel = context.getState();
			state.authorizationUrl = resp.authorizationUrl;
			context.patchState(state);
			await firstValueFrom(context.dispatch(new GetConnections()));
		} catch (error) {
			throw error;
		}
	}

	@Action(CreateConnection)
	async createConnection(context: StateContext<ConnectionsStateModel>, action: CreateConnection): Promise<void> {
		try {
			await lastValueFrom(this.connectionsService.createConnection(action.body));
			context.dispatch(new GetConnections());
		} catch (error) {
			throw error;
		}
	}

	@Action(CreateOauthConnection)
	async createOauthConnection(context: StateContext<ConnectionsStateModel>, action: CreateOauthConnection): Promise<void> {
		try {
			const resp: CreateOAuthConnectionResponse = await lastValueFrom(this.connectionsService.createOAuthConnection(action.body));
			const state: ConnectionsStateModel = context.getState();
			state.authorizationUrl = resp.authorizationUrl;
			context.patchState(state);
			await firstValueFrom(context.dispatch(new GetConnections()));
		} catch (error) {
			throw error;
		}
	}

	@Action(HandleOauthCallback)
	async handleOauthCallback(context: StateContext<ConnectionsStateModel>, action: HandleOauthCallback): Promise<void> {
		try {
			await lastValueFrom(this.connectionsService.handleOAuthCallback(action.connectionId, action.queryString));
			context.dispatch(new GetConnections());
		} catch (error) {
			throw error;
		}
	}

	@Action(PostConnectionRequest)
	async postConnectionRequest(context: StateContext<ConnectionsStateModel>, action: PostConnectionRequest): Promise<void> {
		try {
			await lastValueFrom(this.connectionsService.postConnectionRequest(action.body));
			await firstValueFrom(context.dispatch(new GetConnectionRequests()));
		} catch (error) {
			throw error;
		}
	}

	@Action(PatchConnectionRequest)
	async patchConnectionRequest(context: StateContext<ConnectionsStateModel>, action: PatchConnectionRequest): Promise<void> {
		try {
			await lastValueFrom(this.connectionsService.patchConnectionRequest(action.body, action.connectionRequestId));
			await firstValueFrom(context.dispatch(new GetConnectionRequests()));
		} catch (error) {
			throw error;
		}
	}

	@Action(ResetConnectionsState)
	resetConnectionsState(context: StateContext<ConnectionsStateModel>): void {
		context.dispatch(new ClearConnectionsState());
	}

	@Action(ClearConnectionsState)
	clearConnectionsState(context: StateContext<ConnectionsStateModel>): void {
		this.appReadySub.unsubscribe();
		const state: ConnectionsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private connectionsStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState && deserializedState.connections && deserializedState.connections.connections) {
			return true;
		} else {
			return false;
		}
	}
}
