export enum PositionType {
	CASH = 'CASH',
	OPERATING = 'OPERATING',
	INVESTMENT = 'INVESTMENT',
	CREDITCARD = 'CREDITCARD',
	// tech debt - need to check if this is still a position type
	DEBT = 'DEBT',
	OTHER = 'OTHER',
}

export enum AccountType {
	CASH = 'CASH',
	OPERATING = 'OPERATING',
	INVESTMENT = 'INVESTMENT',
	DEBT = 'DEBT',
	OTHER = 'OTHER',
}
