import { Tag } from '../../transactions/models/tag.model';
import { Institution } from 'src/app/shared/models/institution.model';
import { Account } from '../../transactions/models/account.model';

export class AccountFilters {
	institutions?: Institution[];
	accounts?: Account[];
	currencies?: string[];
	accountTags?: Tag[];
	totalInstitutions?: number;
	totalAccounts?: number;
	totalCurrencies?: number;
	totalAccountTags?: number;

	constructor(json?: Object) {
		if (json) {
			this.institutions = json['institutions'];
			this.accounts = json['accounts'];
			this.currencies = json['currencies'];
			this.accountTags = json['accountTags'];
		} else {
			this.institutions = null;
			this.accounts = null;
			this.currencies = null;
			this.accountTags = null;
		}
	}

	public displayValueForFilter(idType: string, id: string) {
		if (idType === 'accountId' || idType === 'accounts') {
			const account = this.accounts.find(acc => acc.accountId === id);
			if (account) {
				let displayValue = '';

				if (account.nickname && account.nickname !== '') {
					displayValue = account.nickname;
				} else if (account.name && account.name !== '') {
					displayValue = account.name;
				} else {
					displayValue = account.accountNumber;
				}

				return displayValue;
			}
		} else if (idType === 'institutionId' || idType === 'institutions') {
			const institution = this.institutions.find(inst => inst.institutionId === id);
			if (institution) {
				return institution.institutionNickname ? institution.institutionNickname : institution.institutionName;
			}
		} else if (idType === 'tagId' || idType === 'tags') {
			const tag = this.accountTags.find(findTag => findTag.tagId === id);
			if (tag) {
				return tag.tagTitle;
			}
		} else if (idType === 'currency' || idType === 'currencies') {
			return id;
		} else if (idType === 'entity' || idType === 'region' || idType === 'division') {
			const tag = this.accountTags.find(findTag => findTag.tagId === id);
			if (tag) {
				return tag.tagTitle;
			}
		}
		return null;
	}

	public secondaryDisplayValueForFilter(idType: string, id: string) {
		if (idType === 'accountId') {
			const account = this.accounts.find(acc => acc.accountId === id);
			if (account) {
				return account.accountNumber;
			}
		} else if (idType === 'institutionId') {
			return null;
		} else if (idType === 'tagId') {
			const tag = this.accountTags.find(findTag => findTag.tagId === id);
			if (tag) {
				return tag.tagDescription;
			}
		} else {
			return null;
		}
	}
}

export enum AccountFilterType {
	accounts = 'accounts',
	institutions = 'institutions',
	currencies = 'currencies',
	accountTags = 'accountTags',
}

export class AccountFilterSettings {
	accounts = {
		size: 'sizeAccounts',
		from: 'fromAccounts',
		total: 'totalAccounts',
	};
	institutions = {
		size: 'sizeInstitutions',
		from: 'fromInstitutions',
		total: 'totalInstitutions',
	};
	currencies = {
		size: 'sizeCurrencies',
		from: 'fromCurrencies',
		total: 'totalCurrencies',
	};
	accountTags = {
		size: 'sizeAccountTags',
		from: 'fromAccountTags',
		total: 'totalAccountTags',
	};
}
