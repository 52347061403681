import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ConsentPartner, ConsentStatus, ConsentType } from '../../models/consent-management';
import { Injectable } from '@angular/core';
import { Observable, Subscription, firstValueFrom, throwError } from 'rxjs';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { ConsentManagementService } from '../../services/consent-management.service';
import { TrovataAppState } from 'src/app/core/models/state.model';
import {
	ClearConsentManagementState,
	GetConsentPartners,
	GetPartnerById,
	InitConsentManagementState,
	UpdatePartnerStatus,
} from '../actions/consent-management.actions';

export class ConsentManagementStateModel {
	partners: ConsentPartner[];
}

@State<ConsentManagementStateModel>({
	name: 'consentManagement',
	defaults: {
		partners: null,
	},
})
@Injectable()
export class ConsentManagementState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private consentManagementService: ConsentManagementService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector() static partners(state: ConsentManagementStateModel): ConsentPartner[] {
		return state.partners;
	}

	@Action(InitConsentManagementState)
	async InitConsentManagementState(context: StateContext<ConsentManagementStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (appReady) {
						const currentState: ConsentManagementStateModel = context.getState();
						const alreadyInStore: boolean = this.consentManagementPartnersIsCached(currentState);
						if (alreadyInStore) {
							const state: ConsentManagementStateModel = deserializedState.consentManagement;
							context.patchState(state);
						} else if (!alreadyInStore) {
							context.dispatch(new GetConsentPartners());
						}
					}
				},
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetConsentPartners)
	getConsentPartners(context: StateContext<ConsentManagementStateModel>): Promise<ConsentPartner[]> {
		return new Promise(async (resolve, reject) => {
			try {
				const resp: ConsentPartner[] = await firstValueFrom(this.consentManagementService.getPartnerList());
				const state: ConsentManagementStateModel = context.getState();
				const consentPartners: ConsentPartner[] = resp['partners'];
				state.partners = consentPartners;
				context.patchState(state);
				resolve(consentPartners);
			} catch (err) {
				reject(err);
			}
		});
	}

	@Action(GetPartnerById)
	getPartnerById(context: StateContext<ConsentManagementStateModel>, action: GetPartnerById): Promise<ConsentPartner> {
		return new Promise(async (resolve, reject) => {
			try {
				const parnterId: string = action.partnerId;
				const resp: ConsentPartner = await firstValueFrom(this.consentManagementService.getPartnerById(parnterId));
				const state: ConsentManagementStateModel = context.getState();
				const consentPartner: ConsentPartner = <ConsentPartner>resp;
				const filteredPartners: ConsentPartner[] = state.partners.filter((filterPartner: ConsentPartner) => filterPartner.partnerId !== parnterId);
				filteredPartners.push(consentPartner);
				state.partners = filteredPartners;
				context.patchState(state);
				resolve(consentPartner);
			} catch (err) {
				reject(err);
			}
		});
	}

	@Action(UpdatePartnerStatus)
	updatePartnerStatus(context: StateContext<ConsentManagementStateModel>, action: UpdatePartnerStatus): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const parnterId: string = action.partnerId;
				const consentType: ConsentType = action.consentStatus;
				await firstValueFrom(this.consentManagementService.providePartnerConsent(parnterId, consentType));
				const state: ConsentManagementStateModel = context.getState();
				const partnersCopy: ConsentPartner[] = JSON.parse(JSON.stringify(state.partners));
				const updatedPartnerIndex: number = partnersCopy.findIndex((partner: ConsentPartner) => partner.partnerId === parnterId);
				const consentStatus: ConsentStatus = consentType === ConsentType.Allow ? ConsentStatus.Consented : ConsentStatus.Declined;
				if (updatedPartnerIndex >= 0) {
					partnersCopy[updatedPartnerIndex].status = consentStatus;
				}
				state.partners = partnersCopy;
				context.patchState(state);
				resolve();
			} catch (err) {
				reject(err);
			}
		});
	}

	@Action(ClearConsentManagementState)
	clearConsentManagementState(context: StateContext<ConsentManagementStateModel>): void {
		this.appReadySub.unsubscribe();
		const state: ConsentManagementStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private consentManagementPartnersIsCached(consentManagementState: ConsentManagementStateModel): boolean {
		if (consentManagementState?.partners) {
			return true;
		} else {
			return false;
		}
	}
}
