import { DateTime } from 'luxon';
import { CheckoutPreview, PreviewInvoice, Product, Subscription, SubscriptionCadence, SubscriptionsAndProducts } from './subscriptions.model';

export interface CheckoutInvoiceRow {
	subscriptionName: string;
	dateDetails: string;
	subtotal: string;
}

export interface SubscriptionCard {
	productId: string;
	name: string;
	description: string;
	amount: string;
	discountAmount: string;
	subscribed: boolean;
	subscriptionId: string;
	trialing: boolean;
	freeTrialDaysLeft: number;
	discountProvidedBy: string;
	cadence: SubscriptionCadence;
	upsellAmount: string;
	bullets: SubscriptionBullet[];
	enterprise: boolean;
	isTrialAvailable: boolean;
}

export interface SubscriptionBullet {
	description: string;
	enabled: boolean;
}

export interface ProductAvailability {
	name: string;
	type: string;
	availabilityMap: Map<string, { enabled: boolean; note?: string }>;
}

export class SubscriptionsViewModel {
	subscriptions: SubscriptionCard[];
	productAvailability: ProductAvailability[];
	isTrialAvailable: boolean;
	currentSubscription?: {
		discount: string;
		cost: string;
		cadence: SubscriptionCadence;
		name: string;
		referralCode: string;
		subscriptionId: string;
	};
	columns: (SubscriptionNames | string)[];

	constructor(subsAndProds: SubscriptionsAndProducts) {
		// used to build first column but not shown in cell
		this.columns = ['Name'];
		this.setCurrentSubscription(subsAndProds);

		this.formatSubscriptions(subsAndProds);
		this.setIsTrialAvailable();
		this.productAvailability = this.hardcodeAvailability();
		this.sortAvailability(this.productAvailability);
	}
	private sortAvailability(availability: ProductAvailability[]): void {
		const [applications, features]: [ProductAvailability[], ProductAvailability[]] = availability.reduce(
			(result, element) => {
				if (element.type === 'application') {
					result[0].push(element);
				} else {
					result[1].push(element);
				}
				return result;
			},
			[[], []]
		);
		applications.unshift({
			name: 'Applications',
			availabilityMap: new Map<string, { enabled: boolean; note?: string }>(),
			type: '',
		});
		features.unshift({
			name: 'Features',
			availabilityMap: new Map<string, { enabled: boolean; note?: string }>(),
			type: '',
		});

		this.productAvailability = applications.concat(features);
	}

	private hardcodeAvailability(): ProductAvailability[] {
		let plans = [enterprisePlan];
		if (this.currentSubscription?.name !== enterprisePlan.name) {
			plans = [freePlan, startUpPlan, growthPlan, corporatePlan, ...plans];
		}
		const availability: ProductAvailability[] = products.map((product, i) => {
			const availabilityMap = new Map<string, { enabled: boolean; note?: string }>();
			plans.forEach(plan => {
				if (i === 0) {
					this.columns.push(plan.name);
				}
				const productMatch: { name: string; enabled: boolean; note?: string } = plan.products.find(p => p.name === product.name);
				availabilityMap.set(plan.name, {
					enabled: productMatch?.enabled || false,
					note: productMatch?.note,
				});
			});
			return { name: product.name, type: product.type, availabilityMap };
		});
		return availability;
	}

	private setCurrentSubscription(subsAndProds: SubscriptionsAndProducts) {
		const currentSubscription: Subscription = subsAndProds.subscriptions.find(sub => sub.subscribed === true);
		if (currentSubscription) {
			this.currentSubscription = {
				discount: currentSubscription ? currentSubscription.discountAmount : '',
				cost: currentSubscription
					? currentSubscription.cadence === 'monthly'
						? this.replaceNaN(currentSubscription.monthlyAmount)
						: this.replaceNaN(currentSubscription.yearlyAmount)
					: '',
				name: currentSubscription.name,
				cadence: currentSubscription.cadence,
				subscriptionId: currentSubscription.subscriptionId,
				// TODO find out where this comes from
				referralCode: '',
			};
		}
	}

	private replaceNaN(amount: string) {
		if (Number.isNaN(parseFloat(amount.replace(/\$|,/g, '')))) {
			return 'Custom';
		} else {
			return amount;
		}
	}

	private formatSubscriptions(subsAndProds: SubscriptionsAndProducts) {
		this.subscriptions = subsAndProds.subscriptions
			.sort((a: Subscription, b: Subscription) => {
				const amountA = parseFloat(a.monthlyAmount.replace(/\$|,/g, ''));
				const amountB = parseFloat(b.monthlyAmount.replace(/\$|,/g, ''));
				if (Number.isNaN(amountA)) {
					return 1;
				}
				if (Number.isNaN(amountB)) {
					return -1;
				}
				return amountA === 0 ? -1 : amountA - amountB;
			})
			.map(sub =>
				// TODO add when table data is not hard coded
				// this.columns.push(sub.name);
				({
					...sub,
					amount: sub.cadence === SubscriptionCadence.monthly ? this.replaceNaN(sub.monthlyAmount) : this.replaceNaN(sub.yearlyAmount),
					yearlyAmount: sub.yearlyAmount,
					enterprise: sub.name === SubscriptionNames.enterprise ? true : false,
					discountAmount: sub.discountAmount,
				})
			)
			.filter((sub: Subscription) => sub.name !== SubscriptionNames.free);
	}

	private setIsTrialAvailable() {
		this.isTrialAvailable = this.subscriptions.find((sub: SubscriptionCard) => sub.name === SubscriptionNames.growth)?.isTrialAvailable;
	}
}

export class UpgradeViewModel {
	invoices: {
		dueDate: string;
		upcomingAmountDue: string;
		invoiceRows: CheckoutInvoiceRow[];
		upgradeBullets: SubscriptionBullet[];
	}[];

	constructor(checkoutPreview: CheckoutPreview, upgrade: Subscription | Product) {
		this.invoices = checkoutPreview.invoices.map((invoice: PreviewInvoice) => ({
			dueDate: this.formatDate(invoice.dueDate),
			upcomingAmountDue: invoice.amountDue,
			upgradeBullets: upgrade.bullets?.filter(bullet => bullet.enabled),
			invoiceRows: this.createCheckoutInvoiceRows(invoice),
		}));
	}

	private createCheckoutInvoiceRows(invoice: PreviewInvoice): CheckoutInvoiceRow[] {
		const invoiceRows: CheckoutInvoiceRow[] = [];
		invoice.products.forEach(product => {
			invoiceRows.push({
				subscriptionName: product.name,
				dateDetails: `${this.formatDate(product.startDate)} - ${this.formatDate(product.endDate)}`,
				subtotal: product.amount,
			});
		});
		return invoiceRows;
	}

	private formatDate(date: string): string {
		return DateTime.fromISO(date).toLocaleString({
			month: 'long',
			day: 'numeric',
			year: 'numeric',
		});
	}
}

export enum SubscriptionNames {
	free = 'Free',
	startup = 'Startup',
	growth = 'Growth',
	corporate = 'Corporate',
	enterprise = 'Enterprise',
}

const freePlan = {
	name: SubscriptionNames.free,
	products: [
		{ name: 'Balances', enabled: true },
		{ name: 'Transactions', enabled: true },
		{ name: 'Analysis', enabled: true },
		{ name: 'Payments', enabled: true },
		{ name: 'Transactions Tagging', enabled: true },
		{ name: 'Support', enabled: true, note: '48hr email' },
	],
};
const startUpPlan = {
	name: SubscriptionNames.startup,
	products: [
		{ name: 'Balances', enabled: true },
		{ name: 'Transactions', enabled: true },
		{ name: 'Analysis', enabled: true },
		{ name: 'Payments', enabled: true },
		{ name: 'Workbooks', enabled: true },
		{ name: 'Transactions Tagging', enabled: true },
		{ name: 'Support', enabled: true, note: '48hr email' },
		{ name: 'Multibank', enabled: true, note: 'Business' },
	],
};
const growthPlan = {
	name: SubscriptionNames.growth,
	products: [
		{ name: 'Balances', enabled: true },
		{ name: 'Transactions', enabled: true },
		{ name: 'Analysis', enabled: true },
		{ name: 'Payments', enabled: true },
		{ name: 'Workbooks', enabled: true },
		{ name: 'Dashboard', enabled: true },
		{ name: 'Transactions Tagging', enabled: true },
		{ name: 'Reports', enabled: true },
		{ name: 'Insights', enabled: true },
		{ name: 'Support', enabled: true, note: '48hr email' },
		{ name: 'Multibank', enabled: true, note: 'Business' },
		{ name: 'User Management', enabled: true },
	],
};
const corporatePlan = {
	name: SubscriptionNames.corporate,
	products: [
		{ name: 'Balances', enabled: true },
		{ name: 'Transactions', enabled: true },
		{ name: 'Analysis', enabled: true },
		{ name: 'Payments', enabled: true },
		{ name: 'Workbooks', enabled: true },
		{ name: 'Dashboard', enabled: true },
		{ name: 'Transactions Tagging', enabled: true },
		{ name: 'Reports', enabled: true },
		{ name: 'Insights', enabled: true },
		{ name: 'Reconciliation', enabled: true },
		{ name: 'Forecasts', enabled: true },
		{ name: 'Support', enabled: true, note: '48hr email' },
		{ name: 'Multibank', enabled: true, note: 'Business' },
		{ name: 'User Management', enabled: true },
		{ name: 'Developer Portal', enabled: true },
	],
};
const enterprisePlan = {
	name: SubscriptionNames.enterprise,
	products: [
		{ name: 'Balances', enabled: true },
		{ name: 'Transactions', enabled: true },
		{ name: 'Analysis', enabled: true },
		{ name: 'Payments', enabled: true },
		{ name: 'Workbooks', enabled: true },
		{ name: 'Dashboard', enabled: true },
		{ name: 'Transactions Tagging', enabled: true },
		{ name: 'Reports', enabled: true },
		{ name: 'Insights', enabled: true },
		{ name: 'Reconciliation', enabled: true },
		{ name: 'Forecasts', enabled: true },
		{ name: 'Support', enabled: true, note: 'Real Time' },
		{ name: 'Multibank', enabled: true, note: 'Enterprise' },
		{ name: 'User Management', enabled: true },
		{ name: 'Developer Portal', enabled: true },
	],
};
const products = [
	{ name: 'Balances', type: 'application' },
	{ name: 'Transactions', type: 'application' },
	{ name: 'Analysis', type: 'application' },
	{ name: 'Payments', type: 'application' },
	{ name: 'Transactions Tagging', type: 'application' },
	{ name: 'Workbooks', type: 'application' },
	{ name: 'Dashboard', type: 'application' },
	{ name: 'Reports', type: 'application' },
	{ name: 'Insights', type: 'application' },
	{ name: 'Reconciliation', type: 'application' },
	{ name: 'Forecasts', type: 'application' },
	{ name: 'Support', type: 'feature' },
	{ name: 'Multibank', type: 'feature' },
	{ name: 'User Management', type: 'feature' },
	{ name: 'Developer Portal', type: 'feature' },
];
