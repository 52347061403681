import { TQLPropertyKey } from '../../models/tql.model';

export class InitTQLFieldsState {
	static readonly type: string = '[TQLFields] InitTQLFieldsState';
}

export class ClearTQLFieldsState {
	static readonly type: string = '[TQLFields] ClearTQLFieldsState';
}

export class ResetTQLFieldsState {
	static readonly type: string = '[TQLFields] ResetTQLFieldsState';
}

export class GetAllTQLFields {
	static readonly type: string = '[TQLFields] GetAllTQLFields';
}

export class GetValues {
	static readonly type: string = '[TQLFields] GetValues';
	constructor(public fields: string[] = []) {}
}
