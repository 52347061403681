import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import {
	Entity,
	EntityExtraProperty,
	GetEntitiesResponse,
	GetEntityTreeResponse,
	PutEntitiesBody,
	EntityPersonnel,
	EntitiesGetBody,
	GetEntityExtraPropertyResponse,
} from '../models/entity.model';
import { environment } from '@trovata/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class EntitiesService {
	constructor(private httpClient: HttpClient) {}

	getEntities(getBody: EntitiesGetBody = { includeAccounts: true }): Observable<GetEntitiesResponse> {
		const url: string = environment.trovataAPI('workspace') + '/data/entities-list';
		return this.httpClient.post<GetEntitiesResponse>(url, getBody).pipe(catchError(err => throwError(err)));
	}

	putEntities(body: PutEntitiesBody): Observable<Entity[]> {
		const url: string = environment.trovataAPI('workspace') + '/data/entities';
		return this.httpClient.put<Entity[]>(url, body).pipe(catchError(err => throwError(err)));
	}

	// TODO do we need to add the other fields available to extra properties here?
	getExtraProperties(): Observable<EntityExtraProperty[]> {
		const url: string = environment.trovataAPI('workspace') + '/data/entities/extra-properties';
		return this.httpClient.get<GetEntityExtraPropertyResponse>(url).pipe(
			map(resp => resp.extraProperties),
			catchError(err => throwError(err))
		);
	}

	putEntity(entity: Entity): Observable<void> {
		const url: string = environment.trovataAPI('workspace') + `/data/entities/${entity.entityId}`;
		return this.httpClient.put<void>(url, entity).pipe(catchError(err => throwError(err)));
	}

	deleteEntity(entityId: string): Observable<void> {
		const url: string = environment.trovataAPI('workspace') + `/data/entities/${entityId}`;
		return this.httpClient.delete<void>(url).pipe(catchError(err => throwError(err)));
	}

	createExtraProperty(extraProperty: EntityExtraProperty): Observable<EntityExtraProperty> {
		const url: string = environment.trovataAPI('workspace') + '/data/entities/extra-properties';
		return this.httpClient.post<{ extraPropertyId: string }>(url, extraProperty).pipe(
			catchError(err => throwError(err)),
			map((resp: { extraPropertyId: string }) => {
				extraProperty.extraPropertyId = resp.extraPropertyId;
				return extraProperty;
			})
		);
	}

	patchExtraProperty(extraPropertyId: string, extraProperty: Partial<EntityExtraProperty>): Observable<void> {
		const url: string = environment.trovataAPI('workspace') + `/data/entities/extra-properties/${extraPropertyId}`;
		return this.httpClient.patch<void>(url, extraProperty).pipe(catchError(err => throwError(err)));
	}

	deleteExtraProperty(extraProperty: EntityExtraProperty): Observable<void> {
		const url: string = environment.trovataAPI('workspace') + `/data/entities/extra-properties/${extraProperty.extraPropertyId}`;
		return this.httpClient.delete<void>(url).pipe(catchError(err => throwError(err)));
	}

	createPersonnel(entity: Entity, personnel: EntityPersonnel): Observable<EntityPersonnel> {
		const url: string = environment.trovataAPI('workspace') + `/data/entities/${entity.entityId}/personnel`;
		return this.httpClient.post<string>(url, personnel).pipe(
			map((response: string) => {
				personnel.personnelId = response;
				return personnel;
			}),
			catchError(err => throwError(err))
		);
	}

	putPersonnel(personnel: EntityPersonnel): Observable<void> {
		const url: string = environment.trovataAPI('workspace') + `/data/entities/personnel/${personnel.personnelId}`;
		return this.httpClient.put<void>(url, personnel).pipe(catchError(err => throwError(err)));
	}

	deletePersonnel(personnel: EntityPersonnel): Observable<void> {
		const url: string = environment.trovataAPI('workspace') + `/data/entities/personnel/${personnel.personnelId}`;
		return this.httpClient.delete<void>(url).pipe(catchError(err => throwError(err)));
	}

	getEntityTrees(): Observable<GetEntityTreeResponse> {
		const url: string = environment.trovataAPI('workspace') + '/data/entities/tree';
		return this.httpClient.get<GetEntityTreeResponse>(url).pipe(catchError(err => throwError(err)));
	}
}
