import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UserConfig } from 'src/app/core/models/user-config.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UserConfigurationService {
	configVersion = 3;
	userConfig;

	constructor(private httpClient: HttpClient) {}

	public getUserConfig(): Observable<HttpResponse<UserConfig>> {
		const url = environment.edgeAPI() + '/user/config';

		let parameters = new HttpParams();

		if (environment.productionToggles) {
			parameters = parameters.append('firebaseEnvironment', 'prod');
		} else {
			parameters = parameters.append('firebaseEnvironment', 'dev');
		}

		return this.httpClient
			.get<UserConfig>(url, {
				params: parameters,
				observe: 'response',
			})
			.pipe(
				catchError(err => throwError(err)),
				map(resp => {
					this.userConfig = resp.body;
					return resp;
				})
			);
	}

	public userConfigurationFor(key: string): Observable<any> {
		if (this.userConfig != null) {
			return of(this.userConfig.features[key]);
		} else {
			return this.getUserConfig().pipe(
				map(resp => this.userConfig.features[key]),
				catchError(err => throwError(err))
			);
		}
	}

	public stripeTier(): Observable<any> {
		if (this.userConfig != null) {
			return of(this.userConfig.stripeTier);
		} else {
			return this.getUserConfig().pipe(
				map(resp => this.userConfig.stripeTier),
				catchError(err => throwError(err))
			);
		}
	}
}

export enum Features {
	settingsStripe = 'settings_stripe',
	forecastView = 'forecast_view',
	notificationsView = 'notifications_view',
	connectionsPlaid = 'connections_plaid',
	connectionsDirect = 'connections_direct',
	reportsView = 'reports_view',
	insights = 'insights',
}
