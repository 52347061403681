import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { combineLatest, firstValueFrom, Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { PostTagResponse, Tag, TagPayload, TagType } from 'src/app/features/transactions/models/tag.model';
import { TagService } from 'src/app/features/transactions/services/tag.service';
import { PermissionId, PermissionMap } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import {
	ClearGlTagsState,
	ClearLastCreatedGlTagId,
	CreateGlTag,
	DeleteGlTag,
	GetGlTags,
	InitGlTagsState,
	ResetGlTagsState,
	SetLastCreatedGlTagId,
	UpdateGlTag,
} from '../actions/glTags.action';
import { UpdateFilter } from 'src/app/shared/store/actions/filter-object.actions';
import { TransactionFilterType } from 'src/app/shared/models/transaction-filters.model';
import { GetValues } from '@trovata/app/shared/store/actions/tql-fields.actions';
import { TQLPropertyKey } from '@trovata/app/shared/models/tql.model';

export class GlTagsStateModel {
	glTags: Tag[];
	isCached: boolean;
	lastCreatedGlTagId: string;
}

@State<GlTagsStateModel>({
	name: 'glTags',
	defaults: {
		glTags: null,
		isCached: false,
		lastCreatedGlTagId: null,
	},
})
@Injectable()
export class GlTagsState {
	@Selector()
	static glTags(state: GlTagsStateModel) {
		return state.glTags;
	}
	@Selector()
	static lastCreatedGlTagId(state: GlTagsStateModel) {
		return state.lastCreatedGlTagId;
	}

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private tagService: TagService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitGlTagsState)
	async initGlTagsState(context: StateContext<GlTagsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const glTagsStateIsCached: boolean = this.glTagsStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$]).subscribe({
				next: ([appReady, permissions]: [boolean, PermissionMap]) => {
					if (permissions && appReady) {
						if (permissions.has(PermissionId.readGlTags)) {
							if (glTagsStateIsCached) {
								context.patchState({
									glTags: deserializedState.glTags.glTags,
									isCached: true,
								});
							} else {
								context.dispatch(new GetGlTags());
							}
						} else {
							context.patchState({
								glTags: [],
								isCached: false,
							});
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetGlTags)
	getGlTags(context: StateContext<GlTagsStateModel>, action: GetGlTags) {
		let state = context.getState();
		if (action.refresh || !state.isCached) {
			return this.tagService.getGLTags().pipe(
				tap((response: HttpResponse<{ tags: Tag[] }>) => {
					const tags: Tag[] = response.body.tags;
					state.glTags = tags;
					context.patchState({ glTags: tags, isCached: true });
				}),
				catchError(error => {
					state = context.getState();
					context.patchState(state);
					return throwError(error);
				})
			);
		}
	}

	@Action(CreateGlTag)
	createGlTag(context: StateContext<GlTagsStateModel>, action: CreateGlTag) {
		return new Promise<void>(async (resolve, reject) => {
			const tagPayload: TagPayload = action.tagPayload;
			try {
				const createGlTagReponse: HttpResponse<PostTagResponse> = await firstValueFrom(this.tagService.createTag(tagPayload));
				if (tagPayload.tagType === TagType.glTag || tagPayload.tagType === TagType.glTagAuto) {
					context.patchState({
						lastCreatedGlTagId: createGlTagReponse.body.tagId,
					});
					await firstValueFrom(context.dispatch(new GetGlTags(true)));
					this.tagService.updateStoreAndFilters(tagPayload.tagType);
					resolve();
				}
			} catch (error) {
				reject(new Error('Could not create Gl Tag'));
			}
		});
	}

	@Action(UpdateGlTag)
	updateGlTag(context: StateContext<GlTagsStateModel>, action: UpdateGlTag) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const glTagPayload: TagPayload = action.tagPayload;
				const oldTag: Tag = action.tag;
				await firstValueFrom(this.tagService.editGLTag(oldTag, glTagPayload));
				await firstValueFrom(context.dispatch(new GetGlTags(true)));
				this.updateFilters(oldTag.tagType);
				resolve();
			} catch (error) {
				reject(new Error('Could not update Gl Tag'));
			}
		});
	}

	@Action(DeleteGlTag)
	deleteGlTag(context: StateContext<GlTagsStateModel>, action: DeleteGlTag) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const tagToDelete: Tag = action.tag;
				const state: GlTagsStateModel = context.getState();
				let tags: Tag[];
				if (tagToDelete.tagType === TagType.glTag || tagToDelete.tagType === TagType.glTagAuto) {
					tags = state.glTags;
				}
				const i: number = tags.findIndex(tag => tag.tagId === tagToDelete.tagId);
				if (i > -1) {
					tags.splice(i, 1);
				}
				context.patchState({ glTags: tags });
				await firstValueFrom(this.tagService.deleteV2Tag(tagToDelete));
				this.updateFilters(tagToDelete.tagType);
				resolve();
			} catch (error) {
				reject(new Error('Could not delete Gl Tag'));
			}
		});
	}

	@Action(ResetGlTagsState)
	resetGlTagsState(context: StateContext<GlTagsStateModel>) {
		context.dispatch(new ClearGlTagsState());
		context.dispatch(new InitGlTagsState());
	}

	@Action(ClearGlTagsState)
	clearGlTagsState(context: StateContext<GlTagsStateModel>) {
		this.appReadySub.unsubscribe();
		const state: GlTagsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ClearLastCreatedGlTagId)
	clearLastCreatedTagId(context: StateContext<GlTagsStateModel>) {
		context.patchState({ lastCreatedGlTagId: null });
	}

	@Action(SetLastCreatedGlTagId)
	setLastCreatedId(context: StateContext<GlTagsStateModel>, action: SetLastCreatedGlTagId) {
		context.patchState({ lastCreatedGlTagId: action.id });
	}

	private glTagsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedGlTagsState: GlTagsStateModel | undefined = deserializedState.glTags;
		if (deserializedGlTagsState && deserializedGlTagsState.glTags) {
			return true;
		} else {
			return false;
		}
	}

	private updateFilters(tagType: TagType) {
		if (tagType === TagType.glTag) {
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.glTags));
		} else if (tagType === TagType.glTagAuto) {
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.glAutoTags));
		}
		this.store.dispatch(new GetValues([TQLPropertyKey.glTag]));
	}
}
