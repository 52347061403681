import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountFilters } from '../../reports/models/account-filters.model';
import { throwError } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AccountFiltersService {
	constructor(private httpClient: HttpClient) {}

	private filterTypeObj: AccountFilterSettings = new AccountFilterSettings();

	public getAccountFilters(filterType: AccountFilterType, fromSize?: number, isPagedCall?: boolean, filterSize?: number) {
		const completeFilter: AccountFilters = new AccountFilters();
		const body: object = {};
		body['filter'] = filterType;

		const size = filterSize ? filterSize : 1000;
		body[this.filterTypeObj[filterType].size] = size;

		const initialFromSize = fromSize ? fromSize : 0;
		body[this.filterTypeObj[filterType].from] = initialFromSize;

		const url = environment.trovataAPI('workspace') + '/data/accounts/filters';
		return this.httpClient
			.post<AccountFilters>(url, body, {
				observe: 'response',
			})
			.pipe(
				switchMap(async resp => {
					if (isPagedCall) {
						return resp.body;
					}
					completeFilter[filterType] = <any>resp.body[filterType];
					completeFilter[this.filterTypeObj[filterType].total] = resp.body[this.filterTypeObj[filterType].total];
					let fromSizeInt = this.getFromSize(filterType, completeFilter);

					while (this.callCheck(filterType, completeFilter)) {
						try {
							const newRes = await this.getFiltersPaged(filterType, fromSizeInt);
							this.addNewResults(filterType, newRes, completeFilter);
							fromSizeInt = this.getFromSize(filterType, completeFilter);
						} catch (err) {
							throw new Error(err);
						}
					}

					return {
						[filterType]: completeFilter[filterType],
						[this.filterTypeObj[filterType].total]: completeFilter[this.filterTypeObj[filterType].total],
					};
				}),
				catchError(err => throwError(err))
			);
	}

	private getFiltersPaged(filterType: AccountFilterType, fromSize: number) {
		return new Promise((resolve, reject) => {
			this.getAccountFilters(filterType, fromSize, true).subscribe(
				res => {
					resolve(res);
				},
				err => {
					reject(err);
				}
			);
		});
	}

	private getFromSize(filterType: AccountFilterType, completeFilter: AccountFilters) {
		const size = completeFilter[filterType].length;
		return size;
	}

	private callCheck(filterType: AccountFilterType, filters: AccountFilters) {
		if (filters[filterType] && filters[this.filterTypeObj[filterType].total] > filters[filterType].length) {
			return true;
		} else {
			return false;
		}
	}

	private addNewResults(filterType: AccountFilterType, accountFilterObj, completeFilter: AccountFilters) {
		completeFilter[filterType].push(...accountFilterObj[filterType]);
	}
}

export enum AccountFilterType {
	accounts = 'accounts',
	institutions = 'institutions',
	currencies = 'currencies',
	accountTags = 'accountTags',
}

export class AccountFilterSettings {
	accounts = {
		size: 'sizeAccounts',
		from: 'fromAccounts',
		total: 'totalAccounts',
	};
	institutions = {
		size: 'sizeInstitutions',
		from: 'fromInstitutions',
		total: 'totalInstitutions',
	};
	currencies = {
		size: 'sizeCurrencies',
		from: 'fromCurrencies',
		total: 'totalCurrencies',
	};
	accountTags = {
		size: 'sizeAccountTags',
		from: 'fromAccountTags',
		total: 'totalAccountTags',
	};
}
