import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import {
	CashPosition,
	CashPositionGrouping,
	CashPositionListResponse,
	CashPositionData,
	CashPositionVersionPostBody,
	CashPositionVersionResponseBody,
	CashPositionVersionsResponse,
	CashPositionVersion,
	CashPositionTimezone,
	CashPositionVersionOverviewResponse,
	CashPositionVersionOverview,
} from '../models/cash-position.model';
import { DateTime } from 'luxon';

@Injectable({
	providedIn: 'root',
})
export class CashPositionService {
	apiVersion = 1;

	constructor(public httpClient: HttpClient) {}

	/**
	 * @description Returns all forecasts
	 */
	public getAllCashPositions(): Observable<HttpResponse<CashPositionListResponse>> {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning/list`;
		return this.httpClient
			.post<CashPositionListResponse>(
				url,
				{},
				{
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(err)));
	}

	public getCashPositionData(cashPositioningId: string, groupBy: CashPositionGrouping): Observable<HttpResponse<CashPositionData>> {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning/${cashPositioningId}/data`;
		return this.httpClient
			.post<CashPositionData>(
				url,
				{
					groupBy: groupBy,
				},
				{
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(err)));
	}

	public getCashPositionAutosaveData(cashPositioningId: string, groupBy: CashPositionGrouping, targetDate: string): Observable<HttpResponse<CashPositionData>> {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning/${cashPositioningId}/data`;
		return this.httpClient
			.post<CashPositionData>(
				url,
				{
					groupBy: groupBy,
					// Sending close time at 00 seconds to API
					targetDate: DateTime.fromISO(targetDate, { zone: 'UTC' }).plus({ hours: 1 }).startOf('hour').toISO(),
				},
				{
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(err)))
			.pipe(
				map((resp: HttpResponse<CashPositionData>) => {
					const transformedVersionResp: HttpResponse<CashPositionData> = Object.assign(new HttpResponse<CashPositionData>(), resp, {
						body: {
							groups: resp.body.groups,
							versionId: '',
							createdAt: targetDate,
							createdBy: '',
						},
					});
					return transformedVersionResp;
				})
			);
	}

	public saveCashPositionSettings(cashPosition: CashPosition) {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning/${cashPosition.cashPositioningId}`;
		return this.httpClient
			.put<CashPositionListResponse>(url, cashPosition, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public createCashPosition(cashPosition: CashPosition): Observable<HttpResponse<CashPosition>> {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning`;
		return this.httpClient
			.post<CashPosition>(url, cashPosition, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public saveNewVersion(
		cashPosition: CashPosition,
		cashPositionVersionPostBody: CashPositionVersionPostBody
	): Observable<HttpResponse<CashPositionVersionResponseBody>> {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning/${cashPosition.cashPositioningId}/versions`;
		return this.httpClient
			.post<CashPositionVersionResponseBody>(url, cashPositionVersionPostBody, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public getCashPositionVersionList(cashPosition: CashPosition): Observable<HttpResponse<CashPositionVersionsResponse>> {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning/${cashPosition.cashPositioningId}/versions/list`;
		return this.httpClient
			.post<CashPositionVersionsResponse>(
				url,
				{},
				{
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(err)))
			.pipe(map(resp => this.addAutosaveVersions(cashPosition, resp)));
	}

	public getCashPositionVersionData(
		cashPositioningId: string,
		versionId: string,
		groupBy: CashPositionGrouping,
		currencyOverride?: string
	): Observable<HttpResponse<CashPositionData>> {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning/${cashPositioningId}/versions/${versionId}/data`;
		return this.httpClient
			.post<CashPositionData>(
				url,
				{
					groupBy: groupBy,
					currencyOverride: currencyOverride,
				},
				{
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(err)));
	}

	public deleteCashPosition(cashPositioningId: string) {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning/${cashPositioningId}`;
		return this.httpClient.delete(url, {
			observe: 'response',
		});
	}

	public getCashPositionHistoricalOverview(cashPosition: CashPosition, periods: number = 365): Observable<HttpResponse<CashPositionVersionOverviewResponse>> {
		const url = `${environment.trovataAPI('workspace')}/data/v1/cash-positioning/${cashPosition.cashPositioningId}/versions/overview`;
		return this.httpClient
			.post<CashPositionVersionOverviewResponse>(
				url,
				{
					periods: periods,
				},
				{
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(err)))
			.pipe(map(resp => this.filterHistoricalVersions(cashPosition, resp)));
	}

	private filterHistoricalVersions(
		cashPosition: CashPosition,
		overviewResp: HttpResponse<CashPositionVersionOverviewResponse>
	): HttpResponse<CashPositionVersionOverviewResponse> {
		overviewResp.body.versions = overviewResp?.body?.versions.filter((versionOverview: CashPositionVersionOverview) => {
			const timezone: CashPositionTimezone = new CashPositionTimezone(cashPosition.utcOffset);
			versionOverview.date = DateTime.fromISO(versionOverview.date, {
				zone: 'UTC',
			})
				.set({ hour: timezone.utcCloseTime.hour })
				.startOf('hour')
				.toISO();
			if (
				(!cashPosition.includeWeekends && DateTime.fromISO(versionOverview.date).weekday > 5) ||
				DateTime.fromISO(cashPosition.createdAt, { zone: 'UTC' }).toLocal().startOf('day') > DateTime.fromISO(versionOverview.date).startOf('day')
			) {
				return false;
			} else {
				return true;
			}
		});
		return overviewResp;
	}

	private addAutosaveVersions(cashPosition: CashPosition, versionResp: HttpResponse<CashPositionVersionsResponse>): HttpResponse<CashPositionVersionsResponse> {
		if (versionResp?.body?.versions) {
			const cashPositionTimezone: CashPositionTimezone = new CashPositionTimezone(cashPosition.utcOffset);
			let indexDate: DateTime = cashPositionTimezone.getPreviousClose().toUTC();
			const minDate: DateTime = DateTime.fromISO(cashPosition.createdAt, {
				zone: 'UTC',
			}).toLocal();
			while (indexDate >= minDate) {
				const insertIdx: number = versionResp.body.versions.findIndex(
					(version: CashPositionVersion) => DateTime.fromISO(version.createdAt, { zone: 'UTC' }).toLocal().toMillis() <= indexDate.toMillis()
				);
				versionResp.body.versions.splice(insertIdx >= 0 ? insertIdx : versionResp.body.versions.length, 0, {
					versionId: '',
					createdBy: '',
					createdAt: indexDate.toUTC().toISO(),
				});
				indexDate = indexDate.minus({ days: 1 });
			}
			if (!cashPosition.includeWeekends) {
				versionResp.body.versions = this.filterWeekends(versionResp.body.versions);
			}
		}
		return versionResp;
	}

	private filterWeekends(versions: CashPositionVersion[]) {
		return versions.filter((version: CashPositionVersion) => {
			const weekday: number = DateTime.fromISO(version.createdAt, {
				zone: 'UTC',
			}).toLocal().weekday;
			return weekday < 6;
		});
	}
}
