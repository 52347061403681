import { CurrentCustomer, Customer } from './identity.model';

export enum Effect {
	allow = 'allow',
	deny = 'deny',
}

export interface EffectsCount {
	allowed: number;
	denied: number;
	noEffect: number;
}

export class Permission {
	permissionId: PermissionId;
	name: string;
	effect?: Effect;
}

export class Feature {
	featureId: number;
	name: string;
	description: string;
	effect?: Effect;
	permissions: Permission[];
	features: Feature[];
}

export class UserAccessConfig {
	userId: string;
	currentCustomer: CurrentCustomer;
	customers: Customer[];
	accountCount: number;
	availableFeatures: Feature[];
	availableServiceFeatures: Feature[];
}

export class UserAccessSettings {
	paymentsReleaseMfaRequired: boolean;
	paymentsBatch: boolean;
	paymentsEnabled?: boolean;
}

export type FeatureMap = Map<FeatureId, boolean>;
export type ServiceFeatureMap = Map<ServiceFeatureId, boolean>;

export interface AccessMap {
	featureIds: FeatureMap;
	permissionIds: PermissionMap;
}

export enum FeatureId {
	transactions = 1,
	manualTransactions = 2,
	reports = 3,
	forecast = 4,
	analysis = 5,
	tags = 6,
	settingsAdmin = 7,
	users = 8,
	editUsers = 9,
	cashFlowReconciliation = 10,
	accounts = 11,
	invoices = 12,
	subscriptions = 13,
	billing = 14,
	accountTags = 16,
	currentCash = 17,
	settings = 19,
	payments = 20,
	glTags = 21,
	insights = 22,
	fxTranslation = 23,
	developerPortal = 24,
	entitlements = 25,
	connections = 26,
	dashboard = 27,
	workbooks = 28,
	currentUser = 29,
	coreUser = 30,
	institutions = 31,
	preferences = 32,
	cashPosition = 40,
	investments = 41,
	morganMoney = 42,
	workflows = 43,
	templates = 44,
	bulkPayments = 45,
	trovataAI = 47,
	dataSharing = 67,
	entities = 100,
	audits = 101,
}

export enum ServiceFeatureId {
	multibank = 33,
	fortyEightHourSupport = 34,
	realTimeChat = 35,
}

export type PermissionMap = Map<PermissionId, boolean>;

export enum PermissionId { // may not need this once an api is hooked up to grab these ids
	// Transactions - featureId: 1
	readTransactions = 1,
	// Manual Transactions - featureId: 2
	createTransactionsManual = 2,
	deleteTransactionsManual = 3,
	// Reports - featureId: 3
	createReports = 4,
	updateReports = 5,
	deleteReports = 6,
	readReports = 7,
	updateReportsExportSchedule = 41,
	createReportsExportSchedule = 42,
	deleteReportsExportSchedule = 44,
	// Forecast - featureId: 4
	createForecast = 9,
	updateForecast = 10,
	deleteForecast = 11,
	readForecast = 12,
	updateForecastStreams = 117,
	deleteForecastStreams = 118,
	createForecastStreams = 119,
	// Analysis - featureId: 5
	readAnalysis = 13,
	// Accounts - featureId: 11
	updateAccounts = 23,
	createAccounts = 25,
	deleteAccounts = 29,
	readAccounts = 26,
	// Account Tags - featureId: 16
	readAccountTags = 62,
	createAccountTags = 63,
	deleteAccountTags = 64,
	// Current Cash - featureId: 17
	readCurrentCash = 66,
	createCurrentCash = 67,
	updateCurrentCash = 68,
	deleteCurrentCash = 69,
	// Settings - featureId: 19
	deleteCustomer = 81,
	editCustomer = 82,
	// Subscriptions - featureId: 13
	createSubscription = 86,
	deleteSubscription = 87,
	updateSubscription = 88,
	// Billing - featureId: 14
	payInvoice = 83,
	readBillingInvoices = 84,
	updatePaymentMethod = 89,
	deletePaymentMethod = 90,
	readPaymentMethod = 91,
	createPaymentMethod = 92,
	// Users - featureId: 8
	deleteUsers = 79,
	readUsers = 20,
	createUsers = 22,
	editUsers = 78,
	// Entitlements - featureId: 25
	createUserGroups = 71,
	deleteUserGroups = 72,
	editUserGroups = 73,
	peerReviewGroup = 165,
	// Connections - featureId: 26
	deleteConnections = 38,
	updateConnections = 35,
	createConnections = 36,
	readConnections = 133,
	// Cash Flow Reconciliation - featureId: 10
	createReconReport = 52,
	updateReconReport = 53,
	deleteReconReport = 54,
	readReconReport = 19,
	// Workbooks - featureId: 28
	readWorkbooks = 100,
	createWorkbooks = 101,
	deleteWorkbooks = 102,
	updateWorkbooks = 103,
	readWorkbookSheets = 104,
	createWorkbookSheets = 105,
	deleteWorkbookSheets = 106,
	updateWorkbookSheets = 107,
	readWorkbookSchedules = 108,
	createWorkbookSchedules = 109,
	deleteWorkbookSchedules = 110,
	updateWorkbookSchedules = 111,
	readWorkbookDataOutlets = 112,
	createWorkbookDataOutlets = 113,
	deleteWorkbookDataOutlets = 114,
	updateWorkbookDataOutlets = 115,
	// Tags - featureId: 6
	deleteTags = 15,
	updateTags = 16,
	createTags = 17,
	readTags = 18,
	// GL Tags - featureId: 21
	reTagGlTags = 93,
	changeGlTagPriority = 94,
	deleteGlTags = 95,
	updateGlTags = 96,
	createGlTags = 97,
	readGlTags = 98,
	// Open Invoices - featureId: 12
	readInvoices = 34,
	// Institutions - featureId: 31
	updateManualInstitutions = 56,
	deleteManualInstitutions = 57,
	updateCustomInstitutions = 58,
	createManualInstitution = 65,
	// Current User - featureId: 29
	deleteCurrentUser = 76,
	editCurrentUser = 77,
	readCurrentUser = 21,
	// Core User - featureId: 30
	readInstitutions = 39,
	// readSubscriptions = 85 - core user
	// readUserGroups = 70 - core user
	readManualInstitutions = 55,
	getCustomer = 80,
	readCurrencies = 48,
	readMetadata = 40,
	readFilters = 99,
	// Preferences - featureId: 32
	createPreferences = 30,
	deletePreferences = 31,
	updatePreferences = 32,
	readPreferences = 33,
	// Payments - featureId: 20
	readPayments = 120,
	initiatePayment = 121,
	// Workflows (43)
	readWorkflows = 135,
	createWorkflow = 136,
	editWorkflow = 137,
	deleteWorkflow = 138,
	approveWorkflow = 160,
	// Templates (44)
	readTemplates = 128,
	createTemplate = 129,
	editTemplate = 130,
	deleteTemplate = 131,
	approveTemplate = 161,
	// Bulk payments - (45)
	initiateBulkPayment = 158,

	readRoles = 132,
	assignRoles = 133,
	removeUserRoles = 134,
	readInstitutionInstructions = 139,
	readDevices = 141,
	createDevice = 142,
	deleteDevice = 143,
	readInsights = 146,
	readTransactionsExplorer = 149,
	// cash position
	readCashPosition = 154,
	createCashPosition = 155,
	editCashPosition = 156,
	deleteCashPosition = 157,
	// data sharing - feature Id: 67
	shareDataWithPartners = 166,
	// entities
	readEntities = 199,
	updateEntities = 200,
	deleteEntities = 201,
	createEntities = 202,
	updateBulkEntities = 204,
	// audit logs
	readAuditEvents = 205,
}

export const FeatureReadPermissions: Map<FeatureId, PermissionId> = new Map<FeatureId, PermissionId>([
	[FeatureId.transactions, PermissionId.readTransactions],
	[FeatureId.reports, PermissionId.readReports],
	[FeatureId.forecast, PermissionId.readForecast],
	[FeatureId.analysis, PermissionId.readAnalysis],
	[FeatureId.tags, PermissionId.readTags],
	[FeatureId.glTags, PermissionId.readGlTags],
	[FeatureId.settingsAdmin, null],
	[FeatureId.users, PermissionId.readUsers],
	[FeatureId.editUsers, null],
	[FeatureId.institutions, null],
	[FeatureId.cashFlowReconciliation, PermissionId.readReconReport],
	[FeatureId.accounts, PermissionId.readAccounts],
	[FeatureId.invoices, PermissionId.readInvoices],
	[FeatureId.subscriptions, null],
	[FeatureId.billing, PermissionId.readBillingInvoices],
	[FeatureId.accountTags, PermissionId.readAccountTags],
	[FeatureId.currentCash, PermissionId.readCurrentCash],
	[FeatureId.settings, null],
	[FeatureId.payments, PermissionId.readPayments],
	[FeatureId.templates, PermissionId.readTemplates],
	[FeatureId.workflows, PermissionId.readWorkflows],
	[FeatureId.insights, null],
	[FeatureId.fxTranslation, null],
	[FeatureId.developerPortal, null],
	[FeatureId.manualTransactions, null],
	[FeatureId.entitlements, null],
	[FeatureId.connections, null],
	[FeatureId.dashboard, null],
	[FeatureId.workbooks, PermissionId.readWorkbooks],
	[FeatureId.currentUser, PermissionId.readCurrentUser],
	[FeatureId.cashPosition, PermissionId.readCashPosition],
	[FeatureId.entities, PermissionId.readEntities],
	[FeatureId.audits, PermissionId.readAuditEvents],
]);

export interface FeaturePermissionMap<T extends boolean | Effect> {
	featureIds: Map<number, T>;
	permissionIds: Map<number, T>;
}

// this uses a type parameter so that apis with the effect field can make use of it and ones without it can just store the permission/feature name
export function featurePermissionMap<T extends boolean | Effect>(
	featurePermission: Feature | Feature[],
	config: FeaturePermissionMap<boolean | Effect> = {
		featureIds: new Map<number, boolean | Effect>(),
		permissionIds: new Map<number, boolean | Effect>(),
	}
): FeaturePermissionMap<T> {
	if ('featureId' in featurePermission) {
		featurePermission.permissions?.forEach((permission: Permission) => {
			config.permissionIds.set(permission.permissionId, permission.hasOwnProperty('effect') ? permission.effect : true);
		});
		config.featureIds.set(featurePermission.featureId, featurePermission.hasOwnProperty('effect') ? featurePermission.effect : true);
		featurePermission.features?.forEach((feature: Feature) => {
			config = featurePermissionMap(feature, config);
		});
	}
	if (Array.isArray(featurePermission)) {
		featurePermission.forEach(feature => (config = featurePermissionMap(feature, config)));
	}
	return config as FeaturePermissionMap<T>;
}

export function getServiceFeatureMap(serviceFeatures: Feature[]): ServiceFeatureMap {
	const serviceFeatureMap: ServiceFeatureMap = new Map<ServiceFeatureId, boolean>();
	serviceFeatures.forEach(serviceFeature => {
		serviceFeatureMap.set(serviceFeature.featureId, true);
	});
	return serviceFeatureMap;
}

export interface GetFeaturePermissionsResponse {
	features: Feature[];
}
