import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataSeries } from '../../models/graphs.models';
import { ColumnChartBuilder } from '../../models/highcharts.model';
import { InsightGroupRow, TrendInputs } from '../../models/insights.model';

@Component({
	selector: 'trovata-column-chart-trend-row',
	templateUrl: './column-chart-trend-row.component.html',
	styleUrls: ['./column-chart-trend-row.component.scss'],
})
export class ColumnChartTrendRowComponent implements InsightGroupRow, OnChanges {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() value: string;
	@Input() trend: TrendInputs;
	@Input() dense: boolean;
	@Input() graphData: DataSeries[];

	columnChartBuilder: ColumnChartBuilder;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['graphData']) {
			this.updateColumnChart();
		}
	}

	private updateColumnChart(): void {
		this.columnChartBuilder = new ColumnChartBuilder({
			dataSeries: this.graphData,
			maxValueRef: undefined,
			showTitles: false,
			showValueLabels: false,
			barSize: this.graphAppearanceClass,
		});
	}

	get graphAppearanceClass() {
		return this.dense ? 'small' : 'medium';
	}
}
