import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ActionType, TagUpdatedEvent } from 'src/app/shared/models/updated-events.model';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { firstValueFrom, Observable } from 'rxjs';
import { Tag, TagChartData, TagChartPreferences, TagPayload } from '../../models/tag.model';
import { RefreshTagSnackData } from '../../store/actions/tag-snacks.action';
import { ClearLastCreatedTagId, CreateTag, DeleteTag, GetTags, UpdateTag } from '../../store/actions/tags.action';
import { TagsState } from '../../store/state/tags.state';
import { TagService } from '../tag.service';
import { PreferencesFacadeService } from 'src/app/shared/services/facade/preferences.facade.service';
import { AnalysisTimeFrame } from '../../../reports/models/analysis.model';
import { Cadence } from '../../../reports/models/cadence.model';
import { ChartType, fixChartType } from 'src/app/shared/models/highcharts.model';

@Injectable({
	providedIn: 'root',
})
export class TagFacadeService {
	@Select(TagsState.tags) tags$: Observable<Tag[]>;
	@Select(TagsState.lastCreatedTagId) lastCreatedTagId$: Observable<string>;

	constructor(
		private store: Store,
		private tagService: TagService,
		private updatedEventsService: UpdatedEventsService,
		private preferencesFacadeService: PreferencesFacadeService
	) {}

	getTags(): Promise<Tag[]> {
		return new Promise(async resolve => {
			this.tags$.subscribe((tags: Tag[]) => {
				if (tags) {
					resolve(tags);
				}
			});
		});
	}

	getTag(tagId: string): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				const tags: Tag[] = await this.getTags();
				const selectedTag: Tag = tags.find((tag: Tag) => tag.tagId === tagId);
				resolve(selectedTag);
			} catch (error) {
				reject(error);
			}
		});
	}

	createTag(tag: Tag): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new CreateTag(tag)));
				let lastCreatedTagId: string;
				this.lastCreatedTagId$.subscribe((id: string) => (lastCreatedTagId = id));
				const newTag: Tag = await this.getTag(lastCreatedTagId);
				const newTagCopy: Tag = JSON.parse(JSON.stringify(newTag));
				this.updatedEventsService.updateItem(new TagUpdatedEvent(ActionType.create, newTagCopy.tagId, newTagCopy));
				this.store.dispatch(new ClearLastCreatedTagId());
				resolve(newTagCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	getTagChartPreferences(tag: Tag): Promise<TagChartData> {
		return new Promise(async (resolve, reject) => {
			try {
				const chartPreferences: TagChartPreferences = await this.preferencesFacadeService.getPreferenceByKey('tagChartData');
				let chartData: TagChartData;
				if (chartPreferences && chartPreferences[tag.tagId]) {
					chartData = chartPreferences[tag.tagId];
				} else {
					chartData = {
						periods: 30,
						cadence: Cadence.daily,
						timeFrame: AnalysisTimeFrame.days,
						netToggle: false,
						chartType: ChartType.column,
					};
				}
				chartData.chartType = fixChartType(chartData.chartType);
				resolve(chartData);
			} catch (err) {
				reject(err);
			}
		});
	}

	saveChartPreference(tag: Tag, chartData: TagChartData): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				let chartPreferences: TagChartPreferences = await this.preferencesFacadeService.getPreferenceByKey('tagChartData');
				if (chartPreferences) {
					chartPreferences[tag.tagId] = chartData;
				} else {
					chartPreferences = {
						[tag.tagId]: chartData,
					};
				}
				this.preferencesFacadeService.updatePreferencesByKey('tagChartData', chartPreferences);
				resolve();
			} catch (err) {
				reject(err);
			}
		});
	}

	updateTag(tag: Tag, tagPayload: TagPayload): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new UpdateTag(tag, tagPayload)));
				const updatedTag: Tag = await this.getTag(tag.tagId);
				const updatedTagCopy: Tag = JSON.parse(JSON.stringify(updatedTag));
				this.updatedEventsService.updateItem(new TagUpdatedEvent(ActionType.create, updatedTagCopy.tagId, updatedTagCopy));
				this.store.dispatch(new RefreshTagSnackData(tag.tagId));
				resolve(updatedTagCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	updateTQLTag(tag: Tag): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new UpdateTag(tag, new TagPayload())));
				const updatedTag: Tag = await this.getTag(tag.tagId);
				const updatedTagCopy: Tag = JSON.parse(JSON.stringify(updatedTag));
				this.updatedEventsService.updatedItem$.next(new TagUpdatedEvent(ActionType.create, updatedTagCopy.tagId, updatedTagCopy));
				this.store.dispatch(new RefreshTagSnackData(tag.tagId));
				resolve(updatedTagCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	deleteTag(tag: Tag): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new DeleteTag(tag)));
				this.updatedEventsService.updateItem(new TagUpdatedEvent(ActionType.create, tag.tagId, tag));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	actionTag(tag: Tag, transactionArray: Array<string>, action: string): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.tagService.actionTag(tag, transactionArray, action));

				let actionType: string;
				switch (action) {
					case 'INCLUDE':
						actionType = 'includeTransactions';
						break;
					case 'EXCLUDE':
						actionType = 'excludeTransactions';
						break;
				}

				if (tag.tagMetadata[actionType]?.length >= 0) {
					transactionArray.forEach(trxnId => {
						const i = tag.tagMetadata[actionType].indexOf(trxnId);
						if (i < 0) {
							tag.tagMetadata[actionType].push(trxnId);
						}
					});
				} else {
					tag.tagMetadata[actionType] = [];
					transactionArray.forEach(trxnId => {
						tag.tagMetadata[actionType].push(trxnId);
					});
				}
				await firstValueFrom(this.store.dispatch(new GetTags(true)));
				resolve(tag);
			} catch (error) {
				reject(error);
			}
		});
	}
}
