import { DateRange } from '@angular/material/datepicker';
import { CalendarSettings } from '../models/date-range-picker.model';
import moment, { Moment } from 'moment';

export const getDateRangeFromCalendarSettings = (calendarSettings: CalendarSettings): DateRange<Moment> => {
	if (calendarSettings.isRolling) {
		const currentDate: Moment = moment();
		let startDate: Moment;
		if (calendarSettings.periodsOffset < 0) {
			startDate = currentDate.subtract(Math.abs(calendarSettings.periodsOffset), 'days');
		} else {
			startDate = currentDate.add(calendarSettings.periodsOffset, 'days');
		}
		const endDate: Moment = startDate.clone().add(calendarSettings.periods - 1, 'days');

		return new DateRange(startDate, endDate);
	} else {
		return new DateRange(moment(calendarSettings.startDate), moment(calendarSettings.endDate));
	}
};
