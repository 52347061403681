import { getActionTypeFromInstance } from '@ngxs/store';
import { Logout } from '../actions/auth.actions';
import * as LocalForage from 'localforage';

export function logoutPlugin(state, action, next) {
	// Use the get action type helper to determine the type
	if (getActionTypeFromInstance(action) === Logout.type) {
		// if we are a logout type, lets erase all the state
		state = {};
		localStorage.clear();
		sessionStorage.clear();
		LocalForage.clear();
	}

	// return the next function with the empty state
	return next(state, action);
}
