import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ConnectionType, Institution, InstitutionApiGetResponse, InstitutionCreateResponse } from '../../../shared/models/institution.model';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class InstitutionService {
	constructor(private httpClient: HttpClient) {}

	public getPremiumConnections(): Observable<HttpResponse<InstitutionApiGetResponse>> {
		const url: string = environment.edgeAPI() + '/institutions';
		return this.httpClient
			.get<InstitutionApiGetResponse>(url, {
				observe: 'response',
				params: {
					connectionType: 'DIRECT',
				},
			})
			.pipe(catchError(err => throwError(err)));
	}

	public getCustomerInstitutions(): Observable<HttpResponse<InstitutionApiGetResponse>> {
		const url: string = environment.trovataAPI('workspace') + '/data/institutions';
		return this.httpClient
			.get<InstitutionApiGetResponse>(url, {
				observe: 'response',
			})
			.pipe(
				map((res: HttpResponse<InstitutionApiGetResponse>) => {
					res.body.institutions = res.body.institutions.map((institution: Institution) => {
						institution.institutionNickname = institution.institutionNickname || institution.institutionName;
						return institution;
					});
					return res;
				}),
				catchError(err => throwError(err))
			);
	}

	public getTrovataInstitutions(connectionTypes?: ConnectionType[], search?: string): Observable<HttpResponse<InstitutionApiGetResponse>> {
		const url: string = environment.trovataAPI('workspace') + '/data/institutions/all';
		let params: HttpParams = new HttpParams();
		if (connectionTypes) {
			connectionTypes.forEach((type: ConnectionType) => {
				params = params.append('connectionType', type);
			});
		}
		params = params.append('size', 12000);
		// shows test institutions if not production
		if (environment.environmentName === 'prod') {
			params = params.append('beta', false);
		}
		if (search) {
			params = params.append('search', search);
		}
		return this.httpClient
			.get<InstitutionApiGetResponse>(url, {
				observe: 'response',
				params: params,
			})
			.pipe(
				map(resp => {
					// the API is sending back a lot of duplicate institution names. This filters the list a bit. Once the data is fixed we can remove this.
					resp.body.institutions = resp.body.institutions.filter(
						(item, index, institutionList) => index === institutionList.findIndex(item2 => item2.institutionName === item.institutionName)
					);
					return resp;
				}),
				catchError(err => throwError(err))
			);
	}

	public getManualInstitutions(from: number, size: number): Observable<HttpResponse<InstitutionApiGetResponse>> {
		const url: string = environment.trovataAPI('workspace') + '/data/institutions/manual';
		let parameters: HttpParams = new HttpParams();
		parameters = parameters.append('from', from);
		parameters = parameters.append('size', size);
		return this.httpClient
			.get<InstitutionApiGetResponse>(url, {
				params: parameters,
				observe: 'response',
			})
			.pipe(
				map((res: HttpResponse<InstitutionApiGetResponse>) => {
					res.body.institutions = res.body.institutions.map((institution: Institution) => {
						institution.institutionNickname = institution.institutionNickname || institution.institutionName;
						return institution;
					});
					return res;
				}),
				catchError(err => throwError(err))
			);
	}

	public postManualInstitution(name: string): Observable<HttpResponse<InstitutionCreateResponse>> {
		const url: string = environment.trovataAPI('workspace') + '/data/institutions/manual';
		const body: Partial<Institution> = {
			institutionName: name,
			institutionNickname: name,
		};
		return this.httpClient
			.post<InstitutionCreateResponse>(url, body, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public updateManualInstitution(name: string, id: string): Observable<HttpResponse<void>> {
		const url: string = environment.trovataAPI('workspace') + `/data/institutions/manual/${id}`;
		const body: Partial<Institution> = {
			institutionName: name,
			institutionNickname: name,
		};
		return this.httpClient
			.put<void>(url, body, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public deleteManualInstitution(id: string): Observable<HttpResponse<void>> {
		const url: string = environment.trovataAPI('workspace') + `/data/institutions/manual/${id}`;
		return this.httpClient
			.delete<void>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public updateCustomInstitution(name: string, id: string): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v2/institutions/custom/${id}`;

		const body: Partial<Institution> = {
			institutionNickname: name,
		};
		return this.httpClient
			.put<void>(url, body, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}
}
