import { Filter, FilterOption } from 'src/app/shared/models/abstract-filter.model';
import { ParameterType, SearchParameter } from 'src/app/shared/models/search-parameter.model';
import { Cadence } from './cadence.model';
import { FormControl } from '@angular/forms';
import moment from 'moment';
import { ReportType } from './report.model';
import { AccountTagType } from '../../transactions/models/tag.model';

export const MY_FORMATS = {
	parse: {
		dateInput: ['MM/DD/YYYY', 'MM/YYYY'],
	},
	display: {
		dateInput: 'MM/DD/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

export interface ReconciliationForm {
	useEndDate: FormControl<boolean>;
	tagFilter: FormControl<string>;
	reconName: FormControl<string>;
	cadence: FormControl<Cadence>;
	periods: FormControl<number>;
	date: FormControl<moment.Moment>;
	filterType: FormControl<string>;
	tagBreakdown: FormControl<Array<string>>;
	includeFullAccountNumbers: FormControl<boolean>;
}
export class ReconciliationReport {
	reportType: ReportType;
	reconId: string;
	name: string;
	cadence: Cadence;
	periods: number;
	endDate?: string;
	includeFullAccountNumbers: boolean;
	isManual: boolean;
	positionType: string;
	groupBy: string;
	creationDate: string;
	lastModifiedDate: string;
	currencyThresholds: { [currencyCode: string]: number };
	tagId: string[];
	accountId: string[];
	institutionId: string[];
	currency: string[];
	entityGroupingId: string[];
	regionGroupingId: string[];
	divisionGroupingId: string[];
	flipped?: boolean;
	dailyData?: ReconData;
	periodData?: ReconData;
	errorMessage?: string;
}

export interface ReconData {
	reconId?: string;
	reconData: {
		periodView: boolean;
		aggregation: ReconDataGroup[];
	};
}

export interface ReconDataGroup {
	groupValue: string;
	groupType: string;
	periodData: ReconDataItem[];
	reconAggregation?: ReconDataGroup[];
	tagAggregation?: ReconDataGroup[];
}

export interface ReconDataItem {
	date: string;
	netTransactionActivity: number;
	netTransactionActivityConverted: number;
	creditAmount: number;
	creditAmountConverted: number;
	debitAmount: number;
	debitAmountConverted: number;
	transactionsCount: number;
	openingBalance?: number;
	openingBalanceConverted?: number;
	closingBalance?: number;
	closingBalanceConverted?: number;
	changeInBalance?: number;
	changeInBalanceConverted?: number;
	delta?: number;
	deltaConverted?: number;
}

export interface ReconciliationReportPreferences {
	currency: string;
	periodView: {
		order: Object;
		shownRows: {
			openingBalanceConverted: boolean;
			closingBalanceConverted: boolean;
			changeInBalanceConverted: boolean;
			netTransactionActivityConverted: boolean;
		};
	};
	dailyView: {
		order: Object;
		shownColumns: ReconDailyColumnPreferences;
	};
}

export interface ReconDailyColumnPreferences {
	openingBalance: boolean;
	closingBalance: boolean;
	changeInBalance: boolean;
	debitAmount: boolean;
	creditAmount: boolean;
	netTransactionActivity: boolean;
	transactionsCount: boolean;
	order: {
		groups: string[];
		balances: string[];
		transactions: string[];
	};
}

export const defaultReconDailyColumnPreferences: ReconDailyColumnPreferences = {
	openingBalance: true,
	closingBalance: true,
	changeInBalance: true,
	creditAmount: true,
	debitAmount: true,
	transactionsCount: true,
	netTransactionActivity: true,
	order: {
		groups: ['balances', 'transactions', 'delta', 'reconCheck'],
		balances: ['openingBalance', 'closingBalance', 'changeInBalance'],
		transactions: ['creditAmount', 'debitAmount', 'transactionsCount', 'netTransactionActivity'],
	},
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ReconFilterTypes = [
	{ label: 'Account', key: 'accountId', value: ParameterType.accountId },
	{ label: 'Currency', key: 'currency', value: ParameterType.currency },
	{
		label: 'Institution',
		key: 'institutionId',
		value: ParameterType.institutionId,
	},
	{ label: AccountTagType.entity, key: 'entityGroupingId', value: ParameterType.entity },
	{ label: AccountTagType.region, key: 'regionGroupingId', value: ParameterType.region },
	{
		label: AccountTagType.division,
		key: 'divisionGroupingId',
		value: ParameterType.division,
	},
];

export interface ReconRequestBody {
	[filterKey: string]: string | string[] | { [currencyCode: string]: number };
}

export class ReconFilter {
	label: string;
	key: string;
	value: ParameterType;
	items: SearchParameter[] = [];
	displayString: string;
	constructor(filterType: { label: string; key: string; value: ParameterType }, reconReport?: ReconciliationReport, filterMap?: Map<string, Filter>) {
		this.label = filterType.label;
		this.key = filterType.key;
		this.value = filterType.value;
		if (reconReport && filterMap && reconReport[this.key] && reconReport[this.key].length) {
			reconReport[this.key].forEach((id: string) => {
				const filterOpt: FilterOption = filterMap[this.value]?.options?.find(filterOption => filterOption.id === id);
				if (filterOpt) {
					this.items.push(new SearchParameter(this.value, filterOpt.displayValue, id));
				}
			});
		} else {
			this.items = [];
		}
	}
	getIds(): string[] {
		return this.items.map(searchParam => searchParam.value);
	}
	setDisplayString() {
		this.displayString = this.items.map(searchParam => searchParam.displayValue).join(', ');
	}
}

export interface ReconCreatedEvent {
	tabId: string;
	reconReport: ReconciliationReport;
}
