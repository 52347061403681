import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GetUserGroupsResponse, UserGroup, UserGroupPostBody } from '../models/user-group.model';
import { GetFeaturePermissionsResponse } from '../models/feature.model';
import { ChangeRequestBody } from 'src/app/shared/models/admin-approval.model';

@Injectable({
	providedIn: 'root',
})
export class UserGroupService {
	private baseApiUrl: string;

	constructor(private httpClient: HttpClient) {
		this.baseApiUrl = environment.trovataAPI('workspace');
	}

	getUserGroups(): Observable<GetUserGroupsResponse> {
		return this.httpClient.get<GetUserGroupsResponse>(`${this.baseApiUrl}/v2/entitlements/customer/user-groups`);
	}

	getUserGroupById(userGroupId: number): Observable<GetUserGroupsResponse> {
		return this.httpClient.get<GetUserGroupsResponse>(`${this.baseApiUrl}/v2/entitlements/customer/user-groups?userGroupId=${userGroupId}`);
	}

	getFeaturePermissions(): Observable<GetFeaturePermissionsResponse> {
		return this.httpClient.get<GetFeaturePermissionsResponse>(`${this.baseApiUrl}/entitlements/customer/user-groups/feature-permissions`);
	}

	createUserGroup(body: UserGroupPostBody): Observable<UserGroup> {
		return this.httpClient.post<UserGroup>(`${this.baseApiUrl}/v2/entitlements/customer/user-groups`, body);
	}

	updateUserGroup(userGroupId: number, body: UserGroupPostBody): Observable<UserGroup> {
		return this.httpClient.patch<UserGroup>(`${this.baseApiUrl}/v2/entitlements/customer/user-groups/${userGroupId}`, body);
	}

	deleteUserGroup(userGroupId: number): Observable<UserGroup | string> {
		return this.httpClient.delete<UserGroup>(`${this.baseApiUrl}/v2/entitlements/customer/user-groups/${userGroupId}`);
	}

	reviewChangeRequest(changeRequestId: number, body: ChangeRequestBody): Observable<UserGroup> {
		return this.httpClient.post<UserGroup>(`${this.baseApiUrl}/entitlements/customer/user-groups/change-requests/${changeRequestId}/review`, body);
	}

	cancelChangeRequest(changeRequestId: number): Observable<UserGroup> {
		return this.httpClient.delete<UserGroup>(`${this.baseApiUrl}/entitlements/customer/user-groups/change-requests/${changeRequestId}`);
	}
}
