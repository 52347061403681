import { Pipe, PipeTransform } from '@angular/core';
import { PaymentExecutionStage, PaymentExecutionStageSignature } from '../models/payment.model';

@Pipe({ name: 'userApprovedPayment' })
export class UserApprovedPaymentPipe implements PipeTransform {
	transform(userId: string, stage: PaymentExecutionStage): boolean {
		const foundSignature: PaymentExecutionStageSignature = stage.signatures.find((signature: PaymentExecutionStageSignature) => signature.userId === userId);
		if (foundSignature) {
			return true;
		} else {
			return false;
		}
	}
}
