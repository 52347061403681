import { rand, randUuid } from '@ngneat/falso';
import { SnacksTabData } from 'src/app/features/balances/models/snacks.model';
import { ForecastsPagePreferences } from 'src/app/features/forecasts/models/forecastV3-forecast.model';
import { Cadence } from 'src/app/features/reports/models/cadence.model';
import { ReportsPagePreferences, ReportTabObjectType } from 'src/app/features/reports/models/report.model';
import { TagType } from 'src/app/features/transactions/models/tag.model';
import { Server, Request, Response } from 'miragejs';
import { AllPreferences } from '../../models/preferences.model';
import { SnackType } from '../../models/snacks.model';
import { AppSchema, AccountDbData, ForecastDbData, ReportDbData } from '../types';

export function addReportsEdgePrefsHandlers(server: Server) {
	server.get('/preferences/global/reportsPagePreferences', (schema, request): ReportsPagePreferences => {
		const reportsIds: string[] = schema.db.reports.map((report: ReportDbData) => report.reportId);
		return {
			tabs: [
				{
					name: 'All Reports',
					tabId: 'All Reports',
					favorites: reportsIds,
					flipped: [],
					reports: reportsIds.map((reportId: string) => ({
						id: reportId,
						type: ReportTabObjectType.report,
					})),
					editable: false,
				},
				{
					name: 'All Reconciliations',
					tabId: 'All Reconciliations',
					favorites: [],
					flipped: [],
					reports: [],
					editable: false,
				},
			],
		};
	});
}

export function addForecastsEdgePrefsHandlers(server: Server) {
	server.get('/preferences/global/forecastsPagePreferences', (schema, request): ForecastsPagePreferences => {
		const forecastIds: string[] = schema.db.forecasts.map((forecast: ForecastDbData) => forecast.forecastId);
		return {
			tabs: [
				{
					name: 'All Forecasts',
					tabId: 'All Forecasts',
					favorites: forecastIds,
					flipped: [],
					forecastIds,
					editable: false,
				},
			],
		};
	});
}

export function addDashboardEdgePrefsHandlers(server: Server) {
	server.get('/preferences/global/dashboardTabs', (schema, request) => [
		{
			name: 'Demo Dashboard',
			id: randUuid(),
			snacks: [
				{
					type: 'report',
					id: rand(schema.db.reports.map(report => report.reportId)),
					component: null,
				},
				{
					chartType: 'bar_chart',
					periods: 10,
					id: rand(schema.db.tags.where({ tagType: TagType.transaction }).map(tag => tag.tagId)),
					type: 'tag',
					netToggle: false,
					timeFrame: 'days',
					component: null,
				},
				{
					type: 'forecastV3',
					id: rand(schema.db.forecasts.map((forecast: ForecastDbData) => forecast.forecastId)),
					component: null,
				},
			],
		},
	]);
}

export function addFreemiumPrefsEdgeHandlers(server: Server) {
	server.get('/preferences/global', (schema: AppSchema, request): Partial<AllPreferences> => {
		const accountDetailsChartData = {};
		schema.db.accounts.map((account: AccountDbData) => {
			accountDetailsChartData[account.accountId] = {
				chartType: 'column',
				periods: 30,
				balanceProperty: 'bankClosingLedger',
				cadence: 'daily',
			};
		});

		return {
			accountDetailsChartData,
			balancesCarousel: {
				snacks: [
					{
						type: SnackType.tag,
						preferences: {
							chartType: 'bar_chart',
							periods: 13,
							index: 0,
							netToggle: false,
							cadence: Cadence.daily,
						},
						id: 'cashFlowDemoId',
					},
					{
						type: SnackType.currency,
						preferences: {
							index: 1,
						},
						id: null,
					},
					{
						type: SnackType.currentCash,
						preferences: {
							index: 2,
						},
						id: 'currentCashSnackId',
					},
				],
			},
		};
	});
}

export function addFreemiumPrefsWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.get('/data/preferences', (schema: AppSchema, request: Request) => {
		const queryParams: Record<string, string> = request.queryParams;
		return [
			{
				id: randUuid(),
				preference: {
					currency: 'USD',
				},
				ownerFlag: queryParams.ownerFlag,
				preferenceKey: 'currency',
			},
		];
	});

	server.post('/preferences/global/**', (schema, request) => null);
	server.get('/preferences/global/**', (schema, request): SnacksTabData[] => null);
	server.post('/data/preferences/global/**', () => null);
	server.put('/data/preferences', () => {
		unhandledRequestMade();
		return new Response(400);
	});
}
