import {
	rand,
	randAccount,
	randAlpha,
	randAmount,
	randBetweenDate,
	randBoolean,
	randCatchPhrase,
	randCity,
	randCompanyName,
	randCountry,
	randDirection,
	randEmail,
	randFullName,
	randJobTitle,
	randNumber,
	randPastDate,
	randState,
	randStreetAddress,
	randStreetName,
	randText,
	randTextRange,
	randUuid,
	randWord,
	randZipCode,
} from '@ngneat/falso';
import { DateTime } from 'luxon';
import { Factory } from 'miragejs';
import { FactoryDefinition } from 'miragejs/-types';
import { CurrentCashData as CurrentCashDataType } from '../../features/balances/models/current-cash.model';
import { CashPosition, CashPositionData } from '../../features/cash-position/models/cash-position.model';
import { IForecastV3Response } from '../../features/forecasts/models/forecastV3-forecast-response.model';
import { DataSourceType, Stream } from '../../features/forecasts/models/forecastV3-stream.model';
import { Insight, InsightFamily, InsightFamilyType } from '../../features/insights/models/insights.model';
import { Cadence } from '../../features/reports/models/cadence.model';
import { Tag, TagType } from '../../features/transactions/models/tag.model';
import { Transaction } from '../../features/transactions/models/transaction.model';
import { AccountTargetV3 } from '../models/account-target.model';
import { PositionType } from '../models/cash-position.model';
import { CurrencyBalance } from '../models/currency.model';
import { Institution } from '../models/institution.model';
import { Rounding } from '../models/rounding.model';
import { SnackSize } from '../models/snacks.model';
import {
	analysisBalanceValues,
	analysisTransactionValues,
	balancesAggregation,
	chosenCurrencies,
	insightDisplayData,
	randAccountName,
	transactionsAggregation,
} from './helpers.mock';
import { ReportType, ReportV4Element, ReportV4ElementData, ReportV4 } from '@trovata/app/features/reports/models/report.model';
import { AnalysisType } from '@trovata/app/features/reports/models/analysis.model';
import { AccountGrouping } from '@trovata/app/features/settings/models/account-grouping.model';
import { Entity, EntityExtraProperty, EntityFieldType, EntityPersonnel, GetEntityTreeResponse } from '@trovata/app/features/entities/models/entity.model';

const currencyBalanceFactory: FactoryDefinition<CurrencyBalance> = Factory.extend<CurrencyBalance>({
	balance: () => randAmount(),
	nativeBalance: () => randAmount(),
	conversionRate: () => randNumber({ min: 0, max: 0.5, fraction: 4 }),
	overallPercentage: () => randNumber({ min: 0, max: 100, fraction: 2 }),
	conversionRateTimestamp: () => DateTime.now().toFormat('yyyy-MM-dd'),
	currency: () => 'USD',
});

const accountFactory: FactoryDefinition<Partial<AccountTargetV3>> = Factory.extend<Partial<AccountTargetV3>>({
	accountId: () => randUuid(),
	accountNumber: () => '****' + randAccount({ accountLength: 4 }),
	balance: () => randAmount(),
	balanceEpoch: () => new Date().toISOString(),
	bankBalanceConverted: () => randAmount(),
	bankClosingAvailable: () => randAmount(),
	bankClosingAvailableConverted: () => randAmount(),
	bankClosingLedger: () => randAmount(),
	bankClosingLedgerConverted: () => randAmount(),
	bankCurrentAvailable: () => randAmount(),
	bankCurrentAvailableConverted: () => randAmount(),
	bankOpeningAvailable: () => randAmount(),
	bankOpeningAvailableConverted: () => randAmount(),
	bankOpeningLedger: () => randAmount(),
	bankOpeningLedgerConverted: () => randAmount(),
	conversionRate: 1,
	conversionRateTimestamp: () => DateTime.now().toFormat('yyyy-MM-dd'),
	currency: () => rand(chosenCurrencies),
	currencyConverted: 'USD',
	dataProviderId: () => randText({ charCount: 5 }),
	divisionGroupingId: () => null,
	entityGroupingId: () => null,
	includeInCashForecast: () => 0,
	institutionName: () => randCompanyName(),
	institutionNickname: () => null,
	institutionId: null,
	isManual: () => false,
	lastRefreshTs: () => randPastDate().toISOString(),
	name: () => '',
	nickname: () => randAccountName(),
	positionType: () => PositionType.CASH,
	regionGroupingId: () => null,
	tags: () => [],
	type: () => 'OPERATING',
});

const institutionFactory: FactoryDefinition<Institution> = Factory.extend<Institution>({
	trovataInstitutionId: () => randUuid(),
	institutionId: () => randUuid(),
	institutionName: () => randCompanyName(),
	institutionNickname: null,
	dataProviderId: () => randUuid(),
	connectionType: '',
	indexTime: '2020-09-04T22:55:07Z',
	url: '',
	metadata: null,
	institutionType: '',
});

const currentCashDataFactory: FactoryDefinition<CurrentCashDataType> = Factory.extend<CurrentCashDataType>({
	balancesAggregation: [
		{
			groupType: 'type',
			groupValue: 'DEBT',
			balancesAggregation: [
				{
					balances: analysisBalanceValues(8, Cadence.daily),
					groupType: 'type',
					groupValue: 'DEBT',
				},
			],
		},
		{
			groupType: 'type',
			groupValue: 'INVESTMENT',
			balancesAggregation: [
				{
					balances: analysisBalanceValues(8, Cadence.daily),
					groupType: 'type',
					groupValue: 'INVESTMENT',
				},
			],
		},
		{
			groupType: 'type',
			groupValue: 'OPERATING',
			balancesAggregation: [
				{
					balances: analysisBalanceValues(8, Cadence.daily),
					groupType: 'type',
					groupValue: 'OPERATING',
				},
			],
		},
		{
			groupType: 'type',
			groupValue: 'OTHER',
			balancesAggregation: [
				{
					balances: analysisBalanceValues(8, Cadence.daily),
					groupType: 'type',
					groupValue: 'OTHER',
				},
			],
		},
	],
	currencyConverted: 'USD',
	summary: {
		balances: analysisBalanceValues(8, Cadence.daily),
	},
});

const transactionFactory: FactoryDefinition<Transaction> = Factory.extend<Partial<Transaction>>({
	transactionId: () => randUuid(),
	date: () =>
		randBetweenDate({
			to: new Date(),
			from: DateTime.now().minus({ month: 1 }).toJSDate(),
		}),
	amount: () => randAmount(),
	amountConverted: () => randAmount(),
	bankReference: () => randAccount({ accountLength: 12 }),
	type: () => rand(['CREDIT', 'DEBIT']),
	description: () => randCompanyName(),
	descriptionDetail: () => `${rand(['deposit', 'withdrawal', 'payment', 'invoice'])} transaction at ${randCompanyName()}`,
	currency: () => rand(chosenCurrencies),
	currencyConverted: () => 'USD',
	category: '',
	account: null,
	tags: [],
	metadata: {
		baiType: null,
		narrativeText: null,
	},
	metadataDisplay: [],
	glTag: null,
	memo: '',
});

const accountGroupingFactory: FactoryDefinition<AccountGrouping> = Factory.extend<AccountGrouping>({
	name: () => randDirection() + ' ' + randStreetName(),
	type: () => rand([TagType.entity, TagType.region, TagType.division]),
	accountGroupingId: () => randUuid(),
	button: true,
});

const tagFactory: FactoryDefinition<Tag> = Factory.extend<Tag>({
	tagTitle: () => randText(),
	tagDescription: '',
	tagType: TagType.transaction,
	tagId: () => randUuid(),
	lastUpdatedTime: '2022-09-08T18:16:34Z',
	priority: null,
	indexTime: null,
	customerId: null,
	tagMetadata: {
		tags: [],
		excludeTags: [],
		startDate: null,
		endDate: null,
		isManual: null,
		q: null,
		glCode1: null,
		glCode2: null,
		includeTransactions: [],
		excludeTransactions: [],
		currency: null,
		includeTransactionsCount: 0,
		excludeTransactionsCount: 0,
		positionType: null,
		type: null,
		displayInstitutions: null,
		displayAccounts: null,
		displayGlCodes: null,
		amountFilters: null,
		sortFields: null,
		tql: null,
	},
});

const reportFactory: FactoryDefinition<ReportV4> = Factory.extend<ReportV4>({
	reportId: () => randUuid(),
	name: () => randCatchPhrase() + ' Report',
	reportType: ReportType.report,
	preferences: {},
	createdDate: '2023-02-17T19:54:42.166550',
	lastModifiedDate: '2023-02-17T19:54:42.166566',
	lastModifiedUser: null,
	reportData: null,
});

const reportElementFactory: FactoryDefinition<ReportV4ElementData & ReportV4Element> = Factory.extend<ReportV4ElementData & ReportV4Element>({
	elementId: () => randUuid(),
	type: rand([AnalysisType.balances, AnalysisType.transactions]),
	parameters: null,
	data() {
		if (this.type === AnalysisType.transactions) {
			return {
				aggregation: transactionsAggregation([], '', 14, 'daily'),
				summary: analysisTransactionValues(14, 'daily'),
				currencyConverted: 'USD',
				type: this.type,
				cadence: null,
			} as any;
		} else {
			return {
				aggregation: balancesAggregation([], '', 14, 'daily'),
				summary: analysisBalanceValues(14, 'daily'),
				currencyConverted: 'USD',
				type: this.type,
				cadence: null,
			};
		}
	},
	preferences: {
		displaySettings: null,
		calendarSettings: null,
		visualType: null,
		elementOrder: null,
		balanceProperty: null,
	},
});

const forecastFactory: FactoryDefinition<IForecastV3Response> = Factory.extend<IForecastV3Response>({
	startDate: DateTime.now().toFormat('yyyy-MM-dd'),
	endDate: DateTime.now().plus({ month: 1 }).toFormat('yyyy-MM-dd'),
	isRolling: true,
	rollingOffset: 0,
	rollingPeriods: 31,
	name: () => randCatchPhrase() + ' Forecast',
	description: '',
	cadence: 'daily',
	currency: 'USD',
	accountIds: [],
	forecastId: () => randUuid(),
	streams: null,
	streamGroups: [],
	factors: [],
	currencyConverted: 'USD',
});

const forecastStreamFactory: FactoryDefinition<Stream> = Factory.extend<Stream>({
	forecastedDataSource: DataSourceType.Transactions,
	actualsDataSource: DataSourceType.Transactions,
	currency: 'EUR',
	startDate: '2023-02-28',
	isRolling: true,
	rollingOffset: 0,
	rollingPeriods: 92,
	name: () => randCatchPhrase() + ' Stream',
	description: null,
	streamId: () => randUuid(),
	cadence: Cadence.daily,
	values: null,
	actualsDataParams: null,
	forecastedDataParams: null,
});

const cashPositionFactory: FactoryDefinition<CashPosition> = Factory.extend<CashPosition>({
	name: 'Demo Cash Position',
	currency: 'USD',
	includeWeekends: false,
	balanceRounding: Rounding.none,
	utcOffset: 0,
	accounts: [],
	cashPositioningId: randUuid(),
	lastModifiedDate: DateTime.now().minus({ day: 1 }).toString(),
	createdAt: DateTime.now().minus({ week: 1 }).toString(),
});

const cashPositionDetailFactory: FactoryDefinition<CashPositionData> = Factory.extend<CashPositionData>({
	groups: [
		{
			groupType: 'accountId',
			groupValue: '',
			data: {
				accounts: [],
				totals: [
					{
						currency: 'USD',
						openingBalance: randAmount({ min: 1000, max: 1000000 }),
						closingBalance: randAmount({ min: 1000, max: 1000000 }),
						currentTransaction: 0,
						assumedTransactions: 0,
						expectedBalance: 0,
						targetBalance: randAmount({ min: 1000, max: 1000000 }),
						delta: randAmount({ min: -1000000, max: 1000000 }),
					},
					{
						currency: 'converted',
						openingBalance: randAmount({ min: 1000, max: 1000000 }),
						closingBalance: randAmount({ min: 1000, max: 1000000 }),
						currentTransaction: 0,
						assumedTransactions: 0,
						expectedBalance: 0,
						targetBalance: randAmount({ min: 10000, max: 1000000 }),
						delta: randAmount({ min: -1000000, max: 1000000 }),
					},
				],
			},
		},
	],
});

const insightFactory: FactoryDefinition<Insight> = Factory.extend<Insight>({
	insightId: randUuid(),
	family: rand(Object.values(InsightFamily)),
	familyType: rand(Object.values(InsightFamilyType)),
	date: DateTime.now().minus({ day: 1 }).toFormat('yyyy-MM-dd'),
	title: randCatchPhrase(),
	size: rand(Object.values(SnackSize)),
	metadata: {
		displayData: insightDisplayData(),
		actionData: [],
	},
	description: randTextRange({ min: 10, max: 100 }),
});

const entityFactory: FactoryDefinition<Entity> = Factory.extend<Entity>({
	entityId: () => randUuid(),
	name: () => randCompanyName(),
	nickname: () => randAlpha({ length: 3 }).join('').toUpperCase(),
	entityCode: () => randNumber({ min: 1000, max: 9999 }).toString(),
	erp: () => randNumber({ min: 1000, max: 9999 }).toString(),
	functionalCurrency: () => rand(chosenCurrencies),
	region: () => rand(['South', 'Northeast', 'West']),
	division: () => rand(['R&D', 'Ops', 'Investment']),
	description: () => randWord({ length: 10 }).join(' '),
	address: () => ({
		stateOrProvince: randState(),
		addressLine1: randStreetAddress(),
		addressLine2: rand(['Suite', 'Unit', 'Apt']) + ' ' + randNumber({ min: 1, max: 199 }).toString(),
		country: randCountry(),
		city: randCity(),
		zipCode: randZipCode(),
	}),
	contactPhone: () => randNumber({ min: 1000000000, max: 9999999999 }).toString(),
	contactEmail: () => randEmail(),
	contactName: () => randFullName(),
	openingDate: () => DateTime.fromJSDate(randPastDate()).toFormat('yyyy-MM-dd'),
	closingDate: () => rand([DateTime.fromJSDate(randPastDate()).toFormat('yyyy-MM-dd'), undefined]),
	accounts: [],
	extraProperties: [],
	personnel: () => {
		const personnel: EntityPersonnel[] = [];
		for (let i: number = 0; i < randNumber({ min: 1, max: 4 }); i++) {
			personnel.push({
				personnelId: randUuid(),
				name: randFullName(),
				email: randEmail(),
				phone: randNumber({ min: 1000000000, max: 9999999999 }).toString(),
				title: randJobTitle(),
				trovataUserId: undefined,
				notes: undefined,
			});
		}
		return personnel;
	},
});

const extraPropFactory: FactoryDefinition<EntityExtraProperty> = Factory.extend<EntityExtraProperty>({
	extraPropertyId: () => randUuid(),
	name: () => randWord({ capitalize: true }),
	type: () => rand([EntityFieldType.text, EntityFieldType.boolean, EntityFieldType.date, EntityFieldType.number]),
	description: () => randWord({ length: 5 }).join(' '),
	isFilter: () => randBoolean(),
});

const getEntityTreeResponseFactory: FactoryDefinition<GetEntityTreeResponse> = Factory.extend<GetEntityTreeResponse>({
	entityTree: undefined,
	unassignedEntities: [],
});

export const factories = {
	account: accountFactory,
	currencyBalance: currencyBalanceFactory,
	currentCash: currentCashDataFactory,
	transaction: transactionFactory,
	accountGrouping: accountGroupingFactory,
	tag: tagFactory,
	institution: institutionFactory,
	report: reportFactory,
	reportElement: reportElementFactory,
	forecast: forecastFactory,
	forecastStream: forecastStreamFactory,
	insight: insightFactory,
	cashPosition: cashPositionFactory,
	cashPositionDetail: cashPositionDetailFactory,
	entity: entityFactory,
	extraProp: extraPropFactory,
	getEntityTreeResponse: getEntityTreeResponseFactory,
};
