import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { GLCode, GlCodePayload } from '../../models/gl-code.model';
import { Tag } from '../../models/tag.model';
import { ClearLastCreatedGlCodeId, CreateGlCode, DeleteGlCode, GetGlCodes, UpdateGlCode } from '../../store/actions/glCode.action';
import { GlCodesState } from '../../store/state/glCode.state';

@Injectable({
	providedIn: 'root',
})
export class GlCodeFacadeService {
	@Select(GlCodesState.glCodes) glCodes$: Observable<GLCode[]>;
	@Select(GlCodesState.lastCreatedGlCodeId)
	lastCreatedGlCodeId$: Observable<string>;

	constructor(private store: Store) {}

	getGlCodes(glTags?: Tag[]): Promise<GLCode[]> {
		return new Promise(async (resolve, reject) => {
			try {
				if (glTags) {
					await firstValueFrom(this.store.dispatch(new GetGlCodes(glTags)));
				}
				this.glCodes$.subscribe((glCodes: GLCode[]) => {
					if (glCodes) {
						resolve(glCodes);
					}
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	getGlCode(codeId: string): Promise<GLCode> {
		return new Promise(async (resolve, reject) => {
			try {
				const glCodes: GLCode[] = await this.getGlCodes();
				const selectedGlCode: GLCode = glCodes.find((glCode: GLCode) => glCode.codeId === codeId);
				resolve(selectedGlCode);
			} catch (error) {
				reject(error);
			}
		});
	}

	createGlCode(glCodePayload: GlCodePayload): Promise<GLCode> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new CreateGlCode(glCodePayload)));
				let lastCreatedGlCodeId: string;
				this.lastCreatedGlCodeId$.subscribe((id: string) => (lastCreatedGlCodeId = id));
				const newGlCode: GLCode = await this.getGlCode(lastCreatedGlCodeId);
				const newGlCodeCopy: GLCode = JSON.parse(JSON.stringify(newGlCode));
				this.store.dispatch(new ClearLastCreatedGlCodeId());
				resolve(newGlCodeCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	updateGlCode(glCodePayload: GlCodePayload, codeId: string): Promise<GLCode> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new UpdateGlCode(glCodePayload, codeId)));
				const updatedGlCode: GLCode = await this.getGlCode(codeId);
				const updatedGlCodeCopy: GLCode = JSON.parse(JSON.stringify(updatedGlCode));
				resolve(updatedGlCodeCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	deleteGlCode(codeId: string): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new DeleteGlCode(codeId)));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}
}
