import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { combineLatest, firstValueFrom, Observable, Subscription, throwError } from 'rxjs';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { GetGlCodesResponse, Tag } from 'src/app/features/transactions/models/tag.model';
import { PermissionId, PermissionMap } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import { UpdateFilter } from 'src/app/shared/store/actions/filter-object.actions';
import {
	ClearGlCodeState,
	ClearLastCreatedGlCodeId,
	CreateGlCode,
	DeleteGlCode,
	GetGlCodes,
	InitGlCodeState,
	ResetGlCodeState,
	SetLastCreatedGlCodeId,
	UpdateGlCode,
} from '../actions/glCode.action';
import { GlCodeService } from '../../services/gl-code.service';
import { CreateGlCodeResponse, GLCode, GlCodePayload } from '../../models/gl-code.model';
import { GetValues } from '@trovata/app/shared/store/actions/tql-fields.actions';
import { TQLPropertyKey } from '@trovata/app/shared/models/tql.model';
import { TransactionFilterType } from '@trovata/app/shared/models/transaction-filters.model';

export class GlCodeStateModel {
	glCodes: GLCode[];
	isCached: boolean;
	lastCreatedGlCodeId: string;
}

@State<GlCodeStateModel>({
	name: 'glCodes',
	defaults: {
		glCodes: null,
		isCached: false,
		lastCreatedGlCodeId: null,
	},
})
@Injectable()
export class GlCodesState {
	@Selector()
	static glCodes(state: GlCodeStateModel) {
		return state.glCodes;
	}
	@Selector()
	static lastCreatedGlCodeId(state: GlCodeStateModel) {
		return state.lastCreatedGlCodeId;
	}

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private glCodeService: GlCodeService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitGlCodeState)
	async initGlCodeState(context: StateContext<GlCodeStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const glCodesStateIsCached: boolean = this.glCodesStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$]).subscribe({
				next: ([appReady, permissions]: [boolean, PermissionMap]) => {
					if (permissions && appReady) {
						if (permissions.has(PermissionId.readGlTags)) {
							if (glCodesStateIsCached) {
								context.patchState({
									glCodes: deserializedState.glCodes.glCodes,
									isCached: true,
								});
							} else {
								context.dispatch(new GetGlCodes());
							}
						} else {
							context.patchState({
								glCodes: [],
								isCached: false,
							});
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}
	@Action(GetGlCodes)
	getGlCodes(context: StateContext<GlCodeStateModel>, action: GetGlCodes): Promise<void> {
		return new Promise(async (resolve, reject) => {
			let state = context.getState();
			try {
				const glCodesResponse: HttpResponse<GetGlCodesResponse> = await firstValueFrom(this.glCodeService.getAllGLCodes());
				const glCodes: GLCode[] = glCodesResponse.body.glCodes;
				if (action.glTags) {
					glCodes.forEach((code: GLCode) => {
						action.glTags.forEach((tag: Tag) => {
							if (code.codeId === tag.tagMetadata.glCode1) {
								if (!code.appliedTags) {
									code.appliedTags = [];
								}
								code.appliedTags.push(tag.tagTitle);
							}
							if (code.codeId === tag.tagMetadata.glCode2) {
								if (!code.appliedTags) {
									code.appliedTags = [];
								}
								code.appliedTags.push(tag.tagTitle);
							}
						});
					});
				}
				const sortedGlCodes: GLCode[] = this.sortGlCodes(glCodes);
				state.glCodes = sortedGlCodes;
				context.patchState({ glCodes: glCodes, isCached: true });
				resolve();
			} catch (error) {
				state = context.getState();
				context.patchState(state);
				reject(error);
			}
		});
	}

	@Action(CreateGlCode)
	createGlCode(context: StateContext<GlCodeStateModel>, action: CreateGlCode): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const glCodePayload: GlCodePayload = action.glCodePayload;
			try {
				const createGlCodeResponse: HttpResponse<CreateGlCodeResponse> = await firstValueFrom(this.glCodeService.createGLCode(glCodePayload));
				if (createGlCodeResponse.body.codeId) {
					context.patchState({
						lastCreatedGlCodeId: createGlCodeResponse.body.codeId,
					});
					await firstValueFrom(context.dispatch(new SetLastCreatedGlCodeId(createGlCodeResponse.body.codeId)));
					await firstValueFrom(context.dispatch(new GetGlCodes()));
					await firstValueFrom(this.store.dispatch(new UpdateFilter('glCode', null)));
					this.store.dispatch(new GetValues([TQLPropertyKey.creditGlCode]));
					await firstValueFrom(this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.tags)));
					this.store.dispatch(new GetValues([TQLPropertyKey.debitGlCode]));
					resolve();
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateGlCode)
	updateGlCode(context: StateContext<GlCodeStateModel>, action: UpdateGlCode) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const updateGlCodePayload: GlCodePayload = action.glCodePayload;
				const updatedGlCode: HttpResponse<GLCode> = await firstValueFrom(this.glCodeService.editGLCode(updateGlCodePayload, action.glCodeId));
				const state: GlCodeStateModel = JSON.parse(JSON.stringify(context.getState()));
				const indexToUpdate: number = state.glCodes.findIndex((glCode: GLCode) => glCode.codeId === updatedGlCode.body.codeId);
				const glCodes1: GLCode[] = state.glCodes.slice(0, indexToUpdate);
				const glCodes2: GLCode[] = state.glCodes.slice(indexToUpdate + 1, state.glCodes.length);
				const updatedGlCodes: GLCode[] = [...glCodes1, updatedGlCode.body, ...glCodes2];
				const sortedGlCodes: GLCode[] = this.sortGlCodes(updatedGlCodes);
				context.patchState({ glCodes: sortedGlCodes });
				await firstValueFrom(this.store.dispatch(new UpdateFilter('glCode', null)));
				resolve();
			} catch (error) {
				reject(new Error('Could not update Gl Code'));
			}
		});
	}

	@Action(DeleteGlCode)
	deleteGlCode(context: StateContext<GlCodeStateModel>, action: DeleteGlCode) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const glCodeToDelete: string = action.glCodeId;
				const state: GlCodeStateModel = context.getState();
				const glCodes: GLCode[] = state.glCodes;
				const i: number = glCodes.findIndex((glCode: GLCode) => glCode.codeId === glCodeToDelete);
				if (i > -1) {
					glCodes.splice(i, 1);
				}
				await firstValueFrom(this.glCodeService.deleteGLCode(glCodeToDelete));
				context.patchState({ glCodes: glCodes });
				await firstValueFrom(this.store.dispatch(new UpdateFilter('glCode', null)));
				resolve();
			} catch (error) {
				reject(new Error('Could not delete Gl Code'));
			}
		});
	}

	@Action(ResetGlCodeState)
	resetGlCodeState(context: StateContext<GlCodeStateModel>) {
		context.dispatch(new ClearGlCodeState());
		context.dispatch(new InitGlCodeState());
	}

	@Action(ClearGlCodeState)
	clearGlCodeState(context: StateContext<GlCodeStateModel>) {
		this.appReadySub.unsubscribe();
		const state: GlCodeStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ClearLastCreatedGlCodeId)
	clearLastCreatedGlCodeId(context: StateContext<GlCodeStateModel>) {
		context.patchState({ lastCreatedGlCodeId: null });
	}

	@Action(SetLastCreatedGlCodeId)
	setLastCreatedGlCodeId(context: StateContext<GlCodeStateModel>, action: SetLastCreatedGlCodeId) {
		context.patchState({ lastCreatedGlCodeId: action.id });
	}

	private glCodesStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedGlCodesState: GlCodeStateModel | undefined = deserializedState.glCodes;
		if (deserializedGlCodesState && deserializedGlCodesState.glCodes) {
			return true;
		} else {
			return false;
		}
	}

	private sortGlCodes(glCodes: GLCode[]) {
		const sortedGlCodes: GLCode[] = glCodes.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
		return sortedGlCodes;
	}
}
