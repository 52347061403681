import { AdminApproval } from 'src/app/shared/models/admin-approval.model';
import { AccountToUpdate } from '../../models/account.model';

export class InitPaymentsAccountsState {
	static readonly type: string = '[PaymentsAccounts] InitPaymentsAccountsState';
}

export class ClearPaymentsAccountsState {
	static readonly type: string = '[PaymentsAccounts] ClearPaymentsAccountsState';
}

export class ResetPaymentsAccountsState {
	static readonly type: string = '[PaymentsAccounts] ResetPaymentsAccountsState';
}

export class GetPaymentsAccounts {
	static readonly type: string = '[PaymentsAccounts] GetPaymentsAccounts';
}

export class UpdatePaymentsAccount {
	static readonly type: string = '[PaymentsAccounts] UpdatePaymentsAccount';
	constructor(public accountToUpdate: AccountToUpdate) {}
}

export class PostPaymentsAccountAdminApproval {
	static readonly type: string = '[PaymentsAccounts] PostPaymentsAccountAdminApproval';
	constructor(public adminApproval: AdminApproval) {}
}

export class GetInstitutionInstructions {
	static readonly type: string = '[PaymentsAccounts] GetInstitutionInstructions';
}

export class GetPaymentsAccountById {
	static readonly type: string = '[PaymentsAccounts] GetPaymentsAccountById';
	constructor(public accountId: string) {}
}
