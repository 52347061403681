import { Tag, TagPayload } from '../../models/tag.model';
import { GLCode } from '../../models/gl-code.model';

export class InitGlTagsState {
	static readonly type: string = '[GlTag] InitGlTagsState';
}

export class ClearGlTagsState {
	static readonly type: string = '[GlTag] ClearGlTagsState';
}

export class ResetGlTagsState {
	static readonly type: string = '[GlTag] ResetGlTagsState';
}

export class GetGlTags {
	static readonly type: string = '[GlTag] GetGlTags';
	constructor(public refresh?: boolean) {}
}

export class CreateGlTag {
	static readonly type: string = '[GlTag] CreateGlTag';
	constructor(public tagPayload: TagPayload) {}
}

export class AddGlTagToState {
	static readonly type: string = '[GlTag] AddGlTagToState';
	constructor(public tag: Tag) {}
}

export class DeleteGlTag {
	static readonly type: string = '[GlTag] DeleteGlTag';
	constructor(public tag: Tag) {}
}

export class UpdateGlTag {
	static readonly type: string = '[GlTag] UpdateGlTag';
	constructor(
		public tag: Tag,
		public tagPayload: TagPayload
	) {}
}

export class CreateGlCode {
	static readonly type: string = '[GlTag] CreateGlCode';
	constructor(public glCode: GLCode) {}
}
export class DeleteGlCode {
	static readonly type: string = '[GlTag] DeleteGlCode';
	constructor(public glCode: GLCode) {}
}
export class UpdateGlCode {
	static readonly type: string = '[GlTag] UpdateGlCode';
	constructor(public glCode: GLCode) {}
}

export class ClearLastCreatedGlTagId {
	static readonly type: string = '[GlTag] ClearLastCreatedGlTagId';
}

export class SetLastCreatedGlTagId {
	static readonly type: string = '[GlTag] SetLastCreatedGlTagId';
	constructor(public id: string) {}
}
