import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataSeries } from '../../models/graphs.models';
import { LineChartBuilder } from '../../models/highcharts.model';
import { InsightGroupRow, TrendInputs } from '../../models/insights.model';

@Component({
	selector: 'trovata-line-chart-trend-row',
	templateUrl: 'line-chart-trend-row.component.html',
	styleUrls: ['./line-chart-trend-row.styles.scss'],
})
export class LineChartTrendRowComponent implements InsightGroupRow, OnChanges {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() value: string;
	@Input() graphData: DataSeries[];
	@Input() trend: TrendInputs;
	@Input() dense: boolean;

	lineChartBuilder: LineChartBuilder;

	constructor() {
		this.dense = false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['graphData'] || changes['tooltipsEnabled']) {
			this.updateLineChart();
		}
	}

	private updateLineChart(): void {
		this.lineChartBuilder = new LineChartBuilder({
			tooltipsEnabled: false,
			dataSeries: this.graphData,
		});
	}
}
