import { Component, Input } from '@angular/core';

@Component({
	selector: 'trovata-value-with-trend',
	templateUrl: './value-with-trend.component.html',
	styleUrls: ['./value-with-trend.component.scss'],
})
export class ValueWithTrendComponent {
	@Input() value: string;
	@Input() trendPosition: 'down' | 'right';
	@Input() valueStyles: string[];
}
