import { InsightFamily, InsightFamilyType } from 'src/app/features/insights/models/insights.model';
import { TransactionsExplorerData } from 'src/app/features/insights/models/transactions-explorer.model';
import { DateTime } from 'luxon';
import { Server } from 'miragejs';
import { explorerSummary, transactionExplorerTransactions } from '../helpers.mock';
import { AppSchema } from '../types';

export function addInsightsWorkspaceHandlers(server: Server) {
	server.get('/data/mlr/v2/insights', (schema: AppSchema) => ({
		insights: {
			categories: {
				displayName: 'Categories',
				insights: schema.db.insights.where({
					familyType: InsightFamilyType.categoryCurrentCredit,
				}),
				position: 2,
				size: 'large',
			},
			direction: {
				position: 3,
				size: 'large',
				insights: schema.db.insights.where({
					familyType: InsightFamilyType.direction,
				}),
				displayName: 'Direction',
			},
			number: {
				position: 0,
				size: 'small',
				displayName: '',
				insights: schema.db.insights.where({ family: InsightFamily.number }),
			},
		},
	}));

	server.post(
		'/data/mlr/v1/transactions/explorer',
		(): TransactionsExplorerData => ({
			transactions: transactionExplorerTransactions(500),
			summaries: explorerSummary(),
			date: DateTime.now().toFormat('yyyy-MM-dd'),
		})
	);
}
