import { Cadence } from 'src/app/features/reports/models/cadence.model';
import { Server } from 'miragejs';
import { RollingType, ToDateOption } from '../../models/date-range-picker.model';
import { analysisBalanceValues, analysisTransactionValues, balancesAggregation } from '../helpers.mock';
import { AppRegistry, ReportDbData, ReportElementDbData } from '../types';
import { AnalysisBalanceProperty, AnalysisType } from 'src/app/features/reports/models/analysis.model';
import { GroupByKey } from '../../utils/key-translator';
import { ChartType } from '../../models/highcharts.model';
import { DateTime } from 'luxon';

export function createReports(server: Server<AppRegistry>) {
	const groupedMonthlyBalancesReport: ReportDbData = server.create('report', {
		reportId: 'groupedBalancesReportId',
		name: 'Monthly Balances by Account',
	});
	const acctIds: string[] = server.db.accounts.map(acc => acc.id);
	const element: ReportElementDbData = server.create('reportElement', {
		elementId: 'groupedBalancesElementId',
		type: AnalysisType.balances,
		report: groupedMonthlyBalancesReport,
		parameters: {
			balanceProperty: AnalysisBalanceProperty.bankClosingLedgerConverted,
			groupBy: [GroupByKey.account],
			tql: null,
			analysisType: null,
			cadence: Cadence.monthly,
		},
		data: {
			aggregation: balancesAggregation(acctIds, 'accountId', 3, 'monthly'),
			summary: analysisBalanceValues(3, 'monthly'),
			currencyConverted: 'USD',
			type: AnalysisType.balances,
			balanceProperty: AnalysisBalanceProperty.bankClosingLedgerConverted,
			cadence: Cadence.monthly,
		},
		preferences: {
			calendarSettings: {
				endDate: DateTime.now().toFormat('yyyy-MM-dd'),
				startDate: DateTime.now().minus({ weeks: 3 }).toFormat('yyyy-MM-dd'),
				showWeekends: true,
				lastModifiedDate: DateTime.now().toFormat('yyyy-MM-dd'),
				customOptionCadence: null,
				customOptionPeriods: null,
				rollingType: RollingType.RollingBoth,
				toDateOption: ToDateOption.wtd,
			},
			displaySettings: {
				chartType: ChartType.stackedbar,
				trueRounding: null,
				trueRoundingOption: null,
				headlineValue: null,
				headlineToggle: null,
				chartToggle: true,
				tableToggle: true,
				trendMetrics: null,
				primaryMetric: null,
				netToggle: null,
			},
			visualType: null,
			elementOrder: null,
			balanceProperty: null,
		},
		// preferences: elementPreferences({
		// 	exclusiveTagAggregation: false,
		// 	elementOrder: 1,
		// 	weekends: false,
		// 	balanceType: AnalysisBalanceProperty.bankClosingLedgerConverted,
		// 	graphType: 'stackedbar',
		// 	endDate: moment().format('YYYY-MM-DD'),
		// 	startDate: moment().add(-3, 'weeks').format('YYYY-MM-DD'),
		// 	datesLastModifiedDate: moment().format('YYYY-MM-DD'),
		// 	cadence: Cadence.monthly,
		// }),
	} as any);
	server.db.reports.update(groupedMonthlyBalancesReport.id, {
		elements: [element],
	});

	const transactionsReport: ReportDbData = server.create('report', {
		reportId: 'transactionsReportId',
		name: 'Weekly Rent Transactions',
	});
	const elementTwo: any = server.create('reportElement', {
		type: AnalysisType.transactions,
		elementId: 'transactionsReportElementId',
		report: transactionsReport,
		preferences: {
			displaySettings: {
				chartType: ChartType.stackedbar,
				trueRounding: null,
				trueRoundingOption: null,
				headlineValue: null,
				headlineToggle: null,
				chartToggle: true,
				tableToggle: true,
				trendMetrics: null,
				primaryMetric: null,
				netToggle: null,
			},
			calendarSettings: {
				endDate: '2023-02-06',
				startDate: DateTime.fromISO('2023-02-06').minus({ weeks: 13 }).toFormat('yyyy-MM-dd'),
				showWeekends: true,
				lastModifiedDate: DateTime.now().toFormat('yyyy-MM-dd'),
				customOptionCadence: null,
				customOptionPeriods: null,
				rollingType: RollingType.RollingBoth,
				toDateOption: ToDateOption.wtd,
			},
			visualType: null,
			elementOrder: null,
			balanceProperty: null,
		},
		parameters: {
			tql: null,
			analysisType: null,
			cadence: Cadence.weekly,
			currencyOverride: 'USD',
			// groupBy: '',
			// tag: ['2'],
			// tagId: ['2'],
		},
		data: {
			aggregation: [],
			summary: analysisTransactionValues(14, 'weekly'),
			currencyConverted: 'USD',
			type: AnalysisType.transactions,
			cadence: Cadence.weekly,
		} as any,
	});
	server.db.reports.update(transactionsReport.id, { elements: [elementTwo] });
}
