import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { catchError, firstValueFrom, Observable, Subscription, tap, throwError } from 'rxjs';
import { DataOutlet, IDataOutlet, ISheet, IWorkbook, Sheet, Workbook } from '../../models/workbook.model';
import {
	ClearWorkbooksState,
	CreateWorkbook,
	DeleteWorkbook,
	GetWorkbooks,
	InitWorkbooksState,
	ResetWorkbooksState,
	UpdateWorkbook,
} from '../actions/workbooks.actions';
import { WorkbooksService } from '../../services/workbooks.service';
import { HttpResponse } from '@angular/common/http';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { ActionType, WorkbookUpdatedEvent } from '@trovata/app/shared/models/updated-events.model';
import { EntitledStateModel } from '@trovata/app/core/store/state/core/core.state';

export class WorkbooksStateModel implements EntitledStateModel {
	workbooks: IWorkbook[];
	workbooksInFlight: boolean;
	isCached: boolean;
}

@State<WorkbooksStateModel>({
	name: 'workbooks',
	defaults: {
		workbooks: null,
		workbooksInFlight: null,
		isCached: null,
	},
})
@Injectable()
export class WorkbooksState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private isInitialized: boolean;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private workbooksService: WorkbooksService,
		private updatedEventsService: UpdatedEventsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.isInitialized = false;
	}

	@Selector() static workbooks(state: WorkbooksStateModel): IWorkbook[] {
		return state.workbooks;
	}

	@Selector() static workbooksInFlight(state: WorkbooksStateModel): boolean {
		return state.workbooksInFlight;
	}

	@Action(InitWorkbooksState)
	async initWorkbooksState(context: StateContext<WorkbooksStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const workbooksStateIsCached: boolean = this.workbooksStateIsCached(deserializedState);
			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (!this.isInitialized && appReady) {
						if (workbooksStateIsCached) {
							const state: WorkbooksStateModel = deserializedState.workbooks;
							context.patchState(state);
						} else {
							context.dispatch(new GetWorkbooks());
						}
						this.isInitialized = true;
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(ResetWorkbooksState)
	resetWorkbooksState(context: StateContext<WorkbooksStateModel>): void {
		context.dispatch(new ClearWorkbooksState());
		context.dispatch(new InitWorkbooksState());
	}

	@Action(ClearWorkbooksState)
	clearWorkbooksState(context: StateContext<WorkbooksStateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: WorkbooksStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(GetWorkbooks)
	getWorkbooks(context: StateContext<WorkbooksStateModel>): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				context.patchState({ workbooksInFlight: true });
				const response: HttpResponse<IWorkbook[]> = await firstValueFrom(this.workbooksService.getWorkbooks());
				const workboooks: IWorkbook[] = response.body;
				const sortedWorkbooks: IWorkbook[] = this.sortWorkbooksByName(workboooks);
				context.patchState({ workbooksInFlight: false, isCached: true, workbooks: sortedWorkbooks });
			} catch (error) {
				context.patchState({ workbooksInFlight: false });
				reject(error);
			}
		});
	}

	@Action(CreateWorkbook)
	async postWorkbook(context: StateContext<WorkbooksStateModel>, action: CreateWorkbook): Promise<IWorkbook> {
		const createdWorkbookResponse: HttpResponse<IWorkbook> = await firstValueFrom(this.workbooksService.postWorkbook(action.workbook));
		await firstValueFrom(context.dispatch(new GetWorkbooks()));
		return createdWorkbookResponse.body;
	}

	@Action(DeleteWorkbook)
	deleteWorkbook(context: StateContext<WorkbooksStateModel>, action: DeleteWorkbook): Observable<HttpResponse<IWorkbook[]>> {
		return this.workbooksService.deleteWorkbook(action.workbookId).pipe(
			tap((response: HttpResponse<any>) => {
				const state: WorkbooksStateModel = context.getState();
				const filteredWorkbooks: IWorkbook[] = state.workbooks.filter((filterWorkbook: IWorkbook) => filterWorkbook.workbookId !== action.workbookId);
				const sortedWorkbooks: IWorkbook[] = this.sortWorkbooksByName(filteredWorkbooks);
				state.workbooks = sortedWorkbooks;
				this.updatedEventsService.updateItem(new WorkbookUpdatedEvent(ActionType.delete, action.workbookId));
				context.patchState(state);
				context.dispatch(new GetWorkbooks());
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(UpdateWorkbook)
	updateWorkbook(context: StateContext<WorkbooksStateModel>, action: UpdateWorkbook): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			try {
				await this.addOrDeleteSheetsAndDataOutlets(action.workbookToUpdate, action.deletedSheetIds, action.deletedDataOutletIds);
				const workbookCopy: IWorkbook = JSON.parse(JSON.stringify(action.workbook));
				const updatedWorkbookResponse: HttpResponse<IWorkbook> = await firstValueFrom(this.workbooksService.patchWorkbook(action.workbookId, workbookCopy));
				const state: WorkbooksStateModel = context.getState();
				const filteredWorkbooks: IWorkbook[] = state.workbooks.filter((filterWorkbook: IWorkbook) => filterWorkbook.workbookId !== action.workbookId);
				filteredWorkbooks.push(updatedWorkbookResponse.body);
				const sortedWorkbooks: IWorkbook[] = this.sortWorkbooksByName(filteredWorkbooks);
				state.workbooks = sortedWorkbooks;
				this.updatedEventsService.updateItem(new WorkbookUpdatedEvent(ActionType.update, action.workbookId));
				context.patchState(state);
				context.dispatch(new GetWorkbooks());
				resolve(true);
			} catch (error) {
				reject(error);
			}
		});
	}

	private async addOrDeleteSheetsAndDataOutlets(workbookToUpdate: Workbook, deletedSheetIds: string[], deletedDataOutletIds: string[]): Promise<void> {
		try {
			const outletsToPost: IDataOutlet[] = [];
			const sheetsToPost: ISheet[] = [];
			workbookToUpdate.sheets.forEach((sheet: Sheet, index: number) => {
				sheet.sortOrderIndex = index;
				if (!sheet.sheetId) {
					sheetsToPost.push(this.workbooksService.prepareSheetForSave(sheet, index, true));
				} else {
					sheet.dataOutlets.forEach((dataOutlet: DataOutlet) => {
						if (!dataOutlet.dataOutletId) {
							dataOutlet.sheetId = sheet.sheetId;
							outletsToPost.push(this.workbooksService.prepareDataOutletForSave(dataOutlet, true));
						}
					});
				}
			});
			const postRequests: Promise<any>[] = [
				...sheetsToPost.map((sheet: ISheet) => this.postSheet(sheet, workbookToUpdate.workbookId)),
				...outletsToPost.map((outlet: IDataOutlet) => this.postOutlet(outlet, outlet.sheetId)),
			];
			await Promise.all(postRequests);
			if (deletedDataOutletIds.length || deletedSheetIds.length) {
				await Promise.all(deletedDataOutletIds.map((deletedOutletId: string) => this.deleteDataOutlet(deletedOutletId)));
				await Promise.all(deletedSheetIds.map((deletedSheetId: string) => this.deleteSheet(deletedSheetId)));
			}
		} catch (error) {
			throw error;
		}
	}

	private postSheet(sheet: ISheet, workbookId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.workbooksService.postSheet(sheet, workbookId).subscribe({
				next: () => resolve(),
				error: err => reject(err),
			});
		});
	}

	private postOutlet(outlet: IDataOutlet, sheetId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.workbooksService.postDataOutlet(outlet, sheetId).subscribe({
				next: () => resolve(),
				error: err => reject(err),
			});
		});
	}

	private deleteDataOutlet(dataOutletId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.workbooksService.deleteDataOutlet(dataOutletId).subscribe({
				next: () => resolve(),
				error: err => reject(err),
			});
		});
	}

	private deleteSheet(sheetId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.workbooksService.deleteSheet(sheetId).subscribe({
				next: () => resolve(),
				error: err => reject(err),
			});
		});
	}

	private workbooksStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState && deserializedState.workbooks && deserializedState.workbooks.workbooks !== null) {
			return true;
		} else {
			return false;
		}
	}

	private sortWorkbooksByName(workbooks: IWorkbook[]): IWorkbook[] {
		workbooks = workbooks.sort((workbookA: IWorkbook, workbookB: IWorkbook) => {
			if (workbookA.name.toLowerCase() < workbookB.name.toLowerCase()) {
				return -1;
			} else if (workbookA.name.toLowerCase() > workbookB.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return workbooks;
	}
}
