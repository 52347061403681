import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UserAccessConfig } from '../models/feature.model';

@Injectable({
	providedIn: 'root',
})
export class FeatureConfigService {
	constructor(private httpClient: HttpClient) {}

	getFeatureConfig(): Observable<UserAccessConfig> {
		return this.httpClient.get<UserAccessConfig>(environment.trovataAPI('workspace') + '/config');
	}

	toggleEnterpriseFlags(): Observable<null> {
		return this.httpClient.post<null>(environment.trovataAPI('workspace') + '/customer/subscription/enterprise', {});
	}
}
