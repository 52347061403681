export const NotAccountAndIntraday: (accountIds: string[]) => any = (accountIds: string[]) => ({
	lhs: {
		op: 'not',
		expr: {
			lhs: {
				field: 'account',
			},
			op: 'in',
			rhs: accountIds,
		},
	},
	op: 'and',
	rhs: {
		lhs: {
			field: 'isIntraday',
		},
		op: '==',
		rhs: 'true',
	},
});

export const AutopayCurrencyWithDateRange: (currencies: string[], startDate: string, toDate: string) => any = (
	currencies: string[],
	startDate: string,
	toDate: string
) => ({
	lhs: {
		lhs: {
			lhs: {
				field: 'date',
			},
			op: '<=',
			rhs: startDate,
		},
		op: 'and',
		rhs: {
			lhs: {
				field: 'date',
			},
			op: '>=',
			rhs: toDate,
		},
	},
	op: 'and',
	rhs: {
		lhs: {
			lhs: {
				field: 'currency',
			},
			op: 'in',
			rhs: currencies,
		},
		op: 'and',
		rhs: {
			lhs: {
				field: 'fuzzy',
			},
			op: 'like',
			meta: {
				op: 'contains',
			},
			rhs: '%autopay%',
		},
	},
});

export const ManualAndMoreThan200 = {
	lhs: {
		lhs: {
			field: 'descriptionDetail',
		},
		op: 'like',
		meta: {
			op: 'contains',
		},
		rhs: '%debt%',
	},
	op: 'and',
	rhs: {
		lhs: {
			lhs: {
				field: 'manualAccount',
			},
			op: '==',
			rhs: 'true',
		},
		op: 'and',
		rhs: {
			meta: {
				nested: 'true',
			},
			lhs: {
				lhs: {
					field: 'amount',
				},
				op: '<=',
				rhs: '-200',
			},
			op: 'or',
			rhs: {
				lhs: {
					field: 'amount',
				},
				op: '>=',
				rhs: '200',
			},
		},
	},
};

export const MoneyOrderForRegionOrInst: (regionIds: string[], institutionIds: string[]) => any = (regionIds: string[], institutionIds: string[]) => ({
	lhs: {
		lhs: {
			field: 'baiDescription',
		},
		op: 'like',
		meta: {
			op: 'contains',
		},
		rhs: '%money order%',
	},
	op: 'and',
	rhs: {
		op: 'and',
		lhs: {
			lhs: {
				field: 'type',
			},
			op: 'in',
			rhs: ['debit'],
		},
		rhs: {
			meta: {
				nested: 'true',
			},
			lhs: {
				lhs: {
					field: 'region',
				},
				op: 'in',
				rhs: regionIds,
			},
			op: 'or',
			rhs: {
				lhs: {
					field: 'institution',
				},
				op: 'in',
				rhs: institutionIds,
			},
		},
	},
});

export const TagParentWithExclude: (includeTagIds: string[], excludeTagIds: string[]) => any = (includeTagIds: string[], excludeTagIds: string[]) => ({
	lhs: {
		op: 'not',
		expr: {
			lhs: {
				field: 'tag',
			},
			op: 'in',
			rhs: excludeTagIds,
		},
	},
	op: 'and',
	rhs: {
		lhs: {
			field: 'tag',
		},
		op: 'in',
		rhs: includeTagIds,
	},
});
