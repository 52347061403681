import { randAmount, randNumber, randUuid } from '@ngneat/falso';
import { DateTime } from 'luxon';
import { Server } from 'miragejs';
import { AccountDbData, AppSchema } from '../types';

export function addCashPositionWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post('/data/v1/cash-positioning', () => {
		unhandledRequestMade();
		return null;
	});

	server.post('/data/v1/cash-positioning/list', (schema: AppSchema) => {
		const reports = schema.db.cashPositions;
		return { reports };
	});

	server.post('/data/v1/cash-positioning/**/data', (schema: AppSchema) => {
		const data = schema.db.cashPositionDetails[0];
		const accounts = schema.db.accounts.map((account: AccountDbData) => ({
			currency: account.currency,
			openingBalance: randAmount({ min: 1000, max: 100000 }),
			closingBalance: randAmount({ min: 1000, max: 100000 }),
			currentTransaction: null,
			assumedTransactions: null,
			expectedBalance: 0,
			targetBalance: randAmount({ min: 1000, max: 100000 }),
			delta: randAmount({ min: 1000, max: 100000 }),
			accountId: account.id,
			institutionId: account.institutionId,
			entityGroupingId: account.entityGroupingId,
			regionGroupingId: account.regionGroupingId,
			divisionGroupingId: account.divisionGroupingId,
			comment: [''],
			assumedTransactionsList: {
				prefillLabels: false,
				prefillValues: false,
				assumptions: [],
			},
		}));
		data.groups[0].data.accounts = accounts;
		return data;
	});
	server.post('/data/v1/cash-positioning/**/versions/list', () => {
		const numb = randNumber({ min: 2, max: 7 });
		const versions = [];
		for (let i = 0; i < numb; i++) {
			versions.push({
				versionId: randUuid(),
				createdAt: DateTime.now().minus({ week: 1 }).toString(),
				createdBy: randUuid(),
			});
		}
		return { versions };
	});
	server.post('data/v1/cash-positioning/**/versions', () => {
		unhandledRequestMade();
		return null;
	});
	server.post('/data/v1/cash-positioning/**/versions/overview', () => {
		let date = DateTime.now();
		const aYearAgo = DateTime.now().minus({ year: 1 });
		const versions = [];
		while (date.startOf('day') > aYearAgo) {
			versions.push({
				date: date.toFormat('yyyy-MM-dd'),
				delta: randAmount(),
				targetBalance: randAmount(),
				expectedBalance: randAmount(),
				accountsBelowTarget: randNumber({ min: 0, max: 6 }),
			});
			date = date.minus({ day: 1 });
		}
		return { versions };
	});
}
