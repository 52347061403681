import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Select, Selector } from '@ngxs/store';
import {
	checkForBadHttpErrorMessage,
	checkToAddStateApiError,
	checkToRemoveStateApiError,
	StateApiError,
	TrovataAppState,
} from 'src/app/core/models/state.model';
import { combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import {
	Payment,
	PaymentExecutionStage,
	PaymentExecutionStageSignature,
	PaymentHistory,
	BatchPayment,
	GetBatchPaymentsResponse,
	GetPaymentsV1Response,
	PaymentsListBody,
} from '../../../models/payment.model';
import { PaymentsService } from '../../../services/payments/payments.service';
import {
	SetStageFeedback,
	CreatePayment,
	SetPaymentsTabIndex,
	CancelPayment,
	ClearPaymentsState,
	ResetPaymentsState,
	InitPaymentsState,
	GetBatchPayments,
	CreateBatchPayment,
	SetBatchStageFeedback,
	CancelBatchPayment,
	GetPaymentById,
	GetPaginatedPayments,
	GetPaginatedBatchPayments,
	UpdateStoreBatchsPayments,
	GetBatchPaymentById,
	GetBatchsPayments,
	ResetCachedPayments,
	ResetCachedBulkPayments,
	GetAssignedPaymentsToPoll,
	GetBatchPaymentsToPoll,
	GetPaymentToPoll,
	GetBatchPaymentToPoll,
	UploadFileToPayment,
	DeleteFileFromPayment,
} from '../../actions/payments.actions';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { PermissionId, PermissionMap } from 'src/app/features/settings/models/feature.model';
import { EntitledStateModel } from 'src/app/core/store/state/core/core.state';
import { AuthUser } from '@trovata/app/core/models/auth.model';
import { ApprovalUser, PaymentStatus } from '../../../models/workflow.model';
import { UserApprovedPaymentPipe } from '../../../pipes/user-approved-payment.pipe';
import { ReadyForReviewAction, ReadyForReviewSnack, ReadyForReviewSnackParams, sortSnacksByDate } from 'src/app/shared/models/ready-for-review-snack.model';
import { TrovataResourceType, TrovataResourceViewText } from 'src/app/shared/models/trovata.model';
import { DateTime } from 'luxon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CancelPollRequestService } from '@trovata/app/shared/services/cancel-poll-request.service';
import { PollRequestKey } from '@trovata/app/shared/models/cancel-poll-request.model';

export class PaymentsStateModel extends EntitledStateModel {
	payments: Payment[];
	batchPayments: BatchPayment[];
	tabIndex: number;
	paymentSnacks: ReadyForReviewSnack[];
	batchPaymentSnacks: ReadyForReviewSnack[];
	getPaymentsFrom: number;
	getPaymentsSize: number;
	getBatchPaymentsFrom: number;
	getBatchPaymentsSize: number;
	apiErrors: StateApiError[];
}

@State<PaymentsStateModel>({
	name: 'payments',
	defaults: {
		payments: null,
		batchPayments: null,
		paymentSnacks: null,
		batchPaymentSnacks: null,
		tabIndex: null,
		isCached: false,
		getPaymentsFrom: null,
		getPaymentsSize: null,
		getBatchPaymentsFrom: null,
		getBatchPaymentsSize: null,
		apiErrors: null,
	},
})
@Injectable()
export class PaymentsState {
	@Select(CustomerFeatureState.permissionIds) userAvailablePermissions$: Observable<PermissionMap>;
	@Select(CustomerFeatureState.paymentsEnabled) paymentsEnabled$: Observable<boolean>;

	private appReady$: Observable<boolean>;
	private authUser$: Observable<AuthUser>;
	private appReadySub: Subscription;
	private isInitialized: boolean;
	private authUser: AuthUser;
	private initGetPaymentsSize: number;
	private initGetBatchPaymentsSize: number;

	constructor(
		private paymentsService: PaymentsService,
		private store: Store,
		private serializationService: SerializationService,
		private sanitizer: DomSanitizer,
		private cancelPollRequestService: CancelPollRequestService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.authUser$ = this.store.select((state: TrovataAppState) => state.auth.authUser);
		this.initGetPaymentsSize = 100;
		this.initGetBatchPaymentsSize = 100;
	}

	@Selector()
	static payments(state: PaymentsStateModel): Payment[] {
		return state.payments;
	}

	@Selector()
	static paymentsIsCached(state: PaymentsStateModel): boolean {
		return state.isCached;
	}

	@Selector()
	static batchPayments(state: PaymentsStateModel): BatchPayment[] {
		return state.batchPayments;
	}

	@Selector()
	static tabIndex(state: PaymentsStateModel): number {
		return state.tabIndex;
	}

	@Selector()
	static paymentsApiErrors(state: PaymentsStateModel): StateApiError[] {
		return state.apiErrors;
	}

	@Selector()
	static paymentSnacks(state: PaymentsStateModel): ReadyForReviewSnack[] {
		return state.paymentSnacks;
	}

	@Action(InitPaymentsState)
	async initPaymentsState(context: StateContext<PaymentsStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const paymentsStateIsCached: boolean = this.paymentsStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$, this.authUser$, this.paymentsEnabled$]).subscribe({
				next: ([appReady, permissions, authUser, paymentsEnabled]: [boolean, PermissionMap, AuthUser, boolean]) => {
					if (!this.isInitialized && appReady && permissions && authUser && paymentsEnabled) {
						this.authUser = authUser;
						if (permissions.has(PermissionId.readPayments)) {
							if (paymentsStateIsCached) {
								const state: PaymentsStateModel = deserializedState.payments;
								this.postProcessPaymentsData(context, state);
								this.postProcessBatchPaymentsData(context, state);
							} else {
								this.initDefaultPaymentsState(context);
							}
							this.isInitialized = true;
						} else {
							context.patchState({ payments: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetPaginatedPayments)
	getPaginatedPayments(context: StateContext<PaymentsStateModel>, action: GetPaginatedPayments): Observable<GetPaymentsV1Response> {
		return this.paymentsService.getPaginatedPayments(action.getPaymentsBody).pipe(
			tap((getPaymentsV1Response: GetPaymentsV1Response) => {
				checkToRemoveStateApiError(context, GetPaginatedPayments);
				const state: PaymentsStateModel = context.getState();
				const payments: Payment[] = getPaymentsV1Response.payments;
				let concatPayments: Payment[] = state.payments.concat(payments);
				concatPayments = [...new Set(concatPayments)];
				state.getPaymentsFrom = state.payments.length + 1;
				state.getPaymentsSize = action.getPaymentsBody.size;
				this.postProcessPaymentsData(context, null, concatPayments);
			}),
			catchError(error =>
				throwError(() => {
					checkToAddStateApiError(error, context, GetPaginatedPayments, 'Payments are down right now. Please try again later.');
				})
			)
		);
	}

	@Action(GetPaymentById)
	getPaymentById(context: StateContext<PaymentsStateModel>, action: GetPaymentById): Observable<GetPaymentsV1Response> {
		return this.paymentsService.getPaymentById(action.paymentId).pipe(
			tap((getPaymentsResponse: GetPaymentsV1Response) => {
				const state: PaymentsStateModel = context.getState();
				const payment: Payment = getPaymentsResponse.payments[0];
				if (payment) {
					const filteredPayments: Payment[] = state.payments.filter((filterPayment: Payment) => filterPayment.paymentId !== payment.paymentId);
					filteredPayments.push(payment);
					this.postProcessPaymentsData(context, null, filteredPayments);
				}
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Payment is down right now. Please try again later.')))
		);
	}

	@Action(GetBatchPaymentById)
	getBatchPaymentById(context: StateContext<PaymentsStateModel>, action: GetBatchPaymentById): Observable<GetBatchPaymentsResponse> {
		return this.paymentsService.getBatchPaymentById(action.batchPaymentId).pipe(
			tap((getBatchPaymentsResponse: GetBatchPaymentsResponse) => {
				const state: PaymentsStateModel = context.getState();
				const batchPayment: BatchPayment = getBatchPaymentsResponse.batches[0];
				if (batchPayment) {
					const filteredBatchPayments: BatchPayment[] = state.batchPayments.filter(
						(filterBatchPayment: BatchPayment) => filterBatchPayment.batchId !== batchPayment.batchId
					);
					filteredBatchPayments.push(batchPayment);
					this.postProcessBatchPaymentsData(context, null, filteredBatchPayments);
				}
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Bulk payment is down right now. Please try again later.')))
		);
	}

	@Action(UpdateStoreBatchsPayments)
	updateStoreBatchsPayments(context: StateContext<PaymentsStateModel>, action: UpdateStoreBatchsPayments): Observable<GetPaymentsV1Response> {
		return this.paymentsService.getPaymentsByBatchId(action.batchId).pipe(
			tap((getPaymentsV1Response: GetPaymentsV1Response) => {
				const state: PaymentsStateModel = context.getState();
				const responsePayments: Payment[] = getPaymentsV1Response.payments;
				let paymentsCopy: Payment[] = [...state.payments];
				paymentsCopy.forEach((storePayment: Payment) => {
					responsePayments.forEach((responsePayment: Payment) => {
						if (storePayment.paymentId === responsePayment.paymentId && storePayment.status !== responsePayment.status) {
							paymentsCopy = state.payments.filter((filterPayment: Payment) => filterPayment.paymentId !== responsePayment.paymentId);
							paymentsCopy.push(responsePayment);
						}
					});
				});
				this.postProcessPaymentsData(context, null, paymentsCopy);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Bulk payment is down right now. Please try again later.')))
		);
	}

	@Action(GetBatchPayments)
	getBatchPayments(context: StateContext<PaymentsStateModel>): Observable<GetBatchPaymentsResponse> {
		return this.paymentsService.getBatchPayments().pipe(
			tap((getBatchPaymentsResponse: GetBatchPaymentsResponse) => {
				checkToRemoveStateApiError(context, GetBatchPayments);
				const batchPayments: BatchPayment[] = getBatchPaymentsResponse.batches;
				this.postProcessBatchPaymentsData(context, null, batchPayments);
			}),
			catchError(error =>
				throwError(() => checkToAddStateApiError(error, context, GetBatchPayments, 'Bulk payments are down right now. Please try again later.'))
			)
		);
	}

	@Action(GetBatchsPayments)
	getBatchsPayments(context: StateContext<PaymentsStateModel>, action: GetBatchsPayments): Observable<GetPaymentsV1Response> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetAssignedAndBatchPayments;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		const batchsTotalPayments: number = action.batchPayment.stats.totalPayments;
		const body: PaymentsListBody = { batchId: action.batchPayment.batchId };
		return this.paymentsService.getPaginatedPayments(body).pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getPaymentsV1Response: GetPaymentsV1Response) => {
				checkToRemoveStateApiError(context, GetBatchsPayments);
				const gotBatchsPayments: Payment[] = getPaymentsV1Response.payments;
				if (batchsTotalPayments === gotBatchsPayments.length) {
					const state: PaymentsStateModel = context.getState();
					let paymentsCopy: Payment[] = [...state.payments];
					paymentsCopy = paymentsCopy.filter((filterPayment: Payment) => filterPayment.batchId !== action.batchPayment.batchId);
					paymentsCopy = paymentsCopy.concat(gotBatchsPayments);
					this.postProcessPaymentsData(context, null, paymentsCopy);
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
				} else {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					throw new Error('Bulk payment is down right now. Please try again later.');
				}
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return checkToAddStateApiError(error, context, GetBatchsPayments, 'Bulks payments are down right now. Please try again later.');
				})
			)
		);
	}

	@Action(GetPaginatedBatchPayments)
	getPaginatedBatchPayments(context: StateContext<PaymentsStateModel>, action: GetPaginatedBatchPayments): Observable<GetBatchPaymentsResponse> {
		return this.paymentsService.getPaginatedBatchPayments(action.from, action.size).pipe(
			tap((getBatchPaymentsResponse: GetBatchPaymentsResponse) => {
				checkToRemoveStateApiError(context, GetPaginatedBatchPayments);
				const state: PaymentsStateModel = context.getState();
				const gotBatchPayments: BatchPayment[] = getBatchPaymentsResponse.batches;
				let concatBatchPayments: BatchPayment[] = state.batchPayments.concat(gotBatchPayments);
				concatBatchPayments = [...new Set(concatBatchPayments)];
				state.getBatchPaymentsFrom = state.batchPayments.length + 1;
				state.getBatchPaymentsSize = action.size;
				this.postProcessBatchPaymentsData(context, null, concatBatchPayments);
			}),
			catchError(error =>
				throwError(() => checkToAddStateApiError(error, context, GetPaginatedBatchPayments, 'Bulk payments are down right now. Please try again later.'))
			)
		);
	}

	@Action(CreatePayment)
	createPayment(context: StateContext<PaymentsStateModel>, action: CreatePayment): Observable<Payment> {
		return this.paymentsService.createPayment(action.paymentToCreate).pipe(
			tap((payment: Payment) => {
				const state: PaymentsStateModel = context.getState();
				const paymentsCopy: Payment[] = [...state.payments];
				paymentsCopy.push(payment);
				this.postProcessPaymentsData(context, null, paymentsCopy);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Create payment is down right now. Please try again later.')))
		);
	}

	@Action(UploadFileToPayment)
	uploadFileToPayment(context: StateContext<PaymentsStateModel>, action: UploadFileToPayment): Observable<Payment> {
		return this.paymentsService.uploadPaymentFile(action.body, action.paymentId).pipe(
			tap((payment: Payment) => {
				const state: PaymentsStateModel = context.getState();
				const filteredPayments: Payment[] = state.payments.filter((filterPayment: Payment) => filterPayment.paymentId !== payment.paymentId);
				filteredPayments.push(payment);
				this.postProcessPaymentsData(context, null, filteredPayments);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Create payment is down right now. Please try again later.')))
		);
	}

	@Action(DeleteFileFromPayment)
	deleteFileFromPayment(context: StateContext<PaymentsStateModel>, action: DeleteFileFromPayment): Observable<Payment> {
		return this.paymentsService.deleteAttachmentFromPayment(action.attachmentId, action.paymentId).pipe(
			tap((payment: Payment) => {
				const state: PaymentsStateModel = context.getState();
				const filteredPayments: Payment[] = state.payments.filter((filterPayment: Payment) => filterPayment.paymentId !== payment.paymentId);
				filteredPayments.push(payment);
				this.postProcessPaymentsData(context, null, filteredPayments);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Create payment is down right now. Please try again later.')))
		);
	}

	@Action(CreateBatchPayment)
	createBatchPayment(context: StateContext<PaymentsStateModel>, action: CreateBatchPayment): Observable<BatchPayment> {
		return this.paymentsService.createBatchPayment(action.batchFile).pipe(
			tap((batchPayment: BatchPayment) => {
				const state: PaymentsStateModel = context.getState();
				const batchPaymentsCopy: BatchPayment[] = [...state.batchPayments];
				batchPaymentsCopy.push(batchPayment);
				this.postProcessBatchPaymentsData(context, null, batchPaymentsCopy);
				context.dispatch(new GetBatchsPayments(batchPayment));
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Create bulk payment is down right now. Please try again later.')))
		);
	}

	@Action(SetPaymentsTabIndex)
	setPaymentsTabIndex(context: StateContext<PaymentsStateModel>, action: SetPaymentsTabIndex): void {
		const state: PaymentsStateModel = context.getState();
		state.tabIndex = action.tabIndex;
		context.patchState(state);
	}

	@Action(SetStageFeedback)
	setStageFeedback(context: StateContext<PaymentsStateModel>, action: SetStageFeedback): Observable<Payment> {
		return this.paymentsService.setStageFeedback(action.stageFeedback).pipe(
			tap((payment: Payment) => {
				const state: PaymentsStateModel = context.getState();
				const filteredPayments: Payment[] = state.payments.filter((filterPayment: Payment) => filterPayment.paymentId !== payment.paymentId);
				filteredPayments.push(payment);
				this.postProcessPaymentsData(context, null, filteredPayments);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Review payment is down right now. Please try again later.')))
		);
	}

	@Action(SetBatchStageFeedback)
	setBatchStageFeedback(context: StateContext<PaymentsStateModel>, action: SetBatchStageFeedback): Observable<BatchPayment> {
		return this.paymentsService.setBatchStageFeedback(action.batchStageFeedback).pipe(
			tap((batchPayment: BatchPayment) => {
				const state: PaymentsStateModel = context.getState();
				const filteredBatchPayments: BatchPayment[] = state.batchPayments.filter(
					(filterPayment: BatchPayment) => filterPayment.batchId !== batchPayment.batchId
				);
				filteredBatchPayments.push(batchPayment);
				this.postProcessBatchPaymentsData(context, null, filteredBatchPayments);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Review bulk payment is down right now. Please try again later.')))
		);
	}

	@Action(CancelPayment)
	cancelPayment(context: StateContext<PaymentsStateModel>, action: CancelPayment): Observable<Payment> {
		return this.paymentsService.cancelPayment(action.paymentCancelation).pipe(
			tap((payment: Payment) => {
				const state: PaymentsStateModel = context.getState();
				const filteredPayments: Payment[] = state.payments.filter((filterPayment: Payment) => filterPayment.paymentId !== payment.paymentId);
				filteredPayments.push(payment);
				this.postProcessPaymentsData(context, null, filteredPayments);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Cancel payment is down right now. Please try again later.')))
		);
	}

	@Action(CancelBatchPayment)
	cancelBatchPayment(context: StateContext<PaymentsStateModel>, action: CancelBatchPayment): Observable<BatchPayment> {
		return this.paymentsService.cancelBatchPayment(action.batchPaymentCancelation).pipe(
			tap((batchPayment: BatchPayment) => {
				const state: PaymentsStateModel = context.getState();
				const filteredBatchPayments: BatchPayment[] = state.batchPayments.filter(
					(filterPayment: BatchPayment) => filterPayment.batchId !== batchPayment.batchId
				);
				filteredBatchPayments.push(batchPayment);
				this.postProcessBatchPaymentsData(context, null, filteredBatchPayments);
			}),
			catchError(error => throwError(() => checkForBadHttpErrorMessage(error, 'Cancel bulk payment is down right now. Please try again later.')))
		);
	}

	@Action(ClearPaymentsState)
	clearPaymentsState(context: StateContext<PaymentsStateModel>): void {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: PaymentsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetPaymentsState)
	resetPaymentsState(context: StateContext<PaymentsStateModel>): void {
		context.dispatch(new ClearPaymentsState());
		const getPaymentsListBody: PaymentsListBody = {
			from: 0,
			size: this.initGetPaymentsSize,
		};
		context.dispatch(new GetPaginatedPayments(getPaymentsListBody));
		context.dispatch(new GetPaginatedBatchPayments(0, this.initGetBatchPaymentsSize));
	}

	@Action(ResetCachedPayments)
	resetCachedPayments(context: StateContext<PaymentsStateModel>): void {
		const state: PaymentsStateModel = context.getState();
		if (state.payments.length > this.initGetPaymentsSize) {
			const slicedPayments: Payment[] = state.payments.slice(0, this.initGetPaymentsSize);
			state.payments = slicedPayments;
			context.patchState(state);
		}
	}

	@Action(ResetCachedBulkPayments)
	resetCachedBulkPayments(context: StateContext<PaymentsStateModel>): void {
		const state: PaymentsStateModel = context.getState();
		if (state.batchPayments.length > this.initGetBatchPaymentsSize) {
			const slicedBatchPayments: BatchPayment[] = state.batchPayments.slice(0, this.initGetBatchPaymentsSize);
			state.batchPayments = slicedBatchPayments;
			context.patchState(state);
		}
	}

	@Action(GetAssignedPaymentsToPoll)
	getAssignedPayments(context: StateContext<PaymentsStateModel>): Observable<GetPaymentsV1Response> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetAssignedAndBatchPayments;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		const getPaymentsBody: PaymentsListBody = { assigned: true };
		return this.paymentsService.getPaginatedPayments(getPaymentsBody).pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getPaymentsV1Response: GetPaymentsV1Response) => {
				checkToRemoveStateApiError(context, GetAssignedPaymentsToPoll);
				const state: PaymentsStateModel = context.getState();
				const cachedPayments: Payment[] = state.payments;
				const gotPayments: Payment[] = getPaymentsV1Response.payments;
				const gotPaymentsToCache: Payment[] = [];
				gotPayments.forEach((gotPayment: Payment) => {
					gotPayment.needsReview = this.checkIfPaymentNeedsReview(gotPayment);
					const foundCachedPayment: Payment = cachedPayments.find((payment: Payment) => payment.paymentId === gotPayment.paymentId);
					if ((gotPayment.needsReview && !foundCachedPayment) || (foundCachedPayment && foundCachedPayment.status !== gotPayment.status)) {
						gotPaymentsToCache.push(gotPayment);
					}
				});
				if (gotPaymentsToCache.length) {
					const concatPayments: Payment[] = cachedPayments.concat(gotPaymentsToCache);
					this.postProcessPaymentsData(context, null, concatPayments);
				}
				this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return checkToAddStateApiError(error, context, GetAssignedPaymentsToPoll, 'Payments are down right now. Please try again later.');
				})
			)
		);
	}

	@Action(GetPaymentToPoll)
	getPaymentToPoll(context: StateContext<PaymentsStateModel>, action: GetPaymentToPoll): Observable<GetPaymentsV1Response> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetPaymentById;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		return this.paymentsService.getPaymentById(action.payment.paymentId).pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getPaymentsResponse: GetPaymentsV1Response) => {
				const state: PaymentsStateModel = context.getState();
				const gotPayment: Payment = getPaymentsResponse.payments[0];
				if (gotPayment && action.payment.status !== gotPayment.status) {
					const filteredPayments: Payment[] = state.payments.filter((filterPayment: Payment) => filterPayment.paymentId !== action.payment.paymentId);
					filteredPayments.push(gotPayment);
					this.postProcessPaymentsData(context, null, filteredPayments);
				}
				this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return checkForBadHttpErrorMessage(error, 'Payment is down right now. Please try again later.');
				})
			)
		);
	}

	@Action(GetBatchPaymentsToPoll)
	getBatchPaymentsToPoll(context: StateContext<PaymentsStateModel>, action: GetBatchPaymentsToPoll): Observable<GetBatchPaymentsResponse> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetAssignedAndBatchPayments;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		const size: number = this.initGetBatchPaymentsSize;
		return this.paymentsService.getPaginatedBatchPayments(action.from, size).pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getBatchPaymentsResponse: GetBatchPaymentsResponse) => {
				checkToRemoveStateApiError(context, GetBatchPaymentsToPoll);
				const gotBatchPayments: BatchPayment[] = getBatchPaymentsResponse.batches;
				const state: PaymentsStateModel = context.getState();
				const cachedBatchPayments: BatchPayment[] = state.batchPayments;
				const gotBatchPaymentsToCache: BatchPayment[] = [];
				gotBatchPayments.forEach((gotBatchPayment: BatchPayment) => {
					gotBatchPayment.needsReview = this.checkIfPaymentNeedsReview(gotBatchPayment);
					const foundCachedBatchPayment: BatchPayment = cachedBatchPayments.find(
						(batchPayment: BatchPayment) => batchPayment.batchId === gotBatchPayment.batchId
					);
					if (
						(gotBatchPayment.needsReview && !foundCachedBatchPayment) ||
						(foundCachedBatchPayment && foundCachedBatchPayment.status !== gotBatchPayment.status)
					) {
						gotBatchPaymentsToCache.push(gotBatchPayment);
					}
				});
				if (gotBatchPaymentsToCache.length) {
					const concatBatchPayments: BatchPayment[] = cachedBatchPayments.concat(gotBatchPaymentsToCache);
					this.postProcessBatchPaymentsData(context, null, concatBatchPayments);
				}
				this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return checkToAddStateApiError(error, context, GetBatchPaymentsToPoll, 'Bulk payments are down right now. Please try again later.');
				})
			)
		);
	}

	@Action(GetBatchPaymentToPoll)
	getBatchPaymentToPoll(context: StateContext<PaymentsStateModel>, action: GetBatchPaymentToPoll): Observable<GetBatchPaymentsResponse> {
		const pollRequestKey: PollRequestKey = PollRequestKey.GetBatchAndBatchsPayments;
		this.cancelPollRequestService.createPollSubject(pollRequestKey);
		return this.paymentsService.getBatchPaymentById(action.batchPayment.batchId).pipe(
			takeUntil(this.cancelPollRequestService.pollSubjectsToCancel[pollRequestKey]),
			tap((getBatchPaymentsResponse: GetBatchPaymentsResponse) => {
				const state: PaymentsStateModel = context.getState();
				const gotBatchPayment: BatchPayment = getBatchPaymentsResponse.batches[0];
				if (gotBatchPayment && action.batchPayment.status !== gotBatchPayment.status) {
					const filteredBatchPayments: BatchPayment[] = state.batchPayments.filter(
						(filterBatchPayment: BatchPayment) => filterBatchPayment.batchId !== action.batchPayment.batchId
					);
					filteredBatchPayments.push(gotBatchPayment);
					this.postProcessBatchPaymentsData(context, null, filteredBatchPayments);
				}
				this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
			}),
			catchError(error =>
				throwError(() => {
					this.cancelPollRequestService.cancelPollSubject(pollRequestKey);
					return checkForBadHttpErrorMessage(error, 'Bulk payment is down right now. Please try again later.');
				})
			)
		);
	}

	private paymentsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedPaymentsState: PaymentsStateModel | undefined = deserializedState.payments;
		if (
			deserializedPaymentsState &&
			deserializedPaymentsState.payments &&
			deserializedPaymentsState.batchPayments &&
			deserializedPaymentsState.paymentSnacks &&
			deserializedPaymentsState.tabIndex !== null &&
			deserializedPaymentsState.isCached &&
			deserializedPaymentsState.getPaymentsFrom !== null &&
			deserializedPaymentsState.getPaymentsSize !== null &&
			deserializedPaymentsState.getBatchPaymentsFrom !== null &&
			deserializedPaymentsState.getBatchPaymentsSize !== null &&
			deserializedPaymentsState.apiErrors !== null
		) {
			return true;
		} else {
			return false;
		}
	}

	private initDefaultPaymentsState(context: StateContext<PaymentsStateModel>): void {
		const state: PaymentsStateModel = context.getState();
		state.tabIndex = 0;
		state.payments = [];
		state.batchPayments = [];
		state.paymentSnacks = [];
		state.batchPaymentSnacks = [];
		state.getPaymentsFrom = 0;
		state.getPaymentsSize = this.initGetPaymentsSize;
		state.getBatchPaymentsFrom = 0;
		state.getBatchPaymentsSize = this.initGetBatchPaymentsSize;
		state.apiErrors = [];
		context.patchState(state);
		const getPaymentsListBody: PaymentsListBody = {
			from: state.getPaymentsFrom,
			size: state.getPaymentsSize,
		};
		context.dispatch(new GetPaginatedPayments(getPaymentsListBody));
		context.dispatch(new GetPaginatedBatchPayments(state.getBatchPaymentsFrom, state.getBatchPaymentsSize));
	}

	private postProcessPaymentsData(context: StateContext<PaymentsStateModel>, state?: PaymentsStateModel, payments?: Payment[]): void {
		const stateToProcess: PaymentsStateModel = state ? state : context.getState();
		let paymentsToProcess: Payment[] = payments ? payments : stateToProcess.payments;
		paymentsToProcess = [...new Set(paymentsToProcess)];
		const paymentNotificationSnacks: ReadyForReviewSnack[] = this.getPaymentSnacks(paymentsToProcess);
		const sortedPayments: Payment[] = this.sortPaymentsByInitiated(paymentsToProcess);
		stateToProcess.paymentSnacks = paymentNotificationSnacks;
		stateToProcess.payments = sortedPayments;
		stateToProcess.isCached = true;
		context.patchState(stateToProcess);
	}

	private getPaymentSnacks(payments: Payment[]): ReadyForReviewSnack[] {
		let paymentNotificationSnacks: ReadyForReviewSnack[] = [];
		payments.forEach((payment: Payment) => {
			payment.needsReview = this.checkIfPaymentNeedsReview(payment);
			if (payment.needsReview) {
				const requestor: string = 'A user '; // TODO: Update with actual user name once apis are updated
				const actionText: string = `${ReadyForReviewAction.initiated} a `;
				const paymentNameText: string = `payment (${payment.name}) `;
				const htmlString: string = `
        <span class="font-700-14-20">${requestor}</span>
        <span class="font-400-14-20">${actionText}</span>
        <span class="font-700-14-20">${paymentNameText}</span>
        <span class="font-400-14-20">to ${payment.instructions?.beneficiary?.name}</span>
        `;
				const messageSafeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlString);
				const readyForReviewSnackParams: ReadyForReviewSnackParams = {
					date: DateTime.fromISO(payment.initiated).toLocaleString(DateTime.DATE_MED),
					messageHtml: messageSafeHtml,
					resource: payment,
					resourceId: payment.paymentId,
					resourceType: TrovataResourceType.payment,
					resourceViewText: TrovataResourceViewText.Payment,
					sortByDate: payment.initiated,
				};
				const snack: ReadyForReviewSnack = new ReadyForReviewSnack(readyForReviewSnackParams);
				paymentNotificationSnacks.push(snack);
			}
		});
		paymentNotificationSnacks = sortSnacksByDate(paymentNotificationSnacks);
		return paymentNotificationSnacks;
	}

	private checkIfPaymentNeedsReview(payment: Payment | BatchPayment): boolean {
		let paymentNeedsReview: boolean = false;
		if (payment.execution && payment.status === PaymentStatus.OPEN) {
			payment.execution.stages.forEach((stage: PaymentExecutionStage) => {
				stage.approval.users.forEach((user: ApprovalUser) => {
					const foundSignature: PaymentExecutionStageSignature = stage.signatures.find(
						(stageSignature: PaymentExecutionStageSignature) => stageSignature.userId === user.userId
					);
					if (
						stage.status === PaymentStatus.PENDING &&
						stage.approval.numApprovals !== stage.signatures.length &&
						user.userId === this.authUser['https://auth.trovata.io/userinfo/userId'] &&
						!foundSignature
					) {
						paymentNeedsReview = true;
						payment.stageNeedingReview = stage;
					}
				});
			});
		}
		return paymentNeedsReview;
	}

	private sortPaymentsByInitiated(payments: Payment[]): Payment[] {
		const sortedPayments: Payment[] = payments.sort((paymentA: Payment, paymentB: Payment) => {
			if (paymentA.execution) {
				this.sortPaymentExecutionUsersByApproval(paymentA);
			}
			if (paymentB.execution) {
				this.sortPaymentExecutionUsersByApproval(paymentB);
			}
			paymentA.history = paymentA.history.sort((paymentAhistory: PaymentHistory, paymentBhistory: PaymentHistory) => {
				if (new Date(paymentAhistory.timestamp) > new Date(paymentBhistory.timestamp)) {
					return -1;
				} else if (new Date(paymentAhistory.timestamp) < new Date(paymentBhistory.timestamp)) {
					return 1;
				}
				return 0;
			});
			paymentB.history = paymentB.history.sort((paymentAhistory: PaymentHistory, paymentBhistory: PaymentHistory) => {
				if (new Date(paymentAhistory.timestamp) > new Date(paymentBhistory.timestamp)) {
					return -1;
				} else if (new Date(paymentAhistory.timestamp) < new Date(paymentBhistory.timestamp)) {
					return 1;
				}
				return 0;
			});
			if (new Date(paymentA.initiated) > new Date(paymentB.initiated)) {
				return -1;
			} else if (new Date(paymentA.initiated) < new Date(paymentB.initiated)) {
				return 1;
			}
			return 0;
		});
		return sortedPayments;
	}

	private sortPaymentExecutionUsersByApproval(payment: Payment): void {
		payment.execution.stages.forEach((stage: PaymentExecutionStage) => {
			stage.approval.users = stage.approval.users.sort((userA: ApprovalUser, userB: ApprovalUser) => {
				if (new UserApprovedPaymentPipe().transform(userA.userId, stage)) {
					return -1;
				} else if (new UserApprovedPaymentPipe().transform(userB.userId, stage)) {
					return 1;
				}
				return 0;
			});
		});
	}

	private postProcessBatchPaymentsData(context: StateContext<PaymentsStateModel>, state?: PaymentsStateModel, batchPayments?: BatchPayment[]): void {
		const stateToProcess: PaymentsStateModel = state ? state : context.getState();
		const batchPaymentsToProcess: BatchPayment[] = batchPayments ? batchPayments : stateToProcess.batchPayments;
		const batchPaymentSnacks: ReadyForReviewSnack[] = this.getBatchPaymentSnacks(batchPaymentsToProcess);
		const sortedBatchPayments: BatchPayment[] = this.sortBatchPaymentsByInitiated(batchPaymentsToProcess);
		stateToProcess.batchPaymentSnacks = batchPaymentSnacks;
		stateToProcess.batchPayments = sortedBatchPayments;
		stateToProcess.isCached = true;
		context.patchState(stateToProcess);
	}

	private getBatchPaymentSnacks(batchPayments: BatchPayment[]): ReadyForReviewSnack[] {
		let paymentNotificationSnacks: ReadyForReviewSnack[] = [];
		batchPayments.forEach((batchPayment: BatchPayment) => {
			batchPayment.needsReview = this.checkIfPaymentNeedsReview(batchPayment);
			if (batchPayment.needsReview) {
				const requestor: string = 'A user '; // TODO: Update with actual user name once apis are updated
				const actionText: string = `${ReadyForReviewAction.initiated} a `;
				const accountNameText: string = `bulk payment (${batchPayment.name})`;
				const htmlString: string = `
        <span class="font-700-14-20">${requestor}</span>
        <span class="font-400-14-20">${actionText}</span>
        <span class="font-700-14-20">${accountNameText}</span>
        `;
				const messageSafeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlString);
				const readyForReviewSnackParams: ReadyForReviewSnackParams = {
					date: DateTime.fromISO(batchPayment.initiated).toLocaleString(DateTime.DATE_MED),
					messageHtml: messageSafeHtml,
					resource: batchPayment,
					resourceId: batchPayment.batchId,
					resourceType: TrovataResourceType.bulkPayment,
					resourceViewText: TrovataResourceViewText.BulkPayment,
					sortByDate: batchPayment.initiated,
				};
				const snack: ReadyForReviewSnack = new ReadyForReviewSnack(readyForReviewSnackParams);
				paymentNotificationSnacks.push(snack);
			}
		});
		paymentNotificationSnacks = sortSnacksByDate(paymentNotificationSnacks);
		return paymentNotificationSnacks;
	}

	private sortBatchPaymentsByInitiated(batchPayments: BatchPayment[]): BatchPayment[] {
		const sortedBatchPayments: BatchPayment[] = batchPayments.sort((paymentA: BatchPayment, paymentB: BatchPayment) => {
			if (new Date(paymentA.initiated) > new Date(paymentB.initiated)) {
				return -1;
			} else if (new Date(paymentA.initiated) < new Date(paymentB.initiated)) {
				return 1;
			}
			return 0;
		});
		return sortedBatchPayments;
	}
}
