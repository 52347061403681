import { Server, Request } from 'miragejs';
import { AccountsObjectV3 } from '../../models/account-target.model';
import { BalanceGetApiResponse } from '../../models/historical-balance.model';
import { nonManualHistoricalBalances } from '../helpers.mock';
import { AppSchema, AccountDbData } from '../types';

export function addAccountsWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post('/data/accounts', (schema: AppSchema, request: Request): AccountsObjectV3 => {
		const body: {
			fullAccountNumbers: boolean;
			accountIds?: string[];
			start?: number;
			size?: number;
			currencyOverride?: string;
		} = JSON.parse(request.requestBody);
		if (body.currencyOverride && body.currencyOverride !== 'USD') {
			unhandledRequestMade();
		}
		return {
			totalAccounts: schema.all('account').length,
			accounts: schema.db.accounts,
		};
	});

	server.post('/data/accounts/balances', (schema: AppSchema, request: Request): BalanceGetApiResponse => {
		const body: {
			startDate: string;
			endDate: string;
			accountId: string;
			isManual: boolean;
		} = JSON.parse(request.requestBody);
		const accountId: string = body['accountId'][0];
		const account: AccountDbData = schema.db.accounts.findBy({ accountId });
		return {
			accountBalances: [
				{
					accountId: account.accountId,
					accountNumber: account.accountNumber,
					nickname: account.nickname,
					institutionName: account.institutionName,
					institutionId: 'JPM',
					currency: account.currency,
					currencyConverted: account.currencyConverted,
					entityGroupingId: null,
					divisionGroupingId: null,
					regionGroupingId: null,
					balances: nonManualHistoricalBalances(30),
				},
			],
		};
	});
}
