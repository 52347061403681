import { DEFAULT_ENVIRONMENT_CONFIG, DefaultEnvironment } from '@trovata/app/core/models/config.model';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: DefaultEnvironment = {
	...DEFAULT_ENVIRONMENT_CONFIG,
	apiSuffix: '-dev',
	environmentName: 'local',
	production: false,
	beta: false,
	development: false,
	productionToggles: false,
	stripe: 'dev',
	stripePublishableKey: 'pk_test_XuRNLNiozZiDBNSpwWpiQXuv',
	// plaidRedirectUri: 'http://localhost:4200/plaid-oauth', // use this url when plaid is in sandbox mode
	plaidRedirectUri: 'https://app.trovata.io/plaid-oauth',
	edgeAPI: DEFAULT_ENVIRONMENT_CONFIG.edgeAPI,
	trovataAPI: DEFAULT_ENVIRONMENT_CONFIG.trovataAPI,
	workspaceAPI: DEFAULT_ENVIRONMENT_CONFIG.workspaceAPI,
};

// export const environment: DefaultEnvironment = {
//   ...DEFAULT_ENVIRONMENT_CONFIG,
//   environmentName: 'prod',
//   production: true,
//   beta: false,
//   development: false,
//   productionToggles: true,
//   plaidRedirectUri: 'https://app.trovata.io/plaid-oauth',
//   stripe: 'prod',
//   stripePublishableKey: 'pk_live_ie9dXwN1zhU1u47M2VYzHErp',
//   edgeAPI: DEFAULT_ENVIRONMENT_CONFIG.edgeAPI,
//   trovataAPI: DEFAULT_ENVIRONMENT_CONFIG.trovataAPI,
//   workspaceAPI: DEFAULT_ENVIRONMENT_CONFIG.workspaceAPI
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
