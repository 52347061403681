import { Injectable } from '@angular/core';
import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import {
	APIError,
	ClearHeapAnalyticsState,
	InitHeapAnalyticsState,
	ResetHeapAnalyticsState,
	SetAnalyticsVariable,
	SetLogin,
	SetUserVariables,
	UserAction,
} from '../../actions/heap-analytics.actions';
import { environment } from 'src/environments/environment';
import { UserActions } from '../../../utils/analyticsEnums';
import { UserConfig } from '../../../models/user-config.model';
import { UserConfigState } from '../user-config/user-config.state';
import { CurrentUser, Customer } from 'src/app/features/settings/models/identity.model';
import { IdentityState } from 'src/app/features/settings/store/state/identity.state';
import { firstValidValueFrom } from 'src/app/shared/utils/firstValidValueFrom';
import { CustomerFeatureState } from 'src/app/features/settings/store/state/customer-feature.state';
import { UserAccessConfig } from 'src/app/features/settings/models/feature.model';

// Heap Analytics dataLayer appears to maintain its' own store, but that store can be invalidated by history changes
// and closing then re-opening our app, something our store can persist through. So we can use our store to correct the
// dataLayer.  It probably won't catch all invalidations, but it's better than nothing.

export class AnalyticsStateModel {
	companyName: string;
	email: string;
	isAdmin: boolean;
	environment: string;
	jpmFlow: boolean;
	isLogin: boolean;
	stripeTier: string;
	subscriptionType: string;
}

@State<AnalyticsStateModel>({
	name: 'heapAnalytics',
	defaults: {
		companyName: '',
		email: '',
		isAdmin: null,
		environment: '',
		jpmFlow: null,
		isLogin: null,
		stripeTier: '',
		subscriptionType: '',
	},
})
@Injectable()
export class HeapAnalyticsState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private theWindow = <any>window;
	private checkInProgress: boolean = false;

	@Select(IdentityState.currentUser) currentUser$: Observable<CurrentUser>;
	@Select(IdentityState.customer) customer$: Observable<Customer>;
	@Select(UserConfigState.userConfig) userConfig$: Observable<UserConfig>;
	@Select(CustomerFeatureState.userAccessConfig)
	userAccessConfig$: Observable<UserAccessConfig>;

	constructor(
		private serializationService: SerializationService,
		private store: Store
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitHeapAnalyticsState)
	async InitHeapAnalyticsState(context: StateContext<AnalyticsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (appReady) {
						const state: AnalyticsStateModel = deserializedState.heapAnalytics;
						if (state) {
							context.patchState(state);
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(ResetHeapAnalyticsState)
	ResetHeapAnalyticsState(context: StateContext<AnalyticsStateModel>) {
		context.dispatch(new ClearHeapAnalyticsState());
		context.dispatch(new InitHeapAnalyticsState());
	}

	@Action(ClearHeapAnalyticsState)
	ClearHeapAnalyticsState(context: StateContext<AnalyticsStateModel>) {
		this.appReadySub.unsubscribe();
		const state: AnalyticsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(SetUserVariables)
	setUserVariables(context: StateContext<AnalyticsStateModel>) {
		this.setUserProperties(context);
		const state = context.getState();
		this.theWindow.heap.track('Set User');
		if (state.isLogin) {
			context.dispatch(new UserAction(UserActions.login));
		}
	}

	@Action(SetAnalyticsVariable)
	setAnalyticsVariable(context: StateContext<AnalyticsStateModel>, action: SetAnalyticsVariable) {
		const state = context.getState();
		state[action.variable] = action.value;
		context.patchState(state);
		this.setUserProperties(context);
	}

	@Action(SetLogin)
	setLogin(context: StateContext<AnalyticsStateModel>, action: SetLogin) {
		const state = context.getState();
		state.isLogin = action.isLogin;
		context.patchState(state);
	}

	@Action(UserAction)
	userAction(context: StateContext<AnalyticsStateModel>, action: UserAction) {
		this.setUserProperties(context);
		this.theWindow.heap.track('User Action', {
			userAction: action.event,
		});
	}

	@Action(APIError)
	apiError(context: StateContext<AnalyticsStateModel>, action: APIError) {
		this.setUserProperties(context);
		this.theWindow.heap.track('API Error', {
			error: action.error,
		});
	}

	private setUserProperties(context: StateContext<AnalyticsStateModel>) {
		if (!this.checkInProgress) {
			this.checkInProgress = true;
			const state = context.getState();
			if (localStorage.getItem('auth')) {
				this.getUser(context);
			}
			if (state.environment === '') {
				state.environment = environment.environmentName;
				this.theWindow.heap.addUserProperties({
					environment: environment.environmentName,
				});
			}
			if (state.jpmFlow === null) {
				this.theWindow.heap.addUserProperties({
					jpmFlow: state.jpmFlow,
				});
			}
			this.checkInProgress = false;
		}
	}

	private async getUser(context: StateContext<AnalyticsStateModel>) {
		try {
			const state: AnalyticsStateModel = context.getState();
			if (state.companyName === '' || state.email === '') {
				const currentUser: CurrentUser = await firstValidValueFrom(this.currentUser$);
				const currentCustomer: Customer = await firstValidValueFrom(this.customer$);
				if (currentUser && currentCustomer) {
					state.companyName = currentCustomer.name;
					state.email = currentUser.email;
					state.isAdmin = currentUser.email.includes('admin');
					this.theWindow.heap.addUserProperties({
						companyName: state.companyName,
						email: state.email,
						isAdmin: state.isAdmin,
					});
					this.theWindow.heap.identify(state.email);
				}
			}
			if (state.stripeTier === '') {
				const userConfig: UserConfig = await firstValidValueFrom(this.userConfig$);
				if (userConfig) {
					state.stripeTier = userConfig.stripeTier;
					this.theWindow.heap.addUserProperties({
						stripeTier: state.stripeTier,
					});
				}
			}
			if (state.subscriptionType === '') {
				const userAccessConfig: UserAccessConfig = await firstValidValueFrom(this.userAccessConfig$);
				if (userAccessConfig) {
					state.subscriptionType = userAccessConfig.currentCustomer.subscriptionType;
					this.theWindow.heap.addUserProperties({
						subscriptionType: state.subscriptionType,
					});
				}
			}
			context.patchState(state);
		} catch (error) {
			throw error;
		}
	}
}
