import { Stream } from 'src/app/features/forecasts/models/forecastV3-stream.model';
import { Cadence } from 'src/app/features/reports/models/cadence.model';
import { Request, Server } from 'miragejs';
import { valuesEntity } from '../helpers.mock';
import { AppSchema, ForecastDbData } from '../types';

export function addForecastWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post('/data/forecasts/forecasts-list', (schema: AppSchema) => ({
		forecasts: schema.db.forecasts,
	}));

	server.post('/data/forecasts/streams-list', (schema: AppSchema) => ({
		streams: schema.db.forecastStreams,
	}));

	server.post('/data/forecasts/**', (schema: AppSchema, request: Request) => {
		const forecastId = request.params['*'];
		const forecast: ForecastDbData = schema.db.forecasts.findBy({ forecastId });
		return {
			...forecast,
			streams: forecast.streams,
			cashBalances: valuesEntity(forecast.startDate, forecast.endDate),
			cashflow: valuesEntity(forecast.startDate, forecast.endDate),
		};
	});

	server.post('/data/forecasts/streams/**', (schema: AppSchema, request: Request): Stream => {
		const streamId = request.params['*'];
		if (request.requestBody['cadence'] !== Cadence.daily) {
			unhandledRequestMade();
		}
		const stream = schema.db.forecastStreams.findBy({ streamId });
		return stream;
	});
}
