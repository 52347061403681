import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { TrovataAppState } from 'src/app/core/models/state.model';
import {
	AddTagSnackData,
	RefreshTagSnackData,
	ClearTagSnacksState,
	GetTagSnacksData,
	InitTagSnacksState,
	ResetTagSnacksState,
} from '../actions/tag-snacks.action';
import { PreferencesState } from 'src/app/shared/store/state/preferences/preferences.state';
import { AllPreferences, BalancesCarouselPreferences, SnackPreference } from 'src/app/shared/models/preferences.model';
import { Snack, SnacksTabData, SnackPreference as DashboardSnackPref } from '../../../balances/models/snacks.model';
import { AnalysisTransactionLegacy } from '../../../reports/models/analysis.legacy.model';
import * as crypto from 'crypto-js';
import { SearchParameter } from 'src/app/shared/models/search-parameter.model';
import { Tag } from '../../models/tag.model';
import { TagsState } from './tags.state';
import { ParameterType } from '../../models/transaction-search-parameter.model';
import { DemoSnackId, SnackType } from 'src/app/shared/models/snacks.model';
import { convertCarouselPrefToHashObject, convertDashboardPrefToHashObject, TagHashObject } from '../../utils/tag-snack-hash-obj';
import { AnalysisLegacyService } from '@trovata/app/features/reports/services/analysis.legacy.service';

export class TagSnacksStateModel {
	tagSnackData: { [key: string]: AnalysisTransactionLegacy };
	apiInFlight: boolean;
}

@State<TagSnacksStateModel>({
	name: 'tagSnacks',
	defaults: {
		tagSnackData: null,
		apiInFlight: false,
	},
})
@Injectable()
export class TagSnacksState {
	@Selector()
	static tagSnackData(state: TagSnacksStateModel) {
		return state.tagSnackData;
	}

	@Selector() static tagSnacksAPIInFlight(tagSnacks: TagSnacksStateModel): boolean {
		return tagSnacks.apiInFlight;
	}

	@Select(PreferencesState.preferences)
	preferences$: Observable<AllPreferences>;
	@Select(TagsState.tags) tags$: Observable<Tag[]>;

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private tagSnacksInitialized: boolean;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private analysisService: AnalysisLegacyService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.tagSnacksInitialized = false;
	}

	@Action(InitTagSnacksState)
	async initTagsState(context: StateContext<TagSnacksStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (appReady) {
						if (!this.tagSnacksInitialized) {
							if (deserializedState?.tagSnacks?.tagSnackData) {
								const state: TagSnacksStateModel = context.getState();
								state.tagSnackData = deserializedState.tagSnacks.tagSnackData;
								context.patchState(state);
							}
							// check for tag snacks missing data
							context.dispatch(new GetTagSnacksData());
							this.tagSnacksInitialized = true;
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetTagSnacksData)
	async getTagSnacksData(context: StateContext<TagSnacksStateModel>): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				context.patchState({ apiInFlight: true });

				// get preferences
				const preferences: AllPreferences = await this.getPreferences();
				// carousel snacks
				const carouselPref: BalancesCarouselPreferences = preferences.balancesCarousel;
				const carouselSnackPrefs: SnackPreference[] = (carouselPref?.snacks || []).filter((filterSnack: SnackPreference) => filterSnack.type === SnackType.tag);

				// dashboard snacks
				const dashboardTabs: SnacksTabData[] = preferences.dashboardTabs;
				const dashboardSnacks: DashboardSnackPref[] = (dashboardTabs || [])
					?.reduce((snacks, tab) => (snacks = [...snacks, ...tab.snacks]), [])
					.filter((filterSnack: Snack) => filterSnack?.type === SnackType.tag);

				// convert each preference into a hash object
				const hashObjs: TagHashObject[] = [
					...carouselSnackPrefs.map((carouselSnackPref: SnackPreference) => convertCarouselPrefToHashObject(carouselSnackPref)),
					...dashboardSnacks.map((dashboardSnackPref: DashboardSnackPref) => convertDashboardPrefToHashObject(dashboardSnackPref)),
				].filter(this.onlyUnique);
				// retrieve data for each unique hash
				if (hashObjs.length) {
					await Promise.all(hashObjs.map((hashObj: TagHashObject) => this.lazyLoadHashObjectData(context, hashObj).catch(error => new Error(error))));
				} else {
					context.patchState({ tagSnackData: {} });
				}
				context.patchState({ apiInFlight: false });
				resolve();
			} catch (error) {
				context.patchState({ apiInFlight: false });
				reject(error);
			}
		});
	}

	@Action(RefreshTagSnackData)
	async clearTagSnackData(context: StateContext<TagSnacksStateModel>, action: RefreshTagSnackData): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				context.patchState({ apiInFlight: true });

				// get preferences
				const preferences: AllPreferences = await this.getPreferences();

				// carousel snacks
				const carouselPref: BalancesCarouselPreferences = preferences.balancesCarousel;
				const carouselSnackPrefs: SnackPreference[] = (carouselPref?.snacks || []).filter(
					(filterSnack: SnackPreference) => filterSnack.type === SnackType.tag && filterSnack.id === action.tagId
				);

				// dashboard snacks
				const dashboardTabs: SnacksTabData[] = preferences.dashboardTabs;
				const dashboardSnacks: DashboardSnackPref[] = (dashboardTabs || [])
					?.reduce((snacks, tab) => (snacks = [...snacks, ...tab.snacks]), [])
					.filter((filterSnack: Snack) => filterSnack?.type === SnackType.tag && filterSnack.id === action.tagId);

				// convert each preference into a hash object
				const hashObjs: TagHashObject[] = [
					...carouselSnackPrefs.map((carouselSnackPref: SnackPreference) => convertCarouselPrefToHashObject(carouselSnackPref)),
					...dashboardSnacks.map((dashboardSnackPref: DashboardSnackPref) => convertDashboardPrefToHashObject(dashboardSnackPref)),
				].filter(this.onlyUnique);
				// retrieve data for each unique hash
				if (hashObjs.length) {
					await Promise.all(hashObjs.map((hashObj: TagHashObject) => this.lazyLoadHashObjectData(context, hashObj, true).catch(error => new Error(error))));
				}
				context.patchState({ apiInFlight: false });
				resolve();
			} catch (error) {
				context.patchState({ apiInFlight: false });
				reject(error);
			}
		});
	}

	private onlyUnique(value: TagHashObject, index: number, self: TagHashObject[]): boolean {
		return self.indexOf(value) === index;
	}

	@Action(AddTagSnackData)
	addTagSnackData(context: StateContext<TagSnacksStateModel>, action: AddTagSnackData): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				context.patchState({ apiInFlight: true });
				const hashObj: TagHashObject = {
					tagId: action.tagId,
					cadence: action.cadence,
					periods: action.periods,
				};
				await this.lazyLoadHashObjectData(context, hashObj, true);
				context.patchState({ apiInFlight: false });
				resolve();
			} catch (error) {
				context.patchState({ apiInFlight: false });
				reject(error);
			}
		});
	}

	private lazyLoadHashObjectData(context: StateContext<TagSnacksStateModel>, hashObj: TagHashObject, forceCall?: boolean): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const state: TagSnacksStateModel = context.getState();
			const tags: Tag[] = await this.getTags();
			const hash: string = crypto.MD5(JSON.stringify(hashObj)).toString(crypto.enc.Hex);
			const hasData: boolean = !!(state.tagSnackData && state.tagSnackData[hash]);
			if (!hasData || forceCall) {
				const searchParams: SearchParameter[] = [];
				const tag: Tag = tags.find((findTag: Tag) => findTag.tagId === hashObj.tagId);
				if (tag) {
					searchParams.push(new SearchParameter(ParameterType.tag, tag.tagTitle, tag.tagId));
					if (tag?.tagMetadata?.startDate?.length > 0) {
						searchParams.push(new SearchParameter(ParameterType.startDate, '', tag.tagMetadata.startDate[0]));
					}
					if (tag?.tagMetadata?.endDate?.length > 0) {
						searchParams.push(new SearchParameter(ParameterType.endDate, '', tag.tagMetadata.endDate[0]));
					}
				}
				if (tag || hashObj.tagId === DemoSnackId.cashFlowDemo) {
					this.analysisService
						.getTransactionsAnalysis({
							cadence: hashObj.cadence,
							periods: hashObj.periods,
							params: searchParams,
						})
						.subscribe({
							next: resp => {
								const currentState: TagSnacksStateModel = context.getState();
								if (!currentState.tagSnackData) {
									currentState.tagSnackData = {};
								}
								const newTagSnackData: { [key: string]: AnalysisTransactionLegacy } = JSON.parse(JSON.stringify(currentState.tagSnackData));
								newTagSnackData[hash] = resp;
								context.patchState({
									tagSnackData: newTagSnackData,
									apiInFlight: false,
								});
								resolve();
							},
							error: error => reject(error),
						});
				}
			} else {
				resolve();
			}
		});
	}

	private getPreferences(): Promise<AllPreferences> {
		return new Promise(resolve => {
			this.preferences$.subscribe(resp => {
				if (resp) {
					resolve(resp);
				}
			});
		});
	}

	private getTags(): Promise<Tag[]> {
		return new Promise(resolve => {
			this.tags$.subscribe((tags: Tag[]) => {
				if (tags) {
					resolve(tags);
				}
			});
		});
	}

	@Action(ResetTagSnacksState)
	resetReportsState(context: StateContext<TagSnacksStateModel>) {
		context.dispatch(new TagSnacksStateModel());
		context.dispatch(new InitTagSnacksState());
	}

	@Action(ClearTagSnacksState)
	clearTagsState(context: StateContext<TagSnacksStateModel>) {
		this.tagSnacksInitialized = false;
		this.appReadySub.unsubscribe();
		const state: TagSnacksStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}
}
