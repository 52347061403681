import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { combineLatest, firstValueFrom, Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { Tag, TagPayload, TagType, TagVersion } from 'src/app/features/transactions/models/tag.model';
import {
	ClearLastCreatedTagId,
	ClearTagsState,
	CreateTag,
	DeleteTag,
	GetTags,
	InitTagsState,
	ResetTagsState,
	SetLastCreatedTagId,
	UpdateTag,
} from '../actions/tags.action';
import { TagService } from 'src/app/features/transactions/services/tag.service';
import { PermissionId, PermissionMap } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import { TransactionFilterType } from 'src/app/shared/models/transaction-filters.model';
import { UpdateFilter } from 'src/app/shared/store/actions/filter-object.actions';
import { GetValues } from '@trovata/app/shared/store/actions/tql-fields.actions';
import { TQLPropertyKey } from '@trovata/app/shared/models/tql.model';

export class TagsStateModel {
	tags: Tag[];
	isCached: boolean;
	lastCreatedTagId: string;
}

@State<TagsStateModel>({
	name: 'tags',
	defaults: {
		tags: null,
		isCached: false,
		lastCreatedTagId: null,
	},
})
@Injectable()
export class TagsState {
	@Selector()
	static tags(state: TagsStateModel) {
		return state.tags;
	}
	@Selector()
	static lastCreatedTagId(state: TagsStateModel) {
		return state.lastCreatedTagId;
	}

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	@Select(CustomerFeatureState.permissionIds)
	userAvailablePermissions$: Observable<PermissionMap>;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private tagService: TagService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitTagsState)
	async initTagsState(context: StateContext<TagsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const tagsStateIsCached: boolean = this.tagsStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.userAvailablePermissions$]).subscribe({
				next: ([appReady, permissions]: [boolean, PermissionMap]) => {
					if (permissions && appReady) {
						if (permissions.has(PermissionId.readTags)) {
							if (tagsStateIsCached) {
								context.patchState({
									tags: deserializedState.tags.tags,
									isCached: true,
								});
							} else {
								context.dispatch(new GetTags());
							}
						} else {
							context.patchState({
								tags: [],
								isCached: false,
							});
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}
	@Action(GetTags)
	getTags(context: StateContext<TagsStateModel>, action: GetTags) {
		let state = context.getState();
		if (action.refresh || !state.isCached) {
			return this.tagService.getTags().pipe(
				tap((response: HttpResponse<any>) => {
					const tags: Tag[] = response.body;
					context.patchState({ tags: tags, isCached: true });
				}),
				catchError(error => {
					state = context.getState();
					context.patchState(state);
					return throwError(() => error);
				})
			);
		}
	}

	@Action(CreateTag)
	createTag(context: StateContext<TagsStateModel>, action: CreateTag): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const tag: TagPayload = action.tag;
			try {
				const createTagResponse: HttpResponse<{ tagId: string }> = await firstValueFrom(this.tagService.createTQLTag(action.tag));
				if (createTagResponse.body.tagId && tag.tagType !== TagType.glTag && tag.tagType !== TagType.glTagAuto) {
					context.patchState({
						lastCreatedTagId: createTagResponse.body.tagId,
					});
					this.store.dispatch(new SetLastCreatedTagId(createTagResponse.body.tagId));
					await firstValueFrom(context.dispatch(new GetTags(true)));
					await firstValueFrom(this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.tags)));
					this.store.dispatch(new GetValues([TQLPropertyKey.tag]));
					resolve();
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateTag)
	updateTag(context: StateContext<TagsStateModel>, action: UpdateTag) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				await firstValueFrom(this.tagService.editTQLTag(action.tag));
				await firstValueFrom(context.dispatch(new GetTags(true)));
				await firstValueFrom(this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.tags)));
				this.store.dispatch(new GetValues([TQLPropertyKey.tag]));
				resolve();
			} catch (error) {
				reject(new Error('Could not update Tag'));
			}
		});
	}

	@Action(DeleteTag)
	deleteTag(context: StateContext<TagsStateModel>, action: DeleteTag) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const tagToDelete: Tag = action.tag;
				const state: TagsStateModel = context.getState();
				let tags: Tag[];
				if (tagToDelete.tagType !== TagType.glTag && tagToDelete.tagType !== TagType.glTagAuto) {
					tags = [...state.tags];
				}
				const i: number = tags.findIndex(tag => tag.tagId === tagToDelete.tagId);
				if (i > -1) {
					tags.splice(i, 1);
				}
				if (tagToDelete.version === TagVersion.v3) {
					await firstValueFrom(this.tagService.deleteTQLTag(tagToDelete));
				} else {
					await firstValueFrom(this.tagService.deleteV2Tag(tagToDelete));
				}
				await context.patchState({ tags: tags });
				firstValueFrom(this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.tags)));
				this.store.dispatch(new GetValues([TQLPropertyKey.tag]));
				resolve();
			} catch (error) {
				reject(new Error('Could not delete Tag'));
			}
		});
	}

	@Action(ResetTagsState)
	resetTagsState(context: StateContext<TagsStateModel>) {
		context.dispatch(new ClearTagsState());
		context.dispatch(new InitTagsState());
	}

	@Action(ClearTagsState)
	clearTagsState(context: StateContext<TagsStateModel>) {
		this.appReadySub.unsubscribe();
		const state: TagsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ClearLastCreatedTagId)
	clearLastCreatedTagId(context: StateContext<TagsStateModel>) {
		context.patchState({ lastCreatedTagId: null });
	}

	@Action(SetLastCreatedTagId)
	setLastCreatedId(context: StateContext<TagsStateModel>, action: SetLastCreatedTagId) {
		context.patchState({ lastCreatedTagId: action.id });
	}

	private tagsStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedTagsState: TagsStateModel | undefined = deserializedState.tags;
		if (deserializedTagsState && deserializedTagsState.tags) {
			return true;
		} else {
			return false;
		}
	}
}
