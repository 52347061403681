import { COUNTRIES, Country, CountryCode } from './country-data';

export enum FlagIconSize {
	oneByOne = '1x1',
	fourByThree = '4x3',
}

export const getFlagIconURLByCode: (countryCode: CountryCode, size: FlagIconSize) => string = (countryCode: CountryCode, size: FlagIconSize) => {
	const baseURL: string = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icons/6.11.1/flags/';
	const url: string = baseURL + `${size}/${countryCode.toString().toLowerCase()}.svg`;
	return url;
};

export const getFlagIconURLByStringName: (countryName: string, size: FlagIconSize) => string = (countryName: string, size: FlagIconSize) => {
	const foundCountry: Country = COUNTRIES.find((country: Country) => country.countryName.toLowerCase().trim() === countryName.toLowerCase().trim());
	if (foundCountry) {
		return getFlagIconURLByCode(foundCountry.countryCode, size);
	}
	return undefined;
};
