import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReportsScheduleService } from 'src/app/features/reports/services/reports-schedule.service';

@Injectable({
	providedIn: 'root',
})
export class CacheService implements OnDestroy {
	subscription: Subscription = new Subscription();

	constructor(private scheduleService: ReportsScheduleService) {}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	clear() {
		this.scheduleService.clearCache();
	}
}
