import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { Observable, Subscription, throwError } from 'rxjs';
import { ClearSettingsState, InitSettingsState, ResetSettingsState, SetSettingsTabIndex } from '../actions/settings.actions';

export class SettingsStateModel {
	tabIndex: number;
}

@State<SettingsStateModel>({
	name: 'settings',
	defaults: {
		tabIndex: null,
	},
})
@Injectable()
export class SettingsState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitSettingsState)
	async initSettingsState(context: StateContext<SettingsStateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();

			const settingsStateIsCached: boolean = this.settingsStateIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (settingsStateIsCached && appReady) {
						const state: SettingsStateModel = deserializedState.settings;
						context.patchState(state);
					} else if (!settingsStateIsCached && appReady) {
						const state: SettingsStateModel = context.getState();
						state.tabIndex = 0;
						context.patchState(state);
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(SetSettingsTabIndex)
	setSettingsTabIndex(context: StateContext<SettingsStateModel>, action: SetSettingsTabIndex) {
		const state = context.getState();
		state.tabIndex = action.tabIndex;
		context.patchState(state);
	}

	@Action(ClearSettingsState)
	clearSettingsState(context: StateContext<SettingsStateModel>) {
		this.appReadySub.unsubscribe();
		const state: SettingsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(ResetSettingsState)
	resetSettingsState(context: StateContext<SettingsStateModel>) {
		const state: SettingsStateModel = context.getState();
		state.tabIndex = 0;
		context.patchState(state);
	}

	private settingsStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState && deserializedState.settings && deserializedState.settings.tabIndex != null) {
			return true;
		} else {
			return false;
		}
	}
}
