import { Tag } from '../../features/transactions/models/tag.model';
import { Institution } from 'src/app/shared/models/institution.model';
import { Account } from '../../features/transactions/models/account.model';
import { GLCode } from '../../features/transactions/models/gl-code.model';

export class TransactionFilters {
	institutions?: Institution[];
	accounts?: Account[];
	currencies?: string[];
	tags?: Tag[];
	glTags?: Tag[];
	glCodes?: GLCode[];
	totalAccounts?: number;
	totalInstitutions?: number;
	totalCurrencies?: number;
	totalTags?: number;
	totalGlTags?: number;

	constructor(json?: Object) {
		if (json) {
			this.institutions = json['institutions'];
			this.accounts = json['accounts'];
			this.currencies = json['currencies'];
			this.tags = json['tags'];
			this.glTags = json['glTags'];
			this.glCodes = json['glCodes'];
		} else {
			this.institutions = null;
			this.accounts = null;
			this.currencies = null;
			this.tags = null;
			this.glTags = null;
			this.glCodes = null;
		}
	}
}

export class TransactionFilterSettings {
	accounts = {
		size: 'sizeAccounts',
		from: 'fromAccounts',
		total: 'totalAccounts',
	};
	institutions = {
		size: 'sizeInstitutions',
		from: 'fromInstitutions',
		total: 'totalInstitutions',
	};
	currencies = {
		size: 'sizeCurrencies',
		from: 'fromCurrencies',
		total: 'totalCurrencies',
	};
	tags = {
		size: 'sizeTags',
		from: 'fromTags',
		total: 'totalTags',
	};
	glTags = {
		size: 'sizeGlTags',
		from: 'fromGlTags',
		total: 'totalGlTags',
	};
	glAutoTags = {
		size: 'sizeGlAutoTags',
		from: 'fromGlAutoTags',
		total: 'totalGlAutoTags',
	};
}

export enum TransactionFilterType {
	accounts = 'accounts',
	institutions = 'institutions',
	currencies = 'currencies',
	tags = 'tags',
	glTags = 'glTags',
	glAutoTags = 'glAutoTags',
}
