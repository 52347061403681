import { CurrencyDict } from '@trovata/app/shared/models/currency.model';
import {
	AnalysisBalanceProperty,
	AnalysisBalanceValue,
	AnalysisData,
	AnalysisDataAggregation,
	AnalysisDisplaySettings,
	AnalysisTransactionValue,
	AnalysisType,
	isConvertedBalance,
	mapGroupTypeToTQLField,
} from './analysis.model';
import { Formatter } from '@trovata/app/shared/utils/formatter';
import { ChartType, TrovataHighChartsBarColorScheme, TrovataHighchartsColors, getHighchartsType } from '@trovata/app/shared/models/highcharts.model';
import { Cadence } from './cadence.model';
import {
	DrilldownEventObject,
	Point,
	PointOptionsObject,
	SeriesAreaOptions,
	SeriesColumnOptions,
	SeriesLineOptions,
	SeriesOptionsType,
	SeriesPieOptions,
	SeriesScatterOptions,
	SeriesZonesOptionsObject,
	Tooltip,
} from 'highcharts';
import { formatDate } from '@angular/common';
import { RoundingOption } from './report.model';
import { roundingDict } from '@trovata/app/shared/models/rounding.model';
import { DateTime } from 'luxon';
import { GenericOption } from '@trovata/app/shared/models/option.model';
import { TQLValuesDict } from '@trovata/app/shared/models/tql.model';

export class AnalysisChartViewModel {
	private datesArr: string[] = [];
	private formatter: Formatter;
	private barColorScheme: SeriesZonesOptionsObject[];
	private colors: string[];
	private yAxisFormatter: (point: any) => string;
	private dateFormat: string;
	balanceAnalysisOpts: BalanceAnalysisOptions;

	trueRoundingOption: RoundingOption;

	private colorsDict: { [id: string]: string };

	constructor(
		private analysisData: AnalysisData<AnalysisTransactionValue> | AnalysisData<AnalysisBalanceValue>,
		private displaySettings: AnalysisDisplaySettings,
		private currencyDict: CurrencyDict,
		private valuesDict: TQLValuesDict,
		private miscChartSettings?: MiscChartSettings
	) {
		this.formatter = new Formatter();
		this.colors = TrovataHighchartsColors;
		this.barColorScheme = TrovataHighChartsBarColorScheme;
		this.yAxisFormatter = (point: any): string => this.formatter.formatValue(point.value, this.currencyDict[this.analysisData.currencyConverted], null, true);
		this.balanceAnalysisOpts = new BalanceAnalysisOptions();
		this.trueRoundingOption = roundingDict.get(this.displaySettings.trueRoundingOption);
		this.colorsDict = {};
		this.setDateFormat();
		this.setDateArray();
	}

	private setDateFormat(): void {
		let dateFormat: string = '';
		switch (this.analysisData.cadence) {
			case Cadence.daily:
			case Cadence.weekly: {
				dateFormat = 'M/d/yy';
				break;
			}
			case Cadence.monthly: {
				dateFormat = 'MMM-y';
				break;
			}
			case Cadence.quarterly: {
				dateFormat = 'MMM-y';
				break;
			}
		}
		this.dateFormat = dateFormat;
	}

	loadDataSeries(pieChartDate?: string): Highcharts.Options {
		let chartOptions: Highcharts.Options;
		this.assignColors(this.analysisData.aggregation);
		switch (this.displaySettings.chartType) {
			case ChartType.column:
				chartOptions = this.setColumnChart();
				break;
			case ChartType.line:
			case ChartType.stackedbar:
			case ChartType.scatter:
				chartOptions = this.setGenericChart();
				break;
			case ChartType.pie:
				chartOptions = this.setPieChart(pieChartDate);
				break;
		}
		chartOptions.chart.type = getHighchartsType(this.displaySettings.chartType);
		return chartOptions;
	}

	private assignColors(aggregation: (AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>)[]): void {
		if (aggregation.length) {
			aggregation.forEach((group: AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>, index: number) => {
				this.colorsDict[group.value] = this.colors[index];
				this.assignColors(group.aggregation);
			});
		}
	}

	private getTransactionsDrilldownData(point: Highcharts.Point): SeriesOptionsType[] {
		const drilldown = JSON.parse(point.options.drilldown);
		if (drilldown.chartType === 'column') {
			const columnDrilldown: SeriesOptionsType = this.createDrilldownForDate(
				drilldown.data,
				drilldown.date,
				drilldown.property,
				'column',
				point.options.custom?.groupBy
			);
			return [columnDrilldown];
		} else {
			const stackedBarDrilldownData: SeriesOptionsType[] = [];
			if (drilldown.data.aggregation.length) {
				drilldown.data.aggregation.forEach(group => {
					this.createNetTrxnSeries(stackedBarDrilldownData, group, null, point.options.custom?.groupBy);
				});
			} else {
				this.createNetTrxnSeries(stackedBarDrilldownData, drilldown.data, true, point.options.custom?.groupBy);
			}

			return stackedBarDrilldownData;
		}
	}

	private getBalancesDrilldownData(point: Highcharts.Point): SeriesOptionsType[] {
		const drilldown = JSON.parse(point.options.drilldown);
		if (drilldown.chartType === 'column') {
			const columnDrilldown: SeriesOptionsType = this.createDrilldownForDate(drilldown.data, drilldown.date, this.analysisData.balanceProperty, 'column');
			return [columnDrilldown];
		} else if (drilldown.chartType === 'pie') {
			const pieDrilldown: SeriesOptionsType[] = [];
			this.generatePieChartData(drilldown.date, drilldown.data, pieDrilldown);
			return pieDrilldown;
		} else {
			const stackedBarDrilldownData: SeriesOptionsType[] = [];
			if (drilldown.data.aggregation.length) {
				drilldown.data.aggregation.forEach(group => {
					this.processBalanceGroup(
						group,
						stackedBarDrilldownData,
						isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType[this.analysisData.balanceProperty])
					);
				});
			} else {
				this.processBalanceGroup(
					drilldown.data,
					stackedBarDrilldownData,
					isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType[this.analysisData.balanceProperty]),
					true
				);
			}
			return stackedBarDrilldownData;
		}
	}

	private getDisplayValue(value: number): string {
		return this.formatter.formatValue(value, this.currencyDict[this.analysisData.currencyConverted], this.trueRoundingOption);
	}

	private setColumnChart(): Highcharts.Options {
		const chartOptions: Highcharts.Options = this.getInitialChartOptions();

		if (this.isTransactionsAnalysis(this.analysisData)) {
			const creditColumn: Array<SeriesOptionsType> = [];
			const debitColumn: Array<SeriesOptionsType> = [];
			const netColumn: Array<SeriesOptionsType> = [];
			const creditObj: SeriesOptionsType = {
				name: 'Credit',
				data: [],
				color: '#0082FF',
				type: 'column',
			};
			const debitObj: SeriesOptionsType = {
				name: 'Debit',
				data: [],
				color: '#FF4979',
				type: 'column',
			};
			const netObj: SeriesOptionsType = {
				name: 'Net',
				data: [],
				color: '#0082FF',
				type: 'column',
			};
			this.analysisData.summary.forEach(analysisValue => {
				const formattedDate: string = formatDate(analysisValue.date, this.dateFormat, 'en-US', 'UTC');
				const creditData: PointOptionsObject = {
					y: analysisValue.credit,
					drilldown: this.analysisData.aggregation.length
						? JSON.stringify({ type: 'CREDIT', date: analysisValue.date, data: this.analysisData.aggregation, property: 'credit', chartType: 'column' })
						: null,
					custom: { displayValue: this.getDisplayValue(analysisValue.credit), date: analysisValue.date },
					name: formattedDate,
				};
				const debitData: PointOptionsObject = {
					y: -analysisValue.debit,
					drilldown: this.analysisData.aggregation.length
						? JSON.stringify({ type: 'DEBIT', date: analysisValue.date, data: this.analysisData.aggregation, property: 'debit', chartType: 'column' })
						: null,
					custom: { displayValue: this.getDisplayValue(analysisValue.debit), date: analysisValue.date },
					name: formattedDate,
				};
				const netData: PointOptionsObject = {
					y: analysisValue.net,
					drilldown: this.analysisData.aggregation.length
						? JSON.stringify({ type: 'NET', date: analysisValue.date, data: this.analysisData.aggregation, property: 'net', chartType: 'column' })
						: null,
					custom: { displayValue: this.getDisplayValue(analysisValue.net), date: analysisValue.date },
					name: formattedDate,
				};
				creditObj.data.push(creditData);
				debitObj.data.push(debitData);
				netObj.data.push(netData);
			});
			creditColumn.push(creditObj);
			debitColumn.push(debitObj);
			netColumn.push(netObj);
			if (!this.miscChartSettings.isNet) {
				chartOptions.series = [...creditColumn, ...debitColumn];
			} else {
				chartOptions.series = [...netColumn];
			}
		} else if (this.isBalanceAnalysis(this.analysisData)) {
			const isConverted: boolean = isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty));
			const mainSeries: Array<SeriesOptionsType> = [];
			this.processBalanceGroup({ value: '', type: '', aggregation: [], summary: this.analysisData.summary }, mainSeries, isConverted);

			chartOptions.yAxis['labels'].formatter = this.yAxisFormatter;
			chartOptions.series = mainSeries;
			chartOptions.title.text = undefined;
			chartOptions.chart.spacingBottom = 0;
		}
		chartOptions.chart.spacingBottom = 0;
		chartOptions.plotOptions.column.zones = this.barColorScheme;
		chartOptions.plotOptions.column.borderRadius = 3;

		return chartOptions;
	}

	private setGenericChart(): Highcharts.Options {
		const chartOptions: Highcharts.Options = this.getInitialChartOptions();

		if (this.isBalanceAnalysis(this.analysisData)) {
			const isConverted: boolean = isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType[this.analysisData.balanceProperty]);
			const mainSeries: SeriesOptionsType[] = [];

			if (!this.analysisData.aggregation.length) {
				this.processBalanceGroup({ value: '', type: '', aggregation: [], summary: this.analysisData.summary }, mainSeries, isConverted);
			} else {
				this.analysisData.aggregation.forEach(group => {
					this.processBalanceGroup(group, mainSeries, isConverted);
				});
			}

			chartOptions.yAxis['labels'].formatter = this.yAxisFormatter;
			chartOptions.series = mainSeries;
			chartOptions.title.text = undefined;
		} else if (this.isTransactionsAnalysis(this.analysisData)) {
			const netLine: SeriesOptionsType[] = [];
			if (this.analysisData.aggregation.length) {
				this.analysisData.aggregation.forEach(group => {
					this.createNetTrxnSeries(netLine, group);
				});
			} else {
				this.createNetTrxnSeries(netLine, { value: '', type: '', aggregation: [], summary: this.analysisData.summary });
			}

			chartOptions.series = [...netLine];
			chartOptions.plotOptions.column.zones = undefined;
			chartOptions.plotOptions.column.borderRadius = 0;
		}
		chartOptions.chart.spacingBottom = 0;
		return chartOptions;
	}

	private setPieChart(pieChartDate?: string): Highcharts.Options {
		const chartOptions: Highcharts.Options = this.getInitialChartOptions();

		const date: string = DateTime.fromISO(pieChartDate).toFormat(this.dateFormat);
		if (this.isBalanceAnalysis(this.analysisData)) {
			const mainSeries: SeriesOptionsType[] = [];

			this.generatePieChartData(date, this.analysisData, mainSeries);
			chartOptions.series = mainSeries;
			chartOptions.chart.spacingBottom = 20;
		}
		return chartOptions;
	}

	private generatePieChartData(
		date: string,
		data: AnalysisDataAggregation<AnalysisBalanceValue>,
		mainSeries: SeriesOptionsType[]
		// pieZeroValues: (string | number)[]
	): void {
		const isConverted: boolean = isConvertedBalance(this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty));
		const seriesIndex: number = this.datesArr.findIndex(point => point === date);
		const seriesData: PointOptionsObject[] = [];

		if (data.aggregation.length) {
			data.aggregation.forEach((group: AnalysisDataAggregation<AnalysisBalanceValue>) => {
				const balances: AnalysisBalanceValue[] = group.summary;
				if (balances && balances.length - 1 >= seriesIndex) {
					const obj: PointOptionsObject = {
						name:
							group.value === 'null-key'
								? this.analysisData.aggregation.length > 1
									? 'Other Accounts'
									: 'All Accounts'
								: this.nameForId(group.type, group.value),
						y: balances[seriesIndex][this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty)],
						custom: {
							curr:
								(this.currencyDict && !isConverted ? this.currencyDict[group.uniformCurrency] : null) || this.currencyDict[this.analysisData.currencyConverted],
						},
						drilldown: group.aggregation.length ? JSON.stringify({ data: group, chartType: 'pie', date }) : null,
					};
					if (obj.y > 0) {
						seriesData.push(obj);
					} else {
						// pieZeroValues.push(obj.name);
					}
				}
			});
		} else {
			const obj: PointOptionsObject = {
				name: 'All Accounts',
				y: this.analysisData.summary[seriesIndex][this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty)],
				custom: {
					curr:
						(this.currencyDict && !isConverted ? this.currencyDict[this.analysisData.uniformCurrency] : null) ||
						this.currencyDict[this.analysisData.currencyConverted],
				},
			};
			seriesData.push(obj);
		}
		mainSeries.push({
			name: date,
			type: 'pie',
			data: seriesData,
		});
	}

	private processBalanceGroup(
		group: AnalysisDataAggregation<AnalysisBalanceValue>,
		mainSeries: Array<SeriesOptionsType>,
		isConverted: boolean,
		noDrilldown?: boolean
	): void {
		const values: AnalysisBalanceValue[] | AnalysisTransactionValue[] = group.summary;
		const seriesObj: SeriesScatterOptions | SeriesLineOptions | SeriesColumnOptions = {
			name: group.value !== 'null-key' ? this.nameForId(group.type, group.value) ?? 'All Accounts' : 'Other Accounts',
			data: [],
			type: <'column' | 'line' | 'scatter'>getHighchartsType(this.displaySettings.chartType),
			color: this.colorsDict[group.value],
		};
		let drilldown: Object;
		if (!noDrilldown) {
			if (this.displaySettings.chartType === ChartType.column) {
				// if basic bar chart, we show all the groups in the groupby on a single day
				drilldown = this.analysisData.aggregation.length ? { data: this.analysisData.aggregation, chartType: 'column' } : null;
			} else {
				// if stacked bar chart, we show the next groupby over the current date range
				drilldown = group.type ? { data: group } : null;
			}
		}
		if (values) {
			values.forEach(balance => {
				const balanceType: string = this.balanceAnalysisOpts.getCorrespondingBalanceType(this.analysisData.balanceProperty);
				const dataObj: PointOptionsObject = {
					y: balance[balanceType],
					drilldown: drilldown ? JSON.stringify({ ...drilldown, date: balance.date }) : null,
					custom: {
						curr:
							(this.currencyDict && !isConverted ? this.currencyDict[group.uniformCurrency] : null) || this.currencyDict[this.analysisData.currencyConverted],
					},
					name: balance.fakeBalance
						? `No historical data for ${formatDate(balance.date, this.dateFormat, 'en-US')}`
						: formatDate(balance.date, this.dateFormat, 'en-US'),
				};
				seriesObj.data.push(dataObj);
			});
		}
		mainSeries.push(seriesObj);
	}

	private createNetTrxnSeries(
		netLine: SeriesOptionsType[],
		group: AnalysisDataAggregation<AnalysisTransactionValue>,
		noDrilldown?: boolean,
		parentGroupBy?: { type: string; value: string }[]
	): void {
		const transactions: AnalysisTransactionValue[] = group.summary;
		const seriesObj: SeriesScatterOptions | SeriesAreaOptions | SeriesPieOptions | SeriesColumnOptions = {
			name: group.value !== 'null-key' ? this.nameForId(group.type, group.value) ?? 'All Transactions' : 'Other Transactions',
			data: [],
			id: group.type + group.value,
			type: getHighchartsType(this.displaySettings.chartType),
			color: this.colorsDict[group.value],
		};
		const groupBy = parentGroupBy ?? [];
		const index: number = groupBy.findIndex(parentGroup => parentGroup.type === group.type);
		if (index >= 0) {
			groupBy[index] = { value: group.value, type: group.type };
		} else if (group.type) {
			groupBy.push({ value: group.value, type: group.type });
		}
		transactions.forEach((trxn, i) => {
			const formattedDate: string = formatDate(trxn.date, this.dateFormat, 'en-US', 'UTC');
			const dataObj: PointOptionsObject = {
				y: trxn.net,
				drilldown: group.type && !noDrilldown ? JSON.stringify({ type: group.type, data: group }) : null,
				name: formattedDate,
				custom: { displayValue: this.getDisplayValue(trxn.net), date: trxn.date, groupBy },
			};
			if (this.miscChartSettings.anomalyDates) {
				if (this.miscChartSettings.anomalyDates.find((anomalyDate: string) => DateTime.fromISO(anomalyDate).hasSame(DateTime.fromISO(trxn.date), 'day'))) {
					dataObj['color'] = '#EF4848';
				} else {
					dataObj['color'] = '#006CE2';
				}
			}

			seriesObj.data.push(dataObj);
		});
		netLine.push(seriesObj);
	}

	private createDrilldownForDate(
		data: AnalysisDataAggregation<AnalysisTransactionValue>[] | AnalysisDataAggregation<AnalysisBalanceValue>[],
		date: string,
		property: string,
		chartType: string,
		parentGroupBy?: { type: string; value: string }[]
	): SeriesOptionsType {
		const breakDownObj: SeriesColumnOptions = {
			data: [],
			id: data[0].type + date,
			type: chartType as 'column',
			name: this.balanceAnalysisOpts.getGroupByName(data[0].type),
			color: this.colorsDict[property],
		};
		data.forEach((group: AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>) => {
			const i: number = group.summary.findIndex((analysisValue: AnalysisTransactionValue | AnalysisBalanceValue) => analysisValue.date === date);
			let amount: number;
			if (this.isTransactionsAnalysisAggregation(group)) {
				if (i < 0) {
					amount = null;
				} else if (property === 'net') {
					amount = group.summary[i].credit - group.summary[i].debit;
				} else if (property === 'credit') {
					amount = group.summary[i][property];
				} else if (property === 'debit') {
					amount = -group.summary[i][property];
				}
			} else {
				amount = group.summary[i][property];
			}

			const groupBy = [...(parentGroupBy ?? []), { value: group.value, type: group.type }];

			breakDownObj.data.push({
				y: amount,
				drilldown: group.aggregation.length ? JSON.stringify({ type: group.type, date: date, data: group.aggregation, property, chartType }) : null,
				custom: {
					displayValue: this.getDisplayValue(amount),
					curr: this.currencyDict[this.analysisData.currencyConverted],
					date: date,
					groupBy,
				},
				name: this.nameForId(group.type, group.value),
			});
		});
		breakDownObj.name = `${this.balanceAnalysisOpts.getGroupByName(data[0].type)} - ${date}`;

		return breakDownObj;
	}

	private setDateArray(): void {
		this.analysisData.summary?.forEach(period => {
			this.datesArr.push(formatDate(period.date, this.dateFormat, 'en-US'));
		});
	}

	// show limited number of x axis labels based on total num of data points
	private getTickInterval(): number {
		const pointsNum = this.datesArr?.length || 0;
		if (this.analysisData.cadence === Cadence.daily) {
			if (pointsNum <= 7) {
				return 1;
			} else if (pointsNum <= 31) {
				return 2;
			} else {
				return 7;
			}
		} else {
			if (pointsNum <= 12) {
				return 1;
			} else {
				return 4;
			}
		}
	}

	private getInitialChartOptions(): Highcharts.Options {
		if (this.analysisData.type === AnalysisType.transactions) {
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			const viewModel: AnalysisChartViewModel = this;
			return {
				accessibility: { enabled: false },
				boost: { useGPUTranslations: true },
				legend: {
					enabled: true,
				},
				chart: {
					spacingBottom: 10,
					backgroundColor: 'transparent',
					type: this.displaySettings.chartType,
					zooming: {
						mouseWheel: {
							enabled: false,
						},
					},
					events: {
						drilldown: function (e): void {
							const drilldownGroup: SeriesOptionsType[] = viewModel.getTransactionsDrilldownData(e.point);
							drilldownGroup.forEach(group => {
								// @ts-ignore
								this.addSingleSeriesAsDrilldown(e.point, group);
							});
							// @ts-ignore
							this.applyDrilldown();

							if (e.points !== false && e.points['length'] > 1) {
								e.preventDefault();
							}
						},
					},
					style: {
						fontFamily: 'AkkuratLLWeb',
						fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
						fontSize: '16px',
					},
				},
				series: [],
				drilldown: {
					breadcrumbs: {
						showFullPath: false,
						format: '◁ Back',
					},
				},
				exporting: {
					enabled: false,
				},
				colors: this.colors,
				title: {
					text: undefined,
					useHTML: false,
					style: {
						color: '#5f5f5f',
					},
				},
				credits: {
					enabled: false,
				},
				xAxis: {
					type: 'category',
					lineWidth: 0,
					tickInterval: viewModel.getTickInterval(),
					labels:
						this.miscChartSettings.anomalyDates &&
						this.miscChartSettings.anomalyDates.find(anomalyDate => DateTime.fromISO(anomalyDate).hasSame(DateTime.fromISO(anomalyDate), 'day'))
							? {
									style: {
										color: 'red',
									},
							  }
							: {},
				},
				yAxis: {
					title: null,
					startOnTick: false,
					endOnTick: true,
					labels: {
						formatter: this.yAxisFormatter,
					},
					opposite: true,
				},
				tooltip: {
					formatter: (a: Tooltip): string => {
						const point: Point = a.chart.hoverPoint;
						const category: string | number = point.name ? point.name : point.category;
						const displayVal: string = point.options.custom['displayValue'];
						return `<div style="font-size: 10px; text-align: center">
                      ${category}
                      <span style="color: ${point.color}">\u25CF</span>
                      ${point.series.name}
                    </div>
                    <div style="text-align:center"><strong>${displayVal}</strong></div>
                    <div style="font-size:8px; text-align:center;">${this.analysisData.currencyConverted} Equiv.</div>`;
					},
					footerFormat: null,
					headerFormat: null,
					useHTML: true,
					style: {
						fontFamily: 'AkkuratLLWeb',
						fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
						fontSize: '16px',
					},
				},
				plotOptions: {
					series: {
						point: {
							events: {
								click: null,
							},
						},
					},
					column: {
						stacking: 'normal',
						visible: true,
						animation: false,
					},
					area: {
						stacking: 'normal',
					},
					pie: {
						dataLabels: {
							enabled: true,
						},
						size: '100%',
						allowPointSelect: true,
						cursor: 'pointer',
					},
				},
				responsive: {
					rules: [
						{
							condition: {
								maxHeight: 300,
							},
							chartOptions: {
								legend: {
									enabled: false,
								},
								chart: {
									spacingBottom: 1,
									zooming: {
										type: null,
									},
									animation: false,
									spacingLeft: 0,
									spacingRight: 0,
									spacingTop: 0,
									style: {
										fontFamily: 'AkkuratLLWeb',
										fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
										fontSize: '16px',
									},
								},
								xAxis: {
									labels: {
										enabled: false,
									},
								},
								yAxis: {
									labels: {
										enabled: false,
									},
									gridLineColor: 'transparent',
									plotLines: [
										{
											color: '#00000014',
											width: 1,
											value: 0,
											zIndex: 3,
										},
									],
								},
								plotOptions: {
									series: {
										pointPlacement: 'on',
										animation: false,
									},
									area: {
										marker: {
											enabled: false,
										},
									},
									pie: {
										allowPointSelect: false,
										slicedOffset: 0,
										size: '90%',
										dataLabels: {
											alignTo: 'connectors',
											distance: 5,
											style: {
												textOverflow: 'ellipsis',
												padding: '0px 5px 0px 5px',
												width: 80,
											},
										},
									},
									column: {
										pointPlacement: 'between',
									},
									columnrange: {
										pointPlacement: 'between',
										dataLabels: {
											formatter: function () {
												const prefix = this.y === this.point['high'] ? 'O' : 'C';
												return `${prefix}`;
											},
										},
									},
								},
							},
						},
					],
				},
			};
		} else if (this.analysisData.type === AnalysisType.balances) {
			const viewModel: AnalysisChartViewModel = this;
			return {
				chart: {
					type: null,
					backgroundColor: 'transparent',
					spacingBottom: 0,
					zooming: {
						mouseWheel: {
							enabled: false,
						},
					},
					events: {
						drilldown: function (e: DrilldownEventObject): void {
							const drilldownGroup: SeriesOptionsType[] = viewModel.getBalancesDrilldownData(e.point);
							drilldownGroup.forEach(group => {
								// @ts-ignore
								this.addSingleSeriesAsDrilldown(e.point, group);
							});
							// @ts-ignore
							this.applyDrilldown();
							if (e.points !== false && e.points['length'] > 1) {
								e.preventDefault();
							}
						},
					},
					style: {
						fontFamily: 'AkkuratLLWeb',
						fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
						fontSize: '16px',
					},
				},
				colors: this.colors,
				series: [],
				drilldown: {
					breadcrumbs: {
						showFullPath: false,
						format: '◁ Back',
					},
				},
				legend: {
					enabled: true,
				},
				exporting: {
					enabled: false,
				},
				title: {
					text: undefined,
					align: 'center',
					verticalAlign: 'middle',
					y: 100,
				},
				credits: {
					enabled: false,
				},
				xAxis: {
					lineWidth: 0,
					type: 'category',
					labels: {
						enabled: !this.miscChartSettings.isSnack,
					},
				},
				yAxis: {
					visible: !this.miscChartSettings.isSnack,
					title: null,
					opposite: true,
					startOnTick: false,
					endOnTick: false,
					labels: {
						enabled: !this.miscChartSettings.isSnack,
						formatter: null,
					},
				},
				tooltip: {
					formatter: (a: Tooltip): string => {
						const point: Point = a.chart.hoverPoint;
						if (point.name && point.name.includes('No historical data')) {
							return `<div style="text-align:center"><strong>${point.name}</strong></div>`;
						}
						const category: string | number = point.name ? point.name : point.category;
						const displayString: string = this.formatter.formatValue(
							point.y,
							point.options.custom['curr'] || this.currencyDict[this.analysisData.currencyConverted],
							this.trueRoundingOption
						);
						const percent: number = point.percentage && Math.round(point.percentage * 100) / 100;
						let returnString: string = `<div style="font-size: 10px; text-align: center">
                                  ${category}
                                  <span >\u25CF</span>
                                  ${point.series.name}
                                </div>
                                <div style="text-align:center"><strong>${displayString}</strong></div>`;
						if (this.displaySettings.chartType === ChartType.pie) {
							returnString = returnString + `<div style="text-align:center"><strong>${percent}%</strong></div>`;
						}
						return returnString;
					},
					headerFormat: null,
					useHTML: true,
					style: {
						fontFamily: 'AkkuratLLWeb',
						fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
						fontSize: '16px',
					},
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						events: {
							legendItemClick: function () {
								return false;
							},
						},
						minPointLength: this.miscChartSettings.isSnack ? 5 : 0,
					},
					area: {
						stacking: 'normal',
					},
					pie: {
						dataLabels: {
							enabled: true,
						},
						size: '100%',
						allowPointSelect: true,
						cursor: 'pointer',
						slicedOffset: 0,
					},
				},
				responsive: {
					rules: [
						{
							condition: {
								maxHeight: 300,
							},
							chartOptions: {
								legend: {
									enabled: false,
								},
								chart: {
									spacingBottom: this.displaySettings.chartType === ChartType.pie || this.miscChartSettings.isSnack ? 5 : 1,
									zooming: {
										type: null,
									},
									animation: false,
									spacingLeft: ChartType.pie ? 5 : 0,
									spacingRight: ChartType.pie ? 5 : 0,
									spacingTop: ChartType.pie || this.miscChartSettings.showYAxis ? 5 : 0,
									style: {
										fontFamily: 'AkkuratLLWeb',
										fontFeatureSettings: '"dlig" on, "ss01" on, "ss02" on, "ss03" on, "ss05" on',
										fontSize: '16px',
									},
								},
								title: {
									text: null,
								},
								xAxis: {
									labels: {
										enabled: false,
									},
								},
								yAxis: {
									// visible: !!this.miscChartSettings.showYAxis,
									title: null,
									labels: {
										enabled: false,
									},
									// gridLineColor: this.miscChartSettings.showYAxis ? '#e6e6e6' : 'transparent',
									gridLineColor: 'transparent',
									plotLines: [
										{
											color: '#00000014',
											width: 1,
											value: 0,
											zIndex: 3,
										},
									],
								},
								plotOptions: {
									series: {
										pointPlacement: 'between',
										animation: false,
									},
									area: {
										marker: {
											enabled: false,
										},
										pointPlacement: 'on',
									},
									line: {
										pointPlacement: 'on',
									},
									pie: {
										allowPointSelect: false,
										slicedOffset: 0,
										size: '90%',
										dataLabels: {
											alignTo: 'connectors',
											distance: 5,
											style: {
												textOverflow: 'ellipsis',
												padding: '0px 5px 0px 5px',
												width: 80,
											},
										},
									},
									column: {
										stacking: 'normal',
										visible: true,
										borderRadius: this.displaySettings.chartType === ChartType.column ? 3 : 0,
									},
									columnrange: {
										dataLabels: {
											formatter: function () {
												const prefix = this.y === this.point['high'] ? 'O' : 'C';
												return `${prefix}`;
											},
										},
									},
								},
							},
						},
						{
							condition: {
								maxHeight: 128,
							},
							chartOptions: {
								xAxis: {
									visible: false,
									endOnTick: false,
									startOnTick: false,
								},
								yAxis: {
									visible: !!this.miscChartSettings.showYAxis,
									title: null,
									labels: {
										enabled: !!this.miscChartSettings.showYAxis,
									},
									min: !this.miscChartSettings.showYAxis ? 0 : null,
									minRange: 2,
								},
								plotOptions: {
									column: {
										minPointLength: 5,
									},
								},
							},
						},
					],
				},
			};
		}
	}

	private isTransactionsAnalysis(
		analysisData: AnalysisData<AnalysisTransactionValue> | AnalysisData<AnalysisBalanceValue>
	): analysisData is AnalysisData<AnalysisTransactionValue> {
		return analysisData.type === AnalysisType.transactions;
	}

	private isBalanceAnalysis(
		analysisData: AnalysisData<AnalysisTransactionValue> | AnalysisData<AnalysisBalanceValue>
	): analysisData is AnalysisData<AnalysisBalanceValue> {
		return analysisData.type === AnalysisType.balances;
	}

	private isTransactionsAnalysisAggregation(
		analysisData: AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>
	): analysisData is AnalysisDataAggregation<AnalysisTransactionValue> {
		return this.analysisData.type === AnalysisType.transactions;
	}

	private isBalanceAnalysisAggregation(
		analysisData: AnalysisDataAggregation<AnalysisTransactionValue> | AnalysisDataAggregation<AnalysisBalanceValue>
	): analysisData is AnalysisDataAggregation<AnalysisBalanceValue> {
		return this.analysisData.type === AnalysisType.balances;
	}

	private nameForId(type: string, id: string): string {
		const filter: GenericOption = this.valuesDict[mapGroupTypeToTQLField(type)]?.values?.find(option => option.id === id);
		if (filter) {
			return filter.displayValue;
		} else {
			return id;
		}
	}
}

export class AnalysisBreakDownGroup {
	id: string;
	data = [];
	keys: string[] = ['name', 'y', 'drilldown'];
	name: string;
	type: string;
	constructor(id: string, name: string, chartType: string) {
		this.id = id;
		this.name = name;
		this.type = chartType;
	}
}

export class BalanceAnalysisOptions {
	primaryBalanceOptions: BalanceAnalysisOption[] = [
		{ value: 'compositeBalanceConverted', view: 'Composite Balance Converted' },
		{ value: 'compositeBalance', view: 'Composite Balance' },
	];

	otherBalanceOptions: BalanceAnalysisOption[] = [
		{
			value: 'bankClosingLedgerConverted',
			view: 'Ending Balance Converted',
			subview: '(Closing Ledger)',
		},
		{
			value: 'bankClosingLedger',
			view: 'Ending Balance',
			subview: '(Closing Ledger)',
		},
		{
			value: 'bankClosingAvailableConverted',
			view: 'Closing Available Converted',
		},
		{ value: 'bankClosingAvailable', view: 'Closing Available' },
		{ value: 'bankOpeningLedgerConverted', view: 'Opening Ledger Converted' },
		{ value: 'bankOpeningLedger', view: 'Opening Ledger' },
		{
			value: 'bankOpeningAvailableConverted',
			view: 'Opening Available Converted',
		},
		{ value: 'bankOpeningAvailable', view: 'Opening Available' },
		{
			value: 'bankCurrentAvailableConverted',
			view: 'Current Available Converted',
		},
		{ value: 'bankCurrentAvailable', view: 'Current Available' },
		{ value: 'bankSyntheticConverted', view: 'Synthetic Converted' },
		{ value: 'bankSynthetic', view: 'Synthetic' },
		{
			value: 'bankOpeningClosingLedgerConverted',
			view: 'Opening/Closing Ledger Converted',
		},
		{ value: 'bankOpeningClosingLedger', view: 'Opening/Closing Ledger' },
	];

	groupBy: BalanceAnalysisOption[] = [
		// { value: '', view: 'None' },
		{ value: 'currency', view: 'Currency' },
		{ value: 'institutionId', view: 'Institution' },
		{ value: 'accountId', view: 'Account' },
	];

	trxnGroupBy: BalanceAnalysisOption[] = [{ value: 'tags', view: 'Tag' }];

	balanceGroupBy: BalanceAnalysisOption[] = [
		{ value: 'entityGroupingId', view: 'Entity' },
		{ value: 'regionGroupingId', view: 'Region' },
		{ value: 'divisionGroupingId', view: 'Division' },
	];

	constructor() {}

	getSecondaryBalanceType(balanceType: AnalysisBalanceProperty): AnalysisBalanceProperty {
		switch (balanceType) {
			case AnalysisBalanceProperty.bankClosingAvailableConverted:
				return AnalysisBalanceProperty.bankOpeningAvailableConverted;
			case AnalysisBalanceProperty.bankOpeningAvailableConverted:
				return AnalysisBalanceProperty.bankClosingAvailableConverted;
			case AnalysisBalanceProperty.bankClosingAvailable:
				return AnalysisBalanceProperty.bankOpeningAvailable;
			case AnalysisBalanceProperty.bankOpeningAvailable:
				return AnalysisBalanceProperty.bankClosingAvailable;
			case AnalysisBalanceProperty.bankClosingLedgerConverted:
				return AnalysisBalanceProperty.bankOpeningLedgerConverted;
			case AnalysisBalanceProperty.bankOpeningLedgerConverted:
				return AnalysisBalanceProperty.bankClosingLedgerConverted;
			case AnalysisBalanceProperty.bankClosingLedger:
				return AnalysisBalanceProperty.bankOpeningLedger;
			case AnalysisBalanceProperty.bankOpeningLedger:
				return AnalysisBalanceProperty.bankClosingLedger;
		}
	}

	getCorrespondingBalanceType(balanceType: AnalysisBalanceProperty): AnalysisBalanceProperty {
		switch (balanceType) {
			case AnalysisBalanceProperty.bankOpeningClosingLedgerConverted:
				return AnalysisBalanceProperty.bankClosingLedgerConverted;
			case AnalysisBalanceProperty.bankOpeningClosingLedger:
				return AnalysisBalanceProperty.bankClosingLedger;
			default:
				return balanceType;
		}
	}

	getGroupByName(key: string): string {
		switch (key) {
			case 'institution':
				return 'Institution';
			case 'currency':
				return 'Currency';
			case 'accountId':
				return 'Account';
			// TODO these got changed to something with (Legacy)
			case 'accountGroupA':
				return 'Entity (Legacy)';
			case 'accountGroupB':
				return 'Region (Legacy)';
			case 'accountGroupC':
				return 'Division (Legacy)';
			case 'entityGroupingId':
				return 'Entity';
			case 'entityRegion':
				return 'Entity Region';
			case 'entityDivision':
				return 'Entity Division';
			case 'tag':
				return 'Tag';
			case 'entityId':
				return 'Entity';
			default:
				return null;
		}
	}
}

export interface BalanceAnalysisOption {
	view: string;
	value: string;
	subview?: string;
}

export interface MiscChartSettings {
	isSnack?: boolean;
	showYAxis?: boolean;
	preventDrilldown?: boolean;
	anomalyDates?: string[];
	isNet?: boolean;
}
