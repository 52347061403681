import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GetResourceTypesResponse } from '../models/user-group.model';

@Injectable({
	providedIn: 'root',
})
export class ResourceTypesService {
	constructor(private httpClient: HttpClient) {}

	getResourceTypes(): Observable<GetResourceTypesResponse> {
		return this.httpClient.get<GetResourceTypesResponse>(environment.trovataAPI('workspace') + '/entitlements/customer/user-groups/resource-types');
	}
}
