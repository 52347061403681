import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, Subscription, combineLatest, firstValueFrom, throwError } from 'rxjs';
import {
	ClearEntitiesState,
	CreateExtraProperty,
	CreatePersonnel,
	DeleteEntity,
	DeleteExtraProperty,
	DeletePersonnel,
	GetEntities,
	GetEntityTrees,
	GetExtraProperties,
	InitEntitiesState,
	PatchExtraProperty,
	PutEntities,
	PutEntity,
	PutPersonnel,
} from '../actions/entities.actions';
import { GetEntitiesResponse, Entity, EntityExtraProperty, EntityTree, GetEntityTreeResponse } from '../../models/entity.model';
import { SerializationService } from '@trovata/app/core/services/serialization.service';
import { TrovataAppState } from '@trovata/app/core/models/state.model';
import { EntitiesService } from '../../services/entities.service';
import { FeatureId } from '@trovata/app/features/settings/models/feature.model';
import { CustomerFeatureState } from '@trovata/app/features/settings/store/state/customer-feature.state';

export class EntitiesStateModel {
	entities: Entity[];
	entitiesInFlight: boolean;
	extraProperties: EntityExtraProperty[];
	entityTrees: EntityTree[];
	entityTreesInFlight: boolean;
}

@State<EntitiesStateModel>({
	name: 'entities',
	defaults: {
		entities: null,
		entitiesInFlight: null,
		entityTrees: null,
		extraProperties: null,
		entityTreesInFlight: null,
	},
})
@Injectable()
export class EntitiesState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;
	private isInitialized: boolean;
	@Select(CustomerFeatureState.hasPermissionOrShouldDemo(FeatureId.entities)) shouldSeeEntities$: Observable<boolean>;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private entitiesService: EntitiesService
	) {
		this.isInitialized = false;
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector() static entities(state: EntitiesStateModel): Entity[] {
		return state.entities;
	}

	@Selector() static entitiesInFlight(state: EntitiesStateModel): boolean {
		return state.entitiesInFlight;
	}

	@Selector() static entityTrees(state: EntitiesStateModel): EntityTree[] {
		if (state.entityTreesInFlight) {
			return undefined;
		}
		return state.entityTrees;
	}

	@Selector() static entityTreesInFlight(state: EntitiesStateModel): boolean {
		return state.entityTreesInFlight;
	}

	@Selector() static extraProperties(state: EntitiesStateModel): EntityExtraProperty[] {
		return state.extraProperties;
	}

	@Action(InitEntitiesState)
	async InitEntitieState(context: StateContext<EntitiesStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const entitiesStateIsCached: boolean = this.entitiesStateIsCached(deserializedState);

			this.appReadySub = combineLatest([this.appReady$, this.shouldSeeEntities$]).subscribe({
				next: ([appReady, shouldSeeEntities]: [boolean, boolean]) => {
					if (!this.isInitialized && appReady && shouldSeeEntities !== undefined) {
						if (shouldSeeEntities) {
							if (entitiesStateIsCached) {
								const state: EntitiesStateModel = deserializedState.entities;
								context.patchState(state);
							} else {
								context.dispatch(new GetEntityTrees());
								context.dispatch(new GetEntities());
								context.dispatch(new GetExtraProperties());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ entities: [], entitiesInFlight: false, entityTrees: [], extraProperties: [], entityTreesInFlight: false });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetEntities)
	async getEntities(context: StateContext<EntitiesStateModel>): Promise<void> {
		try {
			context.patchState({ entitiesInFlight: true });
			const resp: GetEntitiesResponse = await firstValueFrom(this.entitiesService.getEntities());
			context.patchState({ entities: resp.entities, entitiesInFlight: false });
		} catch (err) {
			context.patchState({ entitiesInFlight: false });
			throw err;
		}
	}

	@Action(PutEntities)
	async putEntities(context: StateContext<EntitiesStateModel>, action: PutEntities): Promise<void> {
		try {
			await firstValueFrom(this.entitiesService.putEntities(action.body));
			await this.store.dispatch(new GetEntities());
			await this.store.dispatch(new GetEntityTrees());
			await this.store.dispatch(new GetExtraProperties());
		} catch (error) {
			throw error;
		}
	}

	@Action(PutEntity)
	async putEntity(context: StateContext<EntitiesStateModel>, action: PutEntity): Promise<void> {
		try {
			await firstValueFrom(this.entitiesService.putEntity(action.entity));
			await this.store.dispatch(new GetEntities());
			await this.store.dispatch(new GetEntityTrees());
		} catch (error) {
			throw error;
		}
	}

	@Action(DeleteEntity)
	async deleteEntity(context: StateContext<EntitiesStateModel>, action: DeleteEntity): Promise<void> {
		try {
			await firstValueFrom(this.entitiesService.deleteEntity(action.entityId));
			await this.store.dispatch(new GetEntities());
			await this.store.dispatch(new GetEntityTrees());
		} catch (error) {
			throw error;
		}
	}

	@Action(GetExtraProperties)
	async getExtraProperties(context: StateContext<EntitiesStateModel>, action: GetExtraProperties): Promise<void> {
		try {
			const resp: EntityExtraProperty[] = await firstValueFrom(this.entitiesService.getExtraProperties());
			context.patchState({ extraProperties: resp });
		} catch (err) {
			throw err;
		}
	}

	@Action(GetEntityTrees)
	async getEntityTrees(context: StateContext<EntitiesStateModel>): Promise<EntityTree[]> {
		try {
			context.patchState({ entityTreesInFlight: true });
			const resp: GetEntityTreeResponse = await firstValueFrom(this.entitiesService.getEntityTrees());
			const trees: EntityTree[] = resp.entityTree.trees;
			context.patchState({ entityTrees: trees, entityTreesInFlight: false });
			return trees;
		} catch (err) {
			context.patchState({ entityTreesInFlight: false });
			throw err;
		}
	}

	@Action(ClearEntitiesState)
	clearEntitiesManagementState(context: StateContext<EntitiesStateModel>): void {
		this.appReadySub.unsubscribe();
		const state: EntitiesStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	@Action(CreatePersonnel)
	async createPersonnel(context: StateContext<EntitiesStateModel>, action: CreatePersonnel): Promise<void> {
		try {
			await firstValueFrom(this.entitiesService.createPersonnel(action.entity, action.personnel));
			await this.store.dispatch(new GetEntities());
		} catch (error) {
			throw error;
		}
	}

	@Action(PutPersonnel)
	async putPersonnel(context: StateContext<EntitiesStateModel>, action: PutPersonnel): Promise<void> {
		try {
			await firstValueFrom(this.entitiesService.putPersonnel(action.personnel));
			await this.store.dispatch(new GetEntities());
		} catch (error) {
			throw error;
		}
	}

	@Action(DeletePersonnel)
	async deletePersonnel(context: StateContext<EntitiesStateModel>, action: DeletePersonnel): Promise<void> {
		try {
			await firstValueFrom(this.entitiesService.deletePersonnel(action.personnel));
			await this.store.dispatch(new GetEntities());
		} catch (error) {
			throw error;
		}
	}

	@Action(CreateExtraProperty)
	async createExtraProperty(context: StateContext<EntitiesStateModel>, action: CreateExtraProperty): Promise<void> {
		try {
			await firstValueFrom(this.entitiesService.createExtraProperty(action.extraProperty));
			await this.store.dispatch(new GetExtraProperties());
		} catch (error) {
			throw error;
		}
	}

	@Action(PatchExtraProperty)
	async patchExtraProperty(context: StateContext<EntitiesStateModel>, action: PatchExtraProperty): Promise<void> {
		try {
			await firstValueFrom(this.entitiesService.patchExtraProperty(action.extraPropertyId, action.extraProperty));
			await this.store.dispatch(new GetExtraProperties());
		} catch (error) {
			throw error;
		}
	}

	@Action(DeleteExtraProperty)
	async deleteExtraProperty(context: StateContext<EntitiesStateModel>, action: DeleteExtraProperty): Promise<void> {
		try {
			await firstValueFrom(this.entitiesService.deleteExtraProperty(action.extraProperty));
			await this.store.dispatch(new GetExtraProperties());
		} catch (error) {
			throw error;
		}
	}

	private entitiesStateIsCached(deserializedState: TrovataAppState): boolean {
		if (deserializedState && deserializedState.entities && deserializedState.entities.entities) {
			return true;
		} else {
			return false;
		}
	}
}
