import { AccountTagType } from '@trovata/app/features/transactions/models/tag.model';
import { Currency } from './currency.model';

export enum ParameterType {
	accountId = 'accountId',
	currency = 'currency',
	endDate = 'endDate',
	endIngestionTimestamp = 'endIngestionTimestamp',
	excludedFromTags = 'excludedFromTags',
	excludeTags = 'excludeTags',
	filter = 'filter',
	institutionId = 'institutionId',
	sort = 'sort',
	startDate = 'startDate',
	startIngestionTimestamp = 'startIngestionTimestamp',
	tag = 'tag',
	tagId = 'tagId',
	type = 'type',
	dealCompanyId = 'dealCompanyId',
	invoiceType = 'invoiceType',
	invoiceProvider = 'invoiceProvider',
	maxAge = 'maxAge',
	startDueDate = 'startDueDate',
	endDueDate = 'endDueDate',
	division = 'division',
	region = 'region',
	entity = 'entity',
	entityId = 'entityId',
	entityRegion = 'entityRegion',
	entityDivision = 'entityDivision',
	q = 'q',
	tagType = 'tagType',
	debitGLCode = 'glCode1',
	creditGLCode = 'glCode2',
	glTagId = 'glTagId',
	noGlTag = 'noGlTag',
	untagged = 'untagged',
	isManual = 'isManual',
	positionType = 'positionType',
	transactionId = 'transactionId',
	PAYMENT_TYPE = 'paymentType',
	PAYMENT_STATUS = 'paymentStatus',
	START_DATE = 'startDate',
	END_DATE = 'endDate',
	includeTransactions = 'includeTransactions',
	granularity = 'granularity',
}

export class SearchParameter {
	type: string;
	displayValue: string;
	value: any;
	locked?: boolean;

	constructor(type: string, displayValue: string, value: any, locked?: boolean) {
		this.type = type;
		this.displayValue = displayValue;
		this.value = value;
		this.locked = locked;
	}

	getDisplayType(): string {
		switch (this.type) {
			case ParameterType.institutionId: {
				return 'Institution: ';
			}
			case ParameterType.accountId: {
				return 'Account: ';
			}
			case ParameterType.currency: {
				return 'Currency: ';
			}
			case ParameterType.tag: {
				return 'Included Tag: ';
			}
			case ParameterType.tagId: {
				return 'Included Tag: ';
				break;
			}
			case ParameterType.startDate: {
				return 'Start Date: ';
			}
			case ParameterType.endDate: {
				return 'End Date: ';
			}
			case ParameterType.type: {
				return 'Type: ';
			}
			case ParameterType.excludeTags: {
				return 'Excluded Tag: ';
			}
			case ParameterType.excludedFromTags: {
				return 'Excluded From Tag: ';
				break;
			}
			case ParameterType.dealCompanyId: {
				return 'Customer / Vendor: ';
			}
			case ParameterType.invoiceType: {
				return 'Invoice Type: ';
			}
			case ParameterType.entityId: {
				return 'Entity: ';
			}
			case ParameterType.entity: {
				return AccountTagType.entity + ': ';
			}
			case ParameterType.region: {
				return AccountTagType.region + ': ';
			}
			case ParameterType.division: {
				return AccountTagType.division + ': ';
			}
			case ParameterType.tagType: {
				return 'Tag Type: ';
			}
			case ParameterType.debitGLCode: {
				return 'Debit G/L Code: ';
			}
			case ParameterType.creditGLCode: {
				return 'Credit G/L Code: ';
			}
			case ParameterType.glTagId: {
				return 'G/L Tag: ';
			}
			case ParameterType.positionType: {
				return 'Position Type: ';
			}
			case ParameterType.maxAge: {
				return 'Max Age: ';
			}
			case ParameterType.startDueDate: {
				return 'Start Due Date: ';
			}
			case ParameterType.endDueDate: {
				return 'End Due Date: ';
			}
			case ParameterType.invoiceProvider: {
				return 'Invoice Provider: ';
			}
		}
	}

	getObjectType(): string {
		switch (this.type) {
			case ParameterType.entity: {
				return 'entityGroupingId';
			}
			case ParameterType.region: {
				return 'regionGroupingId';
			}
			case ParameterType.division: {
				return 'divisionGroupingId';
			}
			default: {
				return this.type;
			}
		}
	}
}

export class SearchData {
	filter: string = '';
	parameters: SearchParameter[] = [];
	startDateParameter: SearchParameter;
	endDateParameter: SearchParameter;
	currencyConverted: Currency;

	static fromSearchData(data: SearchData): SearchData {
		const newData = new SearchData();
		newData.filter = data.filter;
		newData.parameters = [...data.parameters];
		newData.endDateParameter = data.endDateParameter;
		newData.startDateParameter = data.startDateParameter;
		newData.currencyConverted = data.currencyConverted;
		return newData;
	}

	validateSearchData(): boolean {
		let valid = false;
		if (this) {
			if (this.filter && this.filter !== '') {
				valid = true;
			} else if (this.parameters && this.parameters.length > 0) {
				valid = true;
			} else if (this.endDateParameter && this.endDateParameter.value !== '') {
				valid = true;
			} else if (this.startDateParameter && this.startDateParameter.value !== '') {
				valid = true;
			}
		}
		return valid;
	}
}

export class CustomButton {
	tooltip: string;
	icon: string;
	id: string;
}
