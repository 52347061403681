export enum UserActions {
	/* eslint-disable-next-line @typescript-eslint/naming-convention */
	login = 'login',

	JPMFlowDemoClicked = 'JPMFlowDemoClicked',

	balancesAccountsSearch = 'balancesSearchAccounts',

	dashboardSearch = 'dashboardSearch',

	snackAddReport = 'snackAddReport',
	snackAddReconReport = 'snackAddReconReport',
	snackAddForecast = 'snackAddForecast',
	snackAddTag = 'snackAddTag',
	snackDelete = 'snackDelete',
	snackMove = 'snackMove',
	snackEditRestoreDefault = 'snackEditRestoreDefault',

	forecastSearch = 'forecastSearch',
	forecastViewForecast = 'forecastViewForecast',

	reportsSearch = 'reportsSearch',
	reportsViewReport = 'reportsViewReport',
	reportsCreateReport = 'reportsCreateReport',
	reportsAddToDashboard = 'reportsAddToDashboard',
	reportsDuplicateReport = 'reportsDuplicateReport',

	accountView = 'accountView',

	balancesEditBalanceGrid = 'balancesEditBalanceGrid',
	balancesEditBalanceGridPasted = 'balancesEditBalanceGridPasted',
	balancesEditBulkBalance = 'balancesEditBulkBalance',
	balancesEditAccountBalance = 'balancesEditAccountBalance',
	balancesPastedBulkBalance = 'balancesPastedBulkBalance',
	balancesPastedAccountBalance = 'balancesPastedAccountBalance',

	searchbarSearch = 'searchbarSearch',

	transactionAddTag = 'transactionAddTag',
	transactionManualUpload = 'transactionManualUpload',

	reconViewRecon = 'reconViewRecon',
	reconSaveReport = 'reconSaveReport',
	reconItems = 'reconItems',
	reconTagsBreakdown = 'reconTagsBreakdown',
	reconDuplicateReport = 'reconDuplicateReport',
	reconAddToDashboard = 'reconAddToDashboard',
	reconToggle = 'reconToggle',

	analysisTypeBalance = 'analysisTypeBalance',
	analysisTypeTransaction = 'analysisTypeTransaction',
	analysisTypeInvoice = 'analysisTypeInvoice',

	passwordReset = 'passwordReset',

	onboardingFinish = 'onboardingFinish',

	// Google Analytics events for Marketing.
	proxySignUp = 'proxy.sign_up',
	proxyLogin = 'proxy.login',
	proxyBankConnected = 'proxy.bank_connected',
	userSubscriptionStartup = 'user.subscription.startup',
	userSubscriptionGrowth = 'user.subscription.growth',
	userSubscriptionCorporate = 'user.subscription.corporate',
	proxyUserDataLoad = 'app.user_data_loaded',
	ecommercePurchase = 'ecommerce.purchase',
	contactSales = 'app.contact_sales',
}
