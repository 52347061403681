import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
	CheckoutPreview,
	CustomerSubscriptionId,
	SubscriptionCadence,
	SubscriptionChangeData,
	SubscriptionsAndProducts,
	SubscriptionUpdate,
} from '../models/subscriptions.model';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionService {
	constructor(private httpClient: HttpClient) {}

	getSubscriptions(): Observable<SubscriptionsAndProducts> {
		const url = environment.trovataAPI('workspace') + '/billing/subscriptions';
		return this.httpClient
			.get<SubscriptionsAndProducts>(url, {
				params: {
					stripeEnvironment: environment.stripe,
				},
			})
			.pipe(
				map(res => res),
				catchError(err => throwError(err))
			);
	}

	updateSubscription(subUpdate: SubscriptionUpdate): Observable<CustomerSubscriptionId> {
		const url = environment.trovataAPI('workspace') + '/billing/customer/subscriptions';
		return this.httpClient
			.put<CustomerSubscriptionId>(
				url,
				{
					products: subUpdate.products,
					cadence: subUpdate.cadence,
					autopay: subUpdate.autopay,
				},
				{
					params: {
						stripeEnvironment: environment.stripe,
						subscriptionId: subUpdate.subscriptionId,
					},
				}
			)
			.pipe(
				map(res => res),
				catchError(err => throwError(err))
			);
	}

	deleteSubscription(subscriptionId: string): Observable<CustomerSubscriptionId> {
		const url = environment.trovataAPI('workspace') + '/billing/customer/subscriptions';
		return this.httpClient.delete<CustomerSubscriptionId>(url, {
			params: { subscriptionId: [subscriptionId] },
		});
	}

	getCheckoutPreview(subscriptionId: string, products: SubscriptionChangeData[], cadence: SubscriptionCadence, autopay: boolean): Observable<CheckoutPreview> {
		const url = environment.trovataAPI('workspace') + '/billing/customer/checkout/preview';
		return this.httpClient
			.post<CheckoutPreview>(
				url,
				{
					products,
					cadence,
					autopay,
				},
				{
					params: {
						stripeEnvironment: environment.stripe,
						subscriptionId,
					},
				}
			)
			.pipe(catchError(err => throwError(err)));
	}
}
