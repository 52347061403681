import { rand, randAmount, randBetweenDate, randBoolean, randNumber, randUuid, randWord } from '@ngneat/falso';
import { SeriesOptionsType } from 'highcharts';
import { DateTime } from 'luxon';
import { Server } from 'miragejs';
import { ValuesEntity } from '../../features/forecasts/models/forecastV3-forecast-response.model';
import { GraphData, InsightDirection, InsightDisplayData, InsightGroupChangeItem, InsightsGroupRowView } from '../../features/insights/models/insights.model';
import { TransactionsExplorerClusterSummary, TransactionsExplorerTransaction } from '../../features/insights/models/transactions-explorer.model';
import { Cadence } from '../../features/reports/models/cadence.model';
import { ReconData, ReconDataGroup } from '../../features/reports/models/reconciliation.model';
import { NonManualHistoricalBalance } from '../models/historical-balance.model';
import { AccountDbData, AccountGroupingDbData, AppSchema, InstitutionDbData, TagDbData } from './types';
import { TagType } from '../../features/transactions/models/tag.model';
import {
	AnalysisTransactionSummaryLegacy,
	AnalysisTransactionValuesLegacy,
	TransactionsAggregationLegacy,
} from '@trovata/app/features/reports/models/analysis.legacy.model';
import { AnalysisBalanceValue, AnalysisDataAggregation, AnalysisTransactionValue } from '@trovata/app/features/reports/models/analysis.model';

export const chosenCurrencies: string[] = [
	'ADF',
	'ADP',
	'AED',
	'ARS',
	'AUD',
	'BDT',
	'BGL',
	'BIF',
	'BRL',
	'BTC',
	'CAD',
	'CHF',
	'CNY',
	'CZK',
	'DKK',
	'EUR',
	'GBP',
	'JPY',
	'MXN',
	'NZD',
	'USD',
];

export const randAccountName = () => {
	const accountTypes = ['Checking', 'Savings', 'Money Market', 'Investment', 'Home Loan', 'Credit Card', 'Auto Loan', 'Personal Loan'];
	return rand(accountTypes) + ' Account';
};

export const randYYmmddDate = (to: DateTime, from: DateTime): string => {
	const date = randBetweenDate({ to: to.toJSDate(), from: from.toJSDate() });
	return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
};

export function shuffleArray<T>(list: T[]): T[] {
	list = [...list];

	for (let i = list.length - 1; i > 0; --i) {
		const j = randNumber({ max: i });
		[list[i], list[j]] = [list[j], list[i]];
	}

	return list;
}

export const accountFilters = (server: Server | AppSchema) => {
	const accountTags = server.db.accountGroupings.map((accountGrouping: AccountGroupingDbData) => ({
		tagTitle: accountGrouping.name,
		tagDescription: '',
		tagType: accountGrouping.type,
		tagId: accountGrouping.id,
	}));
	return {
		accountTags,
	};
};

export const transactionFilters = (server: Server | AppSchema) => {
	const institutions = server.db.institutions.map((inst: InstitutionDbData) => ({
		institutionId: inst.id,
		institutionName: inst.institutionName,
		institutionNickname: null,
		isManual: false,
	}));
	const currencies = chosenCurrencies;
	const accounts = server.db.accounts.map((acct: AccountDbData) => ({
		accountId: acct.id,
		accountNumber: acct.accountNumber,
		isManual: false,
		name: acct.name,
		nickname: acct.nickname,
	}));
	const tags = server.db.tags.where({ tagType: TagType.transaction }).map((tag: TagDbData) => ({
		tagTitle: tag.tagTitle,
		tagDescription: tag.tagDescription,
		tagType: 'transaction',
		tagId: tag.id,
		creationTime: '2022-09-08T18:16:34Z',
		lastUpdatedTime: '2022-09-08T18:16:34Z',
		priority: null,
		tagMetadata: {},
		isProcessing: false,
	}));
	return {
		institutions,
		currencies,
		accounts,
		tags,
	};
};

export const analysisBalanceValues = (length: number, cadence: string): AnalysisBalanceValue[] => {
	const values: AnalysisBalanceValue[] = [];
	for (let index = 0; index < length; index++) {
		values.push(analysisBalanceValue(index, cadence));
	}
	return values.reverse();
};

export const analysisTransactionValues = (length: number, cadence: string): AnalysisTransactionValue[] => {
	const values: AnalysisTransactionValue[] = [];
	for (let index = 0; index < length; index++) {
		const credit: number = randAmount();
		const debit: number = randAmount();
		values.push({
			date: cadenceDate(cadence, index).toISO(),
			credit,
			debit,
			net: credit - debit,
			// debitAmountConverted: randAmount(),
		});
	}
	return values.reverse();
};

export const analysisTransactionValuesLegacy = (length: number, cadence: string): AnalysisTransactionValuesLegacy[] => {
	const values: AnalysisTransactionValuesLegacy[] = [];
	for (let index = 0; index < length; index++) {
		values.push({
			epoch: cadenceDate(cadence, index).toISO(),
			creditAmount: randAmount(),
			creditAmountConverted: randAmount(),
			debitAmount: randAmount(),
			debitAmountConverted: randAmount(),
		});
	}
	return values.reverse();
};

const cadenceDate = (cadence: string, index: number): DateTime => {
	switch (cadence) {
		case Cadence.daily:
			return DateTime.now().minus({ days: index });
		case Cadence.monthly:
			return DateTime.now().minus({ months: index });
		case Cadence.weekly:
			return DateTime.now().minus({ weeks: index });
		case Cadence.quarterly:
			return DateTime.now().minus({ quarters: index });
		default:
			break;
	}
};

export const analysisBalanceValue = (index: number, cadence: string): AnalysisBalanceValue => ({
	date: cadenceDate(cadence, index).toFormat('yyyy-MM-dd'),
	accountIds: Array.from({ length: 5 }, randUuid),
	containsIsFilled: true,
	containsIsCalculated: false,
	bankOpeningAvailable: null,
	bankClosingAvailable: null,
	bankOpeningLedger: null,
	bankClosingLedger: randAmount(),
	bankCurrentAvailable: null,
	bankCurrentAvailableConverted: randAmount(),
	bankOpeningAvailableConverted: randAmount(),
	bankClosingAvailableConverted: randAmount(),
	bankOpeningLedgerConverted: randAmount(),
	bankClosingLedgerConverted: randAmount(),
	bankSynthetic: null,
	bankSyntheticConverted: null,
	trovataOpeningBalance: null,
	trovataOpeningBalanceConverted: randAmount(),
	trovataClosingBalance: null,
	trovataClosingBalanceConverted: randAmount(),
	compositeBalance: null,
	compositeBalanceConverted: randAmount(),
	compositeField: null,
	compositeFieldConverted: 'multiple',
	currency: 'USD',
	currencyConverted: 'USD',
});

export const nonManualHistoricalBalances = (length: number): NonManualHistoricalBalance[] => {
	const historicalBalances = [];
	for (let i = 0; i < length; i++) {
		historicalBalances.push({
			date: DateTime.now().minus({ days: i }).toFormat('yyyy-MM-dd'),
			bankClosingAvailable: randAmount(),
			bankOpeningLedger: randAmount(),
			bankClosingLedger: randAmount(),
			bankCurrentAvailable: null,
			bankCurrentAvailableConverted: null,
			bankOpeningAvailable: randAmount(),
			bankClosingAvailableConverted: randAmount(),
			bankClosingLedgerConverted: randAmount(),
			bankOpeningAvailableConverted: randAmount(),
			bankOpeningLedgerConverted: randAmount(),
			compositeBalance: randAmount(),
			compositeBalanceConverted: randAmount(),
			compositeField: 'bankClosingLedger',
			compositeFieldConverted: 'bankClosingLedgerConverted',
			currency: 'USD',
			currencyConverted: 'USD',
		});
	}
	return historicalBalances;
};

export const balancesAggregation = (groupIds: string[], type: string, periods: number, cadence: string): AnalysisDataAggregation<AnalysisBalanceValue>[] => {
	const balancesAgg: AnalysisDataAggregation<AnalysisBalanceValue>[] = [];
	for (let i: number = 0; i <= groupIds.length; i++) {
		balancesAgg.push({
			type,
			value: groupIds.length ? groupIds[i] : '',
			summary: analysisBalanceValues(periods, cadence),
			aggregation: [],
		});
	}
	return balancesAgg;
};

export const transactionsAggregation = (
	groupIds: string[],
	type: string,
	periods: number,
	cadence: string
): AnalysisDataAggregation<AnalysisTransactionValue>[] => {
	const trxnAgg: AnalysisDataAggregation<AnalysisTransactionValue>[] = [];
	for (let i = 0; i <= groupIds.length; i++) {
		trxnAgg.push({
			type: type,
			value: groupIds.length ? groupIds[i] : '',
			aggregation: [],
			summary: analysisTransactionValues(periods, cadence),
		});
	}
	return trxnAgg;
};
export const transactionsAggregationLegacy = (groupIds: string[], groupType: string, periods: number, cadence: string): TransactionsAggregationLegacy[] => {
	const trxnAgg = [];
	for (let i = 0; i <= groupIds.length; i++) {
		trxnAgg.push({
			groupType,
			groupValue: groupIds.length ? groupIds[i] : '',
			transactions: null,
			transactionsAggregation: [
				{
					groupType: '',
					groupValue: '',
					transactions: analysisTransactionValuesLegacy(periods, cadence),
				},
			],
		});
	}
	return trxnAgg;
};

export const transactionSummary = (currencyOverride?: string): AnalysisTransactionSummaryLegacy => ({
	currencyConverted: currencyOverride ?? 'USD',
	creditAmount: randAmount(),
	creditAmountConverted: randAmount(),
	debitAmount: 0,
	debitAmountConverted: 0,
	creditCountTransactions: 15,
	debitCountTransactions: 0,
});

export const valuesEntity = (startDate: string, endDate: string): ValuesEntity[] => {
	let valueDate = DateTime.fromISO(startDate);
	const endDateCheck = DateTime.fromISO(endDate);
	const values = [];
	while (valueDate.startOf('day') <= endDateCheck.startOf('day')) {
		const forecasted: number = randNumber({ min: 10000, max: 999999999 });
		const actual = forecasted + randNumber({ min: -100000000, max: 100000000 });
		values.push({
			date: valueDate,
			forecasted,
			actual,
			forecastedManuallyEdited: false,
			actualManuallyEdited: false,
			forecastedWithFactor: forecasted,
		});
		valueDate = valueDate.plus({ day: 1 });
	}
	return values;
};

export const reconData = (schema: AppSchema): ReconData => {
	const currencyAggregation: ReconDataGroup[] = [];
	const tagAggregation: ReconDataGroup[] = schema.db.tags.map((tag: TagDbData) => reconDataGroup('tagId', tag.tagId));
	chosenCurrencies.map(curr => {
		const foundAccts: AccountDbData[] = schema.db.accounts.filter((acct: AccountDbData) => acct.currency === curr);
		if (foundAccts.length) {
			const institutionAggregation = foundAccts.map((acct: AccountDbData) => reconDataGroup('institutionId', acct.institutionId));
			currencyAggregation.push({
				...reconDataGroup('currency', curr),
				reconAggregation: institutionAggregation,
				tagAggregation,
			});
		}
	});
	return {
		reconData: {
			periodView: false,
			aggregation: currencyAggregation,
		},
	};
};

const reconDataGroup = (groupType: string, groupValue: string): ReconDataGroup => ({
	groupType,
	groupValue,
	periodData: [
		{
			date: DateTime.now().minus({ day: 1 }).toFormat('yyyy-MM-dd'),
			openingBalance: randAmount(),
			openingBalanceConverted: randAmount(),
			closingBalance: randAmount(),
			closingBalanceConverted: randAmount(),
			changeInBalance: randAmount(),
			changeInBalanceConverted: randAmount(),
			netTransactionActivity: randAmount(),
			netTransactionActivityConverted: randAmount(),
			creditAmount: randAmount(),
			creditAmountConverted: randAmount(),
			debitAmount: randAmount(),
			debitAmountConverted: randAmount(),
			delta: rand([0, randAmount()]),
			deltaConverted: rand([0, randAmount()]),
			transactionsCount: randAmount(),
		},
	],
	reconAggregation: [],
});

export const insightDisplayData = (insightData?: Partial<InsightDisplayData>): InsightDisplayData => ({
	dateRange: `${DateTime.now().minus({ day: 4 }).toFormat('MM/dd')} to ${DateTime.now().minus({ day: 1 }).toFormat('MM/dd')}`,
	displayIconColor: 'red',
	detailView: true,
	titleOverride: false,
	displayValue: randUuid(),
	displayType: 'tag',
	displayIcon: 'arrow_downward',
	displayDelta: randAmount({ min: -100000, max: 100000 }),
	showType: true,
	displayCurrency: 'USD',
	startDate: null,
	endDate: null,
	cadence: null,
	periods: null,
	view: InsightsGroupRowView.PercentageBarChartRow,
	sizeV2: 'medium',
	...insightData,
});

export const insightSeries = (length: number, type?: string): SeriesOptionsType[] => {
	const data = [];
	for (let i = 0; i < length; i++) {
		data.push({ y: randAmount({ min: 10000000, max: 99999999 }) });
	}
	return [
		{
			type: (type as any) ?? 'column',
			data,
		},
	];
};

export const insightGroupChangeItem = (
	groupChangeItem?: Partial<InsightGroupChangeItem>,
	graphData?: Partial<GraphData>,
	positivePercentage?: boolean
): InsightGroupChangeItem => {
	const positive: boolean = positivePercentage !== undefined ? positivePercentage : randBoolean();
	const groupPercentage: string = `${positive ? '+' : '-'}${randAmount({
		min: 20,
		max: 50,
	})}%`;
	const colors: string[] = positive ? ['#00ab00e3'] : ['#e10000d4'];
	const groupColorClass: string = positive ? 'direction-up' : 'direction-down';
	return {
		groupPercentage,
		groupGraphData: {
			series: insightSeries(4),
			options: {},
			tooltips: false,
			labels: false,
			colors,
			isPercentage: false,
			maxValue: 0,
			...graphData,
		},
		groupColorClass,
		groupArrowRotateClass: 'arrow-rotate-down',
		groupDisplay: 'ACH [AR]',
		groupCurrency: 'USD',
		groupDirection: rand([InsightDirection.down, InsightDirection.up]),
		groupValue: `${positive ? '+' : '-' + randAmount({ min: 1000, max: 1000000 })}`,
		...groupChangeItem,
	};
};

export const transactionExplorerTransactions = (length: number): TransactionsExplorerTransaction[] => {
	const trxns: TransactionsExplorerTransaction[] = [];
	for (let i = 0; i < length; i++) {
		trxns.push({
			account_transaction_id: randUuid(),
			account_id: randUuid(),
			institution_id: randUuid(),
			institution_name: randWord(),
			account_number: '****6834',
			account_name: 'US - Ops',
			transaction_epoch: '2022-04-04 00:00:00',
			base_type: 'DEBIT',
			currency: 'USD',
			description: '',
			description_detail: '',
			x1: randNumber({ min: -20, max: 20, fraction: 15 }),
			x2: randNumber({ min: -20, max: 20, fraction: 15 }),
			x3: randNumber({ min: -20, max: 20, fraction: 15 }),
			amountasusd: '60962.15',
			status: null,
			account_type: null,
			position_type: null,
			cluster: randNumber({ min: 1, max: 6 }),
		});
	}
	return trxns;
};

export const explorerSummary = (): {
	[clusterIndex: number]: TransactionsExplorerClusterSummary;
} => {
	const summaries: {
		[clusterIndex: number]: TransactionsExplorerClusterSummary;
	} = {};
	for (let i = 1; i <= 6; i++) {
		summaries[i] = {
			topics: [
				{
					weight: 0.47,
					keywords: 'eft credit',
				},
				{
					weight: 0.393,
					keywords: 'autopay cr',
				},
				{
					weight: 0.082,
					keywords: 'ach credit received',
				},
				{
					weight: 0.055,
					keywords: 'credit received',
				},
			],
			tagTerms: 'ach eft received credit cr autopay',
			account_id: {
				[randUuid()]: 0.06,
				[randUuid()]: 0.05,
				[randUuid()]: 0.05,
				[randUuid()]: 0.03,
				[randUuid()]: 0.02,
				[randUuid()]: 0.06,
				[randUuid()]: 0.05,
				[randUuid()]: 0.06,
				[randUuid()]: 0.02,
				[randUuid()]: 0.02,
				[randUuid()]: 0.04,
				[randUuid()]: 0.03,
				[randUuid()]: 0.03,
			},
			institution_id: {
				DB: 0.08,
				BOFA: 0.17,
				HSBC: 0.05,
				JPM: 0.28,
				CITI: 0.12,
				WFB: 0.22,
				PNC: 0.02,
				BARCLAYS: 0.03,
				SCB: 0.02,
				ins_112374: 0,
			},
			currency: {
				CHF: 0.06,
				ARS: 0.08,
				CNY: 0.05,
				USD: 0.49,
				EUR: 0.06,
				NZD: 0.06,
				BRL: 0.06,
				MXN: 0.02,
				GBP: 0.04,
				CAD: 0.06,
				JPY: 0.02,
			},
			amountAsUSD: {
				min: 0.27,
				avg: 10619.57,
				max: 314670.42,
			},
			transactionCount: 578,
			transactionProportion: 0.29,
		};
	}
	return summaries;
};
