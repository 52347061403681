export class InitHeapAnalyticsState {
	static readonly type: string = '[HeapAnalytics] InitHeapAnalyticsState';
}

export class ClearHeapAnalyticsState {
	static readonly type: string = '[HeapAnalytics] ClearHeapAnalyticsState';
}

export class ResetHeapAnalyticsState {
	static readonly type: string = '[HeapAnalytics] ResetHeapAnalyticsState';
}

export class SetUserVariables {
	static readonly type: string = '[HeapAnalytics] SetUserVariables';
}

export class SetAnalyticsVariable {
	static readonly type: string = '[HeapAnalytics] SetAnalyticsVariable';
	constructor(
		public variable: string,
		public value: any
	) {}
}

export class SetLogin {
	static readonly type: string = '[HeapAnalytics] SetLogin';
	constructor(public isLogin: boolean) {}
}

export class UserAction {
	static readonly type: string = '[HeapAnalytics] UserAction';
	constructor(public event: string) {}
}

export class APIError {
	static readonly type: string = '[HeapAnalytics] APIError';
	constructor(public error: string) {}
}
