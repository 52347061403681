import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'minusSignToParens',
})
export class MinusSignToParens implements PipeTransform {
	transform(value: any, roundingSymbol?: string, args?: any): any {
		value = value || 0;
		value = value.toString();
		let symbol;
		if (roundingSymbol) {
			symbol = roundingSymbol;
		} else {
			symbol = '';
		}
		return value.charAt(0) === '-' ? '(' + value.substring(1, value.length) + symbol + ')' : value + symbol;
	}
}
