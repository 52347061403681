export class InitGoogleAnalyticsState {
	static readonly type: string = '[GoogleAnalytics] InitGoogleAnalyticsState';
}

export class ClearGoogleAnalyticsState {
	static readonly type: string = '[GoogleAnalytics] ClearGoogleAnalyticsState';
}

export class ResetGoogleAnalyticsState {
	static readonly type: string = '[GoogleAnalytics] ResetGoogleAnalyticsState';
}

export class SetLogin {
	static readonly type: string = '[GoogleAnalytics] SetLogin';
	constructor() {}
}

export class LoadUserData {
	static readonly type: string = '[GoogleAnalytics] LoadUserData';
	constructor(
		public userId: string,
		public parameters: object
	) {}
}

export class UserAction {
	static readonly type: string = '[GoogleAnalytics] UserAction';
	constructor(public event: string) {}
}

export class UserActionWithParam {
	static readonly type: string = '[GoogleAnalytics] UserActionWithParam';
	constructor(
		public event: string,
		public parameters: object
	) {}
}

export class Ecommerce {
	static readonly type: string = '[GoogleAnalytics] Ecommerce';
	constructor(
		public event: string,
		public parameters: object
	) {}
}
