<div class="main-container" [ngClass]="{ dense: dense, normal: !dense }">
	<div class="labels">
		<div class="mat-subtitle-2 title" [matTooltip]="title" matTooltipPosition="above">
			{{ title }}
		</div>
		<div *ngIf="dense" class="chart">
			<trovata-app-highcharts [chartBuilder]="lineChartBuilder"></trovata-app-highcharts>
		</div>
		<div class="trend-col">
			<trovata-value-with-trend class="trend-data" [value]="value" [valueStyles]="['mat-body-1']" [trendPosition]="dense ? 'right' : 'down'">
				<trovata-trend-indicator [color]="trend?.color" [arrowDirection]="trend?.direction" [percentage]="trend?.percentage"></trovata-trend-indicator>
			</trovata-value-with-trend>
		</div>
	</div>
	<div *ngIf="!dense" class="chart">
		<trovata-app-highcharts [chartBuilder]="lineChartBuilder"></trovata-app-highcharts>
	</div>
</div>
