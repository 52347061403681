import { SubscriptionUpdate } from '../../models/subscriptions.model';

export class InitSubscriptionsState {
	static readonly type: string = '[Subscriptions] InitSubscriptionsState';
}

export class GetSubscriptions {
	static readonly type: string = '[Subscriptions] GetSubscriptions';
}

export class UpdateSubscription {
	static readonly type: string = '[Subscriptions] UpdateSubscription';
	constructor(public subUpdate: SubscriptionUpdate) {}
}

export class ResetSubscriptionsState {
	static readonly type: string = '[Subscriptions] ResetSubscriptionsState';
}

export class ClearSubscriptionsState {
	static readonly type: string = '[Subscriptions] ClearSubscriptionsState';
}

export class DeleteSubscription {
	static readonly type: string = '[Subscriptions] DeleteSubscription';
	constructor(public subscriptionId: string) {}
}
