import { ValuesEntity } from 'src/app/features/forecasts/models/forecastV3-forecast-response.model';
import { Server } from 'miragejs';
import { valuesEntity } from '../helpers.mock';
import { AppRegistry } from '../types';

export function createForecastsAndStreams(server: Server<AppRegistry>) {
	const dailyForecast = server.create('forecast', {
		name: 'Daily Forecast',
		forecastId: 'dailyForecastId',
	});
	const valuesEnt: ValuesEntity[] = valuesEntity(dailyForecast.startDate, dailyForecast.endDate);
	const acctIds: string[] = server.db.accounts.map(acc => acc.id);
	let streamOne = server.create('forecastStream', {
		streamId: 'streamOneId',
		name: 'Equity',
	});
	streamOne = server.db.forecastStreams.update(streamOne.id, {
		values: valuesEnt,
	});
	let streamTwo = server.create('forecastStream', {
		streamId: 'streamTwoId',
		name: 'International Activity',
	});
	const streamUpdate2 = {
		values: valuesEntity(dailyForecast.startDate, dailyForecast.endDate),
	};
	streamTwo = server.db.forecastStreams.update(streamTwo.id, streamUpdate2);
	const streams = [streamOne, streamTwo];
	server.db.forecasts.update(dailyForecast.id, {
		forecastId: dailyForecast.id,
		streams,
		accounts: acctIds,
		cashBalances: valuesEnt,
		cashflow: valuesEnt,
	});

	const aggressiveForecast = server.create('forecast', {
		name: 'Aggresive Daily Forecast',
		forecastId: 'dailyForecastId',
	});

	const updatedValues = valuesEnt.map(ve => ({
		...ve,
		forecasted: ve.forecasted * 1.25,
	}));
	server.db.forecasts.update(aggressiveForecast.id, {
		forecastId: aggressiveForecast.id,
		streams,
		accounts: acctIds,
		cashBalances: updatedValues,
		cashflow: updatedValues,
	});
}
