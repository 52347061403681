import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { Observable } from 'rxjs';
import {
	StageFeedback,
	PaymentToCreate,
	Payment,
	PaymentSubmissionOrCancelation,
	BatchPayment,
	GetBatchPaymentsResponse,
	GetPaymentsV1Response,
	ValidateBatchCsvFileResponse,
	PaymentsListBody,
	LexisNexisBankInformationResponse,
} from '../../models/payment.model';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PaymentsService {
	constructor(private httpService: HttpService) {}

	getPaginatedPayments(body: PaymentsListBody): Observable<GetPaymentsV1Response> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/payments-list`, body);
	}

	getPaymentById(paymentId: string): Observable<GetPaymentsV1Response> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/v1/payments?paymentId=${paymentId}`);
	}

	getBatchPaymentById(batchId: string): Observable<GetBatchPaymentsResponse> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/v1/batches?batchId=${batchId}`);
	}

	createPayment(body: PaymentToCreate): Observable<Payment> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/payment`, body);
	}

	setStageFeedback(body: StageFeedback): Observable<Payment> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/payment/stage/feedback`, body);
	}

	setBatchStageFeedback(body: StageFeedback): Observable<BatchPayment> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/batches/stage/feedback`, body);
	}

	cancelPayment(body: PaymentSubmissionOrCancelation): Observable<Payment> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/payment/cancelation`, body);
	}

	cancelBatchPayment(body: PaymentSubmissionOrCancelation): Observable<BatchPayment> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/batches/cancelation`, body);
	}

	getBatchPayments(): Observable<GetBatchPaymentsResponse> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/v1/batches`);
	}

	getPaginatedBatchPayments(from: number, size: number): Observable<GetBatchPaymentsResponse> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/v1/batches?from=${from}&size=${size}`);
	}

	createBatchPayment(body: FormData): Observable<BatchPayment> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/batches`, body);
	}

	getPaymentsByBatchId(batchId: string): Observable<GetPaymentsV1Response> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/v1/payments?batchId=${batchId}`);
	}

	validateBatchCsvFile(body: FormData): Observable<ValidateBatchCsvFileResponse> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/batches/validation/file`, body);
	}

	getBankInformationByAccountNumber(isNumberbic: boolean, accountNumberOrBic: string, countryCode: string): Observable<LexisNexisBankInformationResponse> {
		const bankIdentifier: string = isNumberbic ? 'bic' : 'routingNumber';
		return this.httpService.get(
			`${environment.workspaceAPI('payments')}/v1/institutions/metadata?${bankIdentifier}=${accountNumberOrBic}&countryCode=${countryCode}`
		);
	}
	uploadPaymentFile(body: FormData, paymentId: string): Observable<Payment> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/payments/${paymentId}/attachments`, body);
	}
	
	deleteAttachmentFromPayment(attachmentId: string, paymentId: string): Observable<Payment> {
		return this.httpService.delete(`${environment.workspaceAPI('payments')}/v1/payments/${paymentId}/attachments?attachmentId=${attachmentId}`);
	}
}
