import { Institution } from '../../models/institution.model';

export class InitInstitutionsState {
	static readonly type: string = '[Institution] InitInstitutionsState';
}

export class ClearInstitutionsState {
	static readonly type: string = '[Institution] ClearInstitutionsState';
}

export class ResetInstitutionsState {
	static readonly type: string = '[Institution] ResetInstitutionsState';
}

export class GetCustomerInstitutions {
	static readonly type: string = '[Institution] GetCustomerInstitutions';
}

export class GetTrovataInstitutions {
	static readonly type: string = '[Institution] GetTrovataInstitutions';
}

export class GetTrovataNonPlaidInstitutions {
	static readonly type: string = '[Institution] GetTrovataNonPlaidInstitutions';
}

export class GetManualInstitutions {
	static readonly type: string = '[Institution] GetManualInstitutions';
}

export class GetPremiumInstitutions {
	static readonly type: string = '[Institution] GetPremiumInstitutions';
}

export class CreateManualInstitution {
	static readonly type: string = '[Institution] CreateManualInstitution';
	constructor(public name: string) {}
}

export class DeleteManualInsitution {
	static readonly type: string = '[Institution] DeleteManualInstitution';
	constructor(public id: string) {}
}

export class UpdateManualInstitution {
	static readonly type: string = '[Institution] UpdateManualInstitution';
	constructor(
		public institution: Institution,
		public name: string
	) {}
}

export class UpdateCustomInstitution {
	static readonly type: string = '[Institution] UpdateCustomInstitution';
	constructor(
		public institution: Institution,
		public name: string
	) {}
}
