import { rand, randNumber, randBetweenDate, randAmount, randCompanyName } from '@ngneat/falso';
import { TagMetadata, TagType, TagVersion } from 'src/app/features/transactions/models/tag.model';
import { Server } from 'miragejs';
import { shuffleArray } from '../helpers.mock';
import { AppRegistry, TagDbData, TransactionDbData } from '../types';
import {
	NotAccountAndIntraday,
	AutopayCurrencyWithDateRange,
	ManualAndMoreThan200,
	MoneyOrderForRegionOrInst,
	TagParentWithExclude,
} from '../../models/mock-tql-query';
import { AccountTargetV3 } from '../../models/account-target.model';
import { MockInstitutionIds } from './institutions-accounts-trxns';
import { MockRegionIds } from './account-groupings';
import { DateTime } from 'luxon';
import { Metadata } from 'src/app/features/transactions/models/transaction.model';

export enum MockGLCodes {
	debitCodeId = 'debitCodeId',
	generalCodeId = 'generalCodeId',
}

export enum MockTransactionTagIds {
	autopay = 'autopaytaguuid',
	intraday = 'intradayguuid',
	moneyOrderDebits = 'moneyordertaguuid',
	manualLarge = 'manuallargetaguuid',
	parent = 'parenttaguuid',
}

export enum MockGLTagIds {
	debitGlTagId = 'debittaguuid',
	payrollAutoGlTagId = 'payrolltaguuid',
	glTagId = 'gltaguuid',
}

export function createAndAttachTags(server: Server<AppRegistry>) {
	attachTransactionTags(server);
	createGLTags(server);
}

function createGLTags(server: Server<AppRegistry>) {
	const payrollAutoGlTag: TagDbData = server.create('tag', {
		tagType: TagType.glTagAuto,
		tagTitle: 'Payroll - Benefits',
		tagId: MockGLTagIds.payrollAutoGlTagId,
		id: MockGLTagIds.payrollAutoGlTagId,
	});
	server.db.tags.update(payrollAutoGlTag.id, {
		tagMetadata: {
			...payrollAutoGlTag.tagMetadata,
			glCode1: MockGLCodes.debitCodeId,
			glCode2: MockGLCodes.generalCodeId,
			q: 'BENEFITO',
			displayGlCodes: {
				glCode1: MockGLCodes.debitCodeId,
				glCode1Name: '2000',
				glCode1Description: 'Debit Code',
				glCode2: MockGLCodes.generalCodeId,
				glCode2Name: '1000',
				glCode2Description: 'General',
			},
		},
	});
	const debitGlTag: TagDbData = server.create('tag', {
		tagType: TagType.glTagAuto,
		tagTitle: 'Debit',
		tagId: MockGLTagIds.debitGlTagId,
		id: MockGLTagIds.debitGlTagId,
	});
	server.db.tags.update(debitGlTag.id, {
		tagMetadata: {
			...debitGlTag.tagMetadata,
			glCode1: MockGLCodes.debitCodeId,
			glCode2: MockGLCodes.generalCodeId,
			type: ['DEBIT'],
			displayGlCodes: {
				glCode1: MockGLCodes.debitCodeId,
				glCode1Name: '2000',
				glCode1Description: 'Debit Code',
				glCode2: MockGLCodes.generalCodeId,
				glCode2Name: '1000',
				glCode2Description: 'General',
			},
		},
	});
	const glTag: TagDbData = server.create('tag', {
		tagType: TagType.glTag,
		tagTitle: 'Checks',
		tagId: MockGLTagIds.glTagId,
		id: MockGLTagIds.glTagId,
	});
	server.db.tags.update(glTag.id, {
		tagMetadata: {
			...glTag.tagMetadata,
			glCode1: MockGLCodes.debitCodeId,
			glCode2: MockGLCodes.generalCodeId,
			displayGlCodes: {
				glCode1: MockGLCodes.debitCodeId,
				glCode1Name: '2000',
				glCode1Description: 'Debit Code',
				glCode2: MockGLCodes.generalCodeId,
				glCode2Name: '1000',
				glCode2Description: 'General',
			},
		},
	});

	const debitTrxns: TransactionDbData[] = server.db.transactions.where({
		type: 'DEBIT',
	});
	debitTrxns.forEach((trxn: TransactionDbData) => {
		server.db.transactions.update(trxn.id, {
			tags: [...trxn.tags, { tagId: MockGLTagIds.debitGlTagId }],
			glTag: MockGLTagIds.debitGlTagId,
		});
	});

	const transactions: TransactionDbData[] = server.db.transactions;
	const shuffledTransactions: TransactionDbData[] = shuffleArray(transactions);

	shuffledTransactions.splice(0, 10).forEach((trxn: TransactionDbData) => {
		server.db.transactions.update(trxn.id, {
			description: (trxn.description += 'Check ' + randNumber({ min: 111111, max: 999999 })),
			tags: [...trxn.tags, { tagId: MockGLTagIds.glTagId }],
			glTag: MockGLTagIds.glTagId,
		});
	});
	shuffledTransactions.splice(0, 10).forEach((trxn: TransactionDbData) => {
		server.db.transactions.update(trxn.id, {
			description: (trxn.description += ' BENEFITO Trxn'),
			tags: [...trxn.tags, { tagId: MockGLTagIds.payrollAutoGlTagId }],
			glTag: MockGLTagIds.payrollAutoGlTagId,
		});
	});
}

function attachTransactionTags(server: Server<AppRegistry>) {
	const transactions: TransactionDbData[] = server.db.transactions;
	const accounts: AccountTargetV3[] = server.db.accounts;
	const shuffledTransactions: TransactionDbData[] = shuffleArray(transactions);
	const manualAccts: AccountTargetV3[] = Array.from(server.db.accounts.where({ institutionId: MockInstitutionIds.instManual }));
	// transaction tags
	const autopayCurrencies: string[] = ['GBP', 'USD'];
	const autopayStartDate: DateTime = DateTime.now().startOf('day').minus({ days: 21 });
	const autopayEndDate: DateTime = DateTime.now().startOf('day').minus({ days: 7 });
	const autopayTag: TagDbData = server.create('tag', {
		tagTitle: 'Autopay Tag',
		tagDescription: 'Autopay transactions',
		tagId: MockTransactionTagIds.autopay,
		id: MockTransactionTagIds.autopay,
		version: TagVersion.v3,
		tagMetadata: Object.assign(new TagMetadata(), {
			tql: {
				type: 'AST',
				expression: AutopayCurrencyWithDateRange(autopayCurrencies, autopayStartDate.toFormat('yyyy-MM-dd'), autopayEndDate.toFormat('yyyy-MM-dd')),
			},
		}),
	});
	const autopayTransactionIds = shuffledTransactions.splice(0, 20).map((transaction: TransactionDbData) => transaction.transactionId);

	const nonIntradayAccounts: string[] = shuffleArray(accounts)
		.splice(0, 2)
		.map((account: AccountTargetV3) => account.accountId);
	const intradayTag: TagDbData = server.create('tag', {
		tagTitle: 'Intraday Transactions',
		tagDescription: 'Intraday transactions',
		tagId: MockTransactionTagIds.intraday,
		id: MockTransactionTagIds.intraday,
		version: TagVersion.v3,
		tagMetadata: Object.assign(new TagMetadata(), {
			tql: {
				type: 'AST',
				expression: NotAccountAndIntraday(nonIntradayAccounts),
			},
		}),
	});
	const intradayTransactions: string[] = shuffleArray(transactions)
		.filter((transaction: TransactionDbData) => nonIntradayAccounts.indexOf(transaction.account.accountId) < 0)
		.splice(0, 20)
		.map((transaction: TransactionDbData) => transaction.transactionId);

	const manualSmallTag: TagDbData = server.create('tag', {
		tagTitle: 'Manual Large Debt Transactions',
		tagDescription: '',
		tagId: MockTransactionTagIds.manualLarge,
		id: MockTransactionTagIds.manualLarge,
		version: TagVersion.v3,
		tagMetadata: Object.assign(new TagMetadata(), {
			tql: { type: 'AST', expression: ManualAndMoreThan200 },
		}),
	});
	const manualSmallTransactions: string[] = [
		...shuffleArray(
			transactions.filter(
				(transaction: TransactionDbData) => manualAccts.findIndex((acct: AccountTargetV3) => acct.accountId === transaction.account.accountId) >= 0
			)
		).splice(0, 20),
	].map((transaction: TransactionDbData) => transaction.transactionId);

	const moneyOrderDebitsTag: TagDbData = server.create('tag', {
		tagTitle: 'Money Order Debits',
		tagDescription: 'Debit money order transactions',
		tagId: MockTransactionTagIds.moneyOrderDebits,
		id: MockTransactionTagIds.moneyOrderDebits,
		version: TagVersion.v3,
		tagMetadata: Object.assign(new TagMetadata(), {
			tql: {
				type: 'AST',
				expression: MoneyOrderForRegionOrInst([MockRegionIds.southwest], [MockInstitutionIds.instFour]),
			},
		}),
	});
	const moneyOrderDebitAccts: string[] = accounts
		.filter((account: AccountTargetV3) => account.regionGroupingId === MockRegionIds.southwest || account.institutionId === MockInstitutionIds.instFour)
		.map((acct: AccountTargetV3) => acct.accountId);
	const moneyOrderDebitTransactions: string[] = [
		...shuffleArray(transactions)
			.filter((transaction: TransactionDbData) => moneyOrderDebitAccts.indexOf(transaction.account.accountId) >= 0)
			.splice(0, 20)
			.map((transaction: TransactionDbData) => transaction.transactionId),
	];

	const parentTag: TagDbData = server.create('tag', {
		tagTitle: 'Automated Cash Flow',
		tagDescription: 'Autopay and intraday transactions, excluding large manual transactions',
		tagId: MockTransactionTagIds.parent,
		id: MockTransactionTagIds.parent,
		version: TagVersion.v3,
		tagMetadata: Object.assign(new TagMetadata(), {
			tql: {
				type: 'AST',
				expression: TagParentWithExclude([MockTransactionTagIds.intraday, MockTransactionTagIds.autopay], [MockTransactionTagIds.manualLarge]),
			},
		}),
	});
	transactions.forEach((transaction: TransactionDbData) => {
		const transactionAttributes: Partial<TransactionDbData> = {
			tags: [...transaction.tags],
		};
		const isAutopayTransaction: boolean = autopayTransactionIds.indexOf(transaction.transactionId) >= 0;
		const isIntradayTransaction: boolean = intradayTransactions.indexOf(transaction.transactionId) >= 0;
		const isManualSmallTransaction: boolean = manualSmallTransactions.indexOf(transaction.transactionId) >= 0;
		const isMoneyOrderDebitsTransaction: boolean = moneyOrderDebitTransactions.indexOf(transaction.transactionId) >= 0;
		if (isAutopayTransaction) {
			transactionAttributes.tags = [...transactionAttributes.tags, autopayTag];
			transactionAttributes.description = (transactionAttributes.description ? transactionAttributes.description + ' ' : '') + randCompanyName() + ' autopay';
			transactionAttributes.currency = rand(autopayCurrencies);
			transactionAttributes.date = randBetweenDate({
				from: autopayStartDate.toJSDate(),
				to: autopayEndDate.minus({ days: 7 }).toJSDate(),
			});
		}
		if (isIntradayTransaction) {
			transactionAttributes.tags = [...transactionAttributes.tags, intradayTag];
		}
		if (isManualSmallTransaction) {
			transactionAttributes.tags = [...transactionAttributes.tags, manualSmallTag];
			transactionAttributes.descriptionDetail = randCompanyName() + ' debt';
			transactionAttributes.amount = rand([randAmount({ max: -200, min: -10000 }), randAmount({ min: -200 })]);
			transactionAttributes.amountConverted = rand([randAmount({ max: -200, min: -10000 }), randAmount({ min: -200 })]);
			transactionAttributes.type = transactionAttributes.amount < 0 ? 'DEBIT' : 'CREDIT';
		}
		if (isMoneyOrderDebitsTransaction) {
			transactionAttributes.tags = [...transactionAttributes.tags, moneyOrderDebitsTag];
			transactionAttributes.amount = randAmount({ max: -200, min: -10000 });
			transactionAttributes.amountConverted = randAmount({
				max: -200,
				min: -10000,
			});
			transactionAttributes.type = transactionAttributes.amount < 0 ? 'DEBIT' : 'CREDIT';
			transactionAttributes.metadata = Object.assign(new Metadata(), {
				description: 'Money Order',
				typeCode: randNumber({ min: 111, max: 999 }),
			});
		}

		if ((isIntradayTransaction || isAutopayTransaction) && !isManualSmallTransaction) {
			transactionAttributes.tags.push(parentTag);
		}
		if (isAutopayTransaction || isIntradayTransaction || isManualSmallTransaction || isMoneyOrderDebitsTransaction) {
			server.db.transactions.update(transaction.id, transactionAttributes);
		}
	});
}
