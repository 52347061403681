import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxsModule, NGXS_PLUGINS, Store } from '@ngxs/store';
import { TROVATA_APP_STATES } from './core/models/state.model';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { deserializeState, serializeState } from './core/services/serialization.service';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { logoutPlugin } from './core/store/plugins/logout.plugin';
import { IndexedDBStorageEngine } from './core/store/storage/indexeddb-storage-engine';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { LottieModule } from 'ngx-lottie';
import { AuthenticationModule } from './core/modules/authentication.module';
import { environment } from '@trovata/environments/environment';
import { ENVIRONMENT_TOKEN, STORE_TOKEN } from './core/models/config.model';
import { provideEnvironmentNgxMask } from 'ngx-mask';

function playerFactory() {
	return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}
@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		CommonModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		AuthenticationModule,
		LottieModule.forRoot({ player: playerFactory }),
		NgxsModule.forRoot(TROVATA_APP_STATES, {
			developmentMode: false,
		}),
		NgxsStoragePluginModule.forRoot({
			deserialize: state => deserializeState(state),
			serialize: state => serializeState(state),
		}),
		NgxsReduxDevtoolsPluginModule.forRoot({
			disabled: environment.production ? true : false,
		}),
		MatSnackBarModule,
		MatNativeDateModule,
	],
	providers: [
		{
			provide: ENVIRONMENT_TOKEN,
			useValue: environment,
		},
		{
			provide: STORE_TOKEN,
			useClass: Store,
		},
		{
			provide: NGXS_PLUGINS,
			useValue: logoutPlugin,
			multi: true,
		},
		{
			provide: STORAGE_ENGINE,
			useClass: IndexedDBStorageEngine,
		},
		provideEnvironmentNgxMask(),
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
