export class InitCoreState {
	static readonly type: string = '[Core] InitCoreState';
}

export class SetUATEnvironment {
	static readonly type: string = '[Core] SetUAT';
	constructor(public uatEnvironment: boolean) {}
}

export class SetAppReady {
	static readonly type: string = '[Core] SetAppReady';
	constructor(public appReady: boolean) {}
}

export class InitTrovataAppStates {
	static readonly type: string = '[Core] InitTrovataAppStates';
}

export class ClearCoreState {
	static readonly type: string = '[Core] ClearCoreState';
}

export class ResetCoreState {
	static readonly type: string = '[Core] ResetCoreState';
}

export class ClearTrovataAppStates {
	static readonly type: string = '[Core] ClearTrovataAppStates';
}

export class ResetTrovataAppStates {
	static readonly type: string = '[Core] ResetTrovataAppStates';
}

export class InitPaymentsStates {
	static readonly type: string = '[Core] InitPaymentsStates';
}

export class ClearPaymentsStates {
	static readonly type: string = '[Core] ClearPaymentsStates';
}

export class ResetPaymentsStates {
	static readonly type: string = '[Core] ResetPaymentsStates';
}

export class ClearTrovataFeatureState {
	static readonly type: string = '[Core] ClearTrovataFeatureState';
}

export class InitTrovataFeatureState {
	static readonly type: string = '[Core] InitTrovataFeatureState';
}

export class ResetTrovataFeatureState {
	static readonly type: string = '[Core] ResetTrovataFeatureState';
}
