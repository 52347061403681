import { CashPositionAccount } from 'src/app/features/cash-position/models/cash-position.model';
import { Server } from 'miragejs';
import { chosenCurrencies } from '../helpers.mock';
import { AccountDbData, AppRegistry, InstitutionDbData } from '../types';
import { createAccountTags } from './account-groupings';
import { createForecastsAndStreams } from './forecasts-streams';
import { createInsights } from './insights';
import { createInstitutionsAndAccounts } from './institutions-accounts-trxns';
import { createReports } from './reports';
import { createAndAttachTags } from './tags';
import { createEntitiesAndProps } from './entities';

export function seedDb(server: Server) {
	createInstitutionsAndAccounts(server);
	createAccountTags(server);
	createAndAttachTags(server);
	server.createList('currencyBalance', 20).forEach((currencyBal: InstitutionDbData, i: number) => {
		const currency: string = chosenCurrencies[i];
		server.db.currencyBalances.update(currencyBal.id, { currency });
	});
	server.create('currentCash');
	createReports(server);
	createForecastsAndStreams(server);
	createInsights(server);
	createCashPositions(server);
	createEntitiesAndProps(server);
}

function createCashPositions(server: Server<AppRegistry>) {
	const cashPosition = server.create('cashPosition');
	const accounts: CashPositionAccount[] = server.db.accounts.map((acct: AccountDbData) => ({
		accountId: acct.id,
		closingBalance: 'currentDayClosingLedger',
		openingBalance: 'currentDayOpeningLedger',
	}));
	server.db.cashPositions.update(cashPosition.id, { accounts });
	const cashData = server.create('cashPositionDetail');
	server.db.cashPositionDetails.update(cashData.id, { cashPosition });
}
