import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'trovata-trend-indicator',
	templateUrl: './trend-indicator.component.html',
	styleUrls: ['./trend-indicator.component.scss'],
	host: { class: 'mat-typography' },
})
export class TrendIndicatorComponent implements OnChanges {
	@Input() percentage: string;
	@Input() arrowDirection: string;
	@Input() color: string;

	_iconName: string;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['arrowDirection']) {
			this._iconName = this.mapIconName();
		}
	}

	private mapIconName(): string {
		switch (this.arrowDirection) {
			case 'arrow_downward':
				return 'trending_down';
			case 'arrow_upward':
				return 'trending_up';
			case 'horizontal_rule':
				return 'trending_flat';
			default:
				return '';
		}
	}
}
