export class GetFeaturePermissions {
	static readonly type: string = '[CustomerFeature] GetFeaturePermissions';
}

export class InitCustomerFeatureState {
	static readonly type: string = '[CustomerFeature] InitCustomerFeatureState';
}

export class GetFeatureConfig {
	static readonly type: string = '[CustomerFeature] GetFeatureConfig';
}

export class ClearCustomerFeatureState {
	static readonly type: string = '[CustomerFeature] ClearCustomerFeatureState';
}

export class UpdateFreemiumConnectionStatus {
	static readonly type: string = '[CustomerFeature] UpdateFreemiumConnectionStatus';
	constructor(public status: boolean) {}
}
