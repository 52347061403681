import { PaymentsAccount } from 'src/app/features/payments/models/account.model';
import { Template } from '../../features/payments/models/template.model';
import { Workflow } from 'src/app/features/payments/models/workflow.model';
import { UserGroup } from '../../features/settings/models/user-group.model';

export interface AdminApprovalDialogData {
	// TODO: remove when redesign is complete
	title: string;
	subTitle: string;
	dataToApprove: DataToApprove[];
	changeRequest: ChangeRequest;
}

export interface AdminApprovalDialogDataNew {
	// TODO: rename to AdminApprovalDialogData when redesign is complete
	title: string;
	changeRequestId: string | null;
	changeRequest?: ChangeRequest;
	paymentsRecordType: PaymentsRecordType;
	adminApprovalDecision: AdminApprovalDecision;
	isPaymentInternational?: boolean;
	paymentId?: string;
	stageId?: string;
}

export interface DataToApprove {
	key: string;
	old: string | string[];
	new: string | string[];
}

export interface AdminApproval {
	changeRequestId: string;
	decision: AdminApprovalDecision;
	comment: string;
	otp?: string;
}

export enum AdminApprovalDecision {
	APPROVE = 'APPROVE',
	REJECT = 'REJECT',
	RELEASE = 'RELEASE',
}

export interface ChangeRequest {
	changeRequestId: string;
	changeType: ChangeRequestType;
	createdAt: string;
	createdBy: string;
	decidedAt: string;
	decidedBy: string;
	decisionComment: string | null;
	recordId: string;
	recordType: PaymentsRecordType;
	status: AdminApprovalRecordStatus;
	updatedAt: string;

	diff?: ChangeRequestDiff[];
	id?: number;
}

export interface ChangeRequestHistory {
	changeRequestId: string;
	changeType: ChangeRequestType;
	createdAt: string;
	createdBy: string;
	customerId: string;
	decidedAt: string | null;
	decidedBy: string | null;
	decisionComment: string | null;
	id: number;
	recordId: string;
	recordType: PaymentsRecordType;
	status: AdminApprovalRecordStatus;
	updatedAt: string;
}

export enum ChangeRequestType {
	CREATE = 'CREATE',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
}

export interface ChangeRequestDiff {
	key: string;
	old: string | string[];
	new: string | string[];
}

export enum PaymentsRecordType {
	WORKFLOW = 'workflow',
	TEMPLATE = 'template',
	USER = 'user',
	ACCOUNT = 'account',
	PAYMENT = 'payment',
	BATCH = 'bulk',
}

export interface AdminApprovalRecord {
	changeRequestId: string;
	changeType: ChangeRequestType;
	createdAt: string;
	createdBy: string;
	customerId: string;
	decidedAt: string;
	decidedBy: string;
	decisionComment: string;
	id: number;
	recordId: string;
	recordType: PaymentsRecordType;
	status: AdminApprovalRecordStatus;
	updatedAt: string;
}

export enum AdminApprovalRecordStatus {
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
}

export interface PostAdminApprovalResponse {
	changeRequest: ChangeRequest;
	record?: Template | Workflow | PaymentsAccount;
}

export interface ChangeRequestV2 {
	peerReviewerLimit: number;
	changeRequestId: number;
	creator: ChangeRequestCreator;
	requestType: ChangeRequestTypeV2;
	status: ChangeRequestStatus;
	createdAt: string;
	updatedAt: string;
	decidedAt: string;
	revision: ChangeRequestRevision | null;
	reviewers: ChangeRequestReviewer[];
}

export interface ChangeRequestCreator {
	userId: string;
	firstName: string;
	lastName: string;
}

export enum ChangeRequestTypeV2 {
	create = 'CREATE',
	update = 'UPDATE',
	delete = 'DELETE',
}

export enum ChangeRequestStatus {
	pending = 'pending',
	approved = 'approved',
	rejected = 'rejected',
	canceled = 'canceled',
}

export type ChangeRequestRevision = UserGroup; // Update as more data types are adopted

export interface ChangeRequestReviewer {
	userId: string;
	firstName: string;
	lastName: string;
	actionType: ChangeRequestReviewAction;
	decidedAt: string;
	comment: string;
}

export enum ChangeRequestReviewAction {
	approved = 'approved',
	rejected = 'rejected',
}

export interface ChangeRequestBody {
	action: ChangeRequestReviewAction;
	comment: string;
}
