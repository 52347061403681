import { Subject, Observable, EMPTY } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

export abstract class RequestThrottler {
	requestQueue: Subject<Observable<HttpResponse<Object>>> = new Subject();
	maxConcurrentRequests: number;

	constructor(maxConcurrentRequests: number) {
		this.maxConcurrentRequests = maxConcurrentRequests;
		this.initializeQueue();
	}

	initializeQueue() {
		this.requestQueue.pipe(mergeMap(observable => observable.pipe(catchError(error => EMPTY)), this.maxConcurrentRequests)).subscribe(
			resp => {
				//  nothing
			},
			err => {
				// should fix error
			}
		);
	}

	addRequestToQueue(request) {
		this.requestQueue.next(request);
	}
}
