<div class="row-container">
	<div class="title-col">
		<div class="mat-subtitle-2 title" [matTooltip]="title" matTooltipPosition="above">
			{{ title }}
		</div>
		<div class="mat-caption">{{ subtitle }}</div>
	</div>
	<div class="center">
		<div class="chart" [ngClass]="graphAppearanceClass">
			<trovata-app-highcharts [chartBuilder]="columnChartBuilder"></trovata-app-highcharts>
		</div>
	</div>
	<div class="numbers">
		<trovata-value-with-trend *ngIf="trend" class="trend-data" [value]="value" [valueStyles]="['mat-body-1']" trendPosition="down">
			<trovata-trend-indicator [color]="trend.color" [arrowDirection]="trend.direction" [percentage]="trend.percentage"></trovata-trend-indicator>
		</trovata-value-with-trend>
	</div>
</div>
