export class ConsentPartner {
	partnerId: string;
	partnerLogoUrl: string;
	partnerName: string;
	status: ConsentStatus;
	user: ConsentUser;
	partnerAccessCopy: string;
	partnerDenyCopy: string;
	partnerEmail: string;
	consentDate: string;
}

export enum ConsentStatus {
	Consented = 'consented',
	Declined = 'declined',
	Pending = 'pending',
}

export enum ConsentType {
	Allow = 'allow',
	Deny = 'deny',
}

export class ConsentUser {
	firstName: string;
	lastName: string;
	email: string;
}
