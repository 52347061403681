import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { Tag, TagPayload } from '../../models/tag.model';
import { ClearLastCreatedGlTagId, CreateGlTag, DeleteGlTag, UpdateGlTag } from '../../store/actions/glTags.action';
import { GlTagsState } from '../../store/state/glTags.state';

@Injectable({
	providedIn: 'root',
})
export class GlTagFacadeService {
	@Select(GlTagsState.glTags) glTags$: Observable<Tag[]>;
	@Select(GlTagsState.lastCreatedGlTagId)
	lastCreatedGlTagId$: Observable<string>;

	constructor(private store: Store) {}

	getGlTags(): Promise<Tag[]> {
		return new Promise(async resolve => {
			this.glTags$.subscribe((glTags: Tag[]) => {
				if (glTags) {
					resolve(glTags);
				}
			});
		});
	}

	getGlTag(glTagId: string): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				const glTags: Tag[] = await this.getGlTags();
				const glTag: Tag = glTags.find((tag: Tag) => tag.tagId === glTagId);
				resolve(glTag);
			} catch (error) {
				reject(error);
			}
		});
	}

	createGlTag(glTagPayload: TagPayload): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new CreateGlTag(glTagPayload)));
				let lastCreatedGlTagId: string;
				this.lastCreatedGlTagId$.subscribe((id: string) => (lastCreatedGlTagId = id));
				const newGlTag: Tag = await this.getGlTag(lastCreatedGlTagId);
				const newGlTagCopy: Tag = JSON.parse(JSON.stringify(newGlTag));
				this.store.dispatch(new ClearLastCreatedGlTagId());
				resolve(newGlTagCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	updateGlTag(glTag: Tag, glTagPayload: TagPayload): Promise<Tag> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new UpdateGlTag(glTag, glTagPayload)));
				const updatedGlTag: Tag = await this.getGlTag(glTag.tagId);
				const updatedGlTagCopy: Tag = JSON.parse(JSON.stringify(updatedGlTag));
				resolve(updatedGlTagCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	deleteGlTag(glTag: Tag): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new DeleteGlTag(glTag)));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}
}
