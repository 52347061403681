import { GetAuditLogsRequestBody } from '../../models/audit-log.model';

export class InitAuditLogsState {
	static readonly type: string = '[AuditLogs] InitAuditLogsState';
}

export class ClearAuditLogsState {
	static readonly type: string = '[AuditLogs] ClearAuditLogsState';
}

export class ResetAuditLogsState {
	static readonly type: string = '[AuditLogs] ResetAuditLogsState';
}

export class GetPaginatedAuditLogs {
	static readonly type: string = '[AuditLogs] GetPaginatedAuditLogs';
	constructor(public body: GetAuditLogsRequestBody) {}
}

export class ResetCachedAuditLogs {
	static readonly type: string = '[AuditLogs] ResetCachedAuditLogs';
}

export class GetAuditLogSearchFilters {
	static readonly type: string = '[AuditLogs] GetAuditLogSearchFilters';
}
