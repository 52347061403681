import { AccountGrouping } from 'src/app/features/settings/models/account-grouping.model';
import { Server, Request } from 'miragejs';
import { AppSchema, AccountGroupingDbData } from '../types';

export function addAccountGroupingEdgeHandlers(server: Server) {
	server.get('/accountGroupings', (schema: AppSchema, request: Request): AccountGrouping[] => {
		const queryParams: Record<string, string> = request.queryParams;
		const groupings: AccountGroupingDbData[] = schema.db.accountGroupings.where({ type: queryParams.type });
		return groupings;
	});
}
