import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/services/http.service';
import { Observable } from 'rxjs';
import { GetWorkflowsResponse, Workflow, WorkflowToCreateOrUpdate, WorkflowToDelete } from '../../models/workflow.model';

@Injectable({
	providedIn: 'root',
})
export class WorkflowsService {
	constructor(private httpService: HttpService) {}

	getWorkflows(): Observable<GetWorkflowsResponse> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/v1/workflows`);
	}

	createWorkflow(body: WorkflowToCreateOrUpdate): Observable<Workflow> {
		return this.httpService.post(`${environment.workspaceAPI('payments')}/v1/workflows`, body);
	}

	updateWorkflow(body: WorkflowToCreateOrUpdate): Observable<Workflow> {
		return this.httpService.patch(`${environment.workspaceAPI('payments')}/v1/workflows`, body);
	}

	deleteWorkflow(body: WorkflowToDelete): Observable<Workflow> {
		return this.httpService.delete(`${environment.workspaceAPI('payments')}/workflow`, { body: body });
	}

	getWorkflowById(workflowId: string): Observable<GetWorkflowsResponse> {
		return this.httpService.get(`${environment.workspaceAPI('payments')}/v1/workflows?workflowId=${workflowId}`);
	}
}
