import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Institution } from '../../models/institution.model';
import { InstitutionsState, InstitutionsStateModel } from '../../store/state/institutions/institutions.state';
import { firstValidValueFrom } from '../../utils/firstValidValueFrom';
import { CreateManualInstitution, UpdateCustomInstitution, UpdateManualInstitution } from '../../store/actions/institutions.actions';
import { TrovataAppState } from 'src/app/core/models/state.model';

@Injectable({
	providedIn: 'root',
})
export class InstitutionFacadeService {
	@Select(InstitutionsState.institutions)
	institutions$: Observable<InstitutionsStateModel>;
	@Select(InstitutionsState.customerInstitutions)
	customerInstitutions$: Observable<Institution[]>;
	@Select(InstitutionsState.manualInstitutions) manualInstitutions$: Observable<Institution[]>;
	@Select(InstitutionsState.lastCreatedInstitutionId)
	lastCreatedInstitutionId$: Observable<string>;

	constructor(private store: Store) {}

	getInstitutionById(institutionId: string): Promise<Institution> {
		return new Promise<Institution>(async (resolve, reject) => {
			try {
				const institutions: Institution[] = await this.getInstitutions();
				resolve(institutions.find((institution: Institution) => institution.institutionId === institutionId));
			} catch (error) {
				reject(error);
			}
		});
	}

	createInstitution(institutionName: string): Promise<Institution> {
		return new Promise<Institution>(async (resolve, reject) => {
			try {
				const snapshotInstitutions: Institution[] = [...this.store.selectSnapshot((appState: TrovataAppState) => appState.institutions.manualInstitutions)];
				await this.store.dispatch(new CreateManualInstitution(institutionName));
				const getInterval = setInterval(async () => {
					const institutions: Institution[] = await firstValidValueFrom(this.manualInstitutions$);
					const newInstitution: Institution = institutions.find(
						(institution: Institution) =>
							!snapshotInstitutions.find((findInstitution: Institution) => institution.institutionId === findInstitution.institutionId) &&
							institution.institutionName === institutionName
					);
					if (newInstitution) {
						clearInterval(getInterval);
						clearInterval(failureInterval);
						resolve(newInstitution);
					}
				}, 1000);
				const failureInterval = setTimeout(() => {
					clearInterval(failureInterval);
					clearInterval(getInterval);
					reject();
				}, 20000);
			} catch (error) {
				reject(error);
			}
		});
	}

	updateInstitution(institution: Institution, institutionNickname: string): Promise<Institution> {
		return new Promise<Institution>(async (resolve, reject) => {
			try {
				if (institution.dataProviderId === 'MANUAL') {
					await this.store.dispatch(new UpdateManualInstitution(institution, institutionNickname));
				} else {
					await this.store.dispatch(new UpdateCustomInstitution(institution, institutionNickname));
				}
				resolve(await this.getInstitutionById(institution.institutionId));
			} catch (error) {
				reject(error);
			}
		});
	}

	getInstitutionDict(): Promise<Map<string, string>> {
		return new Promise(async (resolve, reject) => {
			try {
				const institutionDict: Map<string, string> = new Map<string, string>();
				const institutions: Institution[] = await this.getInstitutions();
				institutions.forEach((institution: Institution) => {
					if (!institutionDict[institution.institutionId]) {
						institutionDict[institution.institutionId] = institution.institutionNickname;
					}
				});
				resolve(institutionDict);
			} catch (error) {
				reject(error);
			}
		});
	}

	private getInstitutions(): Promise<Institution[]> {
		return new Promise((resolve, reject) => {
			try {
				Promise.all([firstValidValueFrom(this.manualInstitutions$), firstValidValueFrom(this.customerInstitutions$)]).then(
					([manualInst, customerInst]: [Institution[], Institution[]]) => resolve([...customerInst, ...manualInst])
				);
			} catch {
				reject();
			}
		});
	}
}
