import { randNumber } from '@ngneat/falso';
import { InsightFamily, InsightFamilyType, InsightGroupChangeItem, InsightsGroupRowView } from 'src/app/features/insights/models/insights.model';
import { DateTime } from 'luxon';
import { Server } from 'miragejs';
import { SnackSize } from '../../models/snacks.model';
import { insightDisplayData, insightGroupChangeItem, insightSeries } from '../helpers.mock';
import { AccountDbData, AppRegistry } from '../types';

export function createInsights(server: Server<AppRegistry>) {
	server.create('insight', {
		title: 'Rent Transactions',
		description: 'tag',
		size: SnackSize.small,
		family: InsightFamily.number,
		familyType: InsightFamilyType.tags,
		metadata: {
			actionData: [],
			displayData: insightDisplayData(),
		},
	});

	const changeItems: InsightGroupChangeItem[] = [];
	const changeNames: string[] = ['Wire [AP]', 'ACH [AP]', 'ZBA / Sweep'];
	for (let i = 0; i < 3; i++) {
		changeItems.push(
			insightGroupChangeItem({
				groupPercentage: `${randNumber({ min: 10, max: 90 })}%`,
				groupDisplay: changeNames[i],
				groupGraphData: { series: insightSeries(1, 'bar'), colors: [] },
				groupValue: null,
				groupColorClass: null,
			})
		);
	}
	server.create('insight', {
		description: '4/01/2023 to 4/11/2023 - Transactions categorized by A.I.',
		family: InsightFamily.statistic,
		title: 'Current Credit Transactions by Category',
		familyType: InsightFamilyType.categoryCurrentCredit,
		size: SnackSize.large,
		metadata: {
			actionData: [],
			displayData: insightDisplayData({
				groupChanges: changeItems,
				snackGroupChanges: changeItems,
				dateRange: '4/01/2023 to 4/12/2023',
			}),
		},
	});

	const acctNames: string[] = [];
	server.db.accounts.forEach((acct: AccountDbData, i) => {
		if (i < 3) {
			acctNames.push(acct.nickname);
		}
	});
	const changes = acctNames.map((name: string) => insightGroupChangeItem({ groupDisplay: name }, { series: insightSeries(31, 'line') }));
	server.create('insight', {
		title: 'Balances by account',
		description: 'Check out the rest of your balances',
		size: SnackSize.large,
		family: InsightFamily.statistic,
		familyType: InsightFamilyType.direction,
		metadata: {
			actionData: [],
			displayData: insightDisplayData({
				sizeV2: 'xlarge',
				view: InsightsGroupRowView.LineChartTrendRow,
				groupChanges: changes,
				snackGroupChanges: changes,
				dateRange: DateTime.now().minus({ day: 1 }).toFormat('MM/dd/yy'),
			}),
		},
	});
}
