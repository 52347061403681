import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
	ClearInstitutionsState,
	DeleteManualInsitution,
	GetManualInstitutions,
	InitInstitutionsState,
	CreateManualInstitution,
	ResetInstitutionsState,
	GetCustomerInstitutions,
	GetTrovataInstitutions,
	GetTrovataNonPlaidInstitutions,
	GetPremiumInstitutions,
	UpdateManualInstitution,
	UpdateCustomInstitution,
} from '../../actions/institutions.actions';
import { InstitutionService } from 'src/app/features/connections/services/institution.service';
import { ConnectionType, Institution, InstitutionApiGetResponse, InstitutionCreateResponse } from '../../../models/institution.model';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { UpdateFilter } from '../../actions/filter-object.actions';
import { TransactionFilterType } from '../../../models/transaction-filters.model';
import { TQLPropertyKey } from '@trovata/app/shared/models/tql.model';
import { GetValues } from '../../actions/tql-fields.actions';

export class InstitutionsStateModel {
	customerInstitutions: Institution[];
	customerInstApiInFlight: boolean;
	customerInstIsError: boolean;
	trovataInstitutions: Institution[];
	trovataInstApiInFlight: boolean;
	trovataInstIsError: boolean;
	lastCreatedInstitutionId: string;
	manualInstitutions: Institution[];
	manualApiInFlight: boolean;
	manualIsError: boolean;
	premiumInstitutions: Institution[];
	premiumInstApiInFlight: boolean;
	premiumInstIsError: boolean;
	trovataNonPlaidInstitutions: Institution[];
	trovataNonPlaidInstApiInFlight: boolean;
	trovataNonPlaidInstIsError: boolean;
}

@State<InstitutionsStateModel>({
	name: 'institutions',
	defaults: {
		customerInstitutions: null,
		customerInstApiInFlight: null,
		customerInstIsError: null,
		trovataInstitutions: null,
		trovataInstApiInFlight: null,
		trovataInstIsError: null,
		lastCreatedInstitutionId: null,
		manualInstitutions: null,
		manualApiInFlight: null,
		manualIsError: null,
		premiumInstitutions: null,
		premiumInstApiInFlight: null,
		premiumInstIsError: null,
		trovataNonPlaidInstitutions: null,
		trovataNonPlaidInstApiInFlight: null,
		trovataNonPlaidInstIsError: null,
	},
})
@Injectable()
export class InstitutionsState {
	@Selector()
	static institutions(state: InstitutionsStateModel): InstitutionsStateModel {
		return state;
	}

	@Selector()
	static customerInstitutions(state: InstitutionsStateModel): Institution[] {
		return state.customerInstApiInFlight ? undefined : state.customerInstitutions;
	}

	@Selector()
	static manualInstitutions(state: InstitutionsStateModel): Institution[] {
		return state.manualApiInFlight ? undefined : state.manualInstitutions;
	}

	@Selector()
	static premiumInstitutions(state: InstitutionsStateModel): Institution[] {
		return state.premiumInstApiInFlight ? undefined : state.premiumInstitutions;
	}

	@Selector()
	static lastCreatedInstitutionId(state: InstitutionsStateModel): string {
		return state.lastCreatedInstitutionId;
	}

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private institutionService: InstitutionService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Action(InitInstitutionsState)
	async initInstitutionState(context: StateContext<InstitutionsStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const institutionStateIsCached: boolean = this.institutionStateIsCached(deserializedState);
			this.appReadySub = this.appReady$.subscribe({
				next: (appReady: boolean) => {
					if (institutionStateIsCached && appReady) {
						const state: InstitutionsStateModel = deserializedState.institutions;
						context.patchState(state);
					} else if (!institutionStateIsCached && appReady) {
						context.dispatch(new GetCustomerInstitutions());
						context.dispatch(new GetManualInstitutions());
						context.dispatch(new GetTrovataInstitutions());
						context.dispatch(new GetTrovataNonPlaidInstitutions());
						context.dispatch(new GetPremiumInstitutions());
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error) {
			throwError(() => error);
		}
	}

	@Action(GetCustomerInstitutions)
	getCustomerInstitutions(context: StateContext<InstitutionsStateModel>): Observable<HttpResponse<InstitutionApiGetResponse>> {
		let state: InstitutionsStateModel = context.getState();
		state.customerInstApiInFlight = true;
		state.customerInstIsError = false;
		context.patchState(state);
		return this.institutionService.getCustomerInstitutions().pipe(
			tap((response: HttpResponse<InstitutionApiGetResponse>) => {
				state = context.getState();
				state.customerInstitutions = response.body.institutions;
				state.customerInstApiInFlight = false;
				context.patchState(state);
			}),
			catchError(error => {
				state = context.getState();
				state.customerInstApiInFlight = false;
				state.customerInstIsError = true;
				context.patchState(state);
				return throwError(error);
			})
		);
	}

	@Action(GetTrovataInstitutions)
	getTrovataInstitutions(context: StateContext<InstitutionsStateModel>): Observable<HttpResponse<InstitutionApiGetResponse>> {
		let state: InstitutionsStateModel = context.getState();
		state.trovataInstApiInFlight = true;
		state.trovataInstIsError = false;
		context.patchState(state);
		return this.institutionService.getTrovataInstitutions().pipe(
			tap((response: HttpResponse<InstitutionApiGetResponse>) => {
				state = context.getState();
				state.trovataInstitutions = response.body.institutions;
				state.trovataInstApiInFlight = false;
				context.patchState(state);
			}),
			catchError(error => {
				state = context.getState();
				state.trovataInstApiInFlight = false;
				state.trovataInstIsError = true;
				context.patchState(state);
				return throwError(error);
			})
		);
	}

	@Action(GetTrovataNonPlaidInstitutions)
	getTrovataNonPlaidInstitutions(context: StateContext<InstitutionsStateModel>): Observable<HttpResponse<InstitutionApiGetResponse>> {
		context.patchState({
			trovataNonPlaidInstApiInFlight: true,
			trovataNonPlaidInstIsError: false,
		});
		const connectionTypes: ConnectionType[] = [ConnectionType.BAI, ConnectionType.DIRECT, ConnectionType.OFX, ConnectionType.SFTP];
		return this.institutionService.getTrovataInstitutions(connectionTypes).pipe(
			tap((response: HttpResponse<InstitutionApiGetResponse>) => {
				context.patchState({
					trovataNonPlaidInstitutions: response.body.institutions,
					trovataNonPlaidInstApiInFlight: false,
					trovataNonPlaidInstIsError: false,
				});
			}),
			catchError(error => {
				context.patchState({
					trovataNonPlaidInstApiInFlight: false,
					trovataNonPlaidInstIsError: true,
				});
				return throwError(error);
			})
		);
	}

	@Action(GetManualInstitutions)
	getManualInstitutions(context: StateContext<InstitutionsStateModel>): Observable<HttpResponse<InstitutionApiGetResponse>> {
		context.patchState({ manualApiInFlight: true, manualIsError: false });
		// getManualInstitution needs to be updated to get in bulk in case user has more than 10000 manual institutions
		return this.institutionService.getManualInstitutions(0, 10000).pipe(
			tap((response: HttpResponse<InstitutionApiGetResponse>) => {
				context.patchState({
					manualInstitutions: response.body.institutions,
					manualApiInFlight: false,
					manualIsError: false,
				});
			}),
			catchError(error => {
				context.patchState({ manualApiInFlight: false, manualIsError: true });
				return throwError(error);
			})
		);
	}

	@Action(GetPremiumInstitutions)
	getPremiumInstitutions(context: StateContext<InstitutionsStateModel>): Observable<HttpResponse<InstitutionApiGetResponse>> {
		context.patchState({
			premiumInstApiInFlight: true,
			premiumInstIsError: false,
		});
		return this.institutionService.getPremiumConnections().pipe(
			tap((response: HttpResponse<InstitutionApiGetResponse>) => {
				context.patchState({
					premiumInstitutions: response.body.institutions,
					premiumInstApiInFlight: false,
					premiumInstIsError: false,
				});
			}),
			catchError(error => {
				context.patchState({
					premiumInstApiInFlight: false,
					premiumInstIsError: true,
				});
				return throwError(error);
			})
		);
	}

	@Action(DeleteManualInsitution)
	deleteManualInstitution(context: StateContext<InstitutionsStateModel>, action: DeleteManualInsitution): Observable<HttpResponse<void>> {
		return this.institutionService.deleteManualInstitution(action.id).pipe(
			tap(() => {
				context.dispatch(new GetManualInstitutions());
				setTimeout(() => {
					this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.accounts));
					this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.institutions));
					this.store.dispatch(new GetValues([TQLPropertyKey.account, TQLPropertyKey.institution]));
				}, 3000);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(CreateManualInstitution)
	createManualInsitution(context: StateContext<InstitutionsStateModel>, action: CreateManualInstitution): Observable<HttpResponse<InstitutionCreateResponse>> {
		return this.institutionService.postManualInstitution(action.name).pipe(
			tap(async (instResp: HttpResponse<InstitutionCreateResponse>) => {
				context.patchState({
					lastCreatedInstitutionId: instResp.body.resourceId,
				});
				await context.dispatch(new GetManualInstitutions());
				setTimeout(() => {
					this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.accounts));
					this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.institutions));
					this.store.dispatch(new GetValues([TQLPropertyKey.account, TQLPropertyKey.institution]));
				}, 3000);
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(UpdateManualInstitution)
	updateManualInstitution(context: StateContext<InstitutionsStateModel>, action: UpdateManualInstitution): Observable<HttpResponse<void>> {
		return this.institutionService.updateManualInstitution(action.name, action.institution.institutionId).pipe(
			tap(() => {
				const state: InstitutionsStateModel = context.getState();
				if (state.manualInstitutions) {
					const index: number = state.manualInstitutions.findIndex(
						(manualInstitution: Institution) => manualInstitution.institutionId === action.institution.institutionId
					);
					state.manualInstitutions[index].institutionName = action.name;
					state.manualInstitutions[index].institutionNickname = action.name;
					context.patchState({
						manualInstitutions: state.manualInstitutions,
					});
				} else {
					context.dispatch(new GetManualInstitutions());
				}
				this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.accounts));
				this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.institutions));
				this.store.dispatch(new GetValues([TQLPropertyKey.account, TQLPropertyKey.institution]));
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(UpdateCustomInstitution)
	updateCustomInstitution(context: StateContext<InstitutionsStateModel>, action: UpdateCustomInstitution): Observable<HttpResponse<void>> {
		return this.institutionService.updateCustomInstitution(action.name, action.institution.institutionId).pipe(
			tap(() => {
				const state: InstitutionsStateModel = context.getState();
				if (state.customerInstitutions) {
					const index: number = state.customerInstitutions.findIndex(
						(institution: Institution) => institution.institutionId === action.institution.institutionId
					);
					state.customerInstitutions[index].institutionNickname = action.name;
					context.patchState({
						customerInstitutions: state.customerInstitutions,
					});
				} else {
					context.dispatch(new GetCustomerInstitutions());
				}

				this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.accounts));
				this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.institutions));
				this.store.dispatch(new GetValues([TQLPropertyKey.account, TQLPropertyKey.institution]));
			}),
			catchError(error => throwError(() => error))
		);
	}

	@Action(ResetInstitutionsState)
	resetInstitutionState(context: StateContext<InstitutionsStateModel>): void {
		context.dispatch(new ClearInstitutionsState());
		setTimeout(() => {
			context.dispatch(new GetCustomerInstitutions());
			context.dispatch(new GetManualInstitutions());
			context.dispatch(new GetTrovataInstitutions());
			context.dispatch(new GetTrovataNonPlaidInstitutions());
		});
	}

	@Action(ClearInstitutionsState)
	clearInstitutionsState(context: StateContext<InstitutionsStateModel>): void {
		this.appReadySub.unsubscribe();
		const state: InstitutionsStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private institutionStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedInstitutionState: InstitutionsStateModel | undefined = deserializedState.institutions;
		if (
			deserializedInstitutionState &&
			deserializedInstitutionState.customerInstitutions &&
			deserializedInstitutionState.trovataInstitutions &&
			deserializedInstitutionState.premiumInstitutions
		) {
			return true;
		} else {
			return false;
		}
	}
}
