import {
	CashPosition,
	CashPositionData,
	CashPositionVersion,
	CashPositionVersionOverview,
	CashPositionVersionPostBody,
} from '../../models/cash-position.model';

export class InitCashPositionState {
	static readonly type: string = '[CashPosition] InitCashPositionState';
}

export class ClearCashPositionState {
	static readonly type: string = '[CashPosition] ClearCashPositionState';
}

export class ResetCashPositionState {
	static readonly type: string = '[CashPosition] ResetCashPositionState';
}

export class CreateCashPosition {
	static readonly type: string = '[CashPosition] CreateCashPosition';
	constructor(public cashPosition: CashPosition) {}
}
export class GetCashPositions {
	static readonly type: string = '[CashPosition] GetCashPositions';
}

export class DeleteCashPosition {
	static readonly type: string = '[CashPosition] DeleteCashPosition';
	constructor(public cashPositioningId: string) {}
}
export class UpdateCashPosition {
	static readonly type: string = '[CashPosition] UpdateCashPosition';
	constructor(public cashPosition: CashPosition) {}
}

export class AddDataToCashPosition {
	static readonly type: string = '[CashPosition] AddDataToCashPosition';
	constructor(
		public cashPosition: CashPosition,
		public cashPositionData?: CashPositionData
	) {}
}

export class AddVersionsToCashPosition {
	static readonly type: string = '[CashPosition] AddVersionsToCashPosition';
	constructor(
		public cashPosition: CashPosition,
		public cashPositionVersions?: CashPositionVersion[]
	) {}
}

export class AddLatestVersionToCashPosition {
	static readonly type: string = '[CashPosition] AddLatestVersionToCashPosition';
	constructor(
		public cashPosition: CashPosition,
		public versionData?: CashPositionData
	) {}
}

export class AddVersionOverviewsToCashPosition {
	static readonly type: string = '[CashPosition] AddVersionOverviewsToCashPosition';
	constructor(
		public cashPosition: CashPosition,
		public versionOverviews?: CashPositionVersionOverview[]
	) {}
}

export class SaveNewCashPositionVersion {
	static readonly type: string = '[CashPosition] SaveNewCashPositionVersion';
	constructor(
		public cashPosition: CashPosition,
		public cashPositionVersionPostBody: CashPositionVersionPostBody
	) {}
}

export class LazyLoadCashPositionData {
	static readonly type: string = '[CashPosition] LazyLoadCashPositionData';
	constructor(public cashPositioningId: string) {}
}

export class LazyLoadVersionList {
	static readonly type: string = '[CashPosition] LazyLoadVersionList';
	constructor(public cashPositioningId: string) {}
}

export class LazyLoadVersionOverviews {
	static readonly type: string = '[CashPosition] LazyLoadVersionOverviews';
	constructor(public cashPositioningId: string) {}
}
