import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'roundNumber',
})
export class RoundNumber implements PipeTransform {
	transform(value: number, rounding: number): any {
		if (rounding && Math.abs(value) >= rounding) {
			if (rounding === 1) {
				return value.toFixed();
			}
			const roundedNumber = parseFloat((value / rounding).toFixed(2));
			return roundedNumber;
		} else if (rounding && Math.abs(value) < rounding) {
			const decimalNumber = parseFloat((value / rounding).toFixed(2));
			return decimalNumber;
		} else {
			return value;
		}
	}
}
