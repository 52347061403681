import { CreateCustomerUserBody, EditCustomerUserBody } from '../../models/identity.model';

export class InitCustomerUsersState {
	static readonly type: string = '[customerUsers] InitIdentityState';
}

export class GetCustomerUsers {
	static readonly type: string = '[customerUsers] GetCustomerUsers';
}

export class CreateCustomerUser {
	static readonly type: string = '[customerUsers] AddNewUserForCustomer';
	constructor(public addNewUserForCustomerBody: CreateCustomerUserBody) {}
}

export class EditCustomerUser {
	static readonly type: string = '[customerUsers] EditUserForCustomer';
	constructor(
		public userId: string,
		public editUserForCustomerBody: EditCustomerUserBody
	) {}
}

export class DeleteCustomerUser {
	static readonly type: string = '[customerUsers] DeleteUserFromCustomer';
	constructor(public userId: string) {}
}

export class ClearCustomerUsersState {
	static readonly type: string = '[customerUsers] ClearIdentityState';
}

export class ResetCustomerUsersState {
	static readonly type: string = '[customerUsers] ResetIdentityState';
}
