import { randNumber } from '@ngneat/falso';
import { Server } from 'miragejs';
import { AccountDbData, AppRegistry, InstitutionDbData } from '../types';

export enum MockInstitutionIds {
	instOne = 'instoneuuid',
	instTwo = 'insttwouuid',
	instThree = 'instthreeuuid',
	instFour = 'instfouruuid',
	instFive = 'instfiveuuid',
	instManual = 'manualinstuuid',
}

export function createInstitutionsAndAccounts(server: Server) {
	const instOne: InstitutionDbData = server.create('institution', {
		institutionName: 'Medhurst - Cremin',
		institutionId: MockInstitutionIds.instOne,
		id: MockInstitutionIds.instOne,
	});
	createAccounts(server, instOne);
	const instTwo: InstitutionDbData = server.create('institution', {
		institutionName: 'Koss LLC',
		institutionId: MockInstitutionIds.instTwo,
		id: MockInstitutionIds.instTwo,
	});
	createAccounts(server, instTwo);
	const instThree: InstitutionDbData = server.create('institution', {
		institutionName: 'Gold Silver',
		institutionId: MockInstitutionIds.instThree,
		id: MockInstitutionIds.instThree,
	});
	createAccounts(server, instThree);
	const instFour: InstitutionDbData = server.create('institution', {
		institutionName: 'Larkenshields',
		institutionId: MockInstitutionIds.instFour,
		id: MockInstitutionIds.instFour,
	});
	createAccounts(server, instFour);
	const instFive: InstitutionDbData = server.create('institution', {
		institutionName: 'Malatawa and Sons',
		institutionId: MockInstitutionIds.instFive,
		id: MockInstitutionIds.instFive,
	});
	createAccounts(server, instFive);
	const manualInst: InstitutionDbData = server.create('institution', {
		institutionName: 'WIS Bank - Manual',
		institutionId: MockInstitutionIds.instManual,
		id: MockInstitutionIds.instManual,
		dataProviderId: 'MANUAL',
	});
	createAccounts(server, manualInst, true);
}

function createAccounts(server: Server<AppRegistry>, institution: InstitutionDbData, manual?: boolean) {
	server
		.createList('account', randNumber({ min: 1, max: 3 }), {
			institution,
			institutionId: institution.attrs.institutionId,
			institutionName: institution.attrs.institutionName,
		})
		.forEach((account: AccountDbData) => {
			server.db.accounts.update(account.id, {
				accountId: account.id,
				dataProviderId: manual ? 'MANUAL' : '',
				isManual: !!manual,
			});
			server.createList('transaction', 10, {
				dbAccount: account,
				currency: account.currency,
				dataProviderId: manual ? 'MANUAL' : '',
				account: {
					accountId: account.accountId,
					accountNumber: account.accountNumber,
					nickname: account.nickname,
					name: account.name,
					institutionId: account.institutionId,
					accountType: null,
					currency: account.currency,
					displayableType: null,
					institutionName: account.institutionName,
					positionType: account.positionType,
				},
			});
		});
}
