import { Cadence } from '../../features/reports/models/cadence.model';

export enum GroupByKeyLegacy {
	account = 'accountId',
	institution = 'institutionId',
	currency = 'currency',
	entityGroupingId = 'entityGroupingId',
	regionGroupingId = 'regionGroupingId',
	divisionGroupingId = 'divisionGroupingId',
	tag = 'tags',
}

export enum GroupByKey {
	account = 'account',
	institution = 'institution',
	currency = 'currency',
	tag = 'tag',
	accountGroupA = 'accountGroupA',
	accountGroupB = 'accountGroupB',
	accountGroupC = 'accountGroupC',
	entityId = 'entityId',
	entityRegion = 'entityRegion',
	entityDivision = 'entityDivision',
}

export enum FilterKey {
	accountId = 'accountId',
	institutionId = 'institutionId',
	currency = 'currency',
	entity = 'entity',
	region = 'region',
	division = 'division',
	tag = 'tag',
}

export function getMomentKeyFromCadence(cadence: Cadence): MomentKey {
	switch (cadence) {
		case Cadence.daily:
			return MomentKey.days;
		case Cadence.weekly:
			return MomentKey.weeks;
		case Cadence.monthly:
			return MomentKey.months;
		case Cadence.quarterly:
			return MomentKey.quarters;
	}
}

export enum MomentKey {
	days = 'days',
	weeks = 'weeks',
	months = 'months',
	quarters = 'quarters',
	years = 'years',
}
