import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionType, UpdatedEvent } from '../models/updated-events.model';
import { PreferencesFacadeService } from './facade/preferences.facade.service';

@Injectable({
	providedIn: 'root',
})
export class UpdatedEventsService {
	updatedItem$: Subject<UpdatedEvent>;

	constructor(private preferencesFacadeService: PreferencesFacadeService) {
		this.updatedItem$ = new Subject<UpdatedEvent>();
	}

	async updateItem(updatedEvent: UpdatedEvent) {
		if (updatedEvent.actionType === ActionType.delete) {
			await this.preferencesFacadeService.removeDeletedItemPrefs(updatedEvent);
		}
		this.updatedItem$.next(updatedEvent);
	}
}
