export class GenericOptionValue {
	option: GenericOption;
	group: GenericOptionGroup;
	selected?: boolean;
}

export class GenericOption<T = string> {
	displayValue: string;
	key?: T;
	id: T;
	disabled?: boolean;
	disabledText?: string;
}

export class GenericOptionGroup {
	type: string;
	displayType: string;
	options: GenericOption[];
}
