import { ParameterType } from './search-parameter.model';
import { Institution } from './institution.model';
import { AccountTagType, Tag } from 'src/app/features/transactions/models/tag.model';
import { Account } from 'src/app/features/transactions/models/account.model';
import { GLCode } from 'src/app/features/transactions/models/gl-code.model';
import { GenericOption, GenericOptionGroup } from './option.model';
import { PaymentType } from '../../features/payments/models/template.model';
import { PaymentStatus } from '../../features/payments/models/workflow.model';
import { Entity } from '@trovata/app/features/entities/models/entity.model';

export abstract class Filter extends GenericOptionGroup {
	container: string;
	abstract setFilter(key, options);
}

export type FilterMap = Map<string, Filter>;

export class TransactionFilter extends Filter {
	constructor(key, options) {
		super();
		this.options = [];
		this.setFilter(key, options);
	}

	setFilter(key, options) {
		if (key === 'institutions') {
			this.container = 'transaction';
			this.type = ParameterType.institutionId;
			this.displayType = 'Banks';
			const institutionOptions: Institution[] = options;
			institutionOptions.forEach(inst => {
				const option: FilterOption = {
					displayValue: inst.institutionNickname,
					key: inst.institutionId,
					id: inst.institutionId,
				};
				this.options.push(option);
			});
		}
		if (key === 'accounts') {
			this.container = 'transaction';
			this.type = ParameterType.accountId;
			this.displayType = 'Accounts';
			const institutionOptions: Account[] = options;
			institutionOptions.forEach(acc => {
				const option: FilterOption = {
					displayValue: acc.nickname ? `${acc.accountNumber} - ${acc.nickname}` : acc.accountNumber,
					key: acc.accountId,
					id: acc.accountId,
				};
				this.options.push(option);
			});
		}

		if (key === 'currencies') {
			this.container = 'transaction';
			this.type = ParameterType.currency;
			this.displayType = 'Currencies';
			const currencyOptions: string[] = options;
			currencyOptions.forEach(cur => {
				const option: FilterOption = {
					displayValue: cur,
					key: cur,
					id: cur,
				};
				this.options.push(option);
			});
		}

		if (key === 'type') {
			this.container = 'transaction';
			this.type = ParameterType.type;
			this.displayType = 'Credit/Debit';
			this.options = [
				{
					displayValue: 'Credit',
					key: 'credit',
					id: 'CREDIT',
				},
				{
					displayValue: 'Debit',
					key: 'debit',
					id: 'DEBIT',
				},
			];
		}

		if (key === 'glTags') {
			this.container = 'glTag';
			this.type = ParameterType.glTagId;
			this.displayType = 'G/L Tags';
			const tagOptions: Tag[] = options;
			tagOptions.forEach(tag => {
				const option: FilterOption = {
					displayValue: tag.tagTitle,
					key: tag.tagId,
					id: tag.tagId,
				};
				this.options.push(option);
			});
		}

		if (key === 'glCode1') {
			this.container = 'glTag';
			this.type = ParameterType.debitGLCode;
			this.displayType = 'Debit G/L Code';
			const codeOptions: GLCode[] = options;
			codeOptions.forEach(code => {
				const option: FilterOption = {
					displayValue: code.name,
					key: code.codeId,
					id: code.codeId,
				};
				this.options.push(option);
			});
		}

		if (key === 'glCode2') {
			this.container = 'glTag';
			this.type = ParameterType.creditGLCode;
			this.displayType = 'Credit G/L Code';
			const codeOptions: GLCode[] = options;
			codeOptions.forEach(code => {
				const option: FilterOption = {
					displayValue: code.name,
					key: code.codeId,
					id: code.codeId,
				};
				this.options.push(option);
			});
		}
	}
}

export class InvoiceFilter extends Filter {
	constructor(key, options) {
		super();
		this.options = [];
		this.setFilter(key, options);
	}
	container = 'invoice';

	setFilter(key, options) {
		if (key === 'companies') {
			this.type = ParameterType.dealCompanyId;
			this.displayType = 'Customer / Vendor';
			const companyOptions: Company[] = options;
			companyOptions.forEach(company => {
				const option: FilterOption = {
					displayValue: company.dealCompany,
					key: company.dealCompanyId,
					id: company.dealCompanyId,
				};
				this.options.push(option);
			});
		}
		if (key === 'invoiceType') {
			this.type = ParameterType.invoiceType;
			this.displayType = 'Invoice Type';
			this.options = [
				{ displayValue: 'Accounts Payable', key: 'ap', id: 'AP' },
				{ displayValue: 'Accounts Receivable', key: 'ar', id: 'AR' },
			];
		}

		if (key === 'invoiceProvider') {
			this.type = ParameterType.invoiceProvider;
			this.displayType = 'Invoice Provider';
			this.options = [
				{ displayValue: 'Netsuite', key: 'netsuite', id: 'netsuite' },
				{ displayValue: 'Oracle', key: 'oracle', id: 'oracle' },
				{ displayValue: 'Sage', key: 'sage', id: 'sage' },
				{ displayValue: 'SAP', key: 'sap', id: 'sap' },
			];
		}
	}
}

export class GenericFilter extends Filter {
	container: string = 'tag';

	constructor(key, options) {
		super();
		this.options = [];
		this.setFilter(key, options);
	}

	setFilter(key: string, options): void {
		if (key === 'tags') {
			this.type = ParameterType.tag;
			this.displayType = 'Tags';
			const tagOptions: Tag[] = options;
			tagOptions.forEach(tag => {
				const option: FilterOption = {
					displayValue: tag.tagTitle,
					key: tag.tagId,
					id: tag.tagId,
				};
				this.options.push(option);
			});
		}
		if (key === ParameterType.excludeTags) {
			this.type = ParameterType.excludeTags;
			this.displayType = 'Exclude Tags';
			const tagOptions: Tag[] = options;
			tagOptions.forEach(tag => {
				const option: FilterOption = {
					displayValue: tag.tagTitle,
					key: tag.tagId,
					id: tag.tagId,
				};
				this.options.push(option);
			});
		}
	}
}

export class AccountFilter extends Filter {
	container = 'account';

	constructor(key, options) {
		super();
		this.options = [];
		this.setFilter(key, options);
	}

	setFilter(key, options) {
		if (key === ParameterType.entity) {
			this.type = ParameterType.entity;
			this.displayType = AccountTagType.entity;
			const accountTags: Tag[] = options;
			accountTags.forEach(tag => {
				if (tag.tagType === 'ENTITY') {
					const option: FilterOption = {
						displayValue: tag.tagTitle,
						key: tag.tagId,
						id: tag.tagId,
					};
					this.options.push(option);
				}
			});
		}
		if (key === ParameterType.region) {
			this.type = ParameterType.region;
			this.displayType = AccountTagType.region;
			const accountTags: Tag[] = options;
			accountTags.forEach(tag => {
				if (tag.tagType === 'REGION') {
					const option: FilterOption = {
						displayValue: tag.tagTitle,
						key: tag.tagId,
						id: tag.tagId,
					};
					this.options.push(option);
				}
			});
		}
		if (key === ParameterType.division) {
			this.type = ParameterType.division;
			this.displayType = AccountTagType.division;
			const accountTags: Tag[] = options;
			accountTags.forEach(tag => {
				if (tag.tagType === 'DIVISION') {
					const option: FilterOption = {
						displayValue: tag.tagTitle,
						key: tag.tagId,
						id: tag.tagId,
					};
					this.options.push(option);
				}
			});
		}
		if (key === ParameterType.entityId) {
			this.type = ParameterType.entityId;
			this.displayType = 'Entity Alias';
			const entities: Entity[] = options;
			entities.forEach((entity: Entity) => {
				const option: FilterOption = {
					displayValue: entity.nickname || entity.name,
					key: entity.entityId,
					id: entity.entityId,
				};
				this.options.push(option);
			});
		}
		if (key === ParameterType.entityDivision) {
			this.type = ParameterType.entityDivision;
			this.displayType = 'Entity Division';
			const entities: Entity[] = options;
			entities.forEach((entity: Entity) => {
				if (entity.division && !this.options.some((option: GenericOption) => option.key === entity.division)) {
					const option: FilterOption = {
						displayValue: entity.division,
						key: entity.division,
						id: entity.division,
					};
					this.options.push(option);
				}
			});
		}
		if (key === ParameterType.entityRegion) {
			this.type = ParameterType.entityRegion;
			this.displayType = 'Entity Region';
			const entities: Entity[] = options;
			entities.forEach((entity: Entity) => {
				if (entity.region && !this.options.some((option: GenericOption) => option.key === entity.region)) {
					const option: FilterOption = {
						displayValue: entity.region,
						key: entity.region,
						id: entity.region,
					};
					this.options.push(option);
				}
			});
		}
	}
}

export class PaymentFilter extends Filter {
	constructor(key: string) {
		super();
		this.options = [];
		this.setFilter(key);
	}

	options: FilterOption[] = [];

	setFilter(key: string) {
		if (key === 'paymentType') {
			this.type = ParameterType.PAYMENT_TYPE;
			this.displayType = 'Payment Types';
			this.options = [
				{
					displayValue: 'RTP',
					key: PaymentType.RTP,
					id: PaymentType.RTP,
				},
				{
					displayValue: 'ACH',
					key: PaymentType.ACH,
					id: PaymentType.ACH,
				},
				{
					displayValue: 'Transfer',
					key: PaymentType.TRANSFER,
					id: PaymentType.TRANSFER,
				},
				{
					displayValue: 'Wire',
					key: PaymentType.WIRE,
					id: PaymentType.WIRE,
				},
			];
		}
		if (key === 'paymentStatus') {
			this.type = ParameterType.PAYMENT_STATUS;
			this.displayType = 'Payment Statuses';
			this.options = [
				{
					displayValue: 'Open',
					key: PaymentStatus.OPEN,
					id: PaymentStatus.OPEN,
				},
				{
					displayValue: 'Rejected',
					key: PaymentStatus.REJECTED,
					id: PaymentStatus.REJECTED,
				},
				{
					displayValue: 'Submitted',
					key: PaymentStatus.SUBMITTED,
					id: PaymentStatus.SUBMITTED,
				},
				{
					displayValue: 'Completed',
					key: PaymentStatus.COMPLETED,
					id: PaymentStatus.COMPLETED,
				},
			];
		}
	}
}

export class ManualFilter extends Filter {
	container = 'manual';
	constructor() {
		super();
		this.setFilter(null, null);
		this.type = ParameterType.isManual;
		this.displayType = 'Manual';
	}

	setFilter(key: any, options: any) {
		this.options = [
			{
				displayValue: 'Manual Accounts Only',
				key: 'isManual',
				id: 'isManual',
			},
		];
	}
}

export class CustomFilters {
	[type: string]: {
		label: string;
		items: { label: string; value: string }[];
		selectMultiple: boolean;
		initialValue: any;
	};
}

export class FilterOption extends GenericOption {}

export class InvoiceFilterOptions {
	companies: Company[];
	invoiceProvider: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	sum_other_doc_count: number;
	totalInvoices: number;
}

export class Company {
	dealCompanyId: string;
	dealCompany: string;
}

export enum QueryType {
	transaction = 'transaction',
	invoice = 'invoice',
	invoiceAP = 'invoiceAP',
	invoiceAR = 'invoiceAR',
	account = 'account',
	custom = 'custom',
}
