import { rand } from '@ngneat/falso';
import { TagType } from '@trovata/app/features/transactions/models/tag.model';
import { Server } from 'miragejs';
import { AppRegistry, AccountGroupingDbData, AccountDbData } from '../types';

export enum MockEntityIds {
	sales = 'salesentityuuid',
}

export enum MockRegionIds {
	midwest = 'midwestregionuuid',
	southwest = 'southwestregionuuid',
}

export enum MockDivisionIds {
	southwest = 'southwestdivisionuuid',
	distribution = 'divisiondivisionuuid',
}

export function createAccountTags(server: Server<AppRegistry>) {
	const divisionOne: AccountGroupingDbData = server.create('accountGrouping', {
		name: 'Southwest Divison',
		id: MockDivisionIds.southwest,
		accountGroupingId: MockDivisionIds.southwest,
		type: TagType.division,
	});
	const accountToSetDivision: AccountDbData = rand(server.db.accounts);
	server.db.accounts.update(accountToSetDivision.id, {
		accountGrouping: divisionOne,
		accountGroupingId: MockDivisionIds.southwest,
		divisionGroupingId: MockDivisionIds.southwest,
	});
	const divisionTwo: AccountGroupingDbData = server.create('accountGrouping', {
		name: 'Distribution Divison',
		id: MockDivisionIds.distribution,
		accountGroupingId: MockDivisionIds.distribution,
		type: TagType.division,
	});
	const accountToSetDivision2: AccountDbData = rand(server.db.accounts);
	server.db.accounts.update(accountToSetDivision2.id, {
		accountGrouping: divisionTwo,
		accountGroupingId: MockDivisionIds.distribution,
		divisionGroupingId: MockDivisionIds.distribution,
	});

	const entityOne: AccountGroupingDbData = server.create('accountGrouping', {
		name: 'Sales',
		id: MockEntityIds.sales,
		accountGroupingId: MockEntityIds.sales,
		type: TagType.entity,
	});
	const accountToSetEntity: AccountDbData = rand(server.db.accounts);
	server.db.accounts.update(accountToSetEntity.id, {
		accountGrouping: entityOne,
		accountGroupingId: MockEntityIds.sales,
		entityGroupingId: MockEntityIds.sales,
	});

	const regionOne: AccountGroupingDbData = server.create('accountGrouping', {
		name: 'Midwest Region',
		id: MockRegionIds.midwest,
		accountGroupingId: MockRegionIds.midwest,
		type: TagType.region,
	});
	const accountToSetRegion: AccountDbData = rand(server.db.accounts);
	server.db.accounts.update(accountToSetRegion.id, {
		accountGrouping: regionOne,
		accountGroupingId: MockRegionIds.midwest,
		regionGroupingId: MockRegionIds.midwest,
	});
	const regionTwo: AccountGroupingDbData = server.create('accountGrouping', {
		name: 'Southwest',
		id: MockRegionIds.southwest,
		accountGroupingId: MockRegionIds.southwest,
		type: TagType.region,
	});
	const accountToSetRegion2: AccountDbData = rand(server.db.accounts);
	server.db.accounts.update(accountToSetRegion2.id, {
		accountGrouping: regionTwo,
		accountGroupingId: MockRegionIds.southwest,
		regionGroupingId: MockRegionIds.southwest,
	});
}
