import { Server } from 'miragejs';
import { AppSchema } from '../types';
import { Entity, GetEntitiesResponse, GetEntityExtraPropertyResponse, GetEntityTreeResponse } from '@trovata/app/features/entities/models/entity.model';

export function addEntitiesWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post(
		'/data/entities-list',
		(schema: AppSchema): GetEntitiesResponse => ({ entities: [...schema.db.entities], totalEntities: schema.db.entities.length })
	);

	server.get('/data/entities/tree', (schema: AppSchema): GetEntityTreeResponse => schema.db.getEntityTreeResponses[0]);

	server.get(
		'/data/entities/extra-properties',
		(schema: AppSchema): GetEntityExtraPropertyResponse => ({ extraProperties: [...schema.db.extraProps], totalExtraProperties: schema.db.extraProps.length })
	);

	server.put('/data/entities', () => {
		unhandledRequestMade();
		return null;
	});

	server.put('/data/entities/:id', () => {
		unhandledRequestMade();
		return null;
	});

	server.delete('/data/entities/:id', () => {
		unhandledRequestMade();
		return null;
	});

	server.patch('/data/entities/extra-properties/:id', () => {
		unhandledRequestMade();
		return null;
	});

	server.post('/data/entities/extra-properties/', () => {
		unhandledRequestMade();
		return null;
	});

	server.delete('/data/entities/extra-properties/:id', () => {
		unhandledRequestMade();
		return null;
	});

	server.post('/data/entities/:id/personnel', () => {
		unhandledRequestMade();
		return null;
	});

	server.put('/data/entities/personnel/:id', () => {
		unhandledRequestMade();
		return null;
	});

	server.delete('/data/entities/personnel/:id', () => {
		unhandledRequestMade();
		return null;
	});
}
