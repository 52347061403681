import { ChangeRequestBody, ChangeRequestV2 } from 'src/app/shared/models/admin-approval.model';
import { UserGroupPostBody } from '../../models/user-group.model';

export class InitEntitlementsState {
	static readonly type: string = '[Entitlements] InitEntitlementsState';
}

export class GetUserGroupsResourceTypes {
	static readonly type: string = '[Entitlements] GetUserGroupsResourceTypes';
}

export class ClearEntitlementsState {
	static readonly type: string = '[Entitlements] ClearEntitlementsState';
}

export class ResetEntitlementsState {
	static readonly type: string = '[Entitlements] ResetEntitlementsState';
}

export class GetUserGroups {
	static readonly type: string = '[Entitlements] GetUserGroups';
}

export class GetUserGroupById {
	static readonly type: string = '[Entitlements] GetUserGroupById';
	constructor(public userGroupId: number) {}
}

export class CreateUserGroup {
	static readonly type: string = '[Entitlements] CreateUserGroup';
	constructor(public postBody: UserGroupPostBody) {}
}

export class RemoveRejectedCreateUserGroup {
	static readonly type: string = '[Entitlements] RemoveRejectedCreateUserGroup';
	constructor(public userGroupId: number) {}
}

export class DeleteUserGroup {
	static readonly type: string = '[Entitlements] DeleteUserGroup';
	constructor(public userGroupId: number) {}
}

export class UpdateUserGroup {
	static readonly type: string = '[Entitlements] UpdateUserGroup';
	constructor(
		public userGroupId: number,
		public body: UserGroupPostBody
	) {}
}

export class ReviewUserGroupChangeRequest {
	static readonly type: string = '[Entitlements] ReviewUserGroupChangeRequest';
	constructor(
		public changeRequest: ChangeRequestV2,
		public body: ChangeRequestBody,
		public userGroupId: number
	) {}
}

export class CancelUserGroupChangeRequest {
	static readonly type: string = '[Entitlements] CancelUserGroupChangeRequest';
	constructor(
		public userGroupId: number,
		public changeRequestId: number
	) {}
}
