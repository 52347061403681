import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@trovata/environments/environment';
import { Observable } from 'rxjs';
import { GetAuditLogSearchFiltersResponse, GetAuditLogsRequestBody, GetAuditLogsResponse } from '../models/audit-log.model';

@Injectable({
	providedIn: 'root',
})
export class AuditLogService {
	private baseApiUrl: string;

	constructor(private httpClient: HttpClient) {
		this.baseApiUrl = `${environment.trovataAPI('workspace')}/data`;
	}

	getPaginatedAuditLogs(body: GetAuditLogsRequestBody): Observable<GetAuditLogsResponse> {
		body = this.checkToRemoveNoDataProperties(body);
		return this.httpClient.post<GetAuditLogsResponse>(`${this.baseApiUrl}/audits/search`, body);
	}

	getAuditLogSearchFilters(): Observable<GetAuditLogSearchFiltersResponse> {
		// TODO: Use one of these GET urls when that endpoint gets fixed
		// return this.httpClient.get<GetAuditLogSearchFiltersResponse>(`${this.baseApiUrl}/filters?filter=audits`);
		// return this.httpClient.get<GetAuditLogSearchFiltersResponse>(`${this.baseApiUrl}/audits/filters`);
		return this.httpClient.post<GetAuditLogSearchFiltersResponse>(`${this.baseApiUrl}/transactions/filters`, { filter: 'audits' });
	}

	getAuditLogsCsv(body: GetAuditLogsRequestBody): Observable<string> {
		return this.httpClient.post<string>(`${this.baseApiUrl}/audits/search/csv`, body);
	}

	private checkToRemoveNoDataProperties(getAuditLogsRequestBody: GetAuditLogsRequestBody): GetAuditLogsRequestBody {
		if ('startTime' in getAuditLogsRequestBody && !getAuditLogsRequestBody.startTime) {
			delete getAuditLogsRequestBody.startTime;
		}
		if ('endTime' in getAuditLogsRequestBody && !getAuditLogsRequestBody.endTime) {
			delete getAuditLogsRequestBody.endTime;
		}
		if (getAuditLogsRequestBody.actors && !getAuditLogsRequestBody.actors.length) {
			delete getAuditLogsRequestBody.actors;
		}
		if (getAuditLogsRequestBody.productCategories && !getAuditLogsRequestBody.productCategories.length) {
			delete getAuditLogsRequestBody.productCategories;
		}
		if (getAuditLogsRequestBody.types && !getAuditLogsRequestBody.types.length) {
			delete getAuditLogsRequestBody.types;
		}
		if (getAuditLogsRequestBody.objectIds && !getAuditLogsRequestBody.objectIds.length) {
			delete getAuditLogsRequestBody.objectIds;
		}
		return getAuditLogsRequestBody;
	}
}
