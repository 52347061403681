import { MinusSignToParens } from '../pipes/minus-sign-to-parens.pipe';
import { RoundNumber } from '../pipes/round-number.pipe';
import { Currency, CurrencyDict } from 'src/app/shared/models/currency.model';
import { RoundingOption } from '@trovata/app/shared/models/rounding.model';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ICellTemplateContext, ICellTemplateFunction } from '@grapecity/wijmo.grid';
import { DateTime } from 'luxon';

export class Formatter {
	maxStringSize: number = 0;

	formatableCellZero: string = ' $0 ';
	excelAccountingCellZero: string = ' $- '; // in Excel if the cell type is Accounting then 0.00 is represented as "$ -  " which changeType doesn't handle
	sheetsAccountingCellZero: string = ' $ - '; // Sheets adds a space. Cool.
	regexAccountingCellZero: RegExp = new RegExp([this.excelAccountingCellZero, this.sheetsAccountingCellZero].join('|').replace(/[.*+?^$-]/g, '\\$&'), 'g');

	formatValue(
		value: number | string,
		currency?: Currency,
		roundingOption?: RoundingOption,
		axisFormatter?: boolean,
		noDecimals?: boolean,
		noSymbol?: boolean,
		addCommas?: boolean,
		noAxisRounding?: boolean,
		removeRoundingMod?: string,
		keepNegativeSign?: boolean
	): string {
		if (typeof value === 'string' && !isNaN(+value)) {
			value = +value;
		} else if (typeof value === 'string') {
			value = null;
		}
		let displayValue: string;
		let roundingMod = '';
		if (value === null) {
			displayValue = 'N/A';
		} else {
			if (roundingOption) {
				value = new RoundNumber().transform(+value, roundingOption.value);
			}
			if (axisFormatter) {
				const absValue = Math.abs(+value);
				if (absValue / 1000000000000 >= 1) {
					displayValue = (+value / 1000000000000).toString();
					roundingMod = 'T';
				} else if (absValue / 1000000000 >= 1) {
					displayValue = (+value / 1000000000).toString();
					roundingMod = 'B';
				} else if (absValue / 1000000 >= 1) {
					displayValue = (+value / 1000000).toString();
					roundingMod = 'M';
				} else if (absValue / 1000 >= 1) {
					displayValue = (+value / 1000).toString();
					roundingMod = 'K';
				} else {
					displayValue = value.toString();
				}
			} else {
				displayValue = value.toString();
			}
			if (currency) {
				displayValue = new CurrencyPipe('en').transform(
					displayValue,
					currency.code,
					noSymbol ? '' : currency.symbol,
					roundingOption?.value === 1 ? '1.0-0' : axisFormatter ? '1.0-2' : '1.' + currency.decimal_digits + '-' + currency.decimal_digits
				);
			}

			if ((noDecimals || (axisFormatter && noAxisRounding)) && displayValue.includes('.')) {
				displayValue = displayValue.substring(0, displayValue.lastIndexOf('.'));
			}

			if (!keepNegativeSign) {
				displayValue = new MinusSignToParens().transform(displayValue);
			}

			if (axisFormatter && roundingMod && !removeRoundingMod) {
				if (+value < 0) {
					displayValue = [displayValue.slice(0, displayValue.length - 1), roundingMod, displayValue.slice(displayValue.length - 1)].join('');
				} else {
					displayValue = [displayValue, roundingMod].join('');
				}
			}
			if (roundingOption) {
				displayValue = (displayValue + roundingOption.symbol).replace(')' + roundingOption.symbol, roundingOption.symbol + ')');
			}
		}

		if (addCommas) {
			displayValue = displayValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
		if (displayValue.length > this.maxStringSize) {
			this.maxStringSize = displayValue.length;
		}
		return displayValue;
	}

	currencyWijmoCellTemplate: (currencyDict: CurrencyDict, placeholder?: string, roundingOption?: RoundingOption) => ICellTemplateFunction =
		(currencyDict: CurrencyDict, placeholder?: string, roundingOption?: RoundingOption) => (ctx: ICellTemplateContext) => {
			const binding = ctx.col.binding;
			let displayValue = ctx.item[binding];
			if (typeof displayValue === 'string') {
				displayValue = displayValue.replace('(', '').replace(')', '').replace(/,/g, '');
			}
			if (currencyDict && !isNaN(+displayValue)) {
				if (roundingOption) {
					displayValue = new RoundNumber().transform(+displayValue, roundingOption.value);
				}
				let currency: Currency;
				if (binding.toLocaleLowerCase().indexOf('converted') >= 0) {
					currency = typeof ctx.item.convertedCurrency === 'string' ? currencyDict[ctx.item.convertedCurrency] : ctx.item.convertedCurrency;
				} else {
					currency = typeof ctx.item.currency === 'string' ? currencyDict[ctx.item.currency] : ctx.item.currency;
				}
				if (currency) {
					displayValue = new CurrencyPipe('en').transform(
						+displayValue || 0,
						currency.code,
						currency.symbol,
						'1.' + currency.decimal_digits + '-' + currency.decimal_digits
					);
					displayValue = new MinusSignToParens().transform(displayValue);
				}
				if (roundingOption) {
					displayValue = (displayValue + roundingOption.symbol).replace(')' + roundingOption.symbol, roundingOption.symbol + ')');
				}
			} else if (!displayValue && placeholder) {
				return placeholder;
			} else {
				return ctx.item[binding] || '';
			}
			return displayValue || '';
		};

	currencyWijmoCellFormat(value: number, currency: Currency): string {
		let displayValue: string = value?.toString();
		if (value !== null && value !== undefined && currency) {
			displayValue = new DecimalPipe('en').transform(displayValue, '1.' + currency.decimal_digits + '-' + currency.decimal_digits);
		}
		return displayValue;
	}

	matchesAccountCells(value: string) {
		return this.regexAccountingCellZero.test(value);
	}

	formatAccountCells(value: string) {
		return value.replace(this.regexAccountingCellZero, this.formatableCellZero);
	}

	// If the date string contains T00:00:00Z then the string is in UTC time then the action occurred at the
	// very specific last millisecond of the day.  This is extremely unlikely and it's probably safe to
	// assume the data just didn't contain the time
	TruncateInvalidTimestamps(dateString: string): string {
		const parsed: DateTime = DateTime.fromISO(dateString, { zone: 'utc' });
		return parsed.toLocaleString(dateString.includes('T00:00:00Z') ? DateTime.DATE_SHORT : DateTime.DATETIME_SHORT);
	}
}
