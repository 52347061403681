import { Currency, CurrencyDict } from 'src/app/shared/models/currency.model';
import { Filter } from 'src/app/shared/models/abstract-filter.model';
import { ReconciliationReport, ReconData, ReconDataGroup, ReconDataItem } from './reconciliation.model';
import { Cadence } from './cadence.model';
import { Formatter } from 'src/app/shared/utils/formatter';
import { TitleCasePipe } from '@angular/common';
import moment from 'moment';
import { Snack, SnackSize, SnackType } from 'src/app/shared/models/snacks.model';
import { SnackDataItem } from './report.model';

export class ReconSnackAltDataItem {
	date: string;
	currency: Currency;
	threshold: number;
	displayChangeInBalance: string;
	displayNetTransactions: string;
	delta: number;
	percentage: number;
	private reconciliationCheck: boolean;
	reconCheckIconString: string;
	reconCheckColorString: string;
	private formatter: Formatter = new Formatter();

	constructor(reconReport: ReconciliationReport, reconDataGroup: ReconDataGroup, currencyDict: CurrencyDict) {
		this.date = moment(reconDataGroup.periodData[0]?.date).format('M/DD/YY');
		const currencyCode = reconDataGroup.groupValue;
		this.currency = currencyDict[currencyCode];
		this.threshold = (reconReport.currencyThresholds || {})[currencyCode] || 0;
		this.displayChangeInBalance = this.formatter.formatValue(reconDataGroup.periodData[0].changeInBalance, this.currency);
		this.displayNetTransactions = this.formatter.formatValue(reconDataGroup.periodData[0].netTransactionActivity, this.currency);
		this.delta = reconDataGroup.periodData[0].delta;
		if (reconDataGroup.periodData[0].changeInBalance === null || reconDataGroup.periodData[0].netTransactionActivity === null) {
			this.percentage = 100;
		} else {
			if (this.threshold) {
				this.percentage = (Math.abs(this.delta) / this.threshold) * 100;
			} else {
				this.percentage = Math.abs(this.delta) ? 100 : 0;
			}
		}
		this.reconciliationCheck = this.percentage < 100;
		this.reconCheckIconString = this.reconciliationCheck ? 'done' : 'close';
		this.reconCheckColorString = this.reconciliationCheck ? 'reconciliation-check-pass' : 'reconciliation-check-fail';
	}
}

export class ReconciliationSnack extends Snack {
	name: string;
	title: string;
	cadence: Cadence;
	displayCadence: string;
	currencyConverted: Currency;
	locale: string;
	subTitle: string;
	dataItems: SnackDataItem[] = [];
	type: SnackType.recon = SnackType.recon;

	reconReport: ReconciliationReport;

	hasPeriodData: boolean;
	hasDailyData: boolean;
	hasData: boolean;
	noValidData: boolean;

	private date: string;
	private filterObject: Map<string, Filter>;
	private formatter: Formatter = new Formatter();

	public altDataItems: ReconSnackAltDataItem[] = [];

	constructor(
		reconReport: ReconciliationReport,
		private currencyDict: CurrencyDict,
		locale: string,
		filterObject: Map<string, Filter>,
		flipped?: boolean
	) {
		super(SnackSize.large, SnackType.recon, reconReport.reconId, flipped);
		this.reconReport = reconReport;
		this.name = reconReport.name;
		this.filterObject = filterObject;
		this.locale = locale;
		this.setCadence();
	}

	set periodViewData(data: ReconData) {
		if (data?.reconData?.aggregation?.length) {
			this.setDate(data);
			this.setTitle();
			this.setSubtitle(data);
			this.setCurrencyConverted(data);
			this.resetDataItems();
			this.setBalanceItem(data);
			this.setTransactionItem(data);
			this.hasPeriodData = true;
		}
		this.setHasData();
	}

	set dailyViewData(data: ReconData) {
		if (data?.reconData?.aggregation?.length) {
			this.setAltDataItems(data);
			this.hasDailyData = true;
		}
		this.setHasData();
	}

	private setHasData() {
		if (this.hasPeriodData && this.hasDailyData) {
			this.hasData = true;
			this.noValidData =
				!this.reconReport.periodData.reconData?.aggregation?.length || !this.reconReport.dailyData.reconData?.aggregation[0]?.periodData?.length;
		}
	}

	private setCadence() {
		this.cadence = this.reconReport.cadence;
	}

	private setTitle() {
		this.displayCadence = new TitleCasePipe().transform(this.cadence);
		this.title = ['Reconcile', this.displayCadence, this.date].join(' - ');
	}

	private setSubtitle(periodData: ReconData) {
		this.subTitle = periodData.reconData.aggregation[0]?.reconAggregation
			?.map((reconDataGroup: ReconDataGroup) => this.getGroupDisplayValue(reconDataGroup.groupType, reconDataGroup.groupValue))
			.filter(groupValue => groupValue)
			.join(', ');
	}

	private getGroupDisplayValue(groupType: string, groupId: string) {
		const fixedType: string = groupType === 'tags' ? 'tag' : groupType.replace('GroupingId', '');
		const filter = this.filterObject[fixedType]?.options?.find(flt => flt.id === groupId);
		if (filter) {
			return filter.displayValue;
		} else {
			return groupId;
		}
	}

	private setDate(periodData: ReconData) {
		const totalPeriodData: ReconDataItem[] = periodData.reconData.aggregation[0].periodData;
		this.date = moment(totalPeriodData[totalPeriodData.length - 1].date).format('M/DD/YY');
	}

	private setCurrencyConverted(periodData: ReconData) {
		this.currencyConverted = this.currencyDict[periodData.reconData.aggregation[0].groupValue];
	}

	private resetDataItems() {
		this.dataItems = [];
		this.altDataItems = [];
	}

	private setBalanceItem(periodData: ReconData) {
		const itemTitle = 'Change In Balance';
		const totalPeriodData: ReconDataItem[] = periodData.reconData.aggregation[0].periodData;
		const changeInBalance = totalPeriodData[totalPeriodData.length - 1].changeInBalanceConverted;
		const balanceDisplayValue = this.formatter.formatValue(changeInBalance, this.currencyConverted);
		this.dataItems.push(new SnackDataItem(itemTitle, balanceDisplayValue));
	}

	private setTransactionItem(periodData: ReconData) {
		const itemTitle = 'Net Transactions';
		const totalPeriodData: ReconDataItem[] = periodData.reconData.aggregation[0].periodData;
		const netTransactions = totalPeriodData[totalPeriodData.length - 1].netTransactionActivityConverted;
		const transactionDisplayValue = this.formatter.formatValue(netTransactions, this.currencyConverted);
		this.dataItems.push(new SnackDataItem(itemTitle, transactionDisplayValue));
	}

	private setAltDataItems(dailyData: ReconData) {
		dailyData.reconData.aggregation.forEach((currencyGroup: ReconDataGroup) => {
			if (currencyGroup.periodData && currencyGroup.periodData.length) {
				this.altDataItems.push(new ReconSnackAltDataItem(this.reconReport, currencyGroup, this.currencyDict));
			}
		});
	}
}
