import { Tag, TagPayload } from '../../models/tag.model';
import { GLCode } from '../../models/gl-code.model';

export class InitTagsState {
	static readonly type: string = '[Tag] InitTagsState';
}

export class ClearTagsState {
	static readonly type: string = '[Tag] ClearTagsState';
}

export class ResetTagsState {
	static readonly type: string = '[Tag] ResetTagsState';
}

export class GetTags {
	static readonly type: string = '[Tag] GetTags';
	constructor(public refresh?: boolean) {}
}

export class GetGlTags {
	static readonly type: string = '[Tag] GetGlTags';
	constructor() {}
}

export class CreateTag {
	static readonly type: string = '[Tag] CreateTag';
	constructor(public tag: Tag) {}
}

export class AddTagToState {
	static readonly type: string = '[Tag] AddTagToState';
	constructor(public tag: Tag) {}
}

export class AddGlTagToState {
	static readonly type: string = '[Tag] AddGlTagToState';
	constructor(public tag: Tag) {}
}

export class DeleteTag {
	static readonly type: string = '[Tag] DeleteTag';
	constructor(public tag: Tag) {}
}

export class UpdateTag {
	static readonly type: string = '[Tag] UpdateTag';
	constructor(
		public tag: Tag,
		public tagPayload: TagPayload
	) {}
}

export class CreateGlCode {
	static readonly type: string = '[Tag] CreateGlCode';
	constructor(public glCode: GLCode) {}
}
export class DeleteGlCode {
	static readonly type: string = '[Tag] DeleteGlCode';
	constructor(public glCode: GLCode) {}
}
export class UpdateGlCode {
	static readonly type: string = '[Tag] UpdateGlCode';
	constructor(public glCode: GLCode) {}
}

export class ClearLastCreatedTagId {
	static readonly type: string = '[Tag] ClearLastCreatedTagId';
}

export class SetLastCreatedTagId {
	static readonly type: string = '[Tag] SetLastCreatedTagId';
	constructor(public id: string) {}
}
