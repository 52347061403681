import { Request, Server } from 'miragejs';
import { reconData } from '../helpers.mock';
import { AppSchema } from '../types';

export function addReconciliationWorkspaceHandlers(server: Server, unhandledRequestMade: () => void) {
	server.post('/data/reports/recon/data', (schema: AppSchema, request: Request) => {
		const queryParams: Record<string, string> = request.queryParams;
		if (queryParams.groupBy !== 'institutionId') {
			unhandledRequestMade();
		}
		return reconData(schema);
	});

	server.post('/data/reports/recon-list', () => ({ recon: [], total: 0 }));
}
